import { createSelector } from 'reselect';

export const getZonesSelector = (state) => state.zones;
export const getAllZonesDataSelector = (state) => state.zones.data;
export const getZoneByIdSelector = (state, props) => props.match.params.id;

export const isZoneRequestPending = createSelector(
  [getZonesSelector],
  (zones) => zones.isRequestPending
);

export const getAllZones = createSelector(getAllZonesDataSelector, (zones) =>
  Object.values(zones)
);

export const getZoneById = createSelector(
  [getAllZonesDataSelector, getZoneByIdSelector],
  (zones, id) => zones[id]
);
