import React from 'react';
import FormInput from '../FormInput';
import formStyles from '../Styles/FormStyles';
import FormSubmitButton from '../FormSubmitButton';
import { BUTTON_CONSTANTS } from '../../shared/Constants/Constant';
import useForm from '../../shared/Hooks/useForm';
import LoginFormValidator from './LoginFormValidator';

function LoginForm(props) {
  const { onSubmit } = props;
  const classes = formStyles();

  const handleFormSubmit = () => {
    onSubmit({
      user_name: values.user_name,
      password: values.password
    });
  };

  const { values, errors, handleChange, handleSubmit } = useForm(
    handleFormSubmit,
    LoginFormValidator
  );

  return (
    <form className={classes.form} noValidate autoComplete="off">
      <FormInput
        type="text"
        label="Login Username"
        placeholder="Enter the Login Username"
        name="user_name"
        value={values.user_name || ''}
        onChange={handleChange}
        error={!!errors.user_name}
        helperText={errors.user_name || ''}
        required
        autoFocus
      />

      <FormInput
        type="password"
        label="Password"
        placeholder="Enter the password"
        name="password"
        value={values.password || ''}
        onChange={handleChange}
        error={!!errors.password}
        helperText={errors.password || ''}
        required
      />

      <div className={classes.buttonRow}>
        <FormSubmitButton
          onClickSubmit={handleSubmit}
          customClassName="fullWidthButton"
          customButtonText={BUTTON_CONSTANTS.SIGN_IN}
        />
      </div>
    </form>
  );
}

export default React.memo(LoginForm);
