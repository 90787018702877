export const FETCH_SECONDARY_ORDERS_REQUEST = 'FETCH_SECONDARY_ORDERS_REQUEST';
export const FETCH_SECONDARY_ORDER_REQUEST = 'FETCH_SECONDARY_ORDER_REQUEST';
export const CREATE_SECONDARY_ORDER_REQUEST = 'CREATE_SECONDARY_ORDER_REQUEST';
export const UPDATE_SECONDARY_ORDER_REQUEST = 'UPDATE_SECONDARY_ORDER_REQUEST';
export const DELETE_SECONDARY_ORDER_REQUEST = 'DELETE_SECONDARY_ORDER_REQUEST';

export const FETCH_SECONDARY_ORDERS_SUCCESS = 'FETCH_SECONDARY_ORDERS_SUCCESS';
export const FETCH_SECONDARY_ORDER_SUCCESS = 'FETCH_SECONDARY_ORDER_SUCCESS';
export const CREATE_SECONDARY_ORDER_SUCCESS = 'CREATE_SECONDARY_ORDER_SUCCESS';
export const UPDATE_SECONDARY_ORDER_SUCCESS = 'UPDATE_SECONDARY_ORDER_SUCCESS';
export const DELETE_SECONDARY_ORDER_SUCCESS = 'DELETE_SECONDARY_ORDER_SUCCESS';

export const FETCH_SECONDARY_ORDERS_ERROR = 'FETCH_SECONDARY_ORDERS_ERROR';
export const FETCH_SECONDARY_ORDER_ERROR = 'FETCH_SECONDARY_ORDER_ERROR';
export const CREATE_SECONDARY_ORDER_ERROR = 'CREATE_SECONDARY_ORDER_ERROR';
export const UPDATE_SECONDARY_ORDER_ERROR = 'UPDATE_SECONDARY_ORDER_ERROR';
export const DELETE_SECONDARY_ORDER_ERROR = 'DELETE_SECONDARY_ORDER_ERROR';

export const ADD_SECONDARY_ORDER_ITEM = 'ADD_SECONDARY_ORDER_ITEM';
export const REMOVE_SECONDARY_ORDER_ITEM = 'REMOVE_SECONDARY_ORDER_ITEM';
export const CLEAR_SECONDARY_ORDER_ORDER_CART =
  'CLEAR_SECONDARY_ORDER_ORDER_CART';

export const SECONDARY_ORDER_PAGINATION = 'SECONDARY_ORDER_PAGINATION';
export const UPDATE_SECONDARY_ORDER_PAGINATION_PAGE =
  'UPDATE_SECONDARY_ORDER_PAGINATION_PAGE';

export const RESET_SECONDARY_ORDER_DATA = 'RESET_SECONDARY_ORDER_DATA';

export const RESET_SECONDARY_ORDER_ERROR = 'RESET_SECONDARY_ORDER_ERROR';

export const CLEAR_SECONDARY_ORDER_CART = 'CLEAR_SECONDARY_ORDER_CART';

export const LOAD_SECONDARY_ORDER_CUSTOMER = 'LOAD_SECONDARY_ORDER_CUSTOMER';
