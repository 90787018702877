import AreaAPI from '../../apis/AreaAPI';
import * as AreaActionTypes from './area.types';
import STATUS from '../../shared/Constants/apiStatus';
import { ROUTE_TO_AREA } from '../../shared/Constants/RouteConstants';

export const fetchAllAreas = () => async (dispatch) => {
  dispatch({
    type: AreaActionTypes.FETCH_AREAS_REQUEST
  });

  try {
    const response = await AreaAPI.getAllAreas();
    if (response.data.status === STATUS.OK) {
      dispatch({
        type: AreaActionTypes.FETCH_AREAS_SUCCESS,
        payload: response.data.areas,
        error: null,
        route: null,
        success: null
      });
    }
  } catch (error) {
    dispatch({
      type: AreaActionTypes.FETCH_AREAS_ERROR,
      error: error.response.data,
      payload: null,
      route: null,
      success: null
    });
  }
};

export const fetchArea = (id) => async (dispatch) => {
  dispatch({
    type: AreaActionTypes.FETCH_AREA_REQUEST
  });

  try {
    const response = await AreaAPI.getArea(id);
    if (response.data.status === STATUS.OK) {
      dispatch({
        type: AreaActionTypes.FETCH_AREA_SUCCESS,
        payload: response.data.area,
        error: null,
        route: null,
        success: null
      });
    }
  } catch (error) {
    dispatch({
      type: AreaActionTypes.FETCH_AREA_ERROR,
      error: error.response.data,
      payload: null,
      route: ROUTE_TO_AREA,
      success: null
    });
  }
};

export const createArea = (formValues) => async (dispatch) => {
  dispatch({
    type: AreaActionTypes.CREATE_AREA_REQUEST
  });

  try {
    const response = await AreaAPI.createArea(formValues);

    if (response.data.status === STATUS.OK) {
      dispatch({
        type: AreaActionTypes.CREATE_AREA_SUCCESS,
        payload: response.data.area,
        error: null,
        route: ROUTE_TO_AREA,
        success: response.data.message
      });
    }
  } catch (error) {
    dispatch({
      type: AreaActionTypes.CREATE_AREA_ERROR,
      error: error.response.data,
      payload: null,
      route: ROUTE_TO_AREA,
      success: null
    });
  }
};

export const editArea = (id, formValues) => async (dispatch) => {
  dispatch({
    type: AreaActionTypes.UPDATE_AREA_REQUEST
  });

  try {
    const response = await AreaAPI.editArea(id, formValues);
    if (response.data.status === STATUS.OK) {
      dispatch({
        type: AreaActionTypes.UPDATE_AREA_SUCCESS,
        payload: response.data.area,
        error: null,
        route: ROUTE_TO_AREA,
        success: response.data.message
      });
    }
  } catch (error) {
    dispatch({
      type: AreaActionTypes.UPDATE_AREA_ERROR,
      error: error.response.data,
      payload: null,
      route: ROUTE_TO_AREA,
      success: null
    });
  }
};

export const deleteArea = (id) => async (dispatch) => {
  dispatch({
    type: AreaActionTypes.DELETE_AREA_REQUEST
  });

  try {
    const response = await AreaAPI.deleteArea(id);
    if (response.data.status === STATUS.OK) {
      dispatch({
        type: AreaActionTypes.DELETE_AREA_SUCCESS,
        payload: id,
        error: null,
        route: ROUTE_TO_AREA,
        success: response.data.message
      });
    }
  } catch (error) {
    dispatch({
      type: AreaActionTypes.DELETE_AREA_ERROR,
      payload: null,
      error: error.response.data,
      route: ROUTE_TO_AREA,
      success: null
    });
  }
};
