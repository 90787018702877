export const FETCH_PRIMARY_ORDERS_REQUEST = 'FETCH_PRIMARY_ORDERS_REQUEST';
export const FETCH_PRIMARY_ORDER_REQUEST = 'FETCH_PRIMARY_ORDER_REQUEST';
export const CREATE_PRIMARY_ORDER_REQUEST = 'CREATE_PRIMARY_ORDER_REQUEST';
export const UPDATE_PRIMARY_ORDER_REQUEST = 'UPDATE_PRIMARY_ORDER_REQUEST';
export const DELETE_PRIMARY_ORDER_REQUEST = 'DELETE_PRIMARY_ORDER_REQUEST';

export const FETCH_PRIMARY_ORDERS_SUCCESS = 'FETCH_PRIMARY_ORDERS_SUCCESS';
export const FETCH_PRIMARY_ORDER_SUCCESS = 'FETCH_PRIMARY_ORDER_SUCCESS';
export const CREATE_PRIMARY_ORDER_SUCCESS = 'CREATE_PRIMARY_ORDER_SUCCESS';
export const UPDATE_PRIMARY_ORDER_SUCCESS = 'UPDATE_PRIMARY_ORDER_SUCCESS';
export const DELETE_PRIMARY_ORDER_SUCCESS = 'DELETE_PRIMARY_ORDER_SUCCESS';

export const FETCH_PRIMARY_ORDERS_ERROR = 'FETCH_PRIMARY_ORDERS_ERROR';
export const FETCH_PRIMARY_ORDER_ERROR = 'FETCH_PRIMARY_ORDER_ERROR';
export const CREATE_PRIMARY_ORDER_ERROR = 'CREATE_PRIMARY_ORDER_ERROR';
export const UPDATE_PRIMARY_ORDER_ERROR = 'UPDATE_PRIMARY_ORDER_ERROR';
export const DELETE_PRIMARY_ORDER_ERROR = 'DELETE_PRIMARY_ORDER_ERROR';

export const ADD_PRIMARY_ORDER_ITEM = 'ADD_PRIMARY_ORDER_ITEM';
export const REMOVE_PRIMARY_ORDER_ITEM = 'REMOVE_PRIMARY_ORDER_ITEM';
export const CLEAR_PRIMARY_ORDER_ORDER_CART = 'CLEAR_PRIMARY_ORDER_ORDER_CART';

export const PRIMARY_ORDER_PAGINATION = 'PRIMARY_ORDER_PAGINATION';
export const UPDATE_PRIMARY_ORDER_PAGINATION_PAGE =
  'UPDATE_PRIMARY_ORDER_PAGINATION_PAGE';

export const RESET_PRIMARY_ORDER_DATA = 'RESET_PRIMARY_ORDER_DATA';

export const RESET_PRIMARY_ORDER_ERROR = 'RESET_PRIMARY_ORDER_ERROR';

export const CLEAR_PRIMARY_ORDER_CART = 'CLEAR_PRIMARY_ORDER_CART';

export const LOAD_PRIMARY_ORDER_CUSTOMER = 'LOAD_PRIMARY_ORDER_CUSTOMER';
