import React, { useState } from 'react';
import { connect } from 'react-redux';
import {
  fetchAllSecondaryOrders,
  deleteSecondaryOrder
} from '../../../redux/secondary-order/secondary-order.actions';
import {
  isSecondaryOrderRequestPending,
  getAllSecondaryOrders,
  getSecondaryOrderById
} from '../../../redux/secondary-order/secondary-order.selector';
import useFetchReducer from '../../../shared/Hooks/useFetchReducer';
import Modal from '../../../components/Modal';
import history from '../../../routes/history';
import { TITLES } from '../../../shared/Constants/Constant';
import { ROUTE_TO_RETAILER } from '../../../shared/Constants/RouteConstants';
import BackdropWithLoader from '../../../components/BackdropWithLoader';
import { setCancel } from '../../../redux/event/event.actions';

function DeleteSecondaryOrder(props) {
  const [showDeleteModal, setShowDeleteModal] = useState(true);
  const {
    fetchAllSecondaryOrdersConnect,
    secondaryOrdersPayload,
    deleteSecondaryOrderConnect,
    isLoading,
    secondaryOrder = {},
    match: {
      params: { id }
    },
    setCancelConnect
  } = props;
  const orderId = id;

  useFetchReducer(fetchAllSecondaryOrdersConnect, secondaryOrdersPayload);

  const handleDelete = async () => {
    await deleteSecondaryOrderConnect(orderId);
    setShowDeleteModal(false);
  };

  const handleClose = () => {
    setShowDeleteModal(false);
    setCancelConnect();
    history.push(ROUTE_TO_RETAILER);
  };

  const actions = [
    {
      buttonText: 'Cancel',
      buttonAction: handleClose,
      buttonStyle: { backgroundColor: '#4caf50', color: '#fff' }
    },
    {
      buttonText: 'Delete',
      buttonAction: handleDelete,
      buttonStyle: { backgroundColor: '#d50000', color: '#fff' }
    }
  ];

  const modelContent = () => {
    const { invoice_number: orderNumber } = secondaryOrder;

    if (!secondaryOrder) {
      return 'Are you sure you want to delete this Secondary Order?';
    }
    return `Are you sure you want 
      to delete Secondary Order ${orderNumber}`;
  };

  if (isLoading) {
    return <BackdropWithLoader />;
  }

  return (
    <Modal
      title={TITLES.DELETE_RETAILER}
      isModalVisible={showDeleteModal}
      actions={actions}
      onClose={handleClose}
      content={modelContent()}
    />
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    isLoading: isSecondaryOrderRequestPending(state),
    secondaryOrdersPayload: getAllSecondaryOrders(state),
    secondaryOrder: getSecondaryOrderById(state, ownProps)
  };
};

const mapDispatchToProps = {
  fetchAllSecondaryOrdersConnect: fetchAllSecondaryOrders,
  deleteSecondaryOrderConnect: deleteSecondaryOrder,
  setCancelConnect: setCancel
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeleteSecondaryOrder);
