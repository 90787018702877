import Login from '../pages/Login';
import MinimalLayout from '../layouts/MinimalLayout';

const pagesWithNoAuth = [
  {
    path: '/login',
    component: Login,
    layout: MinimalLayout
  }
];

export default pagesWithNoAuth;
