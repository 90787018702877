import React, { useState } from 'react';
import FormInput from '../FormInput';
import formStyles from '../Styles/FormStyles';
import FormCancelButton from '../FormCancelButton';
import FormSubmitButton from '../FormSubmitButton';
import useForm from '../../shared/Hooks/useForm';
import DistrictFormValidator from '../../pages/District/DistrictFormValidator';
import FormAutoComplete from '../FormAutoComplete';
import { isEqual, size } from 'lodash';
import { getFilteredDistrictsPayload } from '../../shared/Utils/CommonUtils';

function EditAreaForm({
  area = {},
  onSubmit,
  onCancel,
  zones = [],
  districts = []
}) {
  const classes = formStyles();

  const [zoneItem, setZoneItem] = useState(area.zone);
  const [districtItem, setDistrictItem] = useState(area.district);

  const normalizeFormValues = () => {
    const updatedValues = {};

    if (values.name && !isEqual(values.name, area.name)) {
      updatedValues.name = values.name;
    }

    if (districtItem && !isEqual(districtItem, area.district)) {
      updatedValues.district = districtItem.id;
    }

    return updatedValues;
  };

  const onChangeZoneSelection = (e, value) => {
    e.persist();
    setZoneItem(value);
    setDistrictItem(null);
  };

  const onChangeDistrictSelection = (e, value) => {
    e.persist();
    setDistrictItem(value);
  };

  const handleFormSubmit = () => {
    onSubmit(normalizeFormValues(), resetValues);
  };

  const { values, errors, handleChange, handleSubmit, resetValues } = useForm(
    handleFormSubmit,
    DistrictFormValidator,
    {
      initialValues: {
        name: area.name
      }
    }
  );

  return (
    <form className={classes.form} noValidate autoComplete="off">
      <FormAutoComplete
        data={zones}
        label="Select Zone"
        name="zone"
        value={zoneItem}
        onChangeSelect={(e, newValue) => onChangeZoneSelection(e, newValue)}
        property="name"
        autoFocus
        required
      />

      <FormAutoComplete
        data={getFilteredDistrictsPayload(districts, zoneItem)}
        label="Select District"
        name="district"
        value={districtItem}
        onChangeSelect={(e, newValue) => onChangeDistrictSelection(e, newValue)}
        property="name"
        required
      />

      <FormInput
        type="text"
        label="Area"
        placeholder="Enter a Area"
        name="name"
        value={values.name || ''}
        onChange={handleChange}
        helperText={errors.name || ''}
        error={!!errors.name}
        required
      />

      <div className={classes.buttonRow}>
        <FormCancelButton onClickCancel={onCancel} />
        <FormSubmitButton
          onClickSubmit={handleSubmit}
          disabled={!size(normalizeFormValues()) > 0}
        />
      </div>
    </form>
  );
}

export default React.memo(EditAreaForm);
