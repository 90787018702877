import MainLayout from '../layouts/MainLayout';
import Home from '../components/Home';
/* Import User Pages */
import User from '../pages/User';
import CreateUserForm from '../pages/User/CreateUserForm';
import AssignUserTerritory from '../pages/User/AssignUserTerritory';
import UserAttendance from '../pages/UserAttendance';
import EmployeeSecondarySalesByDay from '../pages/EmployeeSecondarySalesByDay';
import EmployeeSecondarySalesByRange from '../pages/EmployeeSecondarySalesByRange';
/* Import Parent Product Pages */
import ParentProduct from '../pages/ParentProduct';
import CreateParentProduct from '../pages/ParentProduct/CreateParentProduct';
import EditParentProduct from '../pages/ParentProduct/EditParentProduct';
/* Import Sku Pages */
import Sku from '../pages/Sku';
import CreateSku from '../pages/Sku/CreateSku';
import EditSku from '../pages/Sku/EditSku';
/* Import Factory Inventory Pages */
import FactoryStock from '../pages/FactoryStock';
/* Import Packaging Material Inventory Pages */
import PackagingMaterialsStock from '../pages/PackagingMaterialsStock';
/* Import Territory Zone Pages */
import Zone from '../pages/Zone';
import CreateZone from '../pages/Zone/CreateZone';
import EditZone from '../pages/Zone/EditZone';
import DeleteZone from '../pages/Zone/DeleteZone';
/* Import Territory District Pages */
import District from '../pages/District';
import CreateDistrict from '../pages/District/CreateDistrict';
import EditDistrict from '../pages/District/EditDistrict';
import DeleteDistrict from '../pages/District/DeleteDistrict';
/* Import Territory Area Pages */
import Area from '../pages/Area';
import CreateArea from '../pages/Area/CreateArea';
import EditArea from '../pages/Area/EditArea';
import DeleteArea from '../pages/Area/DeleteArea';
/* Import Territory Beat Area Pages */
import BeatArea from '../pages/BeatArea';
import CreateBeat from '../pages/BeatArea/CreateBeat';
import EditBeat from '../pages/BeatArea/EditBeat';
import DeleteBeatArea from '../pages/BeatArea/DeleteBeatArea';
/* Import Customer - Super Stockist Pages */
import SuperStockist from '../pages/SuperStockist';
import CreateSuperStockist from '../pages/SuperStockist/CreateSuperStockist';
import EditSuperStockist from '../pages/SuperStockist/EditSuperStockist';
import DeleteSuperStockist from '../pages/SuperStockist/DeleteSuperStockist';
import ManageSuperStockistInventory from '../pages/SuperStockist/ManageSuperStockistInventory';
/* Import Customer - Distributor Pages */
import Distributor from '../pages/Distributor';
import CreateDistributor from '../pages/Distributor/CreateDistributor';
import EditDistributor from '../pages/Distributor/EditDistributor';
import DeleteDistributor from '../pages/Distributor/DeleteDistributor';
import ManageDistributorInventory from '../pages/Distributor/ManageDistributorInventory';
/* Import Customer - Retailer Pages */
import Retailer from '../pages/Retailer';
import CreateRetailer from '../pages/Retailer/CreateRetailer';
import EditRetailer from '../pages/Retailer/EditRetailer';
import DeleteRetailer from '../pages/Retailer/DeleteRetailer';
import ManageRetailerInventory from '../pages/Retailer/ManageRetailerInventory';
/* Import Order Pages */
import PrimaryOrder from '../pages/PrimaryOrder';
import CreatePrimaryOrder from '../pages/PrimaryOrder/CreatePrimaryOrder';
import ViewPrimaryOrder from '../pages/PrimaryOrder/ViewPrimaryOrder';
import DeletePrimaryOrder from '../pages/PrimaryOrder/DeletePrimaryOrder';

import SecondPrimaryOrder from '../pages/SecondPrimaryOrder';
import CreateSecondPrimaryOrder from '../pages/SecondPrimaryOrder/CreateSecondPrimaryOrder';
import ViewSecondPrimaryOrder from '../pages/SecondPrimaryOrder/ViewSecondPrimaryOrder';
import DeleteSecondPrimaryOrder from '../pages/SecondPrimaryOrder/DeleteSecondPrimaryOrder';

import SecondaryOrder from '../pages/SecondaryOrder';
import CreateSecondaryOrder from '../pages/SecondaryOrder/CreateSecondaryOrder';
import ViewSecondaryOrder from '../pages/SecondaryOrder/ViewSecondaryOrder';
import DeleteSecondaryOrder from '../pages/SecondaryOrder/DeleteSecondaryOrder';

const pages = [
  {
    path: '/home',
    component: Home,
    layout: MainLayout
  },
  {
    path: '/user',
    component: User,
    layout: MainLayout
  },
  {
    path: '/user/create',
    component: CreateUserForm,
    layout: MainLayout
  },
  {
    path: '/user/assign-territory/:id',
    component: AssignUserTerritory,
    layout: MainLayout
  },
  {
    path: '/user/attendance',
    component: UserAttendance,
    layout: MainLayout
  },
  {
    path: '/user/employee-secondary-sales-day-wise',
    component: EmployeeSecondarySalesByDay,
    layout: MainLayout
  },
  {
    path: '/user/employee-secondary-sales-range-wise',
    component: EmployeeSecondarySalesByRange,
    layout: MainLayout
  },
  {
    path: '/parent-product',
    component: ParentProduct,
    layout: MainLayout
  },
  {
    path: '/parent-product/create',
    component: CreateParentProduct,
    layout: MainLayout
  },
  {
    path: '/parent-product/edit/:id',
    component: EditParentProduct,
    layout: MainLayout
  },
  {
    path: '/sku',
    component: Sku,
    layout: MainLayout
  },
  {
    path: '/sku/create',
    component: CreateSku,
    layout: MainLayout
  },
  {
    path: '/sku/edit/:id',
    component: EditSku,
    layout: MainLayout
  },
  {
    path: '/factory-stock',
    component: FactoryStock,
    layout: MainLayout
  },
  {
    path: '/packaging-material-stock',
    component: PackagingMaterialsStock,
    layout: MainLayout
  },
  {
    path: '/zone/',
    component: Zone,
    layout: MainLayout
  },
  {
    path: '/zone/create',
    component: CreateZone,
    layout: MainLayout
  },
  {
    path: '/zone/edit/:id',
    component: EditZone,
    layout: MainLayout
  },
  {
    path: '/zone/delete/:id',
    component: DeleteZone,
    layout: MainLayout
  },

  {
    path: '/district/',
    component: District,
    layout: MainLayout
  },
  {
    path: '/district/create',
    component: CreateDistrict,
    layout: MainLayout
  },
  {
    path: '/district/edit/:id',
    component: EditDistrict,
    layout: MainLayout
  },
  {
    path: '/district/delete/:id',
    component: DeleteDistrict,
    layout: MainLayout
  },
  {
    path: '/area/',
    component: Area,
    layout: MainLayout
  },
  {
    path: '/area/create',
    component: CreateArea,
    layout: MainLayout
  },
  {
    path: '/area/edit/:id',
    component: EditArea,
    layout: MainLayout
  },
  {
    path: '/area/delete/:id',
    component: DeleteArea,
    layout: MainLayout
  },

  {
    path: '/beatarea/',
    component: BeatArea,
    layout: MainLayout
  },
  {
    path: '/beatarea/create',
    component: CreateBeat,
    layout: MainLayout
  },
  {
    path: '/beatarea/edit/:id',
    component: EditBeat,
    layout: MainLayout
  },
  {
    path: '/beatarea/delete/:id',
    component: DeleteBeatArea,
    layout: MainLayout
  },
  {
    path: '/superstockist/',
    component: SuperStockist,
    layout: MainLayout
  },
  {
    path: '/superstockist/create',
    component: CreateSuperStockist,
    layout: MainLayout
  },
  {
    path: '/superstockist/edit/:id',
    component: EditSuperStockist,
    layout: MainLayout
  },
  {
    path: '/superstockist/delete/:id',
    component: DeleteSuperStockist,
    layout: MainLayout
  },
  {
    path: '/inventory/superstockist/:id',
    component: ManageSuperStockistInventory,
    layout: MainLayout
  },
  {
    path: '/distributor/',
    component: Distributor,
    layout: MainLayout
  },
  {
    path: '/distributor/create',
    component: CreateDistributor,
    layout: MainLayout
  },
  {
    path: '/distributor/edit/:id',
    component: EditDistributor,
    layout: MainLayout
  },
  {
    path: '/distributor/delete/:id',
    component: DeleteDistributor,
    layout: MainLayout
  },
  {
    path: '/inventory/distributor/:id',
    component: ManageDistributorInventory,
    layout: MainLayout
  },
  {
    path: '/retailer/',
    component: Retailer,
    layout: MainLayout
  },
  {
    path: '/retailer/create',
    component: CreateRetailer,
    layout: MainLayout
  },
  {
    path: '/retailer/edit/:id',
    component: EditRetailer,
    layout: MainLayout
  },
  {
    path: '/retailer/delete/:id',
    component: DeleteRetailer,
    layout: MainLayout
  },
  {
    path: '/inventory/retailer/:id',
    component: ManageRetailerInventory,
    layout: MainLayout
  },
  {
    path: '/order/primary-order',
    component: PrimaryOrder,
    layout: MainLayout
  },
  {
    path: '/order/primary-order/create',
    component: CreatePrimaryOrder,
    layout: MainLayout
  },
  {
    path: '/order/primary-order/view/:id',
    component: ViewPrimaryOrder,
    layout: MainLayout
  },
  {
    path: '/order/primary-order/delete/:id',
    component: DeletePrimaryOrder,
    layout: MainLayout
  },
  {
    path: '/order/second-primary-order',
    component: SecondPrimaryOrder,
    layout: MainLayout
  },
  {
    path: '/order/second-primary-order/create',
    component: CreateSecondPrimaryOrder,
    layout: MainLayout
  },
  {
    path: '/order/second-primary-order/view/:id',
    component: ViewSecondPrimaryOrder,
    layout: MainLayout
  },
  {
    path: '/order/second-primary-order/delete/:id',
    component: DeleteSecondPrimaryOrder,
    layout: MainLayout
  },
  {
    path: '/order/secondary-order',
    component: SecondaryOrder,
    layout: MainLayout
  },
  {
    path: '/order/secondary-order/create',
    component: CreateSecondaryOrder,
    layout: MainLayout
  },
  {
    path: '/order/secondary-order/view/:id',
    component: ViewSecondaryOrder,
    layout: MainLayout
  },
  {
    path: '/order/secondary-order/delete/:id',
    component: DeleteSecondaryOrder,
    layout: MainLayout
  }
];

export default pages;
