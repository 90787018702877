import { ssAndDbrAndRetailer, dbrAndRetailer } from './PriceCalculationUtils';

export const calculateSkuPrice = (sku = {}, customerLevelMargins = {}) => {
  const { tax = {}, margin = {}, special_selling_price, mrp } = sku;

  const { sgst, cgst } = tax;

  const {
    super_stockist: productLevelSuperStockistMargin,
    distributor: productLevelDistributorMargin,
    retailer: productLevelRetailerMargin
  } = margin;

  const {
    super_stockist: customerLevelSuperStockistMargin,
    distributor: customerLevelDistributorMargin,
    retailer: customerLevelRetailerMargin
  } = customerLevelMargins;

  // RetailPrice, Retailer, Distributor, SS, GST - Arguments Order
  const ssAndDbrAndRetailerLandedPrices = ssAndDbrAndRetailer({
    retailPrice: special_selling_price || mrp,
    retailerMargin: customerLevelRetailerMargin || productLevelRetailerMargin,
    distributorMargin:
      customerLevelDistributorMargin || productLevelDistributorMargin,
    superStockistMargin:
      customerLevelSuperStockistMargin || productLevelSuperStockistMargin,
    gst: Number(sgst) + Number(cgst)
  });

  const distributorAndRetailerLandedPrices = dbrAndRetailer({
    retailPrice: special_selling_price || mrp,
    retailerMargin: customerLevelRetailerMargin || productLevelRetailerMargin,
    distributorMargin:
      customerLevelDistributorMargin || productLevelDistributorMargin,
    gst: Number(sgst) + Number(cgst)
  });

  return [ssAndDbrAndRetailerLandedPrices, distributorAndRetailerLandedPrices];
};

export const getSsAndDbrAndRetailerLandedPrices = (
  sku = {},
  customerLevelMargins = {}
) => {
  const { tax = {}, margin = {}, special_selling_price, mrp } = sku;

  const { sgst, cgst } = tax;

  const {
    super_stockist: productLevelSuperStockistMargin,
    distributor: productLevelDistributorMargin,
    retailer: productLevelRetailerMargin
  } = margin;

  const {
    super_stockist: customerLevelSuperStockistMargin,
    distributor: customerLevelDistributorMargin,
    retailer: customerLevelRetailerMargin
  } = customerLevelMargins;

  // RetailPrice, Retailer, Distributor, SS, GST - Arguments Order
  const ssAndDbrAndRetailerLandedPrices = ssAndDbrAndRetailer({
    retailPrice: special_selling_price || mrp,
    retailerMargin: customerLevelRetailerMargin || productLevelRetailerMargin,
    distributorMargin:
      customerLevelDistributorMargin || productLevelDistributorMargin,
    superStockistMargin:
      customerLevelSuperStockistMargin || productLevelSuperStockistMargin,
    gst: Number(sgst) + Number(cgst)
  });

  return ssAndDbrAndRetailerLandedPrices;
};

export const getDbrAndRetailerLandedPrices = (
  sku = {},
  customerLevelMargins = {}
) => {
  const { tax = {}, margin = {}, special_selling_price, mrp } = sku;

  const { sgst, cgst } = tax;

  const {
    distributor: productLevelDistributorMargin,
    retailer: productLevelRetailerMargin
  } = margin;

  const {
    distributor: customerLevelDistributorMargin,
    retailer: customerLevelRetailerMargin
  } = customerLevelMargins;

  // RetailPrice, Retailer, Distributor, SS, GST - Arguments Order
  const distributorAndRetailerLandedPrices = dbrAndRetailer({
    retailPrice: special_selling_price || mrp,
    retailerMargin: customerLevelRetailerMargin || productLevelRetailerMargin,
    distributorMargin:
      customerLevelDistributorMargin || productLevelDistributorMargin,
    gst: Number(sgst) + Number(cgst)
  });

  return distributorAndRetailerLandedPrices;
};
