import EmployeeSecondarySalesApi from '../../apis/EmployeeSecondarySalesAPI';
import * as EmployeeSecondarySalesTypes from './employee-secondary-sales.types';

export const fetchEmployeeSecondarySales = (userId, params) => async (
  dispatch
) => {
  dispatch({
    type: EmployeeSecondarySalesTypes.FETCH_EMPLOYEE_SECONDARY_SALES_REQUEST
  });

  try {
    const response = await EmployeeSecondarySalesApi.getEmployeeSecondarySales(
      userId,
      params
    );

    dispatch({
      type: EmployeeSecondarySalesTypes.FETCH_EMPLOYEE_SECONDARY_SALES_SUCCESS,
      payload: {
        secondary_activities:
          response.data.employeeDailySalesReport.secondary_activities
      },

      error: null
    });
  } catch (error) {
    dispatch({
      type: EmployeeSecondarySalesTypes.FETCH_EMPLOYEE_SECONDARY_SALES_ERROR,
      error: error.response.data,
      payload: null
    });
  }
};

export const editEmployeeSecondarySalesEntry = (
  id,
  formValues,
  callBack
) => async (dispatch) => {
  dispatch({
    type: EmployeeSecondarySalesTypes.UPDATE_EMPLOYEE_SECONDARY_SALES_REQUEST
  });

  try {
    const response = await EmployeeSecondarySalesApi.editEmployeeSecondarySales(
      id,
      formValues
    );
    dispatch({
      type: EmployeeSecondarySalesTypes.UPDATE_EMPLOYEE_SECONDARY_SALES_SUCCESS,
      payload: response.data.packingVendorEntry,
      error: null,
      success: response.data.message
    });
    if (callBack) {
      callBack();
    }
  } catch (error) {
    dispatch({
      type: EmployeeSecondarySalesTypes.UPDATE_EMPLOYEE_SECONDARY_SALES_ERROR,
      error: error.response.data,
      payload: null
    });
  }
};

export const deleteEmployeeSecondarySalesEntry = (id) => async (dispatch) => {
  dispatch({
    type: EmployeeSecondarySalesTypes.DELETE_EMPLOYEE_SECONDARY_SALES_REQUEST
  });

  try {
    const response = await EmployeeSecondarySalesApi.deleteEmployeeSecondarySales(
      id
    );
    dispatch({
      type: EmployeeSecondarySalesTypes.DELETE_EMPLOYEE_SECONDARY_SALES_SUCCESS,
      payload: id,
      error: null,
      success: response.data.message
    });
  } catch (error) {
    dispatch({
      type: EmployeeSecondarySalesTypes.DELETE_EMPLOYEE_SECONDARY_SALES_ERROR,
      payload: null,
      error: error.response.data
    });
  }
};

export const resetFactoryPackingVendorError = () => (dispatch) => {
  dispatch({
    type: EmployeeSecondarySalesTypes.RESET_EMPLOYEE_SECONDARY_SALES_ERROR
  });
};

export const resetSecondarySalesData = () => (dispatch) => {
  dispatch({
    type: EmployeeSecondarySalesTypes.RESET_EMPLOYEE_SECONDARY_SALES_DATA
  });
};
