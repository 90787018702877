import OrderApi from '../../apis/OrderAPI';
import * as SecondPrimaryOrderActionTypes from './second-primary-order.types';
import { ROUTE_TO_SECOND_PRIMARY_ORDER } from '../../shared/Constants/RouteConstants';
import { get } from 'lodash';

export const fetchAllSecondPrimaryOrders = () => async (dispatch) => {
  dispatch({
    type: SecondPrimaryOrderActionTypes.FETCH_SECOND_PRIMARY_ORDERS_REQUEST
  });

  try {
    const response = await OrderApi.getAllSecondPrimaryOrders();

    dispatch({
      type: SecondPrimaryOrderActionTypes.FETCH_SECOND_PRIMARY_ORDERS_SUCCESS,
      payload: response.data.secondPrimaryOrders,
      error: null,
      success: null,
      route: null
    });

    dispatch({
      type: SecondPrimaryOrderActionTypes.SECOND_PRIMARY_ORDER_PAGINATION,
      payload: get(response.data, 'pagination', {}),
      error: null
    });
  } catch (error) {
    dispatch({
      type: SecondPrimaryOrderActionTypes.FETCH_SECOND_PRIMARY_ORDERS_ERROR,
      error: error.response.data,
      payload: null,
      success: null,
      route: null
    });
  }
};

export const fetchSecondPrimaryOrder = (id) => async (dispatch) => {
  dispatch({
    type: SecondPrimaryOrderActionTypes.FETCH_SECOND_PRIMARY_ORDER_REQUEST
  });

  try {
    const response = await OrderApi.getSecondPrimaryOrder(id);

    dispatch({
      type: SecondPrimaryOrderActionTypes.FETCH_SECOND_PRIMARY_ORDER_SUCCESS,
      payload: response.data.secondPrimaryOrder,
      error: null,
      success: null,
      route: null
    });
  } catch (error) {
    dispatch({
      type: SecondPrimaryOrderActionTypes.FETCH_SECOND_PRIMARY_ORDER_ERROR,
      error: error.response.data,
      payload: null,
      success: null,
      route: null
    });
  }
};

export const createSecondPrimaryOrder = (orderDetails) => async (dispatch) => {
  dispatch({
    type: SecondPrimaryOrderActionTypes.CREATE_SECOND_PRIMARY_ORDER_REQUEST
  });

  try {
    const response = await OrderApi.createSecondPrimaryOrder(orderDetails);

    dispatch({
      type: SecondPrimaryOrderActionTypes.CREATE_SECOND_PRIMARY_ORDER_SUCCESS,
      payload: response.data.secondPrimaryOrder,
      error: null,
      route: ROUTE_TO_SECOND_PRIMARY_ORDER,
      success: response.data.message
    });
  } catch (error) {
    dispatch({
      type: SecondPrimaryOrderActionTypes.CREATE_SECOND_PRIMARY_ORDER_ERROR,
      error: error.response.data,
      payload: null,
      route: ROUTE_TO_SECOND_PRIMARY_ORDER,
      success: null
    });
  }
};

export const editSecondPrimaryOrder = (
  { secondPrimaryOrderId, orderDetails },
  callback
) => async (dispatch) => {
  dispatch({
    type: SecondPrimaryOrderActionTypes.UPDATE_SECOND_PRIMARY_ORDER_REQUEST
  });

  try {
    const response = await OrderApi.editSecondPrimaryOrder(
      secondPrimaryOrderId,
      orderDetails
    );

    if (callback) {
      callback();
    }

    dispatch({
      type: SecondPrimaryOrderActionTypes.UPDATE_SECOND_PRIMARY_ORDER_SUCCESS,
      payload: response.data.secondPrimaryOrder,
      error: null,
      route: ROUTE_TO_SECOND_PRIMARY_ORDER,
      success: response.data.message
    });
  } catch (error) {
    dispatch({
      type: SecondPrimaryOrderActionTypes.UPDATE_SECOND_PRIMARY_ORDER_ERROR,
      error: error.response.data,
      payload: null,
      route: ROUTE_TO_SECOND_PRIMARY_ORDER,
      success: null
    });
  }
};

export const deleteSecondPrimaryOrder = (id) => async (dispatch) => {
  dispatch({
    type: SecondPrimaryOrderActionTypes.DELETE_SECOND_PRIMARY_ORDER_REQUEST
  });

  try {
    const response = await OrderApi.deleteSecondPrimaryOrder(id);

    dispatch({
      type: SecondPrimaryOrderActionTypes.DELETE_SECOND_PRIMARY_ORDER_SUCCESS,
      payload: id,
      error: null,
      route: ROUTE_TO_SECOND_PRIMARY_ORDER,
      success: response.data.message
    });
  } catch (error) {
    dispatch({
      type: SecondPrimaryOrderActionTypes.DELETE_SECOND_PRIMARY_ORDER_ERROR,
      payload: null,
      error: error.response.data,
      route: ROUTE_TO_SECOND_PRIMARY_ORDER,
      success: null
    });
  }
};

export const addItem = (item, callBack) => (dispatch) => {
  dispatch({
    type: SecondPrimaryOrderActionTypes.ADD_SECOND_PRIMARY_ORDER_ITEM,
    payload: item
  });
  if (callBack) {
    callBack();
  }
};

export const removeItem = (itemId) => (dispatch) => {
  dispatch({
    type: SecondPrimaryOrderActionTypes.REMOVE_SECOND_PRIMARY_ORDER_ITEM,
    payload: itemId
  });
};

export const clearCart = () => (dispatch) => {
  dispatch({
    type: SecondPrimaryOrderActionTypes.CLEAR_SECOND_PRIMARY_ORDER_ORDER_CART
  });
};

export const updatePaginationPage = (pageNumber) => (dispatch) => {
  dispatch({
    type:
      SecondPrimaryOrderActionTypes.UPDATE_SECOND_PRIMARY_ORDER_PAGINATION_PAGE,
    payload: pageNumber
  });
};

export const resetSecondPrimaryOrderData = () => (dispatch) => {
  dispatch({
    type: SecondPrimaryOrderActionTypes.RESET_SECOND_PRIMARY_ORDER_DATA
  });
};

export const resetSecondPrimaryOrderError = () => (dispatch) => {
  dispatch({
    type: SecondPrimaryOrderActionTypes.RESET_SECOND_PRIMARY_ORDER_ERROR
  });
};

export const loadSecondPrimaryOrderCustomer = (customer) => (dispatch) => {
  dispatch({
    type: SecondPrimaryOrderActionTypes.LOAD_SECOND_PRIMARY_ORDER_CUSTOMER,
    payload: customer
  });
};
