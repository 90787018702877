import React, { useEffect, useState, useCallback } from 'react';
import { get } from 'lodash';
import moment from 'moment';
import { useJsApiLoader } from '@react-google-maps/api';
import {
  Container,
  Grid,
  Divider,
  MenuItem,
  FormLabel,
  FormControlLabel,
  Checkbox
} from '@material-ui/core';
import { connect } from 'react-redux';
import EmployeeSecondarySalesReportTable from '../../components/EmployeeSecondarySalesReportTable';
import FormSelect from '../../components/FormSelect';
import GoogleMapsMultiMarker from '../../components/GoogleMapsMultiMarker';
import SecondarySalesSummary from './SecondarySalesSummary';
import AntdDatePicker from '../../components/AntdDatePicker';
import {
  fetchEmployeeSecondarySales,
  resetSecondarySalesData
} from '../../redux/employee-secondary-sales/employee-secondary-sales.actions';
import {
  getEmployeeSecondarySales,
  isEmployeeSecondarySalesRequestPending
} from '../../redux/employee-secondary-sales/employee-secondary-sales.selectors';
import { fetchAllUsers } from '../../redux/user/user.actions';
import {
  getAllUsers,
  isUserRequestPending
} from '../../redux/user/user.selector';
import tableStyles from '../../Styles/CommonStyles';
import { EMPLOYEE_SALES_ACTIVITIES } from '../../shared/Constants/Constant';

function EmployeeSecondarySalesByDay(props) {
  const {
    isUserLoading,
    fetchAllUsersConnect,
    usersPayload,
    fetchEmployeeSecondarySalesConnect,
    entriesPayload,
    isRequestPending,
    resetSecondarySalesDataConnect
  } = props;

  const commonClasses = tableStyles();
  const [userId, setUserId] = useState('');
  const [selectedDate, setSelectedDate] = useState(moment().startOf('day'));
  const [showMap, setMap] = useState(false);

  useEffect(() => {
    fetchAllUsersConnect();
  }, []);

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY
  });

  useEffect(() => {
    let mounted = true;
    const controller = new AbortController();

    const loadActivities = async () => {
      if (userId && selectedDate) {
        fetchEmployeeSecondarySalesConnect(userId, {
          fromDate: selectedDate.toISOString(),
          toDate: moment(selectedDate).endOf('day').toISOString()
        });
      }
    };

    if (mounted) {
      loadActivities();
    }

    return () => {
      controller.abort();
      resetSecondarySalesDataConnect();
      mounted = false;
    };
  }, [fetchAllUsersConnect, userId, selectedDate]);

  const handleUserChange = (event) => {
    event.persist();

    const { value } = event.target;
    setUserId(value);
  };

  const getTransformedPayload = useCallback(() => {
    const entriesObj = entriesPayload.reduce(
      (acc, entry) => {
        const showMarker =
          entry.location &&
          entry.location.coordinates &&
          entry.location.coordinates.length > 0;

        if (showMarker) {
          return {
            ...acc,
            entries: [
              ...acc.entries,
              {
                ...entry,
                showMarker: !!showMarker,
                lng: get(entry, 'location.coordinates[0]', ''),
                lat: get(entry, 'location.coordinates[1]', ''),
                mapId: acc.startMapIndex,
                markerPrimaryText: get(entry, 'retailer.name', ''),
                markerSecondaryText:
                  EMPLOYEE_SALES_ACTIVITIES[entry.activity] || ''
              }
            ],
            startMapIndex: acc.startMapIndex + 1
          };
        }

        return {
          ...acc,
          entries: [
            ...acc.entries,
            {
              ...entry,
              showMarker: !!showMarker
            }
          ]
        };
      },
      {
        startMapIndex: 1,
        entries: []
      }
    );

    return entriesObj.entries || [];
  }, [entriesPayload]);

  const mapEntries = getTransformedPayload().filter(
    (entry) => entry.showMarker
  );

  return (
    <Container className={commonClasses.root} maxWidth="lg">
      <Grid container spacing={3}>
        <Grid item lg={12} md={12} xs={12}>
          <Grid container spacing={3} direction="row">
            <Grid item lg={4} md={4} xs={12}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-around',
                  height: '50vh',
                  alignItems: 'flex-start'
                }}
              >
                <FormSelect
                  label="User Name"
                  name="user"
                  value={userId || ''}
                  onChange={handleUserChange}
                  showDefaultEmptyValue={false}
                  showEmptyErrorMenuItem={!usersPayload.length}
                  disabled={isUserLoading}
                  required
                >
                  {usersPayload.map((user) => {
                    const { name, id } = user;
                    return (
                      <MenuItem key={id} value={id}>
                        {name}
                      </MenuItem>
                    );
                  })}
                </FormSelect>
                <div className={commonClasses.dailySalesDatePicker}>
                  <FormLabel style={{ margin: 10 }}>Choose Date</FormLabel>
                  <AntdDatePicker
                    date={selectedDate}
                    handleSelectedDate={setSelectedDate}
                    disableFutureDates={true}
                  />
                </div>
                <SecondarySalesSummary entriesPayload={entriesPayload} />
                <FormControlLabel
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center'
                  }}
                  control={
                    <Checkbox
                      checked={showMap}
                      color="primary"
                      onChange={(e) => setMap(e.target.checked)}
                      name="showMap"
                    />
                  }
                  label="Show Map"
                />
              </div>
            </Grid>
            <Grid
              item
              lg={8}
              md={8}
              xs={12}
              className={commonClasses.mapContainer}
            >
              {showMap && entriesPayload.length > 0 && isLoaded && (
                <Grid container spacing={3}>
                  <Grid item md={12} xs={12}>
                    <GoogleMapsMultiMarker entries={mapEntries} />
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid container spacing={3} style={{ marginTop: 30 }}>
            <Grid item md={12} xs={12}>
              <EmployeeSecondarySalesReportTable
                secondaryActivities={getTransformedPayload()}
                isLoading={isRequestPending}
                isUserSelected={userId}
                showDateColumn={false}
                showMapIdColumn={showMap}
              />
            </Grid>
          </Grid>
          <Divider />
        </Grid>
      </Grid>
    </Container>
  );
}

const mapStateToProps = (state) => {
  return {
    isUserLoading: isUserRequestPending(state),
    usersPayload: getAllUsers(state),
    entriesPayload: getEmployeeSecondarySales(state),
    isRequestPending: isEmployeeSecondarySalesRequestPending(state)
  };
};

const mapDispatchToProps = {
  fetchEmployeeSecondarySalesConnect: fetchEmployeeSecondarySales,
  fetchAllUsersConnect: fetchAllUsers,
  resetSecondarySalesDataConnect: resetSecondarySalesData
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EmployeeSecondarySalesByDay);
