import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Select,
  InputLabel,
  FormControl,
  MenuItem,
  Input,
  FormHelperText
} from '@material-ui/core';

const useStyles = makeStyles({
  formControl: {
    width: '100%',
    height: '100px',
    marginTop: '10px'
  }
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

function FormMultiSelect(props) {
  const classes = useStyles();
  const {
    label,
    value,
    payload,
    onChange,
    children,
    error = false,
    helperText,
    ...otherProps
  } = props;

  const getName = (id) => {
    return (
      (payload.find((doc) => doc.id.toString() === id.toString()) || {}).name ||
      ''
    );
  };

  return (
    <FormControl className={classes.formControl}>
      <InputLabel shrink style={{ fontSize: '18px' }}>
        {label}
      </InputLabel>
      <Select
        style={{
          marginTop: '30px',
          marginBottom: '30px',
          height: '100%',
          border: '1px solid grey',
          borderRadius: '4px',
          padding: '10px 0 10px 10px',
          backgroundColor: '#fff'
        }}
        multiple
        renderValue={(selectedIds) => {
          return selectedIds
            .map((selectedId) => {
              return getName(selectedId);
            })
            .join(', ');
        }}
        input={<Input />}
        value={value || []}
        onChange={onChange}
        fullWidth
        MenuProps={MenuProps}
        {...otherProps}
      >
        <MenuItem disabled value="">
          <em>Select One</em>
        </MenuItem>
        {children}
      </Select>
      {error && (
        <FormHelperText style={{ color: '#d50000', marginBottom: 10 }}>
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
}

export default React.memo(FormMultiSelect);
