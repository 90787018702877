import baseURL from '../shared/AxiosBaseUrl/baseURL';

class TerritoryApi {
  static getTerritoryData(queryParams) {
    return baseURL.get('/api/v1/territory/', {
      params: queryParams
    });
  }
}

export default TerritoryApi;
