import React from 'react';
import { connect } from 'react-redux';
import { Container, Avatar, Typography } from '@material-ui/core';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import formStyles from '../../../components/Styles/FormStyles';
import useFetchReducerWithId from '../../../shared/Hooks/useFetchReducerWithId';
import EditZoneForm from '../../../components/EditZoneForm';
import { editZone, fetchZone } from '../../../redux/zone/zone.actions';
import {
  getZoneById,
  isZoneRequestPending
} from '../../../redux/zone/zone.selector';
import { TITLES } from '../../../shared/Constants/Constant';
import BackdropWithLoader from '../../../components/BackdropWithLoader';
import history from '../../../routes/history';
import { ROUTE_TO_ZONE } from '../../../shared/Constants/RouteConstants';
import { setCancel } from '../../../redux/event/event.actions';

const EditZone = (props) => {
  const {
    fetchZoneConnect,
    editZoneConnect,
    zone = {},
    isZoneLoading,
    setCancelConnect,
    match: {
      params: { id }
    }
  } = props;
  const zoneId = id;

  useFetchReducerWithId(fetchZoneConnect, zoneId, zone);

  const handleCancel = () => {
    setCancelConnect();
    history.push(ROUTE_TO_ZONE);
  };

  const classes = formStyles();

  const handleSubmit = async (formValues) => {
    await editZoneConnect(zoneId, formValues);
  };

  if (isZoneLoading) {
    return <BackdropWithLoader />;
  }

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <AccountCircleIcon color="primary" />
        </Avatar>
        <Typography component="h1" variant="h5">
          {TITLES.EDIT_ZONE}
        </Typography>
        <EditZoneForm
          zone={zone}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
        />
      </div>
    </Container>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    isZoneLoading: isZoneRequestPending(state),
    zone: getZoneById(state, ownProps)
  };
};

const mapDispatchToProps = {
  fetchZoneConnect: fetchZone,
  editZoneConnect: editZone,
  setCancelConnect: setCancel
};

export default connect(mapStateToProps, mapDispatchToProps)(EditZone);
