import React, { useCallback } from 'react';
import { StyledTableCell, StyledTableRow } from '../../Styles/TableStyles';
import {
  ROUTE_TO_EDIT_RETAILER,
  ROUTE_TO_DELETE_RETAILER,
  ROUTE_TO_MANAGE_STOCK_RETAILER
} from '../../shared/Constants/RouteConstants';
import {
  CUSTOMER_TYPES_CONSTANTS,
  FORM_SUB_HEADER_TITLES,
  RETAILER_TYPE
} from '../../shared/Constants/Constant';
import PlaceOrder from '../TableRowAction/PlaceOrder';
import MaterialButton from '../MaterialButton';
import { Button, Popover } from 'antd';
import history from '../../routes/history';

function RetailerTableItem({ retailer, isRetailerLoading }) {
  const {
    id: retailerId,
    name,
    retail_type: type,
    address: { place },
    area = {},
    beat = {},
    contact = {}
  } = retailer;

  const pageNavigate = useCallback((route, id) => {
    history.push(`${route}${id}`);
  }, []);

  const retailerPopoverContent = (distributor, pageNavigate) => {
    return (
      <div>
        <p>{FORM_SUB_HEADER_TITLES.ADDRESS}</p>
        <p>{distributor.address.street_address},</p>
        <p>{distributor.address.landmark},</p>
        <p>{distributor.address.place}</p>
        <p>{distributor.address.zip_code}</p>
        <p style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button
            type="primary"
            onClick={() => pageNavigate(ROUTE_TO_EDIT_RETAILER, retailerId)}
          >
            Edit
          </Button>
          <Button
            type="danger"
            onClick={() => pageNavigate(ROUTE_TO_DELETE_RETAILER, retailerId)}
          >
            Delete
          </Button>
        </p>
      </div>
    );
  };

  return (
    <StyledTableRow>
      <StyledTableCell component="th" scope="row" align="center">
        <Popover
          placement="right"
          title={name}
          content={retailerPopoverContent(retailer, pageNavigate)}
          trigger="hover"
        >
          <MaterialButton buttonText={name} />
        </Popover>
      </StyledTableCell>

      <StyledTableCell component="th" scope="row" align="center">
        {place}
      </StyledTableCell>
      <StyledTableCell component="th" scope="row" align="center">
        {RETAILER_TYPE[type]}
      </StyledTableCell>
      <StyledTableCell component="th" scope="row" align="center">
        {beat.name || 'NA'}
      </StyledTableCell>
      <StyledTableCell component="th" scope="row" align="center">
        {area.name || 'NA'}
      </StyledTableCell>
      <StyledTableCell component="th" scope="row" align="center">
        {contact.contact_person_name || '-'}
      </StyledTableCell>
      <StyledTableCell component="th" scope="row" align="center">
        {contact.contact_number || '-'}
      </StyledTableCell>
      <StyledTableCell component="th" scope="row" align="center">
        <PlaceOrder
          customerDetails={retailer}
          customerType={CUSTOMER_TYPES_CONSTANTS.RETAILER}
          disabled={isRetailerLoading}
        />
        <MaterialButton
          buttonText="Stock"
          handleClick={() =>
            pageNavigate(ROUTE_TO_MANAGE_STOCK_RETAILER, retailerId)
          }
          disabled={isRetailerLoading}
        />
      </StyledTableCell>
    </StyledTableRow>
  );
}

export default RetailerTableItem;
