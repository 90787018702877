import React from 'react';
import { Button } from '@material-ui/core';
import formStyles from '../Styles/FormStyles';
import { BUTTON_CONSTANTS } from '../../shared/Constants/Constant';

function FormSubmitButton(props) {
  const classes = formStyles();
  const {
    onClickSubmit,
    customClassName,
    customButtonText,
    disabled = false
  } = props;
  return (
    <Button
      type="submit"
      variant="contained"
      color="primary"
      align="right"
      margin="normal"
      fullWidth
      className={
        customClassName ? `classes.${customClassName}` : classes.buttonStyle
      }
      onClick={onClickSubmit}
      disabled={disabled}
    >
      {customButtonText || BUTTON_CONSTANTS.SAVE}
    </Button>
  );
}

export default React.memo(FormSubmitButton);
