export const FETCH_RETAILERS_REQUEST = 'FETCH_RETAILERS_REQUEST';
export const FETCH_RETAILER_REQUEST = 'FETCH_RETAILER_REQUEST';
export const FETCH_RETAILER_INVENTORY_REQUEST =
  'FETCH_RETAILER_INVENTORY_REQUEST';
export const CREATE_RETAILER_REQUEST = 'CREATE_RETAILER_REQUEST';
export const CREATE_RETAILER_INVENTORY_REQUEST =
  'CREATE_RETAILER_INVENTORY_REQUEST';
export const UPDATE_RETAILER_REQUEST = 'UPDATE_RETAILER_REQUEST';
export const UPDATE_RETAILER_INVENTORY_REQUEST =
  'UPDATE_RETAILER_INVENTORY_REQUEST';
export const DELETE_RETAILER_REQUEST = 'DELETE_RETAILER_REQUEST';

export const FETCH_RETAILERS_SUCCESS = 'FETCH_RETAILERS_SUCCESS';
export const FETCH_RETAILER_SUCCESS = 'FETCH_RETAILER_SUCCESS';
export const FETCH_RETAILER_INVENTORY_SUCCESS =
  'FETCH_RETAILER_INVENTORY_SUCCESS';
export const CREATE_RETAILER_SUCCESS = 'CREATE_RETAILER_SUCCESS';
export const CREATE_RETAILER_INVENTORY_SUCCESS =
  'CREATE_RETAILER_INVENTORY_SUCCESS';
export const UPDATE_RETAILER_SUCCESS = 'UPDATE_RETAILER_SUCCESS';
export const UPDATE_RETAILER_INVENTORY_SUCCESS =
  'UPDATE_RETAILER_INVENTORY_SUCCESS';
export const DELETE_RETAILER_SUCCESS = 'DELETE_RETAILER_SUCCESS';

export const FETCH_RETAILERS_ERROR = 'FETCH_RETAILERS_ERROR';
export const FETCH_RETAILER_ERROR = 'FETCH_RETAILER_ERROR';
export const FETCH_RETAILER_INVENTORY_ERROR = 'FETCH_RETAILER_INVENTORY_ERROR';
export const CREATE_RETAILER_ERROR = 'CREATE_RETAILER_ERROR';
export const CREATE_RETAILER_INVENTORY_ERROR =
  'CREATE_RETAILER_INVENTORY_ERROR';
export const UPDATE_RETAILER_ERROR = 'UPDATE_RETAILER_ERROR';
export const UPDATE_RETAILER_INVENTORY_ERROR =
  'UPDATE_RETAILER_INVENTORY_ERROR';
export const DELETE_RETAILER_ERROR = 'DELETE_RETAILER_ERROR';
