import { useEffect, useCallback } from 'react';

const useRefreshFetchReducer = (effect, cancelledEffect, isCancel = false) => {
  const effectFunction = useCallback(async () => {
    if (isCancel) {
      cancelledEffect();
    } else {
      await effect();
    }
  }, []);

  useEffect(() => {
    effectFunction();
  }, [effectFunction]);
};

export default useRefreshFetchReducer;
