export const FETCH_BEAT_AREAS_REQUEST = 'FETCH_BEAT_AREAS_REQUEST';
export const FETCH_BEAT_AREA_REQUEST = 'FETCH_BEAT_AREA_REQUEST';
export const CREATE_BEAT_AREA_REQUEST = 'CREATE_BEAT_AREA_REQUEST';
export const UPDATE_BEAT_AREA_REQUEST = 'UPDATE_BEAT_AREA_REQUEST';
export const DELETE_BEAT_AREA_REQUEST = 'DELETE_BEAT_AREA_REQUEST';

export const FETCH_BEAT_AREAS_SUCCESS = 'FETCH_BEAT_AREAS_SUCCESS';
export const FETCH_BEAT_AREA_SUCCESS = 'FETCH_BEAT_AREA_SUCCESS';
export const CREATE_BEAT_AREA_SUCCESS = 'CREATE_BEAT_AREA_SUCCESS';
export const UPDATE_BEAT_AREA_SUCCESS = 'UPDATE_BEAT_AREA_SUCCESS';
export const DELETE_BEAT_AREA_SUCCESS = 'DELETE_BEAT_AREA_SUCCESS';

export const FETCH_BEAT_AREAS_ERROR = 'FETCH_BEAT_AREAS_ERROR';
export const FETCH_BEAT_AREA_ERROR = 'FETCH_BEAT_AREA_ERROR';
export const CREATE_BEAT_AREA_ERROR = 'CREATE_BEAT_AREA_ERROR';
export const UPDATE_BEAT_AREA_ERROR = 'UPDATE_BEAT_AREA_ERROR';
export const DELETE_BEAT_AREA_ERROR = 'DELETE_BEAT_AREA_ERROR';
