import React from 'react';
import {
  Table as MuiTable,
  TableBody,
  TableHead,
  TableRow,
  Paper,
  TableContainer
} from '@material-ui/core';
import tableStyles from '../Styles/TableStyles';
import StyledTableCell from '../Styles/StyledTableCell';
import BeatAreaTableItem from './BeatAreaTableItem';

import { BEAT_AREA_HEADER_CONSTANTS } from '../../shared/Constants/TableHeaderConstants';

function BeatAreaTable(props) {
  const classes = tableStyles();
  const { beatAreas = [], isbeatAreaLoading } = props;

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.tableWithTopButtonContainer}>
        <MuiTable stickyHeader>
          <TableHead>
            <TableRow>
              {Object.keys(BEAT_AREA_HEADER_CONSTANTS).map((header) => (
                <StyledTableCell align="center" key={header}>
                  {BEAT_AREA_HEADER_CONSTANTS[header]}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {beatAreas.map((beatArea) => {
              return (
                <BeatAreaTableItem
                  key={beatArea.id}
                  beat={beatArea}
                  isbeatAreaLoading={isbeatAreaLoading}
                />
              );
            })}
          </TableBody>
        </MuiTable>
      </TableContainer>
    </Paper>
  );
}

export default React.memo(BeatAreaTable);
