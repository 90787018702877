import baseURL from '../shared/AxiosBaseUrl/baseURL';

class ZoneApi {
  static getAllZones() {
    return baseURL.get('/api/v1/zone/');
  }

  static getZone(id) {
    return baseURL.get(`/api/v1/zone/${id}`);
  }

  static createZone(formValues) {
    return baseURL.post('/api/v1/zone/', { ...formValues });
  }

  static editZone(id, formValues) {
    return baseURL.patch(`/api/v1/zone/${id}`, { ...formValues });
  }

  static deleteZone(id) {
    return baseURL.delete(`/api/v1/zone/${id}`);
  }
}

export default ZoneApi;
