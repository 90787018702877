import React from 'react';
import { Typography } from '@material-ui/core';

function SummaryRowInfo(props) {
  return (
    <div
      style={{
        flexDirection: 'row',
        display: 'flex'
      }}
    >
      <Typography component="h5" style={{ width: '60%' }}>
        {props.title}
      </Typography>
      <Typography component="h5" style={{ width: '40%' }}>
        {props.value}
      </Typography>
    </div>
  );
}

export default SummaryRowInfo;
