import React from 'react';
import { StyledTableRow, StyledTableCell } from '../../Styles/TableStyles';
import { formatInInr } from '../../shared/Utils/CommonUtils';

function OrderSkuTableItem(props) {
  const { sku } = props;

  return (
    <StyledTableRow>
      <StyledTableCell align="center">{sku.name}</StyledTableCell>
      <StyledTableCell align="center">
        {sku.parent_product_name}
      </StyledTableCell>
      <StyledTableCell align="center">{sku.ordered_quantity}</StyledTableCell>
      <StyledTableCell align="center">{sku.margin}%</StyledTableCell>
      <StyledTableCell align="center">
        {formatInInr(sku.landed_cost)}
      </StyledTableCell>
      <StyledTableCell align="center">
        {formatInInr(sku.total_cost)}
      </StyledTableCell>
    </StyledTableRow>
  );
}

export default OrderSkuTableItem;
