import React, { useState } from 'react';
import { connect } from 'react-redux';
import InventoryTable from '../../../components/InventoryTable';
import {
  editRetailer,
  fetchRetailer,
  fetchRetailerInventory
} from '../../../redux/retailer/retailer.actions';
import {
  getRetailerById,
  isRetailerRequestPending,
  getRetailerSkuInventory
} from '../../../redux/retailer/retailer.selector';
import history from '../../../routes/history';
import { ROUTE_TO_DISTRIBUTOR } from '../../../shared/Constants/RouteConstants';
import { setCancel } from '../../../redux/event/event.actions';
import BackdropWithLoader from '../../../components/BackdropWithLoader';
import useFetchReducerWithId from '../../../shared/Hooks/useFetchReducerWithId';
import useRefreshFetchReducerWithId from '../../../shared/Hooks/useRefreshFetchReducerWithId';
import { makeStyles } from '@material-ui/core';
import TableHeadingWithCreateButton from '../../../components/TableHeadingWithCreateButton';
import RetailerInventoryNewItemForm from './RetailerInventoryNewItemForm/RetailerInventoryNewItemForm';
import { fetchAllSkus } from '../../../redux/sku/sku.actions';
import {
  getAllSkus,
  isSkuRequestPending
} from '../../../redux/sku/sku.selector';
import RetailerInventoryEditItemForm from './RetailerInventoryEditItemForm';

const useStyles = makeStyles((theme) => ({
  content: {
    marginTop: theme.spacing(2)
  }
}));

const ManageRetailerInventory = (props) => {
  const {
    match: {
      params: { id }
    },
    retailerSkuInventories,
    editRetailerConnect,
    fetchRetailerConnect,
    fetchRetailerInventoryConnect,
    retailer = {},
    isRetailerLoading,
    setCancelConnect,
    fetchAllSkusConnect,
    skusPayload
  } = props;
  const retailerId = id;
  const classes = useStyles();

  useFetchReducerWithId(fetchRetailerConnect, retailerId, retailer);

  useRefreshFetchReducerWithId(fetchRetailerInventoryConnect, retailerId);

  const [openSkuForm, setOpenSkuForm] = useState(false);

  const handleClickOpen = () => {
    fetchAllSkusConnect();
    setOpenSkuForm(true);
  };

  const handleClose = () => {
    setOpenSkuForm(false);
  };

  const handleSubmit = (formValues) => {
    editRetailerConnect(retailerId, formValues);
  };

  const handleCancel = () => {
    setCancelConnect();
    history.push(ROUTE_TO_DISTRIBUTOR);
  };

  if (isRetailerLoading) {
    return <BackdropWithLoader />;
  }

  const getFilteredSkusPayload = () => {
    const retailerSkuIds = retailerSkuInventories.reduce((allIds, sku) => {
      return [...allIds, sku.sku_id];
    }, []);

    return skusPayload.filter((sku) => !retailerSkuIds.includes(sku.id));
  };

  return (
    <>
      <TableHeadingWithCreateButton
        component={RetailerInventoryNewItemForm}
        open={openSkuForm}
        handleButtonClick={handleClickOpen}
        handleModalClose={handleClose}
        showCreateModal
        buttonText="ADD NEW SKU"
        headerTitle="RETAILER STOCK MANAGEMENT"
        skusPayload={getFilteredSkusPayload()}
        retailerId={retailerId}
      />
      <div className={classes.content}>
        <InventoryTable
          skus={retailerSkuInventories}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
          EditItemComponent={RetailerInventoryEditItemForm}
          customerId={retailerId}
        />
      </div>
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    retailer: getRetailerById(state, ownProps),
    retailerSkuInventories: getRetailerSkuInventory(state, ownProps),
    isRetailerLoading: isRetailerRequestPending(state),
    isSkuRequestPending: isSkuRequestPending(state),
    skusPayload: getAllSkus(state)
  };
};

const mapDispatchToProps = {
  fetchAllSkusConnect: fetchAllSkus,
  fetchRetailerConnect: fetchRetailer,
  fetchRetailerInventoryConnect: fetchRetailerInventory,
  editRetailerConnect: editRetailer,
  setCancelConnect: setCancel
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManageRetailerInventory);
