import React from 'react';
import { connect } from 'react-redux';
import AlertSnackBar from '../../components/AlertSnackBar';

const Alerts = ({ alerts }) =>
  alerts !== null &&
  alerts.length > 0 &&
  alerts.map((alert) => {
    return (
      <div key={alert.id}>
        <AlertSnackBar alert={alert} />
      </div>
    );
  });

const mapStateToProps = (state) => ({
  alerts: Object.values(state.alert)
});

export default connect(mapStateToProps)(Alerts);
