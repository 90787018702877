import React from 'react';
import { connect } from 'react-redux';
import { Container, Avatar, Typography } from '@material-ui/core';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import useRefreshFetchReducer from '../../../shared/Hooks/useRefreshFetchReducer';
import formStyles from '../../../components/Styles/FormStyles';
import CreateBeatForm from '../../../components/CreateBeatForm';
import { TITLES } from '../../../shared/Constants/Constant';
import { createBeatArea } from '../../../redux/beatArea/beatArea.actions';
import { isBeatAreaRequestPending } from '../../../redux/beatArea/beatArea.selector';
import {
  getAllAreas,
  isAreaRequestPending
} from '../../../redux/area/area.selector';
import {
  getAllDistricts,
  isDistrictRequestPending
} from '../../../redux/district/district.selector';
import {
  getAllZones,
  isZoneRequestPending
} from '../../../redux/zone/zone.selector';
import BackdropWithLoader from '../../../components/BackdropWithLoader';
import history from '../../../routes/history';
import { ROUTE_TO_AREA } from '../../../shared/Constants/RouteConstants';
import { setCancel } from '../../../redux/event/event.actions';
import { fetchAllZonesAndDistrictsAndAreas } from '../../../redux/territory/territory.actions';

function CreateBeat(props) {
  const {
    fetchAllZonesAndDistrictsAndAreasConnect,
    zonesPayload,
    isZonesLoading,
    districtsPayload,
    isDistrictsLoading,
    areasPayload,
    isAreasLoading,
    isbeatAreaLoading,
    createBeatAreaConnect,
    setCancelConnect
  } = props;
  const classes = formStyles();

  useRefreshFetchReducer(fetchAllZonesAndDistrictsAndAreasConnect);

  const handleSubmit = async (formValues) => {
    await createBeatAreaConnect(formValues);
  };

  const handleCancel = () => {
    setCancelConnect();
    history.push(ROUTE_TO_AREA);
  };

  if (
    isbeatAreaLoading ||
    isAreasLoading ||
    isDistrictsLoading ||
    isZonesLoading
  ) {
    return <BackdropWithLoader />;
  }

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <AccountCircleIcon color="primary" />
        </Avatar>
        <Typography component="h1" variant="h5">
          {TITLES.CREATE_BEATAREA}
        </Typography>
        <CreateBeatForm
          zones={zonesPayload}
          districts={districtsPayload}
          areas={areasPayload}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
        />
      </div>
    </Container>
  );
}

const mapStateToProps = (state) => {
  return {
    isZonesLoading: isZoneRequestPending(state),
    zonesPayload: getAllZones(state),
    isDistrictsLoading: isDistrictRequestPending(state),
    districtsPayload: getAllDistricts(state),
    isAreasLoading: isAreaRequestPending(state),
    areasPayload: getAllAreas(state),
    isbeatAreaLoading: isBeatAreaRequestPending(state)
  };
};

const mapDispatchToProps = {
  createBeatAreaConnect: createBeatArea,
  fetchAllZonesAndDistrictsAndAreasConnect: fetchAllZonesAndDistrictsAndAreas,
  setCancelConnect: setCancel
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateBeat);
