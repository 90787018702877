import React from 'react';
import Alert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';
import { Container } from '@material-ui/core';

const useStyles = makeStyles({
  paper: {
    marginTop: '15px'
  }
});

export default function AlertSnackBar({ alert }) {
  const classes = useStyles();

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <Alert variant="filled" severity={alert.alertType}>
          {alert.message}
        </Alert>
      </div>
    </Container>
  );
}
