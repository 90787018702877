import { createSelector } from 'reselect';

export const getBeatAreasSelector = (state) => state.beatAreas;
export const getAllBeatAreasDataSelector = (state) => state.beatAreas.data;
export const getBeatAreaIdSelector = (state, props) => props.match.params.id;

export const isBeatAreaRequestPending = createSelector(
  [getBeatAreasSelector],
  (beatAreas) => beatAreas.isRequestPending
);

export const getAllBeatAreas = createSelector(
  getAllBeatAreasDataSelector,
  (beatAreas) => Object.values(beatAreas)
);

export const getBeatAreaById = createSelector(
  [getAllBeatAreasDataSelector, getBeatAreaIdSelector],
  (beatAreas, id) => beatAreas[id]
);
