import React from 'react';
import { connect } from 'react-redux';
import tableStyles from '../../components/Styles/TableStyles';
import CreateToolBar from '../../shared/CreateToolBar';
import SuperStockistTable from '../../components/SuperStockistTable';
import useRefreshFetchReducer from '../../shared/Hooks/useRefreshFetchReducer';
import { ROUTE_TO_CREATE_SUPER_STOCKIST } from '../../shared/Constants/RouteConstants';
import { TITLES } from '../../shared/Constants/Constant';
import { fetchAllSuperStockists } from '../../redux/superStockist/superStockist.actions';
import {
  getAllSuperStockists,
  isSuperStockistRequestPending
} from '../../redux/superStockist/superStockist.selector';
import { getCancelledState } from '../../redux/event/event.selector';
import { resetCancel } from '../../redux/event/event.actions';
import { currentUserRole, currentUserId } from '../../redux/auth/auth.selector';
import { SUPER_STOCKIST_CREATE_ACTION_ALLOWED_USERS } from '../../shared/Constants/UserPermissons';

function SuperStockist(props) {
  const classes = tableStyles();
  const {
    superStockistsPayload,
    fetchAllSuperStockistsConnect,
    resetCancelConnect,
    isCancelledEvent,
    isSuperStockistLoading,
    authUserRole,
    authUserId
  } = props;

  useRefreshFetchReducer(
    fetchAllSuperStockistsConnect,
    resetCancelConnect,
    isCancelledEvent
  );

  return (
    <>
      <CreateToolBar
        route={ROUTE_TO_CREATE_SUPER_STOCKIST}
        buttonText={TITLES.CREATE_SUPER_STOCKIST}
        showSpinner={isSuperStockistLoading}
        isAuthenticatedToAdd={SUPER_STOCKIST_CREATE_ACTION_ALLOWED_USERS.includes(
          authUserRole
        )}
      />
      <div className={classes.content}>
        <SuperStockistTable
          superStockists={superStockistsPayload}
          isSuperStockistLoading={isSuperStockistLoading}
          currentUserRole={authUserRole}
          currentUserId={authUserId}
        />
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    isSuperStockistLoading: isSuperStockistRequestPending(state),
    superStockistsPayload: getAllSuperStockists(state),
    isCancelledEvent: getCancelledState(state),
    authUserRole: currentUserRole(state),
    authUserId: currentUserId(state)
  };
};

const mapDispatchToProps = {
  fetchAllSuperStockistsConnect: fetchAllSuperStockists,
  resetCancelConnect: resetCancel
};

SuperStockist.defaultProps = {
  superStockistsPayload: [],
  isSuperStockistLoading: false
};

const memoizedSuperStockist = React.memo(SuperStockist);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(memoizedSuperStockist);
