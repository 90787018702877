import React from 'react';
import { get } from 'lodash';
import { StyledTableCell, StyledTableRow } from '../../Styles/TableStyles';
import moment from 'moment';
import { formatInInr } from '../../shared/Utils/CommonUtils';
import { EMPLOYEE_SALES_ACTIVITIES } from '../../shared/Constants/Constant';

function EmployeeSecondarySalesReportTableItem({
  salesActivity,
  showDateColumn,
  showMapIdColumn
}) {
  const { retailer, activity, createdAt, beat, mapId } = salesActivity;
  const orderValue = get(salesActivity, 'secondary_order.total_order_value');

  return (
    <StyledTableRow>
      {showMapIdColumn && (
        <StyledTableCell align="center">{mapId || ''}</StyledTableCell>
      )}
      {showDateColumn && (
        <StyledTableCell align="center">
          {moment(createdAt).format('DD-MMM-YYYY')}
        </StyledTableCell>
      )}
      <StyledTableCell align="center">
        {moment(createdAt).format('hh:mm A')}
      </StyledTableCell>
      <StyledTableCell component="th" scope="row" align="center">
        {retailer.name || '-'}
      </StyledTableCell>
      <StyledTableCell component="th" scope="row" align="center">
        {beat.name || '-'}
      </StyledTableCell>
      <StyledTableCell component="th" scope="row" align="center">
        {EMPLOYEE_SALES_ACTIVITIES[activity]}
      </StyledTableCell>
      <StyledTableCell component="th" scope="row" align="center">
        {orderValue ? formatInInr(orderValue) : '-'}
      </StyledTableCell>
      <StyledTableCell component="th" scope="row" align="center">
        {get(salesActivity, 'secondary_order.invoice_number', '-')}
      </StyledTableCell>
      <StyledTableCell component="th" scope="row" align="center">
        {get(salesActivity, 'secondary_order.total_ordered_quantities', '-')}
      </StyledTableCell>
    </StyledTableRow>
  );
}

export default EmployeeSecondarySalesReportTableItem;
