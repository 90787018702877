import _ from 'lodash';
import * as UserActionTypes from './user.types';

const initialState = {
  data: {},
  attendanceData: {},
  isRequestPending: false
};

const userDataReducer = (state = {}, action) => {
  const { payload, type } = action;

  switch (type) {
    case UserActionTypes.FETCH_USERS_SUCCESS:
      return { ..._.mapKeys(payload, 'id') };

    case UserActionTypes.FETCH_USER_SUCCESS:
      return { ...state, [payload.id]: payload };

    case UserActionTypes.CREATE_USER_SUCCESS:
      return { ...state, [payload.id]: payload };

    case UserActionTypes.UPDATE_USER_SUCCESS:
      return { ...state, [payload.id]: payload };

    case UserActionTypes.DELETE_USER_SUCCESS:
      return _.omit(state, payload);

    default:
      return state;
  }
};

const userReducer = (state = initialState, action) => {
  const { type } = action;

  switch (type) {
    case UserActionTypes.FETCH_USERS_REQUEST:
    case UserActionTypes.FETCH_USERS_ATTENDANCE_REQUEST:
      return {
        ...state,
        isRequestPending: true
      };

    case UserActionTypes.FETCH_USERS_SUCCESS:
      return {
        ...state,
        isRequestPending: false,
        data: userDataReducer(state.data, action)
      };

    case UserActionTypes.FETCH_USERS_ATTENDANCE_SUCCESS:
      return {
        ...state,
        isRequestPending: false,
        attendanceData: action.payload
      };

    case UserActionTypes.FETCH_USERS_ERROR:
    case UserActionTypes.FETCH_USERS_ATTENDANCE_ERROR:
      return {
        ...state,
        isRequestPending: false
      };

    case UserActionTypes.FETCH_USER_REQUEST:
      return {
        ...state,
        isRequestPending: true
      };

    case UserActionTypes.FETCH_USER_SUCCESS:
      return {
        ...state,
        isRequestPending: false,
        data: userDataReducer(state.data, action)
      };

    case UserActionTypes.FETCH_USER_ERROR:
      return {
        ...state,
        isRequestPending: false
      };

    case UserActionTypes.CREATE_USER_REQUEST:
      return {
        ...state,
        isRequestPending: true
      };

    case UserActionTypes.CREATE_USER_SUCCESS:
      return {
        ...state,
        isRequestPending: false,
        data: userDataReducer(state.data, action)
      };

    case UserActionTypes.CREATE_USER_ERROR:
      return {
        ...state,
        isRequestPending: false
      };

    case UserActionTypes.UPDATE_USER_REQUEST:
      return {
        ...state,
        isRequestPending: true
      };

    case UserActionTypes.UPDATE_USER_SUCCESS:
      return {
        ...state,
        isRequestPending: false,
        data: userDataReducer(state.data, action)
      };

    case UserActionTypes.UPDATE_USER_ERROR:
      return {
        ...state,
        isRequestPending: false
      };

    case UserActionTypes.DELETE_USER_REQUEST:
      return {
        ...state,
        isRequestPending: true
      };

    case UserActionTypes.DELETE_USER_SUCCESS:
      return {
        ...state,
        isRequestPending: false,
        data: userDataReducer(state.data, action)
      };

    case UserActionTypes.DELETE_USER_ERROR:
      return {
        ...state,
        isRequestPending: false
      };

    case UserActionTypes.CLEAR_USER_ATTENDANCE_DATA:
      return {
        ...state,
        attendanceData: {}
      };

    default:
      return state;
  }
};

export default userReducer;
