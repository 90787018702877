import { get, has } from 'lodash';
import { createSelector } from 'reselect';
import { formatInInr } from '../../shared/Utils/CommonUtils';

export const primaryOrderSelector = (state) => state.primaryOrders.data;
export const primaryOrderCartSelector = (state) => state.primaryOrders.cart;
export const primaryOrderDataSelector = (state) => state.primaryOrders.data;
export const primaryOrderByIdSelector = (state, props) => props.match.params.id;
export const skuSelector = (state) => state.skus.data;

export const getAllPrimaryOrders = createSelector(
  primaryOrderSelector,
  (primaryOrders) => Object.values(primaryOrders)
);

export const isPrimaryOrderRequestPending = (state) =>
  state.primaryOrders.isRequestPending;

export const isPrimaryOrderModalRequestPending = (state) =>
  state.primaryOrders.isModalUpdateRequestPending;

export const primaryOrderPaginationSelector = (state) =>
  state.primaryOrders.pagination;

export const isNextPagePrimaryOrder = createSelector(
  primaryOrderPaginationSelector,
  (pagination) => has(pagination, 'next.page')
);

export const nextPagePrimaryOrder = createSelector(
  primaryOrderPaginationSelector,
  (pagination) => get(pagination, 'next.page', 1)
);

export const getCurrentPaginationPage = (state) =>
  state.primaryOrders.primaryOrderPaginationPage;

export const getErrorMessage = (state) => state.primaryOrders.error.message;

export const getPrimaryOrderById = createSelector(
  [primaryOrderSelector, primaryOrderByIdSelector],
  (primaryOrders, id) => primaryOrders[id]
);

export const getPrimaryOrderCartItems = createSelector(
  [primaryOrderCartSelector],
  (primaryOrderCartItems) => Object.values(primaryOrderCartItems)
);

export const getPrimaryOrderCartTotalQtyInCases = createSelector(
  [getPrimaryOrderCartItems],
  (primaryOrderCartItems) => {
    return primaryOrderCartItems.reduce((total, cartItem) => {
      return (total += Number(cartItem.quantityInCases));
    }, 0);
  }
);

export const getPrimaryOrderCartTotalQtyInPcs = createSelector(
  [getPrimaryOrderCartItems],
  (primaryOrderCartItems) => {
    return primaryOrderCartItems.reduce((total, cartItem) => {
      return (total += Number(cartItem.quantityInPcs));
    }, 0);
  }
);

export const getPrimaryOrderTotalCostQuantity = createSelector(
  [getPrimaryOrderCartItems],
  (primaryOrderCartItems) => {
    return formatInInr(
      primaryOrderCartItems.reduce((total, cartItem) => {
        return (total += Number(cartItem.totalSkuCost));
      }, 0)
    );
  }
);

export const getFilteredSkusPayload = createSelector(
  [skuSelector, primaryOrderCartSelector],
  (skusPayload, cartItems) => {
    return Object.values(skusPayload).filter(
      (sku) => !Object.keys(cartItems).includes(sku.id)
    );
  }
);

export const getPrimaryOrderCustomer = (state) => state.primaryOrders.customer;
