import ProductApi from '../../apis/ProductAPI';
import * as ProductActionTypes from './product.types';
import STATUS from '../../shared/Constants/apiStatus';
import { ROUTE_TO_PARENT_PRODUCT } from '../../shared/Constants/RouteConstants';

export const fetchAllProducts = () => async (dispatch) => {
  dispatch({
    type: ProductActionTypes.FETCH_PRODUCTS_REQUEST
  });

  try {
    const response = await ProductApi.getAllProducts({
      sort: 'name'
    });
    if (response.data.status === STATUS.OK) {
      dispatch({
        type: ProductActionTypes.FETCH_PRODUCTS_SUCCESS,
        payload: response.data.parentProducts,
        error: null,
        route: null,
        success: null
      });
    }
  } catch (error) {
    dispatch({
      type: ProductActionTypes.FETCH_PRODUCTS_ERROR,
      error: error.response.data,
      payload: null,
      route: null,
      success: null
    });
  }
};

export const fetchProduct = (id) => async (dispatch) => {
  dispatch({
    type: ProductActionTypes.FETCH_PRODUCT_REQUEST
  });

  try {
    const response = await ProductApi.getProduct(id);
    if (response.data.status === STATUS.OK) {
      dispatch({
        type: ProductActionTypes.FETCH_PRODUCT_SUCCESS,
        payload: response.data.parentProduct,
        error: null,
        route: null,
        success: null
      });
    }
  } catch (error) {
    dispatch({
      type: ProductActionTypes.FETCH_PRODUCT_ERROR,
      error: error.response.data,
      payload: null,
      route: ROUTE_TO_PARENT_PRODUCT,
      success: null
    });
  }
};

export const createProduct = (formValues) => async (dispatch) => {
  dispatch({
    type: ProductActionTypes.CREATE_PRODUCT_REQUEST
  });

  try {
    const response = await ProductApi.createProduct(formValues);

    if (response.data.status === STATUS.OK) {
      dispatch({
        type: ProductActionTypes.CREATE_PRODUCT_SUCCESS,
        payload: response.data.parentProduct,
        error: null,
        route: ROUTE_TO_PARENT_PRODUCT,
        success: response.data.message
      });
    }
  } catch (error) {
    dispatch({
      type: ProductActionTypes.CREATE_PRODUCT_ERROR,
      error: error.response.data,
      payload: null,
      route: ROUTE_TO_PARENT_PRODUCT,
      success: null
    });
  }
};

export const editProduct =
  (id, formValues, callback, routeTo) => async (dispatch) => {
    dispatch({
      type: ProductActionTypes.UPDATE_PRODUCT_REQUEST
    });

    try {
      const response = await ProductApi.editProduct(id, formValues);
      if (response.data.status === STATUS.OK) {
        dispatch({
          type: ProductActionTypes.UPDATE_PRODUCT_SUCCESS,
          payload: response.data.parentProduct,
          error: null,
          route: routeTo,
          success: response.data.message
        });
      }

      if (callback) {
        callback();
      }
    } catch (error) {
      dispatch({
        type: ProductActionTypes.UPDATE_PRODUCT_ERROR,
        error: error.response.data,
        payload: null,
        route: ROUTE_TO_PARENT_PRODUCT,
        success: null
      });
    }
  };

export const deleteProduct = (id) => async (dispatch) => {
  dispatch({
    type: ProductActionTypes.DELETE_PRODUCT_REQUEST
  });

  try {
    const response = await ProductApi.deleteProduct(id);
    if (response.data.status === STATUS.OK) {
      dispatch({
        type: ProductActionTypes.DELETE_PRODUCT_SUCCESS,
        payload: id,
        error: null,
        route: ROUTE_TO_PARENT_PRODUCT,
        success: response.data.message
      });
    }
  } catch (error) {
    dispatch({
      type: ProductActionTypes.DELETE_PRODUCT_ERROR,
      payload: null,
      error: error.response.data,
      route: ROUTE_TO_PARENT_PRODUCT,
      success: null
    });
  }
};
