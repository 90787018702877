import { useState, useEffect } from 'react';
import { get } from 'lodash';

const DecimalNumberRegex = /^\d*\.?\d*$/;
const OnlyNumberRegex = /^[-+]?[0-9]\d*$/;

const useForm = (callback, validate, properties) => {
  const [values, setValues] = useState(get(properties, 'initialValues', {}));
  const [errors, setErrors] = useState({});
  const [isFormSubmit, setIsFormSubmit] = useState(false);

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isFormSubmit) {
      callback();
    }
  }, [errors]);

  useEffect(() => {
    if (
      properties &&
      properties.populatedValues &&
      Object.keys(properties.populatedValues).length > 0
    ) {
      setValues(get(properties, 'populatedValues', {}));
      properties.resetPopulatedValues({});
    }
  }, [properties]);

  const resetValues = () => {
    setValues({});
  };

  const resetInitialValues = () => {
    setValues(get(properties, 'initialValues', {}));
  };

  const handleSubmit = (event) => {
    if (event) {
      event.preventDefault();
    }
    setErrors(validate(values, properties));
    setIsFormSubmit(true);
  };

  const handleChange = (event) => {
    event.persist();
    const { name, value } = event.target;
    setValues((values) => ({
      ...values,
      [name]: value
    }));
  };

  const handleNumericInputChange = (event) => {
    event.persist();
    const { name, value } = event.target;
    if (value === '' || value.match(OnlyNumberRegex) != null) {
      setValues((values) => ({
        ...values,
        [name]: value
      }));
    }
  };

  const handleDecimalInputChange = (event) => {
    event.persist();
    const { name, value } = event.target;
    if (value === '' || value.match(DecimalNumberRegex) != null) {
      setValues((values) => ({
        ...values,
        [name]: value
      }));
    }
  };

  const handleDecimalInputChangeOnBlur = (event) => {
    event.persist();
    const { name, value } = event.target;
    if (value === '' || value.match(DecimalNumberRegex) != null) {
      setValues((values) => ({
        ...values,
        [name]: Number(value).toFixed(2)
      }));
    }
  };

  return {
    values,
    errors,
    handleChange,
    handleDecimalInputChange,
    handleDecimalInputChangeOnBlur,
    handleNumericInputChange,
    handleSubmit,
    resetValues,
    resetInitialValues
  };
};

export default useForm;
