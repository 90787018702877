import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Typography
} from '@material-ui/core';

const Modal = (props) => {
  const { title, content, isModalVisible, actions, onClose } = props;

  return (
    <Dialog open={isModalVisible} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle align="center">
        <Typography
          align="center"
          style={{
            fontSize: '18px',
            fontWeight: 'normal',
            color: '#d50000',
            textAlign: 'center'
          }}
        >
          {title}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText align="center">{content}</DialogContentText>
      </DialogContent>
      <DialogActions>
        {actions.map(({ buttonAction, buttonText, buttonStyle }) => {
          return (
            <Button
              onClick={buttonAction}
              color="primary"
              key={buttonText}
              style={{ ...buttonStyle }}
            >
              {buttonText}
            </Button>
          );
        })}
      </DialogActions>
    </Dialog>
  );
};

Modal.defaultProps = {
  isModalVisible: false
};

export default Modal;
