import React, { useEffect, useState } from 'react';
import {
  Container,
  Grid,
  Divider,
  MenuItem,
  FormLabel,
  makeStyles
} from '@material-ui/core';
import EmployeeSecondarySalesReportTable from '../../components/EmployeeSecondarySalesReportTable';
import { connect } from 'react-redux';
import FormSelect from '../../components/FormSelect';
import DateRangePicker from '../../components/DateRangePicker';
import { fetchAllUsers } from '../../redux/user/user.actions';
import {
  getAllUsers,
  isUserRequestPending
} from '../../redux/user/user.selector';
import {
  fetchEmployeeSecondarySales,
  resetSecondarySalesData
} from '../../redux/employee-secondary-sales/employee-secondary-sales.actions';
import {
  getEmployeeSecondarySales,
  isEmployeeSecondarySalesRequestPending
} from '../../redux/employee-secondary-sales/employee-secondary-sales.selectors';
import commonStyles from '../../Styles/CommonStyles';

const useStyles = makeStyles(() => ({
  flexVerticalDatePicker: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-evenly'
  }
}));

function EmployeeSecondarySalesByRange(props) {
  const {
    isUserLoading,
    fetchAllUsersConnect,
    usersPayload,
    fetchEmployeeSecondarySalesConnect,
    entriesPayload,
    isRequestPending,
    resetSecondarySalesDataConnect
  } = props;

  const classes = useStyles();
  const commonClasses = commonStyles();
  const [userId, setUserId] = useState('');
  const [dateRange, setDateRanges] = useState([]);

  useEffect(() => {
    fetchAllUsersConnect();
  }, []);

  useEffect(() => {
    let mounted = true;
    const controller = new AbortController();

    const loadActivities = async () => {
      if (userId && dateRange.length === 2) {
        fetchEmployeeSecondarySalesConnect(userId, {
          fromDate: dateRange[0].toISOString(),
          toDate: dateRange[1].toISOString()
        });
      }
    };

    if (mounted) {
      loadActivities();
    }

    return () => {
      controller.abort();
      resetSecondarySalesDataConnect();
      mounted = false;
    };
  }, [fetchAllUsersConnect, userId, dateRange]);

  const handleUserChange = (event) => {
    event.persist();

    const { value } = event.target;
    setUserId(value);
  };

  return (
    <Container className={commonClasses.root} maxWidth="lg">
      <Grid container spacing={3}>
        <Grid item lg={12} md={12} xs={12}>
          <Grid container spacing={3} direction="row">
            <Grid item lg={6} md={6} xs={12}>
              <FormSelect
                label="User Name"
                name="user"
                value={userId || ''}
                onChange={handleUserChange}
                showDefaultEmptyValue={false}
                showEmptyErrorMenuItem={!usersPayload.length}
                disabled={isUserLoading}
                required
              >
                {usersPayload.map((user) => {
                  const { name, id } = user;
                  return (
                    <MenuItem key={id} value={id}>
                      {name}
                    </MenuItem>
                  );
                })}
              </FormSelect>
            </Grid>
            <Grid
              item
              lg={6}
              md={6}
              xs={12}
              className={classes.flexVerticalDatePicker}
            >
              <FormLabel>Choose Time Period</FormLabel>
              <DateRangePicker
                selectedDates={dateRange}
                handleSelectedDates={setDateRanges}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item md={12} xs={12} className={commonClasses.table}>
              <EmployeeSecondarySalesReportTable
                secondaryActivities={entriesPayload}
                isLoading={isRequestPending}
                isUserSelected={userId}
                showDateColumn={true}
              />
            </Grid>
          </Grid>
          <Divider />
        </Grid>
      </Grid>
    </Container>
  );
}

const mapStateToProps = (state) => {
  return {
    isUserLoading: isUserRequestPending(state),
    usersPayload: getAllUsers(state),
    entriesPayload: getEmployeeSecondarySales(state),
    isRequestPending: isEmployeeSecondarySalesRequestPending(state)
  };
};

const mapDispatchToProps = {
  fetchEmployeeSecondarySalesConnect: fetchEmployeeSecondarySales,
  fetchAllUsersConnect: fetchAllUsers,
  resetSecondarySalesDataConnect: resetSecondarySalesData
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EmployeeSecondarySalesByRange);
