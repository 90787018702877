import _ from 'lodash';
import * as SuperStockistActionTypes from './superStockist.types';

const initialState = {
  data: {},
  isRequestPending: false,
  isSuperStockistInventoryModalRequestPending: false
};

const superStockistDataReducer = (state = {}, action) => {
  const { payload, type } = action;

  switch (type) {
    case SuperStockistActionTypes.FETCH_SUPER_STOCKISTS_SUCCESS:
      return { ..._.mapKeys(payload, 'id') };

    case SuperStockistActionTypes.FETCH_SUPER_STOCKIST_SUCCESS:
      return { ...state, [payload.id]: payload };

    case SuperStockistActionTypes.FETCH_SUPER_STOCKIST_INVENTORY_SUCCESS:
      return {
        ...state,
        [payload.id]: {
          ...state[payload.id],
          sku_items: { ..._.mapKeys(payload.sku_items, 'inventory_id') }
        }
      };

    case SuperStockistActionTypes.CREATE_SUPER_STOCKIST_INVENTORY_SUCCESS:
    case SuperStockistActionTypes.UPDATE_SUPER_STOCKIST_INVENTORY_SUCCESS:
      return {
        ...state,
        [payload.super_stockist]: {
          ...state[payload.super_stockist],
          sku_items: {
            ...state[payload.super_stockist].sku_items,
            [payload.inventory_id]: payload
          }
        }
      };

    case SuperStockistActionTypes.CREATE_SUPER_STOCKIST_SUCCESS:
      return { ...state, [payload.id]: payload };

    case SuperStockistActionTypes.UPDATE_SUPER_STOCKIST_SUCCESS:
      return { ...state, [payload.id]: payload };

    case SuperStockistActionTypes.DELETE_SUPER_STOCKIST_SUCCESS:
      return _.omit(state, payload);

    default:
      return state;
  }
};

const superStockistReducer = (state = initialState, action) => {
  const { type } = action;

  switch (type) {
    case SuperStockistActionTypes.FETCH_SUPER_STOCKISTS_REQUEST:
      return {
        ...state,
        isRequestPending: true
      };

    case SuperStockistActionTypes.FETCH_SUPER_STOCKISTS_SUCCESS:
      return {
        ...state,
        isRequestPending: false,
        data: superStockistDataReducer(state.data, action)
      };

    case SuperStockistActionTypes.FETCH_SUPER_STOCKISTS_ERROR:
      return {
        ...state,
        isRequestPending: false
      };

    case SuperStockistActionTypes.FETCH_SUPER_STOCKIST_REQUEST:
      return {
        ...state,
        isRequestPending: true
      };

    case SuperStockistActionTypes.FETCH_SUPER_STOCKIST_SUCCESS:
      return {
        ...state,
        isRequestPending: false,
        data: superStockistDataReducer(state.data, action)
      };

    case SuperStockistActionTypes.FETCH_SUPER_STOCKIST_ERROR:
      return {
        ...state,
        isRequestPending: false
      };

    case SuperStockistActionTypes.FETCH_SUPER_STOCKIST_INVENTORY_REQUEST:
      return {
        ...state,
        isRequestPending: true
      };

    case SuperStockistActionTypes.FETCH_SUPER_STOCKIST_INVENTORY_SUCCESS:
      return {
        ...state,
        isRequestPending: false,
        data: superStockistDataReducer(state.data, action)
      };

    case SuperStockistActionTypes.FETCH_SUPER_STOCKIST_INVENTORY_ERROR:
      return {
        ...state,
        isRequestPending: false
      };

    case SuperStockistActionTypes.CREATE_SUPER_STOCKIST_REQUEST:
      return {
        ...state,
        isRequestPending: true
      };

    case SuperStockistActionTypes.CREATE_SUPER_STOCKIST_SUCCESS:
      return {
        ...state,
        isRequestPending: false,
        data: superStockistDataReducer(state.data, action)
      };

    case SuperStockistActionTypes.CREATE_SUPER_STOCKIST_ERROR:
      return {
        ...state,
        isRequestPending: false
      };

    case SuperStockistActionTypes.CREATE_SUPER_STOCKIST_INVENTORY_REQUEST:
      return {
        ...state,
        isSuperStockistInventoryModalRequestPending: true
      };

    case SuperStockistActionTypes.CREATE_SUPER_STOCKIST_INVENTORY_SUCCESS:
      return {
        ...state,
        isSuperStockistInventoryModalRequestPending: false,
        data: superStockistDataReducer(state.data, action)
      };

    case SuperStockistActionTypes.CREATE_SUPER_STOCKIST_INVENTORY_ERROR:
      return {
        ...state,
        isSuperStockistInventoryModalRequestPending: false
      };

    case SuperStockistActionTypes.UPDATE_SUPER_STOCKIST_REQUEST:
      return {
        ...state,
        isRequestPending: true
      };

    case SuperStockistActionTypes.UPDATE_SUPER_STOCKIST_SUCCESS:
      return {
        ...state,
        isRequestPending: false,
        data: superStockistDataReducer(state.data, action)
      };

    case SuperStockistActionTypes.UPDATE_SUPER_STOCKIST_ERROR:
      return {
        ...state,
        isRequestPending: false
      };

    case SuperStockistActionTypes.UPDATE_SUPER_STOCKIST_INVENTORY_REQUEST:
      return {
        ...state,
        isSuperStockistInventoryModalRequestPending: true
      };

    case SuperStockistActionTypes.UPDATE_SUPER_STOCKIST_INVENTORY_SUCCESS:
      return {
        ...state,
        isSuperStockistInventoryModalRequestPending: false,
        data: superStockistDataReducer(state.data, action)
      };

    case SuperStockistActionTypes.UPDATE_SUPER_STOCKIST_INVENTORY_ERROR:
      return {
        ...state,
        isSuperStockistInventoryModalRequestPending: false
      };

    case SuperStockistActionTypes.DELETE_SUPER_STOCKIST_REQUEST:
      return {
        ...state,
        isRequestPending: true
      };

    case SuperStockistActionTypes.DELETE_SUPER_STOCKIST_SUCCESS:
      return {
        ...state,
        isRequestPending: false,
        data: superStockistDataReducer(state.data, action)
      };

    case SuperStockistActionTypes.DELETE_SUPER_STOCKIST_ERROR:
      return {
        ...state,
        isRequestPending: false
      };

    default:
      return state;
  }
};

export default superStockistReducer;
