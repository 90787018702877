import React, { useCallback, useState, useEffect } from 'react';
import { GoogleMap, Marker, InfoWindow } from '@react-google-maps/api';
import moment from 'moment';
import { size } from 'lodash';

function GoogleMapsMultiMarker(props) {
  const { entries } = props;
  const [map, setMap] = useState(null);
  const [selectedMarker, setSelectedMarker] = useState(null);

  const onLoad = useCallback((map) => setMap(map), []);

  useEffect(() => {
    if (map) {
      const bounds = new window.google.maps.LatLngBounds();
      entries.forEach((marker) => {
        const position = new window.google.maps.LatLng(marker.lat, marker.lng);
        bounds.extend(position);
      });
      map.fitBounds(bounds);
    }
  }, [map, props.entries]);

  const onUnmount = useCallback((map) => {
    setMap(null);
  }, []);

  const handleMarkerTap = (marker) => {
    setSelectedMarker(marker);
  };

  return (
    <GoogleMap
      id="map"
      zoom={15}
      mapContainerStyle={{
        height: '50vh',
        margin: 'auto'
      }}
      options={{
        mapTypeControlOptions: {
          mapTypeIds: [window.google.maps.MapTypeId.ROADMAP]
        },
        clickableIcons: false,
        disableDefaultUI: true,
        zoomControl: true
      }}
      onLoad={onLoad}
      onUnmount={onUnmount}
    >
      {entries.map((marker, index) => {
        return (
          <Marker
            key={index}
            position={{ lat: marker.lat, lng: marker.lng }}
            onClick={() => {
              handleMarkerTap(marker);
            }}
            onCloseClick={() => {
              handleMarkerTap(null);
            }}
            options={{
              label: marker.mapId.toString()
            }}
          />
        );
      })}
      {size(selectedMarker) > 0 ? (
        <InfoWindow
          onCloseClick={() => {
            handleMarkerTap(null);
          }}
          position={{
            lat: selectedMarker.lat,
            lng: selectedMarker.lng
          }}
        >
          <div>
            <h2 style={{ fontFamily: 'Archia', fontSize: 12 }}>
              {moment(selectedMarker.markerDateAndTime).format(
                'DD-MMM-YYYY hh:mm A'
              )}
            </h2>
            <p>{selectedMarker.markerPrimaryText || ''}</p>
            <p>{selectedMarker.markerSecondaryText || ''}</p>
          </div>
        </InfoWindow>
      ) : null}
    </GoogleMap>
  );
}

export default GoogleMapsMultiMarker;
