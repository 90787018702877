import baseURL from '../shared/AxiosBaseUrl/baseURL';

class BeatAreaAPI {
  static getAllBeatAreas() {
    return baseURL.get('/api/v1/beat/');
  }

  static getBeatArea(id) {
    return baseURL.get(`/api/v1/beat/${id}`);
  }

  static createBeatArea(formValues) {
    return baseURL.post('/api/v1/beat/', { ...formValues });
  }

  static editBeatArea(id, formValues) {
    return baseURL.patch(`/api/v1/beat/${id}`, { ...formValues });
  }

  static deleteBeatArea(id) {
    return baseURL.delete(`/api/v1/beat/${id}`);
  }
}

export default BeatAreaAPI;
