import React, { useState } from 'react';
import { connect } from 'react-redux';
import {
  fetchAllSecondPrimaryOrders,
  deleteSecondPrimaryOrder
} from '../../../redux/second-primary-order/second-primary-order.actions';
import {
  isSecondPrimaryOrderRequestPending,
  getAllSecondPrimaryOrders,
  getSecondPrimaryOrderById
} from '../../../redux/second-primary-order/second-primary-order.selector';
import useFetchReducer from '../../../shared/Hooks/useFetchReducer';
import Modal from '../../../components/Modal';
import history from '../../../routes/history';
import { TITLES } from '../../../shared/Constants/Constant';
import { ROUTE_TO_RETAILER } from '../../../shared/Constants/RouteConstants';
import BackdropWithLoader from '../../../components/BackdropWithLoader';
import { setCancel } from '../../../redux/event/event.actions';

function DeleteSecondPrimaryOrder(props) {
  const [showDeleteModal, setShowDeleteModal] = useState(true);
  const {
    fetchAllSecondPrimaryOrdersConnect,
    secondPrimaryOrdersPayload,
    deleteSecondPrimaryOrderConnect,
    isLoading,
    secondPrimaryOrder = {},
    match: {
      params: { id }
    },
    setCancelConnect
  } = props;
  const orderId = id;

  useFetchReducer(
    fetchAllSecondPrimaryOrdersConnect,
    secondPrimaryOrdersPayload
  );

  const handleDelete = async () => {
    await deleteSecondPrimaryOrderConnect(orderId);
    setShowDeleteModal(false);
  };

  const handleClose = () => {
    setShowDeleteModal(false);
    setCancelConnect();
    history.push(ROUTE_TO_RETAILER);
  };

  const actions = [
    {
      buttonText: 'Cancel',
      buttonAction: handleClose,
      buttonStyle: { backgroundColor: '#4caf50', color: '#fff' }
    },
    {
      buttonText: 'Delete',
      buttonAction: handleDelete,
      buttonStyle: { backgroundColor: '#d50000', color: '#fff' }
    }
  ];

  const modelContent = () => {
    const { invoice_number: orderNumber } = secondPrimaryOrder;

    if (!secondPrimaryOrder) {
      return 'Are you sure you want to delete this Second Primary Order?';
    }
    return `Are you sure you want 
      to delete Second Primary Order ${orderNumber}`;
  };

  if (isLoading) {
    return <BackdropWithLoader />;
  }

  return (
    <Modal
      title={TITLES.DELETE_RETAILER}
      isModalVisible={showDeleteModal}
      actions={actions}
      onClose={handleClose}
      content={modelContent()}
    />
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    isLoading: isSecondPrimaryOrderRequestPending(state),
    secondPrimaryOrdersPayload: getAllSecondPrimaryOrders(state),
    secondPrimaryOrder: getSecondPrimaryOrderById(state, ownProps)
  };
};

const mapDispatchToProps = {
  fetchAllSecondPrimaryOrdersConnect: fetchAllSecondPrimaryOrders,
  deleteSecondPrimaryOrderConnect: deleteSecondPrimaryOrder,
  setCancelConnect: setCancel
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeleteSecondPrimaryOrder);
