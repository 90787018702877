import React, { useCallback } from 'react';
import { StyledTableCell, StyledTableRow } from '../../Styles/TableStyles';
import MaterialButton from '../MaterialButton';
import { Button, Popover } from 'antd';
import {
  ROUTE_TO_EDIT_DISTRIBUTOR,
  ROUTE_TO_DELETE_DISTRIBUTOR,
  ROUTE_TO_MANAGE_STOCK_DISTRIBUTOR
} from '../../shared/Constants/RouteConstants';
import {
  FORM_SUB_HEADER_TITLES,
  DISTRIBUTOR_DISTRIBUTION_PICKER
} from '../../shared/Constants/Constant';
import PlaceOrder from '../TableRowAction/PlaceOrder';
import history from '../../routes/history';

function DistributorTableItem({ distributor, isdistributorLoading }) {
  const {
    id: distributorId,
    name,
    address: { place },
    appointed_areas = [],
    contact,
    distribution_type: distributionType
  } = distributor;

  const pageNavigate = useCallback((route, id) => {
    history.push(`${route}${id}`);
  }, []);

  const distributorPopoverContent = (distributor, pageNavigate) => {
    return (
      <div>
        <p>{FORM_SUB_HEADER_TITLES.ADDRESS}</p>
        <p>{distributor.address.street_address},</p>
        <p>{distributor.address.landmark},</p>
        <p>{distributor.address.place}</p>
        <p>{distributor.address.zip_code}</p>
        <p style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button
            type="primary"
            onClick={() =>
              pageNavigate(ROUTE_TO_EDIT_DISTRIBUTOR, distributorId)
            }
          >
            Edit
          </Button>
          <Button
            type="danger"
            onClick={() =>
              pageNavigate(ROUTE_TO_DELETE_DISTRIBUTOR, distributorId)
            }
          >
            Delete
          </Button>
        </p>
      </div>
    );
  };

  const appointedDistrictsPopoverContent = (areas) => {
    return (
      <div>
        <p>APPOINTED AREAS:</p>
        {areas.length ? (
          areas.map((area) => <p key={area.id}>{area.name}</p>)
        ) : (
          <p>NONE</p>
        )}
      </div>
    );
  };

  return (
    <StyledTableRow>
      <StyledTableCell component="th" scope="row" align="center">
        <Popover
          placement="right"
          title={name}
          content={distributorPopoverContent(distributor, pageNavigate)}
          trigger="hover"
        >
          <MaterialButton buttonText={name} />
        </Popover>
      </StyledTableCell>

      <StyledTableCell component="th" scope="row" align="center">
        {place}
      </StyledTableCell>

      <StyledTableCell component="th" scope="row" align="center">
        <Popover
          placement="right"
          title={name}
          content={appointedDistrictsPopoverContent(appointed_areas)}
          trigger="hover"
        >
          <MaterialButton buttonText={appointed_areas.length} />
        </Popover>
      </StyledTableCell>

      <StyledTableCell component="th" scope="row" align="center">
        {DISTRIBUTOR_DISTRIBUTION_PICKER[distributionType]}
      </StyledTableCell>

      <StyledTableCell component="th" scope="row" align="center">
        {contact.contact_person_name}
      </StyledTableCell>

      <StyledTableCell component="th" scope="row" align="center">
        {contact.contact_number}
      </StyledTableCell>

      <StyledTableCell component="th" scope="row" align="center">
        <PlaceOrder
          customerDetails={distributor}
          customerType={distributionType}
          disabled={isdistributorLoading}
        />
        <MaterialButton
          buttonText="Stock"
          handleClick={() =>
            pageNavigate(ROUTE_TO_MANAGE_STOCK_DISTRIBUTOR, distributorId)
          }
          disabled={isdistributorLoading}
        />
      </StyledTableCell>
    </StyledTableRow>
  );
}

export default DistributorTableItem;
