import DistributorApi from '../../apis/DistributorAPI';
import * as DistributorActionTypes from './distributor.types';
import STATUS from '../../shared/Constants/apiStatus';
import { ROUTE_TO_DISTRIBUTOR } from '../../shared/Constants/RouteConstants';

export const fetchAllDistributors = (queryParams) => async (dispatch) => {
  dispatch({
    type: DistributorActionTypes.FETCH_DISTRIBUTORS_REQUEST
  });

  try {
    const response = await DistributorApi.getAllDistributors(queryParams);

    if (response.data.status === STATUS.OK) {
      dispatch({
        type: DistributorActionTypes.FETCH_DISTRIBUTORS_SUCCESS,
        payload: response.data.distributors,
        error: null,
        route: null,
        success: null
      });
    }
  } catch (error) {
    dispatch({
      type: DistributorActionTypes.FETCH_DISTRIBUTORS_ERROR,
      error: error.response.data,
      payload: null,
      route: null,
      success: null
    });
  }
};

export const fetchDistributor = (id) => async (dispatch) => {
  dispatch({
    type: DistributorActionTypes.FETCH_DISTRIBUTOR_REQUEST
  });

  try {
    const response = await DistributorApi.getDistributor(id);
    if (response.data.status === STATUS.OK) {
      dispatch({
        type: DistributorActionTypes.FETCH_DISTRIBUTOR_SUCCESS,
        payload: response.data.distributor,
        error: null,
        route: null,
        success: null
      });
    }
  } catch (error) {
    dispatch({
      type: DistributorActionTypes.FETCH_DISTRIBUTOR_ERROR,
      error: error.response.data,
      payload: null,
      route: ROUTE_TO_DISTRIBUTOR,
      success: null
    });
  }
};

export const fetchDistributorInventory = (id) => async (dispatch) => {
  dispatch({
    type: DistributorActionTypes.FETCH_DISTRIBUTOR_INVENTORY_REQUEST
  });

  try {
    const response = await DistributorApi.getDistributorInventory(id);
    if (response.data.status === STATUS.OK) {
      dispatch({
        type: DistributorActionTypes.FETCH_DISTRIBUTOR_INVENTORY_SUCCESS,
        payload: response.data.distributor,
        error: null,
        route: null,
        success: null
      });
    }
  } catch (error) {
    dispatch({
      type: DistributorActionTypes.FETCH_DISTRIBUTOR_INVENTORY_ERROR,
      error: error.response.data,
      payload: null,
      route: ROUTE_TO_DISTRIBUTOR,
      success: null
    });
  }
};

export const createDistributor = (formValues) => async (dispatch) => {
  dispatch({
    type: DistributorActionTypes.CREATE_DISTRIBUTOR_REQUEST
  });

  try {
    const response = await DistributorApi.createDistributor(formValues);
    if (response.data.status === STATUS.OK) {
      dispatch({
        type: DistributorActionTypes.CREATE_DISTRIBUTOR_SUCCESS,
        payload: response.data.distributor,
        error: null,
        route: ROUTE_TO_DISTRIBUTOR,
        success: response.data.message
      });
    }
  } catch (error) {
    dispatch({
      type: DistributorActionTypes.CREATE_DISTRIBUTOR_ERROR,
      error: error.response.data,
      payload: null,
      route: ROUTE_TO_DISTRIBUTOR,
      success: null
    });
  }
};

export const createDistributorInventory = (id, formValues, callback) => async (
  dispatch
) => {
  dispatch({
    type: DistributorActionTypes.CREATE_DISTRIBUTOR_INVENTORY_REQUEST
  });

  try {
    const response = await DistributorApi.createDistributorInventory(
      id,
      formValues
    );
    if (response.data.status === STATUS.OK) {
      if (callback) {
        callback();
      }

      dispatch({
        type: DistributorActionTypes.CREATE_DISTRIBUTOR_INVENTORY_SUCCESS,
        payload: response.data.distributorInventory,
        error: null,
        route: null,
        success: null
      });
    }
  } catch (error) {
    dispatch({
      type: DistributorActionTypes.CREATE_DISTRIBUTOR_INVENTORY_ERROR,
      error: error.response.data,
      payload: null,
      success: null
    });
  }
};

export const editDistributor = (id, formValues) => async (dispatch) => {
  dispatch({
    type: DistributorActionTypes.UPDATE_DISTRIBUTOR_REQUEST
  });

  try {
    const response = await DistributorApi.editDistributor(id, formValues);
    if (response.data.status === STATUS.OK) {
      dispatch({
        type: DistributorActionTypes.UPDATE_DISTRIBUTOR_SUCCESS,
        payload: response.data.distributor,
        error: null,
        route: ROUTE_TO_DISTRIBUTOR,
        success: response.data.message
      });
    }
  } catch (error) {
    dispatch({
      type: DistributorActionTypes.UPDATE_DISTRIBUTOR_ERROR,
      error: error.response.data,
      payload: null,
      route: ROUTE_TO_DISTRIBUTOR,
      success: null
    });
  }
};

export const editDistributorInventory = (
  distributorId,
  inventoryId,
  formValues,
  callback
) => async (dispatch) => {
  dispatch({
    type: DistributorActionTypes.UPDATE_DISTRIBUTOR_INVENTORY_REQUEST
  });

  try {
    const response = await DistributorApi.editDistributorInventory(
      distributorId,
      inventoryId,
      formValues
    );
    if (response.data.status === STATUS.OK) {
      if (callback) {
        callback();
      }

      dispatch({
        type: DistributorActionTypes.UPDATE_DISTRIBUTOR_INVENTORY_SUCCESS,
        payload: response.data.distributorInventory,
        error: null,
        route: null,
        success: null
      });
    }
  } catch (error) {
    dispatch({
      type: DistributorActionTypes.UPDATE_DISTRIBUTOR_INVENTORY_ERROR,
      error: error.response.data,
      payload: null,
      success: null
    });
  }
};

export const deleteDistributor = (id) => async (dispatch) => {
  dispatch({
    type: DistributorActionTypes.DELETE_DISTRIBUTOR_REQUEST
  });

  try {
    const response = await DistributorApi.deleteDistributor(id);
    if (response.data.status === STATUS.OK) {
      dispatch({
        type: DistributorActionTypes.DELETE_DISTRIBUTOR_SUCCESS,
        payload: id,
        error: null,
        route: ROUTE_TO_DISTRIBUTOR,
        success: response.data.message
      });
    }
  } catch (error) {
    dispatch({
      type: DistributorActionTypes.DELETE_DISTRIBUTOR_ERROR,
      payload: null,
      error: error.response.data,
      route: ROUTE_TO_DISTRIBUTOR,
      success: null
    });
  }
};
