import React from 'react';
import {
  Table as MuiTable,
  TableBody,
  TableHead,
  TableRow,
  Paper,
  TableContainer
} from '@material-ui/core';
import tableStyles from '../Styles/TableStyles';
import StyledTableCell from '../Styles/StyledTableCell';
import TableRowAction from '../TableRowAction';
import { BUTTON_ACTIONS_CONSTANTS } from '../../shared/Constants/Constant';
import {
  ROUTE_TO_EDIT_DISTRICT,
  ROUTE_TO_DELETE_DISTRICT
} from '../../shared/Constants/RouteConstants';
import { DISTRICT_HEADER_CONSTANTS } from '../../shared/Constants/TableHeaderConstants';

function DistrictTable(props) {
  const classes = tableStyles();
  const { districts = [], isDistrictLoading } = props;

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.tableWithTopButtonContainer}>
        <MuiTable stickyHeader>
          <TableHead>
            <TableRow>
              {Object.keys(DISTRICT_HEADER_CONSTANTS).map((header) => (
                <StyledTableCell align="center" key={header}>
                  {DISTRICT_HEADER_CONSTANTS[header]}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {districts.map((district) => {
              const {
                id: districtId,
                name = '',
                zone = {},
                areasCount
              } = district;

              return (
                <TableRow key={districtId}>
                  <StyledTableCell component="th" scope="row" align="center">
                    <TableRowAction
                      buttonText={BUTTON_ACTIONS_CONSTANTS.EDIT}
                      urlLink={`${ROUTE_TO_EDIT_DISTRICT}${districtId}`}
                      color="primary"
                      disabled={isDistrictLoading}
                    />

                    <TableRowAction
                      buttonText={BUTTON_ACTIONS_CONSTANTS.DELETE}
                      urlLink={`${ROUTE_TO_DELETE_DISTRICT}${districtId}`}
                      disabled={isDistrictLoading}
                    />
                  </StyledTableCell>

                  <StyledTableCell component="th" scope="row" align="center">
                    {name}
                  </StyledTableCell>

                  <StyledTableCell component="th" scope="row" align="center">
                    {areasCount}
                  </StyledTableCell>

                  <StyledTableCell component="th" scope="row" align="center">
                    {zone.name}
                  </StyledTableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </MuiTable>
      </TableContainer>
    </Paper>
  );
}

export default React.memo(DistrictTable);
