import { useEffect, useCallback } from 'react';
import { isArray } from 'lodash';

const useFetchReducer = (effect, data) => {
  const effectFunction = useCallback(async () => {
    await effect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isArray(data) && data.length === 0) {
      effectFunction();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [effectFunction]);
};

export default useFetchReducer;
