import { makeStyles } from '@material-ui/core/styles';

const commonStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  heading: {
    textAlign: 'center',
    color: '#1e88e5'
  },
  table: {
    margin: '0 auto'
  },
  loader: {
    marginRight: 10
  },
  datePicker: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  headerContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  paperWidthSm: {
    width: '600px'
  },
  avatar: {
    margin: '0 auto',
    backgroundColor: theme.palette.secondary.main
  },
  modalSpinner: {
    marginTop: 5,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  mapContainer: {
    borderStyle: 'solid',
    borderColor: 'darkgrey'
  },
  dailySalesDatePicker: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    justifyContent: 'space-evenly',
    alignItems: 'center'
  },
  divisonCenter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row'
  }
}));

export default commonStyles;
