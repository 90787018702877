const EDIT = 'Edit';
const DELETE = 'Delete';
const EDIT_DELETE = 'Edit / Delete';

const PARENT_PRODUCT_TABLE_HEADER_CONSTANTS = {
  NAME: 'Name',
  BRAND_NAME: 'Brand Name'
};

const SKU_TABLE_HEADER_CONSTANTS = {
  SKU: 'Sku',
  NAME: 'Name',
  PARENT_PRODUCT_NAME: 'Parent Product Name',
  PACK: 'Pack',
  MRP: 'Mrp',
  SPECIAL_PRICE: 'Spcl Price'
};

const FACTORY_STOCK_HEADER_CONSTANTS = {
  NAME: 'Sku Name',
  PARENT_PRODUCT_NAME: 'Parent Product Name',
  PACK: 'Pack',
  STOCK_IN_CASES: 'Stock In Cases',
  PIECES_PER_CARTON: 'Pcs / Carton',
  STOCK_IN_PCS: 'Stock In Pcs',
  STOCK_IN_LOOSE_PCS: 'Loose Pcs Stock',
  TOTAL_PCS_IN_STOCK: 'Total Pcs Stock'
};

const PACKAGING_STOCK_HEADER_CONSTANTS = {
  NAME: 'Parent Product Name',
  CARTON_BOX_STOCK: 'Carton Box Stock',
  BOTTLE_STOCK: 'Bottle Stock'
};

const COMBO_SKU_TABLE_HEADER_CONSTANTS = {
  EDIT_DELETE: ' ',
  COMBO_PACK_NAME: 'Combo Pack Name',
  SKUS: 'Skus',
  PIECES_PER_CARTON: 'Pieces/Carton',
  MRP: 'MRP'
};

const ZONE_HEADER_CONSTANTS = {
  EDIT_DELETE: ' ',
  ZONE_NAME: 'Zone Name',
  NO_OF_DISTRICTS: 'No of Districts'
};

const DISTRICT_HEADER_CONSTANTS = {
  EDIT_DELETE: ' ',
  DISTRICT_NAME: 'District Name',
  NO_OF_AREAS: 'No of Areas',
  ZONE: 'Zone'
};

const AREA_HEADER_CONSTANTS = {
  EDIT_DELETE: ' ',
  AREA_NAME: 'Area Name',
  NO_OF_BEATS: 'No of Beats',
  DISTRICT: 'District',
  ZONE: 'Zone'
};

const BEAT_AREA_HEADER_CONSTANTS = {
  EDIT_DELETE: ' ',
  BEAT_NAME: 'Beat Name',
  AREA: 'Area',
  DISTRICT: 'District',
  ZONE: 'Zone'
};

const SUPER_STOCKIST_HEADER_CONSTANTS = {
  SUPER_STOCKIST_NAME: 'Super Stockist Name',
  PLACE: 'Place',
  NO_OF_DISTRICTS: 'No of Districts',
  CONTACT_PERSON_NAME: 'Contact Person',
  CONTACT_PERSON_NUMBER: 'Mobile',
  MANAGE: 'Manage'
};

const DISTRIBUTOR_HEADER_CONSTANTS = {
  DISTRIBUTOR_NAME: 'Distributor Name',
  PLACE: 'Place',
  AREAS_ASSIGNED: 'Areas Assigned',
  DISTRIBUTION_TYPE: 'Dbr Type',
  CONTACT_PERSON_NAME: 'Contact Person',
  CONTACT_PERSON_NUMBER: 'Mobile',
  MANAGE: 'Manage'
};

const RETAILER_HEADER_CONSTANTS = {
  RETAILER_NAME: 'Retailer Name',
  PLACE: 'Place',
  OUTLET_TYPE: 'Outlet Type',
  BEAT_AREA: 'BeatArea',
  AREA: 'Area',
  CONTACT_PERSON_NAME: 'Contact Person',
  CONTACT_PERSON_NUMBER: 'Mobile',
  MANAGE: 'Manage'
};

const PRIMARY_ORDER_HEADER_CONSTANTS = {
  ORDER_NO: 'Order No',
  CUSTOMER_TYPE: 'Customer Type',
  CUSTOMER_NAME: 'Customer Name',
  ORDER_AMOUNT: 'Order Amt',
  ORDER_TAKEN_BY: 'Order TakenBy',
  ORDER_DATE: 'Order Date',
  ORDER_TIME: 'Time',
  STATUS: 'Status',
  UPDATE_STATUS: 'Order Update'
};

const PRIMARY_ORDER_CART_HEADER_CONSTANTS = {
  PARENT_PRODUCT_NAME: 'Parent Product Name',
  SKU: 'Sku',
  PER_CASE: 'Per case',
  CASES_QTY: 'Cases Qty',
  PIECES_QTY: 'Pcs Qty',
  MARGIN: 'Margin',
  PRICE_PER_PIECE: 'Price/pc',
  TOTAL_PRICE: 'Total Price',
  REMOVE: 'Remove'
};

const SECOND_PRIMARY_ORDER_HEADER_CONSTANTS = {
  ORDER_NO: 'Order No',
  DISTRIBUTOR_NAME: 'Distributor',
  SUPER_STOCKIST_NAME: 'SuperStockist',
  ORDER_AMOUNT: 'Order Amt',
  ORDER_TAKEN_BY: 'Order TakenBy',
  ORDER_DATE: 'Order Date',
  ORDER_TIME: 'Time',
  STATUS: 'Status',
  UPDATE_STATUS: 'Order Update'
};

const SECONDARY_ORDER_HEADER_CONSTANTS = {
  ORDER_NO: 'Order No',
  RETAILER_NAME: 'Retailer',
  BEAT_NAME: 'Beat',
  AREA_NAME: 'Area',
  DISTRIBUTOR_NAME: 'Distributor',
  ORDER_AMOUNT: 'Order Amt',
  ORDER_TAKEN_BY: 'Order TakenBy',
  ORDER_DATE: 'Order Date',
  ORDER_TIME: 'Time',
  STATUS: 'Status',
  UPDATE_STATUS: 'Order Update'
};

const ORDER_HEADER_CONSTANTS = {
  ORDER_NO: 'Order No',
  CUSTOMER_NAME: 'Customer Name',
  PLACE: 'Place',
  CUSTOMER_TYPE: 'Customer Type',
  TOTAL_PRICE: 'Total Price',
  ORDER_TAKEN_BY: 'Order-Taken-By'
};

const VIEW_ORDER_HEADER_CONSTANTS = {
  NAME: 'Name',
  PARENT_PRODUCT: 'Parent Product',
  ORDERED_QTY: 'Ordered Qty',
  MARGIN: 'Margin',
  LANDED_COST: 'Landed Cost',
  TOTAL: 'Total'
};

const USER_HEADER_CONSTANTS = {
  NAME: 'Employee Name',
  USER_NAME: 'Login UserName',
  MOBILE_NUMBER: 'Mobile Number',
  ROLE: 'Role',
  ALLOWED_APPS: 'Allowed Apps',
  ASSIGN_TERRITORY: 'Assign Terr',
  USER_STATE: 'Active/Inactive'
};

const USER_ATTENDANCE_HEADER_CONSTANTS = {
  NAME: 'Employee Name',
  ROLE: 'Role',
  DATE: 'Date',
  STATE_TIME: 'Start Time',
  END_TIME: 'End Time',
  WORK_HRS: 'Work Hrs',
  LOCATION: 'Location'
};

const INVENTORY_HEADER_CONSTANTS = {
  SKU: 'Sku',
  PARENT_PRODUCT: 'Parent Product',
  NAME: 'Name',
  SKU_TYPE: 'Type',
  CURRENT_INVENTORY: 'Inventory Level',
  UPDATE: 'Update'
};

const USER_SECONDARY_SALES_REPORT_HEADER_CONSTANTS = {
  MAP_INDEX: 'Map #',
  DATE: 'Date',
  TIME: 'Time',
  RETAILER: 'Retailer',
  BEAT: 'Beat',
  ACTIVITY: 'Activity',
  ORDER_VALUE: 'Order Value',
  ORDER_NUMBER: 'Invoice #',
  ORDERED_QTY: 'Ordered Pcs'
};

module.exports = {
  EDIT,
  DELETE,
  EDIT_DELETE,
  PARENT_PRODUCT_TABLE_HEADER_CONSTANTS,
  SKU_TABLE_HEADER_CONSTANTS,
  COMBO_SKU_TABLE_HEADER_CONSTANTS,
  FACTORY_STOCK_HEADER_CONSTANTS,
  PACKAGING_STOCK_HEADER_CONSTANTS,
  ZONE_HEADER_CONSTANTS,
  DISTRICT_HEADER_CONSTANTS,
  AREA_HEADER_CONSTANTS,
  BEAT_AREA_HEADER_CONSTANTS,
  SUPER_STOCKIST_HEADER_CONSTANTS,
  DISTRIBUTOR_HEADER_CONSTANTS,
  RETAILER_HEADER_CONSTANTS,
  PRIMARY_ORDER_HEADER_CONSTANTS,
  PRIMARY_ORDER_CART_HEADER_CONSTANTS,
  SECOND_PRIMARY_ORDER_HEADER_CONSTANTS,
  SECONDARY_ORDER_HEADER_CONSTANTS,
  ORDER_HEADER_CONSTANTS,
  USER_HEADER_CONSTANTS,
  USER_ATTENDANCE_HEADER_CONSTANTS,
  INVENTORY_HEADER_CONSTANTS,
  VIEW_ORDER_HEADER_CONSTANTS,
  USER_SECONDARY_SALES_REPORT_HEADER_CONSTANTS
};
