import React, { useCallback } from 'react';
import { StyledTableCell, StyledTableRow } from '../../Styles/TableStyles';
import MaterialButton from '../../components/MaterialButton';
import { Popover, Button } from 'antd';
import history from '../../routes/history';
import { ROUTE_TO_EDIT_SKU } from '../../shared/Constants/RouteConstants';
import {
  DISTRIBUTION_TYPES_CONSTANTS,
  INDIAN_CURRENCY,
  SKU_TYPES
} from '../../shared/Constants/Constant';
import SkuSsDbrRetailItem from './SkuSsDbrRetailItem';
import SkuDbrRetailItem from './SkuDbrRetailItem';

function SkuTableItem(props) {
  const { sku } = props;

  const navigateToEditSku = useCallback((id) => {
    history.push(`${ROUTE_TO_EDIT_SKU}${id}`);
  }, []);

  const parentProductPopoverContent = (sku, navigateToEditSku) => {
    const getPrices = (type) => {
      return sku.sku_prices.find(
        (priceObj) => priceObj.distributionType === type
      );
    };

    return (
      <div>
        <SkuSsDbrRetailItem
          priceList={getPrices(
            DISTRIBUTION_TYPES_CONSTANTS.SUPERSTOCKIST_DISTRIBUTOR_RETAILER
          )}
        />

        <SkuDbrRetailItem
          priceList={getPrices(
            DISTRIBUTION_TYPES_CONSTANTS.DISTRIBUTOR_RETAILER
          )}
        />

        <p style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button type="primary" onClick={() => navigateToEditSku(sku.id)}>
            Edit
          </Button>
          <Button type="danger" onClick={() => false}>
            Delete
          </Button>
        </p>
      </div>
    );
  };

  return (
    <StyledTableRow>
      <StyledTableCell align="center">
        <Popover
          placement="right"
          title={sku.sku}
          content={parentProductPopoverContent(sku, navigateToEditSku)}
          trigger="hover"
        >
          <MaterialButton buttonText={sku.sku} />
        </Popover>
      </StyledTableCell>
      <StyledTableCell align="center">{sku.name || 'NA'}</StyledTableCell>
      <StyledTableCell align="center">
        {sku.parent_product_name || 'NA'}
      </StyledTableCell>
      <StyledTableCell align="center">
        {SKU_TYPES[sku.sku_type]}
      </StyledTableCell>
      <StyledTableCell align="center">
        {`${INDIAN_CURRENCY}${sku.mrp}`}
      </StyledTableCell>
      <StyledTableCell align="center">
        {Number(sku.special_selling_price) > 0
          ? `${INDIAN_CURRENCY}${sku.special_selling_price}`
          : 'NA'}
      </StyledTableCell>
    </StyledTableRow>
  );
}

export default SkuTableItem;
