function ParentProductFormValidator(values) {
  const errors = {};

  if (!values.brand) {
    errors.brand = '**Brand Name is required';
  }

  if (!values.name) {
    errors.name = '**Parent Product Name is required';
  }

  return errors;
}

export default ParentProductFormValidator;
