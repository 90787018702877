import React from 'react';
import { connect } from 'react-redux';
import { Container, Avatar, Typography } from '@material-ui/core';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import formStyles from '../../../components/Styles/FormStyles';
import useRefreshFetchReducer from '../../../shared/Hooks/useRefreshFetchReducer';
import CreateDistrictForm from '../../../components/CreateDistrictForm';
import { TITLES } from '../../../shared/Constants/Constant';
import {
  fetchAllDistricts,
  createDistrict
} from '../../../redux/district/district.actions';
import { isDistrictRequestPending } from '../../../redux/district/district.selector';
import { fetchAllZones } from '../../../redux/zone/zone.actions';
import {
  getAllZones,
  isZoneRequestPending
} from '../../../redux/zone/zone.selector';
import BackdropWithLoader from '../../../components/BackdropWithLoader';
import history from '../../../routes/history';
import { ROUTE_TO_DISTRICT } from '../../../shared/Constants/RouteConstants';
import { setCancel } from '../../../redux/event/event.actions';

function CreateDistrict(props) {
  const classes = formStyles();
  const {
    createDistrictConnect,
    isDistrictLoading,
    fetchAllZonesConnect,
    isZonesLoading,
    zonesPayload,
    setCancelConnect
  } = props;

  useRefreshFetchReducer(fetchAllZonesConnect);
  const handleSubmit = (formValues) => {
    createDistrictConnect(formValues);
  };

  const handleCancel = () => {
    setCancelConnect();
    history.push(ROUTE_TO_DISTRICT);
  };

  if (isDistrictLoading || isZonesLoading) {
    return <BackdropWithLoader />;
  }

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <AccountCircleIcon color="primary" />
        </Avatar>
        <Typography component="h1" variant="h5">
          {TITLES.CREATE_DISTRICT}
        </Typography>
        <CreateDistrictForm
          zones={zonesPayload}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
        />
      </div>
    </Container>
  );
}

const mapStateToProps = (state) => {
  return {
    isDistrictLoading: isDistrictRequestPending(state),
    isZonesLoading: isZoneRequestPending(state),
    zonesPayload: getAllZones(state)
  };
};

const mapDispatchToProps = {
  createDistrictConnect: createDistrict,
  fetchAllDistrictsConnect: fetchAllDistricts,
  fetchAllZonesConnect: fetchAllZones,
  setCancelConnect: setCancel
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateDistrict);
