import baseURL from '../shared/AxiosBaseUrl/baseURL';

class AuthApi {
  static userLogin(formValues) {
    return baseURL.post('/api/v1/auth/login', { ...formValues });
  }

  static getCurrentUser(formValues) {
    return baseURL.get('/api/v1/auth/currentUser', {
      params: {
        loggedInFrom: 'dashboard'
      }
    });
  }
}

export default AuthApi;
