import React, { useState } from 'react';
import { MenuItem, ListItemText, Checkbox } from '@material-ui/core';
import FormInput from '../FormInput';
import FormMultiSelect from '../FormMultiSelect';
import FormSubHeader from '../FormSubHeader';
import formStyles from '../Styles/FormStyles';
import FormCancelButton from '../FormCancelButton';
import FormSubmitButton from '../FormSubmitButton';
import AdditionalContactsForm from '../AdditionalContactsForm';
import CurrentBrandsDealingForm from '../CurrentBrandsDealingForm';
import { FORM_SUB_HEADER_TITLES } from '../../shared/Constants/Constant';
import useForm from '../../shared/Hooks/useForm';
import SuperStockistFormValidator from '../../pages/SuperStockist/SuperStockistFormValidator';
import { get, size } from 'lodash';
import {
  areValidAdditionalContacts,
  areValidCustomerBrands
} from '../../shared/Utils/CommonUtils';
import {
  reformatSuperStockistFormValues,
  getAssignedZoneIds,
  getAssignedDistrictIds,
  exportNormalizedFormValues
} from './EditSuperStockistForm.utils';

// Additional Contact Details
const blankAdditionalContact = {
  contact_person_name: '',
  contact_number: '',
  designation: ''
};

// SS Brands Details
const blankCurrentBrandsDealing = {
  brand_name: '',
  turn_over_amount: '',
  brand_dealing_time_period: ''
};

function EditSuperStockistForm(props) {
  const {
    zonesPayload = [],
    districtsPayload = [],
    onCancel,
    superStockist
  } = props;
  const classes = formStyles();

  const [additionalContacts, setAdditionalContacts] = useState(
    get(superStockist, 'additional_contacts', [])
  );

  const [additionalContactsError, setAdditionalContactsError] = useState('');

  const [currentBrandsDealing, setCurrentBrandsDealing] = useState(
    get(superStockist, 'other_brands_dealing_experience', [])
  );

  const [currentBrandsDealingError, setCurrentBrandsDealingError] = useState(
    ''
  );

  const [assignedZones, setAssignedZones] = useState(
    getAssignedZoneIds(superStockist.appointed_districts)
  );

  const [assignedDistricts, setAssignedDistricts] = useState(
    getAssignedDistrictIds(superStockist.appointed_districts)
  );

  const addAdditionalContact = () => {
    setAdditionalContacts((prevAdditionalContact) => [
      ...prevAdditionalContact,
      { ...blankAdditionalContact }
    ]);
  };

  const removeAdditionalContactByIndex = (index) => {
    const duplicateAdditionalContacts = [...additionalContacts];
    duplicateAdditionalContacts.splice(index, 1);

    setAdditionalContacts([...duplicateAdditionalContacts]);
  };

  const handleAdditionalContactChange = (e, index) => {
    e.persist();
    const duplicateAdditionalContacts = [...additionalContacts];
    duplicateAdditionalContacts[index][e.target.name] = e.target.value;

    if (additionalContactsError) {
      setAdditionalContactsError('');
    }

    setAdditionalContacts(duplicateAdditionalContacts);
  };

  const addCurrentBrandsDealing = () => {
    setCurrentBrandsDealing((prevCurrentBrandsDealing) => [
      ...prevCurrentBrandsDealing,
      { ...blankCurrentBrandsDealing }
    ]);
  };

  const removeCurrentBrandsDealing = (index) => {
    const duplicateCurrentBrandsDealing = [...currentBrandsDealing];
    duplicateCurrentBrandsDealing.splice(index, 1);

    setCurrentBrandsDealing([...duplicateCurrentBrandsDealing]);
  };

  const handleCurrentBrandsDealingChange = (e, index) => {
    e.persist();
    const duplicateCurrentBrandsDealing = [...currentBrandsDealing];
    duplicateCurrentBrandsDealing[index][e.target.name] = e.target.value;

    if (currentBrandsDealingError) {
      setCurrentBrandsDealingError('');
    }

    setCurrentBrandsDealing(duplicateCurrentBrandsDealing);
  };

  const onChangeZoneAssign = (e) => {
    e.persist();
    const { value } = e.target;
    let districtIds = [];

    setAssignedZones(value);

    value.map((zoneId) => {
      const filterAlreadyAssignedDistricts = districtsPayload
        .filter(
          (district) =>
            assignedDistricts.includes(district.id) &&
            district.zone.id === zoneId
        )
        .map((filteredDistrict) => filteredDistrict.id);
      districtIds = districtIds.concat(filterAlreadyAssignedDistricts);

      return value;
    });

    setAssignedDistricts(districtIds);
  };

  const onChangeDistrictAssign = (e) => {
    e.persist();
    const { value } = e.target;

    setAssignedDistricts(value);
  };

  const handleFormSubmit = () => {
    if (!areValidAdditionalContacts(additionalContacts)) {
      return setAdditionalContactsError(
        '** Additional Contacts entry is incomplete'
      );
    }

    if (!areValidCustomerBrands(currentBrandsDealing)) {
      return setCurrentBrandsDealingError(
        '** Current Brands Dealing entry is incomplete'
      );
    }

    const updatedValues = exportNormalizedFormValues(
      {
        values,
        assignedDistricts,
        additionalContacts,
        currentBrandsDealing
      },
      superStockist
    );

    if (size(updatedValues) > 0) {
      props.onSubmit(updatedValues);
    }
  };

  const reFormatDetails = (superStockist) => {
    return reformatSuperStockistFormValues(superStockist);
  };

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    handleNumericInputChange
  } = useForm(handleFormSubmit, SuperStockistFormValidator, {
    initialValues: reFormatDetails(superStockist)
  });

  return (
    <form className={classes.form} noValidate autoComplete="off">
      <FormInput
        type="text"
        label="Super Stockist Name"
        placeholder="Enter Super Stockist Name"
        name="name"
        value={values.name || ''}
        onChange={handleChange}
        helperText={errors.name || ''}
        error={!!errors.name}
        required
        autoFocus
      />

      <FormInput
        type="text"
        label="Place"
        placeholder="Enter the Place"
        name="place"
        value={values.place || ''}
        onChange={handleChange}
        helperText={errors.place || ''}
        error={!!errors.place}
        required
      />

      <FormInput
        type="text"
        label="GST Number"
        placeholder="Enter the GST Number"
        name="gstin"
        value={values.gstin || ''}
        onChange={handleChange}
      />

      <FormSubHeader text={FORM_SUB_HEADER_TITLES.TERRITORY} />

      <FormMultiSelect
        label="Choose Zones"
        value={assignedZones}
        payload={zonesPayload}
        onChange={onChangeZoneAssign}
      >
        {zonesPayload.map((zone) => {
          const { id } = zone;
          return (
            <MenuItem key={id} value={id}>
              <Checkbox
                checked={assignedZones.indexOf(id) > -1}
                color="primary"
              />
              <ListItemText primary={zone.name} />
            </MenuItem>
          );
        })}
      </FormMultiSelect>

      <FormMultiSelect
        label="Choose Districts"
        value={assignedDistricts}
        payload={districtsPayload}
        onChange={onChangeDistrictAssign}
      >
        {assignedZones.length &&
          districtsPayload
            .filter((district) => assignedZones.includes(district.zone.id))
            .map((district) => {
              const { id } = district;
              return (
                <MenuItem key={id} value={id}>
                  <Checkbox
                    checked={assignedDistricts.indexOf(id) > -1}
                    color="primary"
                  />
                  <ListItemText primary={district.name} />
                </MenuItem>
              );
            })}
      </FormMultiSelect>

      <FormSubHeader text={FORM_SUB_HEADER_TITLES.ADDRESS} />

      <FormInput
        type="text"
        label="Street Address"
        placeholder="Enter the Address"
        name="street_address"
        value={values.street_address || ''}
        onChange={handleChange}
        helperText={errors.street_address || ''}
        error={!!errors.street_address}
        required
      />

      <FormInput
        type="text"
        label="Landmark"
        placeholder="Enter the Landmark"
        name="landmark"
        value={values.landmark || ''}
        onChange={handleChange}
        helperText={errors.landmark || ''}
        error={!!errors.landmark}
        required
      />

      <FormInput
        type="text"
        label="Pin Code"
        placeholder="Enter the Pin Code"
        name="zip_code"
        value={values.zip_code || ''}
        onChange={handleNumericInputChange}
        helperText={errors.zip_code || ''}
        error={!!errors.zip_code}
        required
      />

      <FormSubHeader text={FORM_SUB_HEADER_TITLES.CONTACT} />

      <FormInput
        type="text"
        label="Key Contact Person Name"
        placeholder="Enter the Key Contact Name"
        name="contact_person_name"
        value={values.contact_person_name || ''}
        onChange={handleChange}
        required
        helperText={errors.contact_person_name || ''}
        error={!!errors.contact_person_name}
      />

      <FormInput
        type="number"
        label="Mobile Number"
        placeholder="Enter the Contact Number"
        name="contact_number"
        value={values.contact_number || ''}
        onChange={handleNumericInputChange}
        required
        helperText={errors.contact_number || ''}
        error={!!errors.contact_number}
      />

      <FormInput
        type="number"
        label="WhatsApp Number"
        placeholder="Enter the WhatsApp Number"
        name="additional_contact_number"
        value={values.additional_contact_number || ''}
        onChange={handleNumericInputChange}
      />

      <FormInput
        type="email"
        label="Email Address"
        placeholder="Enter the Email Address"
        name="email"
        value={values.email || ''}
        onChange={handleChange}
      />

      <AdditionalContactsForm
        subHeaderTitle={FORM_SUB_HEADER_TITLES.ADDITIONAL_CONTACTS}
        contacts={additionalContacts}
        handleInputChange={
          (e, index) => handleAdditionalContactChange(e, index)
          // eslint-disable-next-line react/jsx-curly-newline
        }
        handleRemove={(index) => removeAdditionalContactByIndex(index)}
        handleAdd={addAdditionalContact}
        errorMessage={additionalContactsError}
      />

      <CurrentBrandsDealingForm
        subHeaderTitle={FORM_SUB_HEADER_TITLES.BRANDS_DEALING_WITH}
        brands={currentBrandsDealing}
        handleRemove={(index) => removeCurrentBrandsDealing(index)}
        handleInputChange={(e, index) =>
          handleCurrentBrandsDealingChange(e, index)
        }
        handleAdd={addCurrentBrandsDealing}
        errorMessage={currentBrandsDealingError}
      />

      <FormInput
        type="number"
        label="No of Existing Distributors"
        placeholder="Enter the Number of Existing Distributors"
        name="existing_distributors_count"
        value={values.existing_distributors_count || ''}
        onChange={handleNumericInputChange}
        helperText={errors.existing_distributors_count || ''}
        error={!!errors.existing_distributors_count}
      />

      <div className={classes.buttonRow}>
        <FormCancelButton onClickCancel={onCancel} />
        <FormSubmitButton onClickSubmit={handleSubmit} />
      </div>
    </form>
  );
}

EditSuperStockistForm.defaultProps = {
  zonesPayload: [],
  districtsPayload: [],
  additional_contacts: []
};

export default React.memo(EditSuperStockistForm);
