import OrderApi from '../../apis/OrderAPI';
import * as SecondaryOrderActionTypes from './secondary-order.types';
import { ROUTE_TO_SECONDARY_ORDER } from '../../shared/Constants/RouteConstants';
import { get } from 'lodash';

export const fetchAllSecondaryOrders = () => async (dispatch) => {
  dispatch({
    type: SecondaryOrderActionTypes.FETCH_SECONDARY_ORDERS_REQUEST
  });

  try {
    const response = await OrderApi.getAllSecondaryOrders();

    dispatch({
      type: SecondaryOrderActionTypes.FETCH_SECONDARY_ORDERS_SUCCESS,
      payload: response.data.secondaryOrders,
      error: null,
      success: null,
      route: null
    });

    dispatch({
      type: SecondaryOrderActionTypes.SECONDARY_ORDER_PAGINATION,
      payload: get(response.data, 'pagination', {}),
      error: null
    });
  } catch (error) {
    dispatch({
      type: SecondaryOrderActionTypes.FETCH_SECONDARY_ORDERS_ERROR,
      error: error.response.data,
      payload: null,
      success: null,
      route: null
    });
  }
};

export const fetchSecondaryOrder = (id) => async (dispatch) => {
  dispatch({
    type: SecondaryOrderActionTypes.FETCH_SECONDARY_ORDER_REQUEST
  });

  try {
    const response = await OrderApi.getSecondaryOrder(id);

    dispatch({
      type: SecondaryOrderActionTypes.FETCH_SECONDARY_ORDER_SUCCESS,
      payload: response.data.secondaryOrder,
      error: null,
      success: null,
      route: null
    });
  } catch (error) {
    dispatch({
      type: SecondaryOrderActionTypes.FETCH_SECONDARY_ORDER_ERROR,
      error: error.response.data,
      payload: null,
      success: null,
      route: null
    });
  }
};

export const createSecondaryOrder = (orderDetails) => async (dispatch) => {
  dispatch({
    type: SecondaryOrderActionTypes.CREATE_SECONDARY_ORDER_REQUEST
  });

  try {
    const response = await OrderApi.createSecondaryOrder(orderDetails);

    dispatch({
      type: SecondaryOrderActionTypes.CREATE_SECONDARY_ORDER_SUCCESS,
      payload: response.data.secondaryOrder,
      error: null,
      route: ROUTE_TO_SECONDARY_ORDER,
      success: response.data.message
    });
  } catch (error) {
    dispatch({
      type: SecondaryOrderActionTypes.CREATE_SECONDARY_ORDER_ERROR,
      error: error.response.data,
      payload: null,
      route: ROUTE_TO_SECONDARY_ORDER,
      success: null
    });
  }
};

export const editSecondaryOrder = (
  { secondaryOrderId, orderDetails },
  callback
) => async (dispatch) => {
  dispatch({
    type: SecondaryOrderActionTypes.UPDATE_SECONDARY_ORDER_REQUEST
  });

  try {
    const response = await OrderApi.editSecondaryOrder(
      secondaryOrderId,
      orderDetails
    );

    if (callback) {
      callback();
    }

    dispatch({
      type: SecondaryOrderActionTypes.UPDATE_SECONDARY_ORDER_SUCCESS,
      payload: response.data.secondaryOrder,
      error: null,
      route: ROUTE_TO_SECONDARY_ORDER,
      success: response.data.message
    });
  } catch (error) {
    dispatch({
      type: SecondaryOrderActionTypes.UPDATE_SECONDARY_ORDER_ERROR,
      error: error.response.data,
      payload: null,
      route: ROUTE_TO_SECONDARY_ORDER,
      success: null
    });
  }
};

export const deleteSecondaryOrder = (id) => async (dispatch) => {
  dispatch({
    type: SecondaryOrderActionTypes.DELETE_SECONDARY_ORDER_REQUEST
  });

  try {
    const response = await OrderApi.deleteSecondaryOrder(id);

    dispatch({
      type: SecondaryOrderActionTypes.DELETE_SECONDARY_ORDER_SUCCESS,
      payload: id,
      error: null,
      route: ROUTE_TO_SECONDARY_ORDER,
      success: response.data.message
    });
  } catch (error) {
    dispatch({
      type: SecondaryOrderActionTypes.DELETE_SECONDARY_ORDER_ERROR,
      payload: null,
      error: error.response.data,
      route: ROUTE_TO_SECONDARY_ORDER,
      success: null
    });
  }
};

export const addItem = (item, callBack) => (dispatch) => {
  dispatch({
    type: SecondaryOrderActionTypes.ADD_SECONDARY_ORDER_ITEM,
    payload: item
  });
  if (callBack) {
    callBack();
  }
};

export const removeItem = (itemId) => (dispatch) => {
  dispatch({
    type: SecondaryOrderActionTypes.REMOVE_SECONDARY_ORDER_ITEM,
    payload: itemId
  });
};

export const clearCart = () => (dispatch) => {
  dispatch({
    type: SecondaryOrderActionTypes.CLEAR_SECONDARY_ORDER_ORDER_CART
  });
};

export const updatePaginationPage = (pageNumber) => (dispatch) => {
  dispatch({
    type: SecondaryOrderActionTypes.UPDATE_SECONDARY_ORDER_PAGINATION_PAGE,
    payload: pageNumber
  });
};

export const resetSecondaryOrderData = () => (dispatch) => {
  dispatch({
    type: SecondaryOrderActionTypes.RESET_SECONDARY_ORDER_DATA
  });
};

export const resetSecondaryOrderError = () => (dispatch) => {
  dispatch({
    type: SecondaryOrderActionTypes.RESET_SECONDARY_ORDER_ERROR
  });
};

export const loadSecondaryOrderCustomer = (customer) => (dispatch) => {
  dispatch({
    type: SecondaryOrderActionTypes.LOAD_SECONDARY_ORDER_CUSTOMER,
    payload: customer
  });
};
