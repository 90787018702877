import { get, isEqual, sortBy, set } from 'lodash';

export const reformatSuperStockistFormValues = (super_stockist = {}) => {
  return {
    name: get(super_stockist, 'name', ''),

    gstin: get(super_stockist, 'gstin', ''),

    existing_distributors_count: get(
      super_stockist,
      'existing_distributors_count',
      ''
    ),

    street_address: get(super_stockist, 'address.street_address', ''),

    landmark: get(super_stockist, 'address.landmark', ''),

    place: get(super_stockist, 'address.place', ''),

    zip_code: get(super_stockist, 'address.zip_code', ''),

    contact_person_name: get(super_stockist, 'contact.contact_person_name', ''),

    contact_number: get(super_stockist, 'contact.contact_number', ''),

    additional_contact_number: get(
      super_stockist,
      'contact.additional_contact_number',
      ''
    ),

    email: get(super_stockist, 'contact.email', '')
  };
};

export const getAssignedDistrictIds = (appointed_districts = []) => {
  return appointed_districts.map((district) => district.id);
};

export const getAssignedZoneIds = (appointed_districts = []) => {
  const zones = appointed_districts.reduce((allIds, district) => {
    return {
      ...allIds,
      [district.zone.id]: {
        name: district.zone.name,
        id: district.zone.id
      }
    };
  }, {});

  return Object.keys(zones);
};

export const exportNormalizedFormValues = (
  { values, assignedDistricts, additionalContacts, currentBrandsDealing },
  ss
) => {
  const updatedValues = {};

  if (values.name && !isEqual(ss.name, values.name)) {
    updatedValues.name = values.name;
  }

  if (values.gstin && !isEqual(ss.gstin, values.gstin)) {
    updatedValues.gstin = values.gstin;
  }

  if (
    values.existing_distributors_count &&
    !isEqual(ss.existing_distributors_count, values.existing_distributors_count)
  ) {
    updatedValues.existing_distributors_count =
      values.existing_distributors_count;
  }

  if (
    !isEqual(
      sortBy(assignedDistricts),
      sortBy(getAssignedDistrictIds(ss.appointed_districts))
    )
  ) {
    updatedValues.appointed_districts = assignedDistricts;
  }

  if (
    values.street_address &&
    !isEqual(ss.address.street_address, values.street_address)
  ) {
    set(updatedValues, 'address.street_address', values.street_address);
  }

  if (values.landmark && !isEqual(ss.address.landmark, values.landmark)) {
    set(updatedValues, 'address.landmark', values.landmark);
  }

  if (values.place && !isEqual(ss.address.place, values.place)) {
    set(updatedValues, 'address.place', values.place);
  }

  if (values.zip_code && !isEqual(ss.address.zip_code, values.zip_code)) {
    set(updatedValues, 'address.zip_code', values.zip_code);
  }

  if (
    values.contact_person_name &&
    !isEqual(ss.contact.contact_person_name, values.contact_person_name)
  ) {
    set(
      updatedValues,
      'contact.contact_person_name',
      values.contact_person_name
    );
  }

  if (
    values.contact_number &&
    !isEqual(ss.contact.contact_number, values.contact_number)
  ) {
    set(updatedValues, 'contact.contact_number', values.contact_number);
  }

  if (
    values.additional_contact_number &&
    !isEqual(
      ss.contact.additional_contact_number,
      values.additional_contact_number
    )
  ) {
    set(
      updatedValues,
      'contact.additional_contact_number',
      values.additional_contact_number
    );
  }

  if (values.email && !isEqual(ss.contact.email, values.email)) {
    set(updatedValues, 'contact.email', values.email);
  }

  if (!isEqual(ss.additional_contacts, additionalContacts)) {
    set(updatedValues, 'additional_contacts', additionalContacts);
  }

  if (!isEqual(ss.other_brands_dealing_experience, currentBrandsDealing)) {
    set(updatedValues, 'other_brands_dealing_experience', currentBrandsDealing);
  }

  return updatedValues;
};
