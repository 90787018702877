import React from 'react';
import {
  Table as MuiTable,
  TableBody,
  TableHead,
  TableRow,
  Paper,
  TableContainer
} from '@material-ui/core';
import tableStyles from '../Styles/TableStyles';
import StyledTableCell from '../Styles/StyledTableCell';
import { SKU_TABLE_HEADER_CONSTANTS } from '../../shared/Constants/TableHeaderConstants';
import { size } from 'lodash';
import SkuTableItem from './SkuTableItem';

function SkuTable(props) {
  const classes = tableStyles();
  const { skus = [] } = props;

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.tableWithTopButtonContainer}>
        <MuiTable stickyHeader>
          <TableHead>
            <TableRow>
              {Object.keys(SKU_TABLE_HEADER_CONSTANTS).map((header) => (
                <StyledTableCell
                  key={header}
                  style={{ position: 'sticky' }}
                  align="center"
                >
                  {SKU_TABLE_HEADER_CONSTANTS[header]}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {size(skus) > 0 ? (
              skus.map((sku, index) => (
                <SkuTableItem key={sku.id} index={index} sku={sku} />
              ))
            ) : (
              <TableRow>
                <StyledTableCell align="center" colSpan={8}>
                  No Skus Found in the database
                </StyledTableCell>
              </TableRow>
            )}
          </TableBody>
        </MuiTable>
      </TableContainer>
    </Paper>
  );
}

export default React.memo(SkuTable);
