import baseURL from '../shared/AxiosBaseUrl/baseURL';

class OrderApi {
  static getAllPrimaryOrders() {
    return baseURL.get('/api/v1/order/primary-order/');
  }

  static getPrimaryOrder(id) {
    return baseURL.get(`/api/v1/order/primary-order/${id}`);
  }

  static createPrimaryOrder(formValues) {
    return baseURL.post('/api/v1/order/primary-order', { ...formValues });
  }

  static editPrimaryOrder(id, formValues) {
    return baseURL.patch(`/api/v1/order/primary-order/${id}`, {
      ...formValues
    });
  }

  static deletePrimaryOrder(id) {
    return baseURL.delete(`/api/v1/order/primary-order/${id}`);
  }

  static getAllSecondPrimaryOrders() {
    return baseURL.get('/api/v1/order/second-primary-order/');
  }

  static getSecondPrimaryOrder(id) {
    return baseURL.get(`/api/v1/order/second-primary-order/${id}`);
  }

  static createSecondPrimaryOrder(formValues) {
    return baseURL.post('/api/v1/order/second-primary-order', {
      ...formValues
    });
  }

  static editSecondPrimaryOrder(id, formValues) {
    return baseURL.patch(`/api/v1/order/second-primary-order/${id}`, {
      ...formValues
    });
  }

  static deleteSecondPrimaryOrder(id) {
    return baseURL.delete(`/api/v1/order/second-primary-order/${id}`);
  }

  static getAllSecondaryOrders() {
    return baseURL.get('/api/v1/order/secondary-order/');
  }

  static getSecondaryOrder(id) {
    return baseURL.get(`/api/v1/order/secondary-order/${id}`);
  }

  static createSecondaryOrder(formValues) {
    return baseURL.post('/api/v1/order/secondary-order', { ...formValues });
  }

  static editSecondaryOrder(id, formValues) {
    return baseURL.patch(`/api/v1/order/secondary-order/${id}`, {
      ...formValues
    });
  }

  static deleteSecondaryOrder(id) {
    return baseURL.delete(`/api/v1/order/secondary-order/${id}`);
  }
}

export default OrderApi;
