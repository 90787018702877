import baseURL from '../shared/AxiosBaseUrl/baseURL';

class RetailerApi {
  static getAllRetailers() {
    return baseURL.get('/api/v1/retailer/');
  }

  static getRetailer(id) {
    return baseURL.get(`/api/v1/retailer/${id}`);
  }

  static getRetailerInventory(id) {
    return baseURL.get(`/api/v1/retailer/${id}/inventory`);
  }

  static createRetailer(formValues) {
    return baseURL.post('/api/v1/retailer/', { ...formValues });
  }

  static createRetailerInventory(retailerId, formValues) {
    return baseURL.post(`/api/v1/retailer/${retailerId}/inventory`, {
      ...formValues
    });
  }

  static editRetailer(id, formValues) {
    return baseURL.patch(`/api/v1/retailer/${id}`, { ...formValues });
  }

  static editRetailerInventory(retailerId, inventoryId, formValues) {
    return baseURL.patch(
      `/api/v1/retailer/${retailerId}/inventory/${inventoryId}`,
      {
        ...formValues
      }
    );
  }

  static deleteRetailer(id) {
    return baseURL.delete(`/api/v1/retailer/${id}`);
  }
}

export default RetailerApi;
