import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AppBar, Toolbar, Button, Hidden, IconButton } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import PappyLogo from '../../images/pappy-logo.svg';
import { userLogout } from '../../redux/auth/auth.actions';

const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1
  },
  flexGrow: {
    flexGrow: 1
  },
  logo: {
    maxWidth: 120,
    height: 'auto'
  }
}));

function Navbar(props) {
  const classes = useStyles();
  const { onSidebarOpen } = props;
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const authLinks = (
    <>
      <Button color="inherit" onClick={() => dispatch(userLogout())}>
        Logout
      </Button>
    </>
  );

  return (
    <>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <Link to="/">
            <img alt="Logo" src={PappyLogo} className={classes.logo} />
          </Link>
          <div className={classes.flexGrow} />
          {!auth.isRequestPending && (
            <>{auth.isAuthenticated ? authLinks : null}</>
          )}
          <Hidden lgUp>
            <IconButton color="inherit" onClick={onSidebarOpen}>
              <MenuIcon />
            </IconButton>
          </Hidden>
        </Toolbar>
      </AppBar>
    </>
  );
}

export default Navbar;
