import React, { useState } from 'react';
import { connect } from 'react-redux';
import {
  fetchAllDistributors,
  deleteDistributor
} from '../../../redux/distributor/distributor.actions';
import {
  getDistributorById,
  getAllDistributors,
  isDistributorRequestPending
} from '../../../redux/distributor/distributor.selector';
import useFetchReducer from '../../../shared/Hooks/useFetchReducer';
import Modal from '../../../components/Modal';
import history from '../../../routes/history';
import { TITLES } from '../../../shared/Constants/Constant';
import { ROUTE_TO_DISTRIBUTOR } from '../../../shared/Constants/RouteConstants';
import BackdropWithLoader from '../../../components/BackdropWithLoader';
import { setCancel } from '../../../redux/event/event.actions';

function DeleteDistributor(props) {
  const [showDeleteModal, setShowDeleteModal] = useState(true);
  const {
    fetchAllDistributorsConnect,
    distributorsPayload,
    deleteDistributorConnect,
    isDistributorLoading,
    distributor = {},
    match: {
      params: { id }
    },
    setCancelConnect
  } = props;
  const distributorId = id;

  useFetchReducer(fetchAllDistributorsConnect, distributorsPayload);

  const handleDelete = async () => {
    await deleteDistributorConnect(distributorId);
    setShowDeleteModal(false);
  };

  const handleClose = () => {
    setShowDeleteModal(false);
    setCancelConnect();
    history.push(ROUTE_TO_DISTRIBUTOR);
  };

  const actions = [
    {
      buttonText: 'Cancel',
      buttonAction: handleClose,
      buttonStyle: { backgroundColor: '#4caf50', color: '#fff' }
    },
    {
      buttonText: 'Delete',
      buttonAction: handleDelete,
      buttonStyle: { backgroundColor: '#d50000', color: '#fff' }
    }
  ];

  const modelContent = () => {
    const { name } = distributor;

    if (!distributor) {
      return 'Are you sure you want to delete this distributor?';
    }
    return `Are you sure you want 
      to delete Distributor ${name}`;
  };

  if (isDistributorLoading) {
    return <BackdropWithLoader />;
  }

  return (
    <Modal
      title={TITLES.DELETE_DISTRIBUTOR}
      isModalVisible={showDeleteModal}
      actions={actions}
      onClose={handleClose}
      content={modelContent()}
    />
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    isDistributorLoading: isDistributorRequestPending(state),
    distributorsPayload: getAllDistributors(state),
    distributor: getDistributorById(state, ownProps)
  };
};

const mapDispatchToProps = {
  fetchAllDistributorsConnect: fetchAllDistributors,
  deleteDistributorConnect: deleteDistributor,
  setCancelConnect: setCancel
};

export default connect(mapStateToProps, mapDispatchToProps)(DeleteDistributor);
