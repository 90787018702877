import React, { useState } from 'react';
import { connect } from 'react-redux';
import { fetchAllZones, deleteZone } from '../../../redux/zone/zone.actions';
import useFetchReducer from '../../../shared/Hooks/useFetchReducer';
import {
  getZoneById,
  isZoneRequestPending,
  getAllZones
} from '../../../redux/zone/zone.selector';
import Modal from '../../../components/Modal';
import history from '../../../routes/history';
import { ROUTE_TO_ZONE } from '../../../shared/Constants/RouteConstants';
import { TITLES } from '../../../shared/Constants/Constant';
import BackdropWithLoader from '../../../components/BackdropWithLoader';
import { setCancel } from '../../../redux/event/event.actions';

function DeleteZone(props) {
  const {
    fetchAllZonesConnect,
    deleteZoneConnect,
    zone = {},
    match: {
      params: { id }
    },
    isZoneLoading,
    zonesPayload,
    setCancelConnect
  } = props;
  const zoneId = id;
  const [showDeleteModal, setShowDeleteModal] = useState(true);

  useFetchReducer(fetchAllZonesConnect, zonesPayload);

  const handleDelete = async () => {
    await deleteZoneConnect(zoneId);
    setShowDeleteModal(false);
  };

  const handleCancel = () => {
    setShowDeleteModal(false);
    setCancelConnect();
    history.push(ROUTE_TO_ZONE);
  };

  if (isZoneLoading) {
    return <BackdropWithLoader />;
  }

  const actions = [
    {
      buttonText: 'Cancel',
      buttonAction: handleCancel,
      buttonStyle: { backgroundColor: '#4caf50', color: '#fff' }
    },
    {
      buttonText: 'Delete',
      buttonAction: handleDelete,
      buttonStyle: { backgroundColor: '#d50000', color: '#fff' }
    }
  ];

  const modelContent = () => {
    const { name } = zone;

    return `Are you sure you want 
      to delete the ${name}?`;
  };

  return (
    <Modal
      title={TITLES.DELETE_ZONE}
      isModalVisible={showDeleteModal}
      actions={actions}
      onClose={handleCancel}
      content={modelContent()}
    />
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    isZoneLoading: isZoneRequestPending(state),
    zonesPayload: getAllZones(state),
    zone: getZoneById(state, ownProps)
  };
};

const mapDispatchToProps = {
  fetchAllZonesConnect: fetchAllZones,
  deleteZoneConnect: deleteZone,
  setCancelConnect: setCancel
};

export default connect(mapStateToProps, mapDispatchToProps)(DeleteZone);
