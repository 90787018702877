import React from 'react';
import {
  Table as MuiTable,
  TableBody,
  TableHead,
  TableRow,
  Paper,
  TableContainer
} from '@material-ui/core';
import tableStyles from '../Styles/TableStyles';
import StyledTableCell from '../Styles/StyledTableCell';
import { USER_SECONDARY_SALES_REPORT_HEADER_CONSTANTS } from '../../shared/Constants/TableHeaderConstants';
import EmployeeSecondarySalesReportTableItem from './EmployeeSecondarySalesReportTableItem';
import CustomSkeleton from '../CustomSkeleton';

const NO_SECONDARY_ACTIVITIES =
  'No Secondary Orders have been placed by the user';

const SELECT_USER = 'Select the user';

function EmployeeSecondarySalesReportTable(props) {
  const classes = tableStyles();
  const {
    secondaryActivities = [],
    isLoading,
    isUserSelected,
    showDateColumn = false,
    showMapIdColumn = false
  } = props;

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <MuiTable stickyHeader>
          <TableHead>
            <TableRow>
              {showMapIdColumn && (
                <StyledTableCell align="center">
                  {USER_SECONDARY_SALES_REPORT_HEADER_CONSTANTS.MAP_INDEX}
                </StyledTableCell>
              )}
              {showDateColumn && (
                <StyledTableCell align="center">
                  {USER_SECONDARY_SALES_REPORT_HEADER_CONSTANTS.DATE}
                </StyledTableCell>
              )}
              <StyledTableCell align="center">
                {USER_SECONDARY_SALES_REPORT_HEADER_CONSTANTS.TIME}
              </StyledTableCell>
              <StyledTableCell align="center">
                {USER_SECONDARY_SALES_REPORT_HEADER_CONSTANTS.RETAILER}
              </StyledTableCell>
              <StyledTableCell align="center">
                {USER_SECONDARY_SALES_REPORT_HEADER_CONSTANTS.BEAT}
              </StyledTableCell>
              <StyledTableCell align="center">
                {USER_SECONDARY_SALES_REPORT_HEADER_CONSTANTS.ACTIVITY}
              </StyledTableCell>
              <StyledTableCell align="center">
                {USER_SECONDARY_SALES_REPORT_HEADER_CONSTANTS.ORDER_VALUE}
              </StyledTableCell>
              <StyledTableCell align="center">
                {USER_SECONDARY_SALES_REPORT_HEADER_CONSTANTS.ORDER_NUMBER}
              </StyledTableCell>
              <StyledTableCell align="center">
                {USER_SECONDARY_SALES_REPORT_HEADER_CONSTANTS.ORDERED_QTY}
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading ? (
              <CustomSkeleton colSpan={9} totalRows={2} />
            ) : secondaryActivities.length > 0 ? (
              secondaryActivities.map((salesActivity, index) => (
                <EmployeeSecondarySalesReportTableItem
                  key={salesActivity.id}
                  index={index + 1}
                  salesActivity={salesActivity}
                  isLoading={isLoading}
                  showDateColumn={showDateColumn}
                  showMapIdColumn={showMapIdColumn}
                />
              ))
            ) : (
              <TableRow>
                <StyledTableCell align="center" colSpan={9}>
                  {!isUserSelected ? SELECT_USER : NO_SECONDARY_ACTIVITIES}
                </StyledTableCell>
              </TableRow>
            )}
          </TableBody>
        </MuiTable>
      </TableContainer>
    </Paper>
  );
}

export default React.memo(EmployeeSecondarySalesReportTable);
