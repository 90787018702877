import React from 'react';
import { connect } from 'react-redux';
import { Container, Avatar, Typography } from '@material-ui/core';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import formStyles from '../../../components/Styles/FormStyles';
import EditDistrictForm from '../../../components/EditDistrictForm';
import {
  editDistrict,
  fetchDistrict
} from '../../../redux/district/district.actions';
import {
  getDistrictById,
  isDistrictRequestPending
} from '../../../redux/district/district.selector';
import { TITLES } from '../../../shared/Constants/Constant';
import BackdropWithLoader from '../../../components/BackdropWithLoader';
import { fetchAllZones } from '../../../redux/zone/zone.actions';
import {
  getAllZones,
  isZoneRequestPending
} from '../../../redux/zone/zone.selector';
import history from '../../../routes/history';
import { ROUTE_TO_DISTRICT } from '../../../shared/Constants/RouteConstants';
import { setCancel } from '../../../redux/event/event.actions';
import useFetchReducerWithId from '../../../shared/Hooks/useFetchReducerWithId';
import useRefreshFetchReducer from '../../../shared/Hooks/useRefreshFetchReducer';

const EditDistrict = (props) => {
  const classes = formStyles();
  const {
    editDistrictConnect,
    fetchDistrictConnect,
    district = {},
    isDistrictsLoading,
    match: {
      params: { id }
    },
    fetchAllZonesConnect,
    isZonesLoading,
    zonesPayload,
    setCancelConnect
  } = props;
  const districtId = id;

  useRefreshFetchReducer(fetchAllZonesConnect);
  useFetchReducerWithId(fetchDistrictConnect, districtId, district);

  const handleSubmit = (formValues) => {
    editDistrictConnect(districtId, formValues);
  };

  const handleCancel = () => {
    setCancelConnect();
    history.push(ROUTE_TO_DISTRICT);
  };

  if (isDistrictsLoading || isZonesLoading) {
    return <BackdropWithLoader />;
  }

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <AccountCircleIcon color="primary" />
        </Avatar>
        <Typography component="h1" variant="h5">
          {TITLES.EDIT_DISTRICT}
        </Typography>
        <EditDistrictForm
          zones={zonesPayload}
          districtId={districtId}
          district={district}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
        />
      </div>
    </Container>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    isDistrictsLoading: isDistrictRequestPending(state),
    district: getDistrictById(state, ownProps),
    isZonesLoading: isZoneRequestPending(state),
    zonesPayload: getAllZones(state)
  };
};

const mapDispatchToProps = {
  fetchDistrictConnect: fetchDistrict,
  editDistrictConnect: editDistrict,
  fetchAllZonesConnect: fetchAllZones,
  setCancelConnect: setCancel
};

export default connect(mapStateToProps, mapDispatchToProps)(EditDistrict);
