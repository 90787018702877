import { makeStyles } from '@material-ui/core/styles';

const createSecondaryOrderStyles = makeStyles((theme) => ({
  distributorPickerContainer: {
    marginLeft: 16,
    marginRight: 16
  }
}));

export default createSecondaryOrderStyles;
