import React from 'react';
import { connect } from 'react-redux';
import { Container, Avatar, Typography } from '@material-ui/core';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import formStyles from '../../../components/Styles/FormStyles';
import EditDistributorForm from '../../../components/EditDistributorForm';
import {
  editDistributor,
  fetchDistributor
} from '../../../redux/distributor/distributor.actions';
import {
  getDistributorById,
  isDistributorRequestPending
} from '../../../redux/distributor/distributor.selector';
import { TITLES } from '../../../shared/Constants/Constant';
import history from '../../../routes/history';
import { ROUTE_TO_DISTRIBUTOR } from '../../../shared/Constants/RouteConstants';
import { setCancel } from '../../../redux/event/event.actions';
import {
  getAllAreas,
  isAreaRequestPending
} from '../../../redux/area/area.selector';
import {
  getAllDistricts,
  isDistrictRequestPending
} from '../../../redux/district/district.selector';
import {
  getAllZones,
  isZoneRequestPending
} from '../../../redux/zone/zone.selector';
import useFetchReducerWithId from '../../../shared/Hooks/useFetchReducerWithId';
import BackdropWithLoader from '../../../components/BackdropWithLoader';
import useRefreshFetchReducer from '../../../shared/Hooks/useRefreshFetchReducer';
import { fetchAllZonesAndDistrictsAndAreas } from '../../../redux/territory/territory.actions';

const EditDistributor = (props) => {
  const classes = formStyles();
  const {
    fetchDistributorConnect,
    editDistributorConnect,
    distributor,
    isDistributorLoading,
    isAreasLoading,
    isDistrictsLoading,
    isZonesLoading,
    zonesPayload,
    districtsPayload,
    areasPayload,
    fetchAllZonesAndDistrictsAndAreasConnect,
    setCancelConnect,
    match: {
      params: { id }
    }
  } = props;

  const distributorId = id;

  useFetchReducerWithId(fetchDistributorConnect, distributorId, distributor);
  useRefreshFetchReducer(fetchAllZonesAndDistrictsAndAreasConnect);

  const handleSubmit = (formValues) => {
    editDistributorConnect(distributorId, formValues);
  };

  const handleCancel = () => {
    setCancelConnect();
    history.push(ROUTE_TO_DISTRIBUTOR);
  };

  if (
    isDistributorLoading ||
    isAreasLoading ||
    isDistrictsLoading ||
    isZonesLoading
  ) {
    return <BackdropWithLoader />;
  }

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <AccountCircleIcon color="primary" />
        </Avatar>
        <Typography component="h1" variant="h5">
          {TITLES.EDIT_DISTRIBUTOR}
        </Typography>
        <EditDistributorForm
          distributor={distributor}
          zonesPayload={zonesPayload}
          districtsPayload={districtsPayload}
          areasPayload={areasPayload}
          distributorId={distributorId}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
        />
      </div>
    </Container>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    distributor: getDistributorById(state, ownProps),
    isDistributorLoading: isDistributorRequestPending(state),
    isZonesLoading: isZoneRequestPending(state),
    zonesPayload: getAllZones(state),
    isDistrictsLoading: isDistrictRequestPending(state),
    districtsPayload: getAllDistricts(state),
    isAreasLoading: isAreaRequestPending(state),
    areasPayload: getAllAreas(state)
  };
};

const mapDispatchToProps = {
  editDistributorConnect: editDistributor,
  fetchDistributorConnect: fetchDistributor,
  fetchAllZonesAndDistrictsAndAreasConnect: fetchAllZonesAndDistrictsAndAreas,
  setCancelConnect: setCancel
};

export default connect(mapStateToProps, mapDispatchToProps)(EditDistributor);
