import { Box, Grid, CircularProgress, makeStyles } from '@material-ui/core';
import React from 'react';
import MaterialButton from '../MaterialButton';

const useStyles = makeStyles({
  headerTitleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  createButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  loader: {
    marginRight: 10
  }
});

function TableHeadingWithCreateButton(props) {
  const {
    component: Component,
    isLoading,
    isCreateAllowed = true,
    open,
    handleButtonClick,
    handleModalClose,
    headerTitle,
    buttonText,
    showCreateModal = false,
    ...otherProps
  } = props;

  const classes = useStyles();
  return (
    <Box display="flex" p={2}>
      <Grid item lg={6} md={6} xs={12} className={classes.headerTitleContainer}>
        {!!headerTitle && (
          <MaterialButton buttonText={headerTitle} variant="outlined" />
        )}
      </Grid>
      <Grid
        item
        lg={6}
        md={6}
        xs={12}
        className={classes.createButtonContainer}
      >
        {isLoading && <CircularProgress className={classes.loader} />}
        {isCreateAllowed && (
          <MaterialButton
            buttonText={buttonText}
            handleClick={handleButtonClick}
            variant="contained"
            disabled={isLoading}
          />
        )}
        {showCreateModal && (
          <Component
            open={open}
            handleClose={handleModalClose}
            {...otherProps}
          />
        )}
      </Grid>
    </Box>
  );
}

export default TableHeadingWithCreateButton;
