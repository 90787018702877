import React from 'react';
import { connect } from 'react-redux';
import { Container, Avatar, Typography } from '@material-ui/core';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import formStyles from '../../../components/Styles/FormStyles';
import CreateRetailerForm from '../../../components/CreateRetailerForm';
import { createRetailer } from '../../../redux/retailer/retailer.actions';
import { TITLES } from '../../../shared/Constants/Constant';
import history from '../../../routes/history';
import { ROUTE_TO_RETAILER } from '../../../shared/Constants/RouteConstants';
import { setCancel } from '../../../redux/event/event.actions';
import {
  isBeatAreaRequestPending,
  getAllBeatAreas
} from '../../../redux/beatArea/beatArea.selector';
import {
  getAllAreas,
  isAreaRequestPending
} from '../../../redux/area/area.selector';
import {
  getAllDistricts,
  isDistrictRequestPending
} from '../../../redux/district/district.selector';
import {
  getAllZones,
  isZoneRequestPending
} from '../../../redux/zone/zone.selector';
import BackdropWithLoader from '../../../components/BackdropWithLoader';
import useRefreshFetchReducer from '../../../shared/Hooks/useRefreshFetchReducer';
import { isRetailerRequestPending } from '../../../redux/retailer/retailer.selector';
import { fetchAllZonesAndDistrictsAndAreasAndBeats } from '../../../redux/territory/territory.actions';

const CreateRetailer = (props) => {
  const classes = formStyles();
  const {
    createRetailerConnect,
    setCancelConnect,
    zonesPayload,
    isZonesLoading,
    districtsPayload,
    isDistrictsLoading,
    areasPayload,
    isAreasLoading,
    isBeatAreasLoading,
    beatAreasPayload,
    fetchAllZonesAndDistrictsAndAreasAndBeatsConnect,
    isRetailersLoading
  } = props;

  useRefreshFetchReducer(fetchAllZonesAndDistrictsAndAreasAndBeatsConnect);

  const handleSubmit = (formValues) => {
    createRetailerConnect(formValues);
  };

  const handleCancel = () => {
    setCancelConnect();
    history.push(ROUTE_TO_RETAILER);
  };

  if (
    isRetailersLoading ||
    isBeatAreasLoading ||
    isAreasLoading ||
    isDistrictsLoading ||
    isZonesLoading
  ) {
    return <BackdropWithLoader />;
  }

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <AccountCircleIcon color="primary" />
        </Avatar>
        <Typography component="h1" variant="h5">
          {TITLES.CREATE_RETAILER}
        </Typography>
        <CreateRetailerForm
          zonesPayload={zonesPayload}
          districtsPayload={districtsPayload}
          areasPayload={areasPayload}
          beatsPayload={beatAreasPayload}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
        />
      </div>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    isRetailersLoading: isRetailerRequestPending(state),
    isZonesLoading: isZoneRequestPending(state),
    zonesPayload: getAllZones(state),
    isDistrictsLoading: isDistrictRequestPending(state),
    districtsPayload: getAllDistricts(state),
    isAreasLoading: isAreaRequestPending(state),
    areasPayload: getAllAreas(state),
    beatAreasPayload: getAllBeatAreas(state),
    isBeatAreasLoading: isBeatAreaRequestPending(state)
  };
};

const mapDispatchToProps = {
  createRetailerConnect: createRetailer,
  fetchAllZonesAndDistrictsAndAreasAndBeatsConnect: fetchAllZonesAndDistrictsAndAreasAndBeats,

  setCancelConnect: setCancel
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateRetailer);
