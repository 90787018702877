import React, { useState } from 'react';
import { connect } from 'react-redux';
import {
  fetchAllBeatAreas,
  deleteBeatArea
} from '../../../redux/beatArea/beatArea.actions';
import useFetchReducer from '../../../shared/Hooks/useFetchReducer';
import Modal from '../../../components/Modal';
import history from '../../../routes/history';
import { TITLES } from '../../../shared/Constants/Constant';
import {
  getAllBeatAreas,
  isBeatAreaRequestPending,
  getBeatAreaById
} from '../../../redux/beatArea/beatArea.selector';
import { ROUTE_TO_BEAT_AREA } from '../../../shared/Constants/RouteConstants';
import BackdropWithLoader from '../../../components/BackdropWithLoader';
import { setCancel } from '../../../redux/event/event.actions';

function DeleteBeatArea(props) {
  const [showDeleteModal, setShowDeleteModal] = useState(true);
  const {
    fetchAllBeatAreasConnect,
    beatAreasPayload,
    deleteBeatAreaConnect,
    isBeatAreaLoading,
    beatArea = {},
    match: {
      params: { id }
    },
    setCancelConnect
  } = props;
  const beatAreaId = id;

  useFetchReducer(fetchAllBeatAreasConnect, beatAreasPayload);

  const handleDelete = async () => {
    await deleteBeatAreaConnect(beatAreaId);
    setShowDeleteModal(false);
  };

  const handleCancel = () => {
    setShowDeleteModal(false);
    setCancelConnect();
    history.push(ROUTE_TO_BEAT_AREA);
  };

  const actions = [
    {
      buttonText: 'Cancel',
      buttonAction: handleCancel,
      buttonStyle: { backgroundColor: '#4caf50', color: '#fff' }
    },
    {
      buttonText: 'Delete',
      buttonAction: handleDelete,
      buttonStyle: { backgroundColor: '#d50000', color: '#fff' }
    }
  ];

  const modelContent = () => {
    const { name, district = {}, zone = {}, area = {} } = beatArea;

    if (!beatArea) {
      return 'Are you sure you want to delete this beat?';
    }
    return `Are you sure you want 
      to delete beat ${name} from Area: ${area.name} - District: ${district.name} - Zone: ${zone.name}`;
  };

  if (isBeatAreaLoading) {
    return <BackdropWithLoader />;
  }

  return (
    <Modal
      title={TITLES.DELETE_BEAT_AREA}
      isModalVisible={showDeleteModal}
      actions={actions}
      onClose={handleCancel}
      content={modelContent()}
    />
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    isBeatAreaLoading: isBeatAreaRequestPending(state),
    beatAreasPayload: getAllBeatAreas(state),
    beatArea: getBeatAreaById(state, ownProps)
  };
};

const mapDispatchToProps = {
  fetchAllBeatAreasConnect: fetchAllBeatAreas,
  deleteBeatAreaConnect: deleteBeatArea,
  setCancelConnect: setCancel
};

export default connect(mapStateToProps, mapDispatchToProps)(DeleteBeatArea);
