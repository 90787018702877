import React from 'react';
import { connect } from 'react-redux';
import { Container, Avatar, Typography } from '@material-ui/core';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import formStyles from '../../../components/Styles/FormStyles';
import CreateSuperStockistForm from '../../../components/CreateSuperStockistForm';
import {
  createSuperStockist,
  fetchAllSuperStockists
} from '../../../redux/superStockist/superStockist.actions';
import { isSuperStockistRequestPending } from '../../../redux/superStockist/superStockist.selector';
import { TITLES } from '../../../shared/Constants/Constant';
import history from '../../../routes/history';
import useRefreshFetchReducer from '../../../shared/Hooks/useRefreshFetchReducer';
import { fetchAllZonesAndDistricts } from '../../../redux/territory/territory.actions';
import { ROUTE_TO_SUPER_STOCKIST } from '../../../shared/Constants/RouteConstants';
import { setCancel } from '../../../redux/event/event.actions';
import {
  getAllDistricts,
  isDistrictRequestPending
} from '../../../redux/district/district.selector';
import {
  getAllZones,
  isZoneRequestPending
} from '../../../redux/zone/zone.selector';
import BackdropWithLoader from '../../../components/BackdropWithLoader';

const CreateSuperStockist = (props) => {
  const classes = formStyles();
  const {
    createSuperStockistConnect,
    setCancelConnect,
    zonesPayload,
    isZonesLoading,
    districtsPayload,
    isDistrictsLoading,
    fetchAllZonesAndDistrictsConnect,
    isSuperStockistLoading
  } = props;

  useRefreshFetchReducer(fetchAllZonesAndDistrictsConnect);

  const handleSubmit = (formValues) => {
    createSuperStockistConnect(formValues);
  };

  const handleCancel = () => {
    setCancelConnect();
    history.push(ROUTE_TO_SUPER_STOCKIST);
  };

  if (isSuperStockistLoading || isDistrictsLoading || isZonesLoading) {
    return <BackdropWithLoader />;
  }

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <AccountCircleIcon color="primary" />
        </Avatar>
        <Typography component="h1" variant="h5">
          {TITLES.CREATE_SUPER_STOCKIST}
        </Typography>
        <CreateSuperStockistForm
          zonesPayload={zonesPayload}
          districtsPayload={districtsPayload}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
        />
      </div>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    isZonesLoading: isZoneRequestPending(state),
    zonesPayload: getAllZones(state),
    isDistrictsLoading: isDistrictRequestPending(state),
    districtsPayload: getAllDistricts(state),
    isSuperStockistLoading: isSuperStockistRequestPending(state)
  };
};

const mapDispatchToProps = {
  fetchAllSuperStockistsConnect: fetchAllSuperStockists,
  createSuperStockistConnect: createSuperStockist,
  fetchAllZonesAndDistrictsConnect: fetchAllZonesAndDistricts,
  setCancelConnect: setCancel
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateSuperStockist);
