import React, { useEffect } from 'react';
import { Backdrop, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CheckCircleOutlineSharpIcon from '@material-ui/icons/CheckCircleOutlineSharp';
import { SEVERITY } from '../../shared/Constants/Constant';

const useStyles = makeStyles((theme) => ({
  contentBox: {
    width: '40%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  contentText: {
    margin: '10px'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 2,
    color: '#ddd'
  },
  largeIcon: {
    width: 100,
    height: 100
  }
}));

function BackdropWithMessage(props) {
  const classes = useStyles();
  const { type, text, handleClose } = props;

  useEffect(() => {
    setTimeout(() => {
      handleClose();
    }, 1500);
  }, [handleClose]);

  return (
    <Backdrop className={classes.backdrop} open>
      <div className={classes.contentBox}>
        {type === SEVERITY.SUCCESS && (
          <CheckCircleOutlineSharpIcon
            style={{ color: '#4caf50', fontSize: '100' }}
          />
        )}
        <Typography
          variant="h4"
          className={classes.contentText}
          color="secondary"
          align="center"
        >
          {text}
        </Typography>
      </div>
    </Backdrop>
  );
}

export default BackdropWithMessage;
