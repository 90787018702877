import { makeStyles } from '@material-ui/core/styles';

const createSkuFormStyles = makeStyles({
  skuTypeRadioContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 16,
    marginBottom: 8
  }
});

export default createSkuFormStyles;
