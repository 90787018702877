import React, { useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Avatar,
  InputAdornment
} from '@material-ui/core';
import MaterialButton from '../../../../components/MaterialButton';
import FormAutoComplete from '../../../../components/FormAutoComplete';
import { FiberNew as FiberNewIcon } from '@material-ui/icons';
import FormInput from '../../../../components/FormInput';
import { get } from 'lodash';
import {
  CUSTOMER_TYPES_CONSTANTS,
  DECIMAL_NUMBER_REGEX
} from '../../../../shared/Constants/Constant';
import {
  getSuperStockistSkuPrice,
  getDistributorSkuPrice,
  getSkuTotalCost
} from '../../../../redux/primary-order/primary-order.utils';
import commonStyles from '../../../../Styles/CommonStyles';

function PrimaryOrderCreateItemForm(props) {
  const {
    open,
    handleClose,
    errorMessage,
    skusPayload,
    addSkuItem,
    customer = {}
  } = props;
  const { customerType } = customer;
  const classes = commonStyles();
  const [selectedSkuItem, setSelectedSkuItem] = useState(null);
  const [quantityInCases, setQuantityInCases] = useState('');
  const [quantityInPcs, setQuantityInPcs] = useState('');
  const [customizedMargin, setCustomizedMargin] = useState('');

  const getCustomerLandedPrice = () => {
    if (customerType === CUSTOMER_TYPES_CONSTANTS.PRIMARY_DISTRIBUTOR) {
      return getDistributorSkuPrice(selectedSkuItem, customizedMargin);
    }

    return getSuperStockistSkuPrice(selectedSkuItem, customizedMargin);
  };

  const getCustomerMargin = (sku) => {
    if (customerType === CUSTOMER_TYPES_CONSTANTS.PRIMARY_DISTRIBUTOR) {
      return Number(get(sku, 'margin.distributor', 10)).toFixed(2);
    }

    return Number(get(sku, 'margin.super_stockist', 8)).toFixed(2);
  };

  const normalizeForm = () => {
    const customerLandedPrice = getCustomerLandedPrice();

    return {
      skuId: selectedSkuItem.id,
      name: selectedSkuItem.name,
      parentProductName: get(selectedSkuItem, 'parent_product_name', 'NA'),
      quantityInCases: quantityInCases,
      quantityInPcs: quantityInPcs,
      customizedMargin: Number(customizedMargin).toFixed(2),
      customerLandedPrice,
      totalSkuCost: getSkuTotalCost({
        piecesPerCarton: selectedSkuItem.pieces_per_carton,
        quantity: quantityInCases,
        customerLandedPrice
      }),
      piecesPerCarton: selectedSkuItem.pieces_per_carton
    };
  };

  const handleModalClose = () => {
    setSelectedSkuItem(null);
    setQuantityInCases('');
    setCustomizedMargin('');
    setQuantityInPcs('');
    handleClose();
  };

  const formSubmit = () => {
    addSkuItem(normalizeForm(), handleModalClose);
  };

  const handleSelectedSkuItem = (value) => {
    setSelectedSkuItem(value);
    setCustomizedMargin(getCustomerMargin(value));
  };

  const handleQuantityInCasesChange = (event) => {
    const { value } = event.target;

    if (value === '' || value.match(DECIMAL_NUMBER_REGEX) != null) {
      setQuantityInCases(value);
      setQuantityInPcs(
        parseFloat(value) * Number(selectedSkuItem.pieces_per_carton)
      );
    }
  };

  const handleMarginInputChange = (event) => {
    event.persist();
    const { value } = event.target;

    if (value === '' || value.match(DECIMAL_NUMBER_REGEX) != null) {
      setCustomizedMargin(Number(value));
    }
  };

  const onBlurDecimalHandle = (event) => {
    event.persist();

    setCustomizedMargin((prevValue) => Number(prevValue).toFixed(2));
  };

  const disableQuantityInput = () => {
    if (!selectedSkuItem) {
      return true;
    }

    return false;
  };

  const disableNext = () => {
    if (!selectedSkuItem) {
      return true;
    } else if (Number(quantityInCases) < 1) {
      return true;
    }
    return !quantityInCases;
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleModalClose}
        classes={{
          paperWidthSm: classes.paperWidthSm
        }}
      >
        <DialogTitle id="form-dialog-title">
          <Avatar className={classes.avatar}>
            <FiberNewIcon color="primary" />
          </Avatar>
          <Typography align="center">Primary Order - Sku Item</Typography>
        </DialogTitle>
        <DialogContent>
          <FormAutoComplete
            data={skusPayload}
            label="Sku Name"
            name="skuName"
            value={selectedSkuItem}
            onChangeSelect={(e, newValue) => handleSelectedSkuItem(newValue)}
            property="name"
          />

          <FormInput
            type="number"
            label="Margin"
            placeholder="Enter the Margin"
            name="customizedMargin"
            value={customizedMargin || ''}
            onChange={handleMarginInputChange}
            disabled={disableQuantityInput()}
            onBlur={onBlurDecimalHandle}
            InputProps={{
              endAdornment: <InputAdornment position="start">%</InputAdornment>
            }}
          />

          <FormInput
            type="number"
            label="Order Quantity(in cases)"
            placeholder="Enter the Order Quantity(in cases)"
            name="quantityInCases"
            value={quantityInCases || ''}
            onChange={handleQuantityInCasesChange}
            disabled={disableQuantityInput()}
          />

          <FormInput
            type="number"
            label="Order Quantity(in pcs)"
            name="quantityInPcs"
            value={quantityInPcs || ''}
            disabled
          />

          {!!errorMessage && (
            <Typography variant="h5" style={{ color: '#d50000', fontSize: 13 }}>
              {`**${errorMessage}`}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <MaterialButton handleClick={handleModalClose} buttonText="Cancel" />
          <MaterialButton
            handleClick={formSubmit}
            disabled={disableNext()}
            buttonText="Confirm"
          />
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default PrimaryOrderCreateItemForm;
