import BeatApi from '../../apis/BeatAPI';
import * as BeatAreaActionTypes from './beatArea.types';
import STATUS from '../../shared/Constants/apiStatus';
import { ROUTE_TO_BEAT_AREA } from '../../shared/Constants/RouteConstants';

export const fetchAllBeatAreas = () => async (dispatch) => {
  dispatch({
    type: BeatAreaActionTypes.FETCH_BEAT_AREAS_REQUEST
  });

  try {
    const response = await BeatApi.getAllBeatAreas();
    if (response.data.status === STATUS.OK) {
      dispatch({
        type: BeatAreaActionTypes.FETCH_BEAT_AREAS_SUCCESS,
        payload: response.data.beats,
        error: null,
        route: null,
        success: null
      });
    }
  } catch (error) {
    dispatch({
      type: BeatAreaActionTypes.FETCH_BEAT_AREAS_ERROR,
      error: error.response.data,
      payload: null,
      route: null,
      success: null
    });
  }
};

export const fetchBeatArea = (id) => async (dispatch) => {
  dispatch({
    type: BeatAreaActionTypes.FETCH_BEAT_AREA_REQUEST
  });

  try {
    const response = await BeatApi.getBeatArea(id);
    if (response.data.status === STATUS.OK) {
      dispatch({
        type: BeatAreaActionTypes.FETCH_BEAT_AREA_SUCCESS,
        payload: response.data.beat,
        error: null,
        route: null,
        success: null
      });
    }
  } catch (error) {
    dispatch({
      type: BeatAreaActionTypes.FETCH_BEAT_AREA_ERROR,
      error: error.response.data,
      payload: null,
      route: ROUTE_TO_BEAT_AREA,
      success: null
    });
  }
};

export const createBeatArea = (formValues) => async (dispatch) => {
  dispatch({
    type: BeatAreaActionTypes.CREATE_BEAT_AREA_REQUEST
  });

  try {
    const response = await BeatApi.createBeatArea(formValues);

    if (response.data.status === STATUS.OK) {
      dispatch({
        type: BeatAreaActionTypes.CREATE_BEAT_AREA_SUCCESS,
        payload: response.data.beat,
        error: null,
        route: ROUTE_TO_BEAT_AREA,
        success: response.data.message
      });
    }
  } catch (error) {
    dispatch({
      type: BeatAreaActionTypes.CREATE_BEAT_AREA_ERROR,
      error: error.response.data,
      payload: null,
      route: ROUTE_TO_BEAT_AREA,
      success: null
    });
  }
};

export const editBeatArea = (id, formValues) => async (dispatch) => {
  dispatch({
    type: BeatAreaActionTypes.UPDATE_BEAT_AREA_REQUEST
  });

  try {
    const response = await BeatApi.editBeatArea(id, formValues);
    if (response.data.status === STATUS.OK) {
      dispatch({
        type: BeatAreaActionTypes.UPDATE_BEAT_AREA_SUCCESS,
        payload: response.data.beat,
        error: null,
        route: ROUTE_TO_BEAT_AREA,
        success: response.data.message
      });
    }
  } catch (error) {
    dispatch({
      type: BeatAreaActionTypes.UPDATE_BEAT_AREA_ERROR,
      error: error.response.data,
      payload: null,
      route: ROUTE_TO_BEAT_AREA,
      success: null
    });
  }
};

export const deleteBeatArea = (id) => async (dispatch) => {
  dispatch({
    type: BeatAreaActionTypes.DELETE_BEAT_AREA_REQUEST
  });

  try {
    const response = await BeatApi.deleteBeatArea(id);
    if (response.data.status === STATUS.OK) {
      dispatch({
        type: BeatAreaActionTypes.DELETE_BEAT_AREA_SUCCESS,
        payload: id,
        error: null,
        route: ROUTE_TO_BEAT_AREA,
        success: response.data.message
      });
    }
  } catch (error) {
    dispatch({
      type: BeatAreaActionTypes.DELETE_BEAT_AREA_ERROR,
      payload: null,
      error: error.response.data,
      route: ROUTE_TO_BEAT_AREA,
      success: null
    });
  }
};
