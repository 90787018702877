import baseURL from '../shared/AxiosBaseUrl/baseURL';

class UserApi {
  static getAllUsers() {
    return baseURL.get('/api/v1/user/');
  }

  static getAllUsersAttendance(queryParams) {
    return baseURL.get('/api/v1/user/attendance', {
      params: queryParams
    });
  }

  static getUser(id) {
    return baseURL.get(`/api/v1/user/${id}`);
  }

  static createUser(formValues) {
    return baseURL.post('/api/v1/user/', { ...formValues });
  }

  static editUser(id, formValues) {
    return baseURL.patch(`/api/v1/user/${id}`, { ...formValues });
  }

  static deleteUser(id) {
    return baseURL.delete(`/api/v1/user/${id}`);
  }
}

export default UserApi;
