import { createSelector } from 'reselect';
import { SKU_TYPES } from '../../shared/Constants/Constant';
import { calculateSkuPrice } from './helpers/SkuPriceCalculation';

export const getSkusSelector = (state) => state.skus;
export const getAllSkusDataSelector = (state) => state.skus.data;
export const getSkuByIdSelector = (state, props) => props.match.params.id;

export const isSkuRequestPending = createSelector(
  [getSkusSelector],
  (skus) => skus.isRequestPending
);

export const getAllSkus = createSelector(getAllSkusDataSelector, (skus) =>
  Object.values(skus).map((sku) => {
    return {
      ...sku,
      sku_prices: calculateSkuPrice(sku)
    };
  })
);

export const getAllComboSkus = createSelector(getAllSkusDataSelector, (skus) =>
  Object.values(skus).filter((sku) => sku.skuType === SKU_TYPES.COMBO_SKU)
);

export const getSkuById = createSelector(
  getAllSkusDataSelector,
  getSkuByIdSelector,
  (skus, id) => skus[id]
);
