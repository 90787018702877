import { CLEAR_ROUTE } from './route.types';

const initState = {
  nextLocation: null
};

const routeReducer = (state = initState, action) => {
  const { route } = action;

  if (route) {
    return {
      ...state,
      nextLocation: route
    };
  }

  if (action.type === CLEAR_ROUTE) {
    return {
      ...state,
      nextLocation: null
    };
  }

  return state;
};

export default routeReducer;
