import React, { useEffect, useState } from 'react';
import {
  Container,
  Grid,
  CardContent,
  Typography,
  Divider,
  Card,
  Box,
  CardHeader
} from '@material-ui/core';
import { connect } from 'react-redux';
import {
  clearCart,
  createSecondPrimaryOrder,
  resetSecondPrimaryOrderData,
  resetSecondPrimaryOrderError
} from '../../../redux/second-primary-order/second-primary-order.actions';
import {
  isSecondPrimaryOrderRequestPending,
  getErrorMessage,
  getSecondPrimaryOrderCartItems,
  getSecondPrimaryOrderCustomer
} from '../../../redux/second-primary-order/second-primary-order.selector';
import MaterialButton from '../../../components/MaterialButton';
import { isEmpty, size } from 'lodash';
import { fetchAllSkus } from '../../../redux/sku/sku.actions';
import {
  getAllSkus,
  isSkuRequestPending
} from '../../../redux/sku/sku.selector';
import { fetchAllSuperStockists } from '../../../redux/superStockist/superStockist.actions';
import { getAllSuperStockists } from '../../../redux/superStockist/superStockist.selector';
import CreateSecondPrimaryOrderCartTable from './CreateSecondPrimaryOrderCartTable';
import { notification } from 'antd';
import BackdropWithLoader from '../../../components/BackdropWithLoader';
import commonStyles from '../../../Styles/CommonStyles';
import { CUSTOMER_TYPES_TITLES } from '../../../shared/Constants/Constant';
import FormAutoComplete from '../../../components/FormAutoComplete';
import createSecondPrimaryOrderStyles from './CreateSecondPrimaryOrder.css';
import { getAssignedDistrictIds } from '../../../components/EditDistributorForm/EditDistributorForm.utils';

const CreateSecondPrimaryOrder = (props) => {
  const commonClasses = commonStyles();
  const createSecondPrimaryOrderClasses = createSecondPrimaryOrderStyles();
  const {
    isSecondPrimaryOrderRequestPending,
    isSkuRequestPending,
    resetSecondPrimaryOrderErrorConnect,
    errorMessage,
    skusPayload,
    clearCartConnect,
    cartSkuPayload,
    fetchAllSkusConnect,
    resetSecondPrimaryOrderDataConnect,
    customer,
    createSecondPrimaryOrderConnect,
    fetchAllSuperStockistsConnect,
    districtSuperStockists
  } = props;

  const isRequestPending =
    isSecondPrimaryOrderRequestPending || isSkuRequestPending;
  const [superStockistItem, setSuperStockistItem] = useState(null);
  const [superStockistError, setSuperStockistError] = useState('');

  useEffect(() => {
    fetchAllSkusConnect();

    if (customer.appointed_areas.length) {
      const districtIds = getAssignedDistrictIds(customer.appointed_areas);

      fetchAllSuperStockistsConnect({
        districts: districtIds
      });
    }

    return () => {
      clearCartConnect();
      resetSecondPrimaryOrderErrorConnect();
      resetSecondPrimaryOrderDataConnect();
    };
  }, []);

  const openErrorNotification = (type, placement, error) => {
    notification[type]({
      message: 'Oops! Something went wrong',
      description: error,
      placement,
      duration: 3.5,
      onClose: resetSecondPrimaryOrderErrorConnect
    });
  };

  if (!isEmpty(errorMessage)) {
    openErrorNotification('error', 'bottomRight', errorMessage);
  }

  const disableContinue = () => {
    return isRequestPending || size(cartSkuPayload) === 0;
  };

  const Heading = `CREATE ${
    CUSTOMER_TYPES_TITLES[customer.customerType]
  } ORDER`;

  const normalizeSkuItems = () => {
    return cartSkuPayload.map((sku) => {
      return {
        sku: sku.skuId,
        name: sku.name,
        ordered_quantity: sku.quantityInPcs,
        margin: sku.customizedMargin,
        landed_cost: sku.distributorLandedPrice,
        total_cost: sku.totalSkuCost
      };
    });
  };

  const normalizeOrderValues = () => {
    const formValues = {};

    if (superStockistItem) {
      formValues.super_stockist = superStockistItem.id;
    }

    return {
      ...formValues,
      distributor: customer.id,
      sku_items: normalizeSkuItems()
    };
  };

  const handleSsChange = (e, value) => {
    e.persist();

    if (superStockistError) {
      setSuperStockistError('');
    }

    setSuperStockistItem(value);
  };

  const handleFormSubmit = () => {
    if (!superStockistItem) {
      return setSuperStockistError('** Super Stockist is required');
    }

    createSecondPrimaryOrderConnect(normalizeOrderValues());
  };

  return (
    <Container className={commonClasses.root} maxWidth="lg">
      <Grid container spacing={3}>
        <Grid item lg={12} md={12} xs={12}>
          <Card>
            <CardHeader title={Heading} className={commonClasses.heading} />
            <CardHeader
              title={customer.name}
              className={commonClasses.heading}
            />
            <Typography component="h5" className={commonClasses.heading}>
              GSTIN: {customer.gstin || 'NA'}
            </Typography>
            <Grid
              item
              md={4}
              xs={12}
              lg={4}
              className={
                createSecondPrimaryOrderClasses.superStockistPickerContainer
              }
            >
              <FormAutoComplete
                data={districtSuperStockists}
                label="Select Super Stockist"
                name="superStockist"
                value={superStockistItem}
                onChangeSelect={(e, newValue) => handleSsChange(e, newValue)}
                property="name"
                error={!!superStockistError}
                helperText={superStockistError}
                required
              />
            </Grid>

            {isRequestPending ? (
              <BackdropWithLoader />
            ) : (
              <>
                <Divider />
                <CardContent>
                  <Grid container spacing={3}>
                    <Grid item md={12} xs={12} className={commonClasses.table}>
                      <CreateSecondPrimaryOrderCartTable
                        skusPayload={skusPayload}
                        customer={customer}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
                <Divider />
                <Box display="flex" justifyContent="flex-end" p={2}>
                  <MaterialButton
                    buttonText="Create Second Primary Order"
                    handleClick={handleFormSubmit}
                    variant="contained"
                    disabled={disableContinue()}
                  />
                </Box>
              </>
            )}
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    isSecondPrimaryOrderRequestPending: isSecondPrimaryOrderRequestPending(
      state
    ),
    isSkuRequestPending: isSkuRequestPending(state),
    errorMessage: getErrorMessage(state),
    skusPayload: getAllSkus(state),
    cartSkuPayload: getSecondPrimaryOrderCartItems(state),
    customer: getSecondPrimaryOrderCustomer(state),
    districtSuperStockists: getAllSuperStockists(state)
  };
};

const mapDispatchToProps = {
  fetchAllSkusConnect: fetchAllSkus,
  createSecondPrimaryOrderConnect: createSecondPrimaryOrder,
  fetchAllSuperStockistsConnect: fetchAllSuperStockists,
  resetSecondPrimaryOrderErrorConnect: resetSecondPrimaryOrderError,
  resetSecondPrimaryOrderDataConnect: resetSecondPrimaryOrderData,
  clearCartConnect: clearCart
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateSecondPrimaryOrder);
