import { withStyles, makeStyles } from '@material-ui/core/styles';
import { TableCell, TableRow } from '@material-ui/core';

export const tableStyles = makeStyles({
  root: {
    minWidth: 700,
    '& > *': {
      borderBottom: 'unset'
    }
  },
  table: {},
  container: {
    maxHeight: 700
  },
  content: {
    marginTop: '16px'
  },
  deleteButton: {
    backgroundColor: '#787878',
    '&:hover': {
      backgroundColor: '#902020',
      color: '#FFF'
    }
  },
  heading: {
    textAlign: 'center',
    color: '#1e88e5'
  }
});

export const cartTableStyles = makeStyles({
  root: {
    minWidth: 350,
    borderBottom: 'unset'
  },
  table: {},
  container: {
    maxHeight: 350
  },
  content: {
    marginTop: '16px'
  }
});

export const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#ffeb3b',
    color: theme.palette.common.black,
    fontSize: 14,
    whiteSpace: 'nowrap'
  },
  body: {
    fontSize: 14,
    whiteSpace: 'nowrap'
  }
}))(TableCell);

export const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover
    }
  }
}))(TableRow);
