import React, { useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Avatar,
  InputAdornment,
  CircularProgress
} from '@material-ui/core';
import MaterialButton from '../../../../components/MaterialButton';
import FormAutoComplete from '../../../../components/FormAutoComplete';
import { FiberNew as FiberNewIcon } from '@material-ui/icons';
import FormInput from '../../../../components/FormInput';
import { ONLY_NUMBER_REGEX } from '../../../../shared/Constants/Constant';
import commonStyles from '../../../../Styles/CommonStyles';
import { connect } from 'react-redux';
import { createDistributorInventory } from '../../../../redux/distributor/distributor.actions';
import { isDistributorInventoryRequestPending } from '../../../../redux/distributor/distributor.selector';
import { isSkuRequestPending } from '../../../../redux/sku/sku.selector';

function DistributorInventoryNewItemForm(props) {
  const {
    open,
    handleClose,
    errorMessage,
    skusPayload,
    isLoading,
    isSkuRequestPending,
    createDistributorInventoryConnect,
    distributorId
  } = props;
  const classes = commonStyles();
  const [selectedSkuItem, setSelectedSkuItem] = useState(null);
  const [quantityInPcs, setQuantityInPcs] = useState('');

  const isRequestPending = isLoading || isSkuRequestPending;

  const normalizeForm = () => {
    return {
      sku: selectedSkuItem.id,
      current_inventory_level: quantityInPcs
    };
  };

  const handleModalClose = () => {
    setSelectedSkuItem(null);
    setQuantityInPcs('');
    handleClose();
  };

  const formSubmit = () => {
    createDistributorInventoryConnect(
      distributorId,
      normalizeForm(),
      handleModalClose
    );
  };

  const handleSelectedSkuItem = (value) => {
    setSelectedSkuItem(value);
  };

  const handleQuantityInPcsChange = (event) => {
    const { value } = event.target;

    if (value === '' || value.match(ONLY_NUMBER_REGEX) != null) {
      setQuantityInPcs(value);
    }
  };

  const disableQuantityInput = () => {
    if (!selectedSkuItem) {
      return true;
    }

    return false;
  };

  const disableNext = () => {
    if (!selectedSkuItem) {
      return true;
    } else if (Number(quantityInPcs) < 1) {
      return true;
    }
    return !quantityInPcs;
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleModalClose}
        classes={{
          paperWidthSm: classes.paperWidthSm
        }}
      >
        <DialogTitle id="form-dialog-title">
          <Avatar className={classes.avatar}>
            <FiberNewIcon color="primary" />
          </Avatar>
          <Typography align="center">
            Distributor Inventory - New Sku Item
          </Typography>
        </DialogTitle>
        {isRequestPending && (
          <div className={classes.modalSpinner}>
            <CircularProgress />
          </div>
        )}
        <DialogContent>
          <FormAutoComplete
            data={skusPayload}
            label="Sku Name"
            name="skuName"
            value={selectedSkuItem}
            onChangeSelect={(e, newValue) => handleSelectedSkuItem(newValue)}
            property="name"
          />

          <FormInput
            type="number"
            label="Opening Stock"
            name="quantityInPcs"
            value={quantityInPcs || ''}
            onChange={handleQuantityInPcsChange}
            disabled={disableQuantityInput()}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">pcs</InputAdornment>
              )
            }}
          />

          {!!errorMessage && (
            <Typography variant="h5" style={{ color: '#d50000', fontSize: 13 }}>
              {`**${errorMessage}`}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <MaterialButton handleClick={handleModalClose} buttonText="Cancel" />
          <MaterialButton
            handleClick={formSubmit}
            disabled={disableNext()}
            buttonText="Confirm"
          />
        </DialogActions>
      </Dialog>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    isLoading: isDistributorInventoryRequestPending(state),
    isSkuRequestPending: isSkuRequestPending(state)
  };
};

const mapDispatchToProps = {
  createDistributorInventoryConnect: createDistributorInventory
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DistributorInventoryNewItemForm);
