export const FETCH_USERS_REQUEST = 'FETCH_USERS_REQUEST';
export const FETCH_USERS_ATTENDANCE_REQUEST = 'FETCH_USERS_ATTENDANCE_REQUEST';
export const FETCH_USER_REQUEST = 'FETCH_USER_REQUEST';
export const CREATE_USER_REQUEST = 'CREATE_USER_REQUEST';
export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST';
export const DELETE_USER_REQUEST = 'DELETE_USER_REQUEST';

export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';
export const FETCH_USERS_ATTENDANCE_SUCCESS = 'FETCH_USERS_ATTENDANCE_SUCCESS';
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';

export const FETCH_USERS_ERROR = 'FETCH_USERS_ERROR';
export const FETCH_USERS_ATTENDANCE_ERROR = 'FETCH_USERS_ATTENDANCE_ERROR';
export const FETCH_USER_ERROR = 'FETCH_USER_ERROR';
export const CREATE_USER_ERROR = 'CREATE_USER_ERROR';
export const UPDATE_USER_ERROR = 'UPDATE_USER_ERROR';
export const DELETE_USER_ERROR = 'DELETE_USER_ERROR';

export const CLEAR_USER_ATTENDANCE_DATA = 'CLEAR_USER_ATTENDANCE_DATA';
