import React from 'react';
import { Router } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/styles';
import history from './history';
import Theme from '../shared/Theme';
import Routes from './routes';

function AppRouter() {
  return (
    <ThemeProvider theme={Theme}>
      <Router history={history}>
        <Routes />
      </Router>
    </ThemeProvider>
  );
}

export default AppRouter;
