import _ from 'lodash';
import { formatNumberWithTwoDecimal } from '../../shared/Utils/CommonUtils';
import { getDbrAndRetailerLandedPrices } from '../sku/helpers/SkuPriceCalculation';

export const addItemToCart = (cartItems, cartItemToAdd) => {
  const existingCartItem = cartItems[cartItemToAdd.skuId];

  if (existingCartItem) {
    return {
      ...cartItems,
      [existingCartItem.skuId]: {
        ...cartItemToAdd
      }
    };
  }

  return {
    ...cartItems,
    [cartItemToAdd.skuId]: {
      ...cartItemToAdd
    }
  };
};

export const removeItemFromCart = (cartItems, cartItemIdToRemove) => {
  const existingCartItem = cartItems[cartItemIdToRemove];

  if (existingCartItem) {
    return _.omit(cartItems, cartItemIdToRemove);
  }

  return {
    ...cartItems
  };
};

export const getDistributorSkuPrice = (sku = {}, customizedMargin) => {
  const prices = getDbrAndRetailerLandedPrices(sku, {
    distributor: customizedMargin
  });

  return formatNumberWithTwoDecimal(prices.distributorLandedPrice);
};

export const getSkuTotalCost = ({ quantity, distributorLandedPrice }) => {
  return Number(Number(quantity) * distributorLandedPrice).toFixed(2);
};
