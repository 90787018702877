import React, { useState } from 'react';
import { connect } from 'react-redux';
import {
  fetchAllDistricts,
  deleteDistrict
} from '../../../redux/district/district.actions';
import useFetchReducer from '../../../shared/Hooks/useFetchReducer';
import Modal from '../../../components/Modal';
import history from '../../../routes/history';
import { TITLES } from '../../../shared/Constants/Constant';
import {
  getAllDistricts,
  isDistrictRequestPending,
  getDistrictById
} from '../../../redux/district/district.selector';
import { ROUTE_TO_DISTRICT } from '../../../shared/Constants/RouteConstants';
import BackdropWithLoader from '../../../components/BackdropWithLoader';
import { setCancel } from '../../../redux/event/event.actions';

function DeleteDistrict(props) {
  const [showDeleteModal, setShowDeleteModal] = useState(true);
  const {
    fetchAllDistrictsConnect,
    districtsPayload,
    deleteDistrictConnect,
    isDistrictLoading,
    district = {},
    match: {
      params: { id }
    },
    setCancelConnect
  } = props;
  const districtId = id;

  useFetchReducer(fetchAllDistrictsConnect, districtsPayload);

  const handleDelete = async () => {
    await deleteDistrictConnect(districtId);
    setShowDeleteModal(false);
  };

  const handleCancel = () => {
    setShowDeleteModal(false);
    setCancelConnect();
    history.push(ROUTE_TO_DISTRICT);
  };

  const actions = [
    {
      buttonText: 'Cancel',
      buttonAction: handleCancel,
      buttonStyle: { backgroundColor: '#4caf50', color: '#fff' }
    },
    {
      buttonText: 'Delete',
      buttonAction: handleDelete,
      buttonStyle: { backgroundColor: '#d50000', color: '#fff' }
    }
  ];

  const modelContent = () => {
    const { name, zone = {} } = district;

    if (!district) {
      return 'Are you sure you want to delete this district?';
    }

    return `Are you sure you want 
      to delete district ${name} from ${zone.name}?`;
  };

  if (isDistrictLoading) {
    return <BackdropWithLoader />;
  }

  return (
    <Modal
      title={TITLES.DELETE_DISTRICT}
      isModalVisible={showDeleteModal}
      actions={actions}
      onClose={handleCancel}
      content={modelContent()}
    />
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    isDistrictLoading: isDistrictRequestPending(state),
    districtsPayload: getAllDistricts(state),
    district: getDistrictById(state, ownProps)
  };
};

const mapDispatchToProps = {
  fetchAllDistrictsConnect: fetchAllDistricts,
  deleteDistrictConnect: deleteDistrict,
  setCancelConnect: setCancel
};

export default connect(mapStateToProps, mapDispatchToProps)(DeleteDistrict);
