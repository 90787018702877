export const ROUTE_TO_LOGIN = '/login';
export const ROUTE_TO_HOME = '/home';
export const ROUTE_TO_USER = '/user';
export const ROUTE_TO_CREATE_USER = '/user/create';
export const ROUTE_TO_EMPLOYEE_ATTENDANCE = '/user/attendance';
export const ROUTE_TO_EMPLOYEE_SECONDARY_SALES_DAY_WISE =
  '/user/employee-secondary-sales-day-wise';
export const ROUTE_TO_EMPLOYEE_SECONDARY_SALES_RANGE_WISE =
  '/user/employee-secondary-sales-range-wise';

export const ROUTE_TO_PARENT_PRODUCT = '/parent-product';
export const ROUTE_TO_CREATE_PARENT_PRODUCT = '/parent-product/create';
export const ROUTE_TO_EDIT_PARENT_PRODUCT = '/parent-product/edit/';

export const ROUTE_TO_SKU = '/sku';
export const ROUTE_TO_CREATE_SKU = '/sku/create';
export const ROUTE_TO_EDIT_SKU = '/sku/edit/';
export const ROUTE_TO_DELETE_SKU = '/sku/delete/';
export const ROUTE_TO_SHOW_SKU_DETAILS = '/sku/show/';

export const ROUTE_TO_FACTORY_STOCK = '/factory-stock';

export const ROUTE_TO_PACKAGING_MATERIAL_STOCK = '/packaging-material-stock';

export const ROUTE_TO_COMBO_SKU = '/sku/combo';
export const ROUTE_TO_CREATE_COMBO_SKU = '/sku/combo/create';
export const ROUTE_TO_EDIT_COMBO_SKU = '/sku/combo/edit/';
export const ROUTE_TO_DELETE_COMBO_SKU = '/sku/combo/delete/';
export const ROUTE_TO_SHOW_COMBO_SKU_DETAILS = '/sku/combo/show/';

export const ROUTE_TO_ZONE = '/zone';
export const ROUTE_TO_CREATE_ZONE = '/zone/create';
export const ROUTE_TO_EDIT_ZONE = '/zone/edit/';
export const ROUTE_TO_DELETE_ZONE = '/zone/delete/';

export const ROUTE_TO_DISTRICT = '/district';
export const ROUTE_TO_CREATE_DISTRICT = '/district/create';
export const ROUTE_TO_EDIT_DISTRICT = '/district/edit/';
export const ROUTE_TO_DELETE_DISTRICT = '/district/delete/';

export const ROUTE_TO_AREA = '/area';
export const ROUTE_TO_CREATE_AREA = '/area/create';
export const ROUTE_TO_EDIT_AREA = '/area/edit/';
export const ROUTE_TO_DELETE_AREA = '/area/delete/';

export const ROUTE_TO_BEAT_AREA = '/beatarea';
export const ROUTE_TO_CREATE_BEAT_AREA = '/beatarea/create';
export const ROUTE_TO_EDIT_BEAT_AREA = '/beatarea/edit/';
export const ROUTE_TO_DELETE_BEAT_AREA = '/beatarea/delete/';

export const ROUTE_TO_SUPER_STOCKIST = '/superstockist';
export const ROUTE_TO_CREATE_SUPER_STOCKIST = '/superstockist/create';
export const ROUTE_TO_EDIT_SUPER_STOCKIST = '/superstockist/edit/';
export const ROUTE_TO_DELETE_SUPER_STOCKIST = '/superstockist/delete/';
export const ROUTE_TO_MANAGE_STOCK_SUPER_STOCKIST = 'inventory/superstockist/';

export const ROUTE_TO_DISTRIBUTOR = '/distributor';
export const ROUTE_TO_CREATE_DISTRIBUTOR = '/distributor/create';
export const ROUTE_TO_EDIT_DISTRIBUTOR = '/distributor/edit/';
export const ROUTE_TO_DELETE_DISTRIBUTOR = '/distributor/delete/';
export const ROUTE_TO_MANAGE_STOCK_DISTRIBUTOR = 'inventory/distributor/';

export const ROUTE_TO_RETAILER = '/retailer';
export const ROUTE_TO_CREATE_RETAILER = '/retailer/create';
export const ROUTE_TO_EDIT_RETAILER = '/retailer/edit/';
export const ROUTE_TO_DELETE_RETAILER = '/retailer/delete/';
export const ROUTE_TO_MANAGE_STOCK_RETAILER = 'inventory/retailer/';

export const ROUTE_TO_CREATE_WHOLESALER = '/wholesaler/create';
export const ROUTE_TO_EDIT_WHOLESALER = '/wholesaler/edit/';
export const ROUTE_TO_DELETE_WHOLESALER = '/wholesaler/delete/';

export const ROUTE_TO_CREATE_DIRECT_RETAILER = '/directretailer/create';
export const ROUTE_TO_EDIT_DIRECT_RETAILER = '/directretailer/edit/';
export const ROUTE_TO_DELETE_DIRECT_RETAILER = '/directretailer/delete/';

export const ROUTE_TO_ADMIN_USERS = '/employee/admin';
export const ROUTE_TO_CREATE_ADMIN_USER = '/employee/admin/create';
export const ROUTE_TO_EDIT_ADMIN_USERS = '/employee/admin/edit/';
export const ROUTE_TO_DELETE_ADMIN_USER = '/employee/admin/delete/';

export const ROUTE_TO_PRIMARY_ORDER = '/order/primary-order';
export const ROUTE_TO_CREATE_PRIMARY_ORDER = '/order/primary-order/create';
export const ROUTE_TO_VIEW_PRIMARY_ORDER_DETAILS = '/order/primary-order/view/';
export const ROUTE_TO_DELETE_PRIMARY_ORDER = '/order/primary-order/delete/';

export const ROUTE_TO_SECOND_PRIMARY_ORDER = '/order/second-primary-order';
export const ROUTE_TO_CREATE_SECOND_PRIMARY_ORDER =
  '/order/second-primary-order/create';
export const ROUTE_TO_VIEW_SECOND_PRIMARY_ORDER_DETAILS =
  '/order/second-primary-order/view/';
export const ROUTE_TO_DELETE_SECOND_PRIMARY_ORDER =
  '/order/second-primary-order/delete/';

export const ROUTE_TO_SECONDARY_ORDER = '/order/secondary-order';
export const ROUTE_TO_CREATE_SECONDARY_ORDER = '/order/secondary-order/create';
export const ROUTE_TO_VIEW_SECONDARY_ORDER_DETAILS =
  '/order/secondary-order/view/';
export const ROUTE_TO_DELETE_SECONDARY_ORDER = '/order/secondary-order/delete/';
