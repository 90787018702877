import baseURL from '../shared/AxiosBaseUrl/baseURL';

class AreaApi {
  static getAllAreas() {
    return baseURL.get('/api/v1/area/');
  }

  static getArea(id) {
    return baseURL.get(`/api/v1/area/${id}`);
  }

  static createArea(formValues) {
    return baseURL.post('/api/v1/area/', { ...formValues });
  }

  static editArea(id, formValues) {
    return baseURL.patch(`/api/v1/area/${id}`, { ...formValues });
  }

  static deleteArea(id) {
    return baseURL.delete(`/api/v1/area/${id}`);
  }
}

export default AreaApi;
