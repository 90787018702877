function LoginFormValidator(values) {
  const errors = {};

  if (!values.user_name) {
    errors.user_name = '**Login User Name is required';
  }

  if (!values.password) {
    errors.password = '**Password is required';
  }

  return errors;
}

export default LoginFormValidator;
