import OrderApi from '../../apis/OrderAPI';
import * as PrimaryOrderActionTypes from './primary-order.types';
import { ROUTE_TO_PRIMARY_ORDER } from '../../shared/Constants/RouteConstants';
import { get } from 'lodash';

export const fetchAllPrimaryOrders = () => async (dispatch) => {
  dispatch({
    type: PrimaryOrderActionTypes.FETCH_PRIMARY_ORDERS_REQUEST
  });

  try {
    const response = await OrderApi.getAllPrimaryOrders();

    dispatch({
      type: PrimaryOrderActionTypes.FETCH_PRIMARY_ORDERS_SUCCESS,
      payload: response.data.primaryOrders,
      error: null,
      success: null,
      route: null
    });

    dispatch({
      type: PrimaryOrderActionTypes.PRIMARY_ORDER_PAGINATION,
      payload: get(response.data, 'pagination', {}),
      error: null
    });
  } catch (error) {
    dispatch({
      type: PrimaryOrderActionTypes.FETCH_PRIMARY_ORDERS_ERROR,
      error: error.response.data,
      payload: null,
      success: null,
      route: null
    });
  }
};

export const fetchPrimaryOrder = (id) => async (dispatch) => {
  dispatch({
    type: PrimaryOrderActionTypes.FETCH_PRIMARY_ORDER_REQUEST
  });

  try {
    const response = await OrderApi.getPrimaryOrder(id);

    dispatch({
      type: PrimaryOrderActionTypes.FETCH_PRIMARY_ORDER_SUCCESS,
      payload: response.data.primaryOrder,
      error: null,
      success: null,
      route: null
    });
  } catch (error) {
    dispatch({
      type: PrimaryOrderActionTypes.FETCH_PRIMARY_ORDER_ERROR,
      error: error.response.data,
      payload: null,
      success: null,
      route: null
    });
  }
};

export const createPrimaryOrder = (orderDetails) => async (dispatch) => {
  dispatch({
    type: PrimaryOrderActionTypes.CREATE_PRIMARY_ORDER_REQUEST
  });

  try {
    const response = await OrderApi.createPrimaryOrder(orderDetails);

    dispatch({
      type: PrimaryOrderActionTypes.CREATE_PRIMARY_ORDER_SUCCESS,
      payload: response.data.primaryOrder,
      error: null,
      route: ROUTE_TO_PRIMARY_ORDER,
      success: response.data.message
    });
  } catch (error) {
    dispatch({
      type: PrimaryOrderActionTypes.CREATE_PRIMARY_ORDER_ERROR,
      error: error.response.data,
      payload: null,
      route: ROUTE_TO_PRIMARY_ORDER,
      success: null
    });
  }
};

export const editPrimaryOrder = (
  { primaryOrderId, orderDetails },
  callback
) => async (dispatch) => {
  dispatch({
    type: PrimaryOrderActionTypes.UPDATE_PRIMARY_ORDER_REQUEST
  });

  try {
    const response = await OrderApi.editPrimaryOrder(
      primaryOrderId,
      orderDetails
    );

    if (callback) {
      callback();
    }

    dispatch({
      type: PrimaryOrderActionTypes.UPDATE_PRIMARY_ORDER_SUCCESS,
      payload: response.data.primaryOrder,
      error: null,
      route: ROUTE_TO_PRIMARY_ORDER,
      success: response.data.message
    });
  } catch (error) {
    dispatch({
      type: PrimaryOrderActionTypes.UPDATE_PRIMARY_ORDER_ERROR,
      error: error.response.data,
      payload: null,
      route: ROUTE_TO_PRIMARY_ORDER,
      success: null
    });
  }
};

export const deletePrimaryOrder = (id) => async (dispatch) => {
  dispatch({
    type: PrimaryOrderActionTypes.DELETE_PRIMARY_ORDER_REQUEST
  });

  try {
    const response = await OrderApi.deletePrimaryOrder(id);

    dispatch({
      type: PrimaryOrderActionTypes.DELETE_PRIMARY_ORDER_SUCCESS,
      payload: id,
      error: null,
      route: ROUTE_TO_PRIMARY_ORDER,
      success: response.data.message
    });
  } catch (error) {
    dispatch({
      type: PrimaryOrderActionTypes.DELETE_PRIMARY_ORDER_ERROR,
      payload: null,
      error: error.response.data,
      route: ROUTE_TO_PRIMARY_ORDER,
      success: null
    });
  }
};

export const addItem = (item, callBack) => (dispatch) => {
  dispatch({
    type: PrimaryOrderActionTypes.ADD_PRIMARY_ORDER_ITEM,
    payload: item
  });
  if (callBack) {
    callBack();
  }
};

export const removeItem = (itemId) => (dispatch) => {
  dispatch({
    type: PrimaryOrderActionTypes.REMOVE_PRIMARY_ORDER_ITEM,
    payload: itemId
  });
};

export const clearCart = () => (dispatch) => {
  dispatch({
    type: PrimaryOrderActionTypes.CLEAR_PRIMARY_ORDER_ORDER_CART
  });
};

export const updatePaginationPage = (pageNumber) => (dispatch) => {
  dispatch({
    type: PrimaryOrderActionTypes.UPDATE_PRIMARY_ORDER_PAGINATION_PAGE,
    payload: pageNumber
  });
};

export const resetPrimaryOrderData = () => (dispatch) => {
  dispatch({
    type: PrimaryOrderActionTypes.RESET_PRIMARY_ORDER_DATA
  });
};

export const resetPrimaryOrderError = () => (dispatch) => {
  dispatch({
    type: PrimaryOrderActionTypes.RESET_PRIMARY_ORDER_ERROR
  });
};

export const loadPrimaryOrderCustomer = (customer) => (dispatch) => {
  dispatch({
    type: PrimaryOrderActionTypes.LOAD_PRIMARY_ORDER_CUSTOMER,
    payload: customer
  });
};
