export const FETCH_AREAS_REQUEST = 'FETCH_AREAS_REQUEST';
export const FETCH_AREA_REQUEST = 'FETCH_AREA_REQUEST';
export const CREATE_AREA_REQUEST = 'CREATE_AREA_REQUEST';
export const UPDATE_AREA_REQUEST = 'UPDATE_AREA_REQUEST';
export const DELETE_AREA_REQUEST = 'DELETE_AREA_REQUEST';

export const FETCH_AREAS_SUCCESS = 'FETCH_AREAS_SUCCESS';
export const FETCH_AREA_SUCCESS = 'FETCH_AREA_SUCCESS';
export const CREATE_AREA_SUCCESS = 'CREATE_AREA_SUCCESS';
export const UPDATE_AREA_SUCCESS = 'UPDATE_AREA_SUCCESS';
export const DELETE_AREA_SUCCESS = 'DELETE_AREA_SUCCESS';

export const FETCH_AREAS_ERROR = 'FETCH_AREAS_ERROR';
export const FETCH_AREA_ERROR = 'FETCH_AREA_ERROR';
export const CREATE_AREA_ERROR = 'CREATE_AREA_ERROR';
export const UPDATE_AREA_ERROR = 'UPDATE_AREA_ERROR';
export const DELETE_AREA_ERROR = 'DELETE_AREA_ERROR';
