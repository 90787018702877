import React from 'react';
import {
  Table as MuiTable,
  TableBody,
  TableHead,
  TableRow,
  TableContainer,
  Paper
} from '@material-ui/core';
import tableStyles from '../Styles/TableStyles';
import StyledTableCell from '../Styles/StyledTableCell';
import { toSentenceCase } from '../../shared/Utils/CommonUtils';
import TableRowAction from '../TableRowAction';
import { BUTTON_ACTIONS_CONSTANTS } from '../../shared/Constants/Constant';
import {
  ROUTE_TO_EDIT_AREA,
  ROUTE_TO_DELETE_AREA
} from '../../shared/Constants/RouteConstants';
import { AREA_HEADER_CONSTANTS } from '../../shared/Constants/TableHeaderConstants';

function AreaTable(props) {
  const classes = tableStyles();
  const { areas = [], isAreaLoading } = props;

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.tableWithTopButtonContainer}>
        <MuiTable stickyHeader>
          <TableHead>
            <TableRow>
              {Object.keys(AREA_HEADER_CONSTANTS).map((header) => (
                <StyledTableCell align="center" key={header}>
                  {AREA_HEADER_CONSTANTS[header]}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {areas.map((area) => {
              const {
                id: areaId,
                name,
                beatsCount,
                zone = {},
                district = {}
              } = area;

              return (
                <TableRow key={areaId}>
                  <StyledTableCell component="th" scope="row" align="center">
                    <TableRowAction
                      buttonText={BUTTON_ACTIONS_CONSTANTS.EDIT}
                      urlLink={`${ROUTE_TO_EDIT_AREA}${areaId}`}
                      color="primary"
                      disabled={isAreaLoading}
                    />

                    <TableRowAction
                      buttonText={BUTTON_ACTIONS_CONSTANTS.DELETE}
                      urlLink={`${ROUTE_TO_DELETE_AREA}${areaId}`}
                      disabled={isAreaLoading}
                    />
                  </StyledTableCell>

                  <StyledTableCell component="th" scope="row" align="center">
                    {toSentenceCase(name)}
                  </StyledTableCell>

                  <StyledTableCell component="th" scope="row" align="center">
                    {beatsCount}
                  </StyledTableCell>

                  <StyledTableCell component="th" scope="row" align="center">
                    {toSentenceCase(district.name)}
                  </StyledTableCell>

                  <StyledTableCell component="th" scope="row" align="center">
                    {toSentenceCase(zone.name)}
                  </StyledTableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </MuiTable>
      </TableContainer>
    </Paper>
  );
}

export default React.memo(AreaTable);
