import React, { useState } from 'react';
import { StyledTableCell, StyledTableRow } from '../../Styles/TableStyles';
import { SKU_TYPES } from '../../shared/Constants/Constant';
import MaterialButton from '../MaterialButton';
import FactoryStockInputForm from '../FactoryStockInputForm';
import { division, addition } from '../../shared/Utils/CommonUtils';

function FactoryStockTableItem(props) {
  const { sku } = props;
  const [openCasesStockInputForm, setOpenCasesStockInputForm] = useState(false);
  const stockInCases = division(
    sku.factory_inventory_in_units,
    sku.pieces_per_carton
  );
  const [openLooseStockInputForm, setOpenLooseStockInputForm] = useState(false);

  return (
    <StyledTableRow>
      <StyledTableCell align="center">{sku.name || 'NA'}</StyledTableCell>
      <StyledTableCell align="center">
        {sku.parent_product_name || 'NA'}
      </StyledTableCell>
      <StyledTableCell align="center">
        {SKU_TYPES[sku.sku_type]}
      </StyledTableCell>
      <StyledTableCell align="center">
        <div>
          <MaterialButton
            buttonText={stockInCases}
            handleClick={() => setOpenCasesStockInputForm(true)}
          />
          <FactoryStockInputForm
            open={openCasesStockInputForm}
            handleClose={() => setOpenCasesStockInputForm(false)}
            stockValue={stockInCases}
            skuId={sku.id}
            skuName={sku.name}
            pcsPerCarton={sku.pieces_per_carton}
            date={new Date()}
            updateCasesStock
          />
        </div>
      </StyledTableCell>
      <StyledTableCell align="center">{sku.pieces_per_carton}</StyledTableCell>
      <StyledTableCell align="center">
        {Number(sku.factory_inventory_in_units)}
      </StyledTableCell>
      <StyledTableCell align="center">
        <div>
          <MaterialButton
            buttonText={Number(sku.factory_inventory_in_loose_units)}
            handleClick={() => setOpenLooseStockInputForm(true)}
          />
          <FactoryStockInputForm
            open={openLooseStockInputForm}
            handleClose={() => setOpenLooseStockInputForm(false)}
            stockValue={Number(sku.factory_inventory_in_loose_units)}
            skuId={sku.id}
            skuName={sku.name}
            pcsPerCarton={sku.pieces_per_carton}
            date={new Date()}
            updateLoosePcsStock
          />
        </div>
      </StyledTableCell>
      <StyledTableCell align="center">
        {addition(
          sku.factory_inventory_in_units,
          sku.factory_inventory_in_loose_units
        )}
      </StyledTableCell>
    </StyledTableRow>
  );
}

export default FactoryStockTableItem;
