import React from 'react';
import { StyledTableRow, StyledTableCell } from '../../Styles/TableStyles';
import TableRowAction from '../TableRowAction';
import { BUTTON_ACTIONS_CONSTANTS } from '../../shared/Constants/Constant';
import {
  ROUTE_TO_EDIT_BEAT_AREA,
  ROUTE_TO_DELETE_BEAT_AREA
} from '../../shared/Constants/RouteConstants';

function BeatAreaTableItem(props) {
  const { beat = {}, isbeatAreaLoading } = props;
  const { id: beatAreaId, name, zone = {}, district = {}, area = {} } = beat;

  return (
    <StyledTableRow>
      <StyledTableCell component="th" scope="row" align="center">
        <TableRowAction
          buttonText={BUTTON_ACTIONS_CONSTANTS.EDIT}
          urlLink={`${ROUTE_TO_EDIT_BEAT_AREA}${beatAreaId}`}
          color="primary"
          disabled={isbeatAreaLoading}
        />
        <TableRowAction
          buttonText={BUTTON_ACTIONS_CONSTANTS.DELETE}
          urlLink={`${ROUTE_TO_DELETE_BEAT_AREA}${beatAreaId}`}
          disabled={isbeatAreaLoading}
        />
      </StyledTableCell>

      <StyledTableCell component="th" scope="row" align="center">
        {name}
      </StyledTableCell>

      <StyledTableCell component="th" scope="row" align="center">
        {area.name}
      </StyledTableCell>

      <StyledTableCell component="th" scope="row" align="center">
        {district.name}
      </StyledTableCell>

      <StyledTableCell component="th" scope="row" align="center">
        {zone.name}
      </StyledTableCell>
    </StyledTableRow>
  );
}

export default BeatAreaTableItem;
