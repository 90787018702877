import baseURL from './baseURL';

const setAuthToken = (token) => {
  if (token) {
    baseURL.defaults.headers.common = { Authorization: `Bearer ${token}` };
    localStorage.setItem('token', token);
  } else {
    delete baseURL.defaults.headers.common.Authorization;
    localStorage.removeItem('token');
  }
};

export default setAuthToken;
