import React from 'react';
import { Backdrop, LinearProgress, Typography } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  progressBar: {
    width: '40%'
  },
  progressBarText: {
    margin: '10px'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 2,
    color: '#ddd'
  }
}));

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === 'light' ? 200 : 700]
  },
  bar: {
    borderRadius: 5,
    backgroundColor: '#1a90ff'
  }
}))(LinearProgress);

function BackdropWithLoader() {
  const classes = useStyles();

  const [progress, setProgress] = React.useState(0);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          return 0;
        }
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 100);
      });
    }, 500);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <Backdrop className={classes.backdrop} open>
      <div className={classes.progressBar}>
        <BorderLinearProgress value={progress} />
        <Typography
          variant="h4"
          className={classes.progressBarText}
          color="secondary"
          align="center"
        >
          Just a few more minutes!
        </Typography>
        <Typography
          variant="h4"
          className={classes.progressBarText}
          color="secondary"
          align="center"
        >
          Please Wait, processing
        </Typography>
      </div>
    </Backdrop>
  );
}

export default BackdropWithLoader;
