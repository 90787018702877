import React from 'react';
import {
  Table as MuiTable,
  TableBody,
  TableHead,
  TableRow,
  Paper,
  TableContainer
} from '@material-ui/core';
import tableStyles from '../Styles/TableStyles';
import StyledTableCell from '../Styles/StyledTableCell';
import { DISTRIBUTOR_HEADER_CONSTANTS } from '../../shared/Constants/TableHeaderConstants';
import DistributorTableItem from './DistributorTableItem';

function DistributorTable(props) {
  const classes = tableStyles();
  const { distributors = [], isdistributorLoading } = props;

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.tableWithTopButtonContainer}>
        <MuiTable stickyHeader>
          <TableHead>
            <TableRow>
              {Object.keys(DISTRIBUTOR_HEADER_CONSTANTS).map((header) => (
                <StyledTableCell align="center" key={header}>
                  {DISTRIBUTOR_HEADER_CONSTANTS[header]}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {distributors.map((distributor) => {
              return (
                <DistributorTableItem
                  key={distributor.id}
                  distributor={distributor}
                  isdistributorLoading={isdistributorLoading}
                />
              );
            })}
          </TableBody>
        </MuiTable>
      </TableContainer>
    </Paper>
  );
}

export default React.memo(DistributorTable);
