import { combineReducers } from 'redux';
import authReducer from './auth/auth.reducer';
import skuReducer from './sku/sku.reducer';
import productReducer from './product/product.reducer';
import zoneReducer from './zone/zone.reducer';
import districtReducer from './district/district.reducer';
import areaReducer from './area/area.reducer';
import beatAreaReducer from './beatArea/beatArea.reducer';
import superStockistReducer from './superStockist/superStockist.reducer';
import distributorReducer from './distributor/distributor.reducer';
import retailerReducer from './retailer/retailer.reducer';
import userReducer from './user/user.reducer';
import errorReducer from './error/error.reducer';
import successReducer from './success/success.reducer';
import routeReducer from './route/route.reducer';
import eventReducer from './event/event.reducer';
import alertReducer from './alert/alert.reducer';
import primaryOrderReducer from './primary-order/primary-order.reducer';
import secondPrimaryOrderReducer from './second-primary-order/second-primary-order.reducer';
import secondaryOrderReducer from './secondary-order/secondary-order.reducer';
import employeeSecondarySalesReducer from './employee-secondary-sales/employee-secondary-sales.reducer';

const rootReducer = combineReducers({
  auth: authReducer,
  skus: skuReducer,
  products: productReducer,
  zones: zoneReducer,
  districts: districtReducer,
  areas: areaReducer,
  beatAreas: beatAreaReducer,
  superStockists: superStockistReducer,
  distributors: distributorReducer,
  retailers: retailerReducer,
  users: userReducer,
  error: errorReducer,
  success: successReducer,
  route: routeReducer,
  event: eventReducer,
  alert: alertReducer,
  primaryOrders: primaryOrderReducer,
  secondPrimaryOrders: secondPrimaryOrderReducer,
  secondaryOrders: secondaryOrderReducer,
  employeeSecondarySales: employeeSecondarySalesReducer
});

export default rootReducer;
