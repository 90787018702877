import { isEmpty } from 'lodash';

export const getAssignedZoneIds = (appointed_beats = [], beatsPayload) => {
  if (!appointed_beats.length) {
    return [];
  }

  const zones = appointed_beats.reduce((allIds, currentBeatId) => {
    const beatDetails =
      beatsPayload.find((beat) => beat.id === currentBeatId) || {};

    if (isEmpty(beatDetails)) {
      return {
        ...allIds
      };
    }

    return {
      ...allIds,
      [beatDetails.zone.id]: {
        name: beatDetails.zone.name,
        id: beatDetails.zone.id
      }
    };
  }, {});

  return Object.keys(zones);
};

export const getAssignedDistrictIds = (appointed_beats = [], beatsPayload) => {
  if (!appointed_beats.length) {
    return [];
  }

  const districts = appointed_beats.reduce((allIds, currentBeatId) => {
    const beatDetails =
      beatsPayload.find((beat) => beat.id === currentBeatId) || {};

    if (isEmpty(beatDetails)) {
      return {
        ...allIds
      };
    }

    return {
      ...allIds,
      [beatDetails.district.id]: {
        name: beatDetails.district.name,
        id: beatDetails.district.id
      }
    };
  }, {});

  return Object.keys(districts);
};

export const getAssignedAreaIds = (appointed_beats = [], beatsPayload) => {
  if (!appointed_beats.length) {
    return [];
  }

  const areas = appointed_beats.reduce((allIds, currentBeatId) => {
    const beatDetails =
      beatsPayload.find((beat) => beat.id === currentBeatId) || {};

    if (isEmpty(beatDetails)) {
      return {
        ...allIds
      };
    }

    return {
      ...allIds,
      [beatDetails.area.id]: {
        name: beatDetails.area.name,
        id: beatDetails.area.id
      }
    };
  }, {});

  return Object.keys(areas);
};
