import baseURL from '../shared/AxiosBaseUrl/baseURL';

class DistrictAPI {
  static getAllDistricts() {
    return baseURL.get('/api/v1/district/');
  }

  static getDistrict(id) {
    return baseURL.get(`/api/v1/district/${id}`);
  }

  static createDistrict(formValues) {
    return baseURL.post('/api/v1/district/', { ...formValues });
  }

  static editDistrict(id, formValues) {
    return baseURL.patch(`/api/v1/district/${id}`, { ...formValues });
  }

  static deleteDistrict(id) {
    return baseURL.delete(`/api/v1/district/${id}`);
  }
}

export default DistrictAPI;
