import React, { useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Avatar,
  InputAdornment
} from '@material-ui/core';
import MaterialButton from '../../../../components/MaterialButton';
import { Edit as EditIcon } from '@material-ui/icons';
import FormInput from '../../../../components/FormInput';
import {
  DECIMAL_NUMBER_REGEX,
  ONLY_NUMBER_REGEX
} from '../../../../shared/Constants/Constant';
import {
  getDistributorSkuPrice,
  getSkuTotalCost
} from '../../../../redux/second-primary-order/second-primary-order.utils';
import commonStyles from '../../../../Styles/CommonStyles';

function CreateSecondPrimaryOrderEditItemForm(props) {
  const { open, handleClose, addSkuItem, skuDetails, cartItem } = props;
  const classes = commonStyles();
  const [quantityInPcs, setQuantityInPcs] = useState(cartItem.quantityInPcs);
  const [customizedMargin, setCustomizedMargin] = useState(
    cartItem.customizedMargin
  );

  const normalizeForm = () => {
    const distributorLandedPrice = getDistributorSkuPrice(
      skuDetails,
      customizedMargin
    );

    return {
      skuId: cartItem.skuId,
      name: cartItem.name,
      parentProductName: cartItem.parentProductName,
      quantityInPcs: quantityInPcs,
      customizedMargin: Number(customizedMargin).toFixed(2),
      distributorLandedPrice,
      totalSkuCost: getSkuTotalCost({
        quantity: quantityInPcs,
        distributorLandedPrice
      })
    };
  };

  const handleModalClose = () => {
    handleClose();
  };

  const formSubmit = () => {
    addSkuItem(normalizeForm(), handleModalClose);
  };

  const handleQuantityInPcsChange = (event) => {
    const { value } = event.target;

    if (value === '' || value.match(ONLY_NUMBER_REGEX) != null) {
      setQuantityInPcs(value);
    }
  };

  const handleMarginInputChange = (event) => {
    event.persist();
    const { value } = event.target;

    if (value === '' || value.match(DECIMAL_NUMBER_REGEX) != null) {
      setCustomizedMargin(Number(value));
    }
  };

  const onBlurDecimalHandle = (event) => {
    event.persist();

    setCustomizedMargin((prevValue) => Number(prevValue).toFixed(2));
  };

  const disableQuantityInput = () => {
    if (!cartItem) {
      return true;
    }

    return false;
  };

  const disableNext = () => {
    if (!cartItem) {
      return true;
    } else if (Number(quantityInPcs) < 1) {
      return true;
    }
    return !quantityInPcs;
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleModalClose}
        classes={{
          paperWidthSm: classes.paperWidthSm
        }}
      >
        <DialogTitle id="form-dialog-title">
          <Avatar className={classes.avatar}>
            <EditIcon color="primary" />
          </Avatar>
          <Typography align="center">
            Edit Second Primary Order - Sku Item
          </Typography>
        </DialogTitle>
        <DialogContent>
          <FormInput
            type="text"
            label="Sku Name"
            name="skuName"
            value={cartItem.name}
            disabled
          />

          <FormInput
            type="number"
            label="Margin"
            placeholder="Enter the Margin"
            name="customizedMargin"
            value={customizedMargin || ''}
            onChange={handleMarginInputChange}
            disabled={disableQuantityInput()}
            onBlur={onBlurDecimalHandle}
            InputProps={{
              endAdornment: <InputAdornment position="start">%</InputAdornment>
            }}
          />

          <FormInput
            type="number"
            label="Order Quantity(in pcs)"
            placeholder="Enter the Order Quantity(in pcs)"
            name="quantityInCases"
            value={quantityInPcs || ''}
            onChange={handleQuantityInPcsChange}
            disabled={disableQuantityInput()}
          />
        </DialogContent>
        <DialogActions>
          <MaterialButton handleClick={handleModalClose} buttonText="Cancel" />
          <MaterialButton
            handleClick={formSubmit}
            disabled={disableNext()}
            buttonText="Confirm"
          />
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default CreateSecondPrimaryOrderEditItemForm;
