import { makeStyles } from '@material-ui/core/styles';

const formAutoCompleteStyles = makeStyles({
  container: {
    marginTop: 16,
    marginBottom: 8
  }
});

export default formAutoCompleteStyles;
