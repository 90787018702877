import React, { useEffect, useState } from 'react';
import {
  Container,
  Grid,
  CardContent,
  Typography,
  Divider,
  Card,
  Box,
  CardHeader
} from '@material-ui/core';
import { connect } from 'react-redux';
import {
  clearCart,
  createSecondaryOrder,
  resetSecondaryOrderData,
  resetSecondaryOrderError
} from '../../../redux/secondary-order/secondary-order.actions';
import {
  isSecondaryOrderRequestPending,
  getErrorMessage,
  getSecondaryOrderCartItems,
  getSecondaryOrderCustomer
} from '../../../redux/secondary-order/secondary-order.selector';
import MaterialButton from '../../../components/MaterialButton';
import { get, isEmpty, size } from 'lodash';
import { fetchAllSkus } from '../../../redux/sku/sku.actions';
import {
  getAllSkus,
  isSkuRequestPending
} from '../../../redux/sku/sku.selector';
import { fetchAllDistributors } from '../../../redux/distributor/distributor.actions';
import CreateSecondaryOrderCartTable from './CreateSecondaryOrderCartTable';
import { notification } from 'antd';
import BackdropWithLoader from '../../../components/BackdropWithLoader';
import commonStyles from '../../../Styles/CommonStyles';
import {
  CUSTOMER_TYPES_TITLES,
  CUSTOMER_TYPES_CONSTANTS
} from '../../../shared/Constants/Constant';
import FormAutoComplete from '../../../components/FormAutoComplete';
import { getAllDistributors } from '../../../redux/distributor/distributor.selector';
import createSecondaryOrderStyles from './CreateSecondaryOrder.css';

const CreateSecondaryOrder = (props) => {
  const commonClasses = commonStyles();
  const createSecondaryOrderClasses = createSecondaryOrderStyles();
  const {
    isSecondaryOrderRequestPending,
    isSkuRequestPending,
    resetSecondaryOrderErrorConnect,
    errorMessage,
    skusPayload,
    clearCartConnect,
    cartSkuPayload,
    fetchAllSkusConnect,
    resetSecondaryOrderDataConnect,
    customer,
    createSecondaryOrderConnect,
    fetchAllDistributorsConnect,
    areaDistributors
  } = props;

  const isRequestPending =
    isSecondaryOrderRequestPending || isSkuRequestPending;
  const [distributorItem, setDistributorItem] = useState(null);
  const [distributorError, setDistributorError] = useState('');

  useEffect(() => {
    fetchAllSkusConnect();

    if (get(customer, 'area.id', null)) {
      fetchAllDistributorsConnect({
        area: customer.area.id
      });
    }

    return () => {
      clearCartConnect();
      resetSecondaryOrderErrorConnect();
      resetSecondaryOrderDataConnect();
    };
  }, []);

  const openErrorNotification = (type, placement, error) => {
    notification[type]({
      message: 'Oops! Something went wrong',
      description: error,
      placement,
      duration: 3.5,
      onClose: resetSecondaryOrderErrorConnect
    });
  };

  if (!isEmpty(errorMessage)) {
    openErrorNotification('error', 'bottomRight', errorMessage);
  }

  const disableContinue = () => {
    return isRequestPending || size(cartSkuPayload) === 0;
  };

  const Heading = `CREATE ${
    CUSTOMER_TYPES_TITLES[customer.customerType]
  } ORDER`;

  const normalizeSkuItems = () => {
    return cartSkuPayload.map((sku) => {
      return {
        sku: sku.skuId,
        name: sku.name,
        ordered_quantity: sku.quantityInPcs,
        margin: sku.customizedMargin,
        landed_cost: sku.retailerLandedPrice,
        total_cost: sku.totalSkuCost
      };
    });
  };

  const normalizeOrderValues = () => {
    const formValues = {};

    if (distributorItem) {
      formValues.distributor = distributorItem.id;
    }

    return {
      ...formValues,
      retailer: customer.id,
      customer_type: CUSTOMER_TYPES_CONSTANTS.RETAILER,
      sku_items: normalizeSkuItems()
    };
  };

  const handleDistributorChange = (e, value) => {
    e.persist();

    if (distributorError) {
      setDistributorError('');
    }

    setDistributorItem(value);
  };

  const handleFormSubmit = () => {
    if (!distributorItem) {
      return setDistributorError('** Distributor is required');
    }

    createSecondaryOrderConnect(normalizeOrderValues());
  };

  return (
    <Container className={commonClasses.root} maxWidth="lg">
      <Grid container spacing={3}>
        <Grid item lg={12} md={12} xs={12}>
          <Card>
            <CardHeader title={Heading} className={commonClasses.heading} />
            <CardHeader
              title={customer.name}
              className={commonClasses.heading}
            />
            <Typography component="h5" className={commonClasses.heading}>
              GSTIN: {customer.gstin || 'NA'}
            </Typography>
            <Grid
              item
              md={4}
              xs={12}
              lg={4}
              className={createSecondaryOrderClasses.distributorPickerContainer}
            >
              <FormAutoComplete
                data={areaDistributors}
                label="Select Distributor"
                name="distributor"
                value={distributorItem}
                onChangeSelect={(e, newValue) =>
                  handleDistributorChange(e, newValue)
                }
                property="name"
                error={!!distributorError}
                helperText={distributorError}
                required
              />
            </Grid>

            {isRequestPending ? (
              <BackdropWithLoader />
            ) : (
              <>
                <Divider />
                <CardContent>
                  <Grid container spacing={3}>
                    <Grid item md={12} xs={12} className={commonClasses.table}>
                      <CreateSecondaryOrderCartTable
                        skusPayload={skusPayload}
                        customer={customer}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
                <Divider />
                <Box display="flex" justifyContent="flex-end" p={2}>
                  <MaterialButton
                    buttonText="Create Secondary Order"
                    handleClick={handleFormSubmit}
                    variant="contained"
                    disabled={disableContinue()}
                  />
                </Box>
              </>
            )}
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    isSecondaryOrderRequestPending: isSecondaryOrderRequestPending(state),
    isSkuRequestPending: isSkuRequestPending(state),
    errorMessage: getErrorMessage(state),
    skusPayload: getAllSkus(state),
    cartSkuPayload: getSecondaryOrderCartItems(state),
    customer: getSecondaryOrderCustomer(state),
    areaDistributors: getAllDistributors(state)
  };
};

const mapDispatchToProps = {
  fetchAllSkusConnect: fetchAllSkus,
  createSecondaryOrderConnect: createSecondaryOrder,
  fetchAllDistributorsConnect: fetchAllDistributors,
  resetSecondaryOrderErrorConnect: resetSecondaryOrderError,
  resetSecondaryOrderDataConnect: resetSecondaryOrderData,
  clearCartConnect: clearCart
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateSecondaryOrder);
