import React from 'react';
import { connect } from 'react-redux';
import CreateToolBar from '../../shared/CreateToolBar';
import BeatAreaTable from '../../components/BeatAreaTable';
import tableStyles from '../../components/Styles/TableStyles';
import useRefreshFetchReducer from '../../shared/Hooks/useRefreshFetchReducer';
import { ROUTE_TO_CREATE_BEAT_AREA } from '../../shared/Constants/RouteConstants';
import { TITLES } from '../../shared/Constants/Constant';
import { fetchAllBeatAreas } from '../../redux/beatArea/beatArea.actions';
import {
  getAllBeatAreas,
  isBeatAreaRequestPending
} from '../../redux/beatArea/beatArea.selector';
import { getCancelledState } from '../../redux/event/event.selector';
import { resetCancel } from '../../redux/event/event.actions';
import { currentUserRole, currentUserId } from '../../redux/auth/auth.selector';
import { BEAT_AREA_CREATE_ACTION_ALLOWED_USERS } from '../../shared/Constants/UserPermissons';

function BeatArea(props) {
  const classes = tableStyles();
  const {
    fetchAllBeatAreasConnect,
    isbeatAreaLoading,
    beatAreasPayload,
    resetCancelConnect,
    isCancelledEvent,
    authUserRole,
    authUserId
  } = props;

  useRefreshFetchReducer(
    fetchAllBeatAreasConnect,
    resetCancelConnect,
    isCancelledEvent
  );

  return (
    <>
      <CreateToolBar
        route={ROUTE_TO_CREATE_BEAT_AREA}
        buttonText={TITLES.CREATE_BEATAREA}
        showSpinner={isbeatAreaLoading}
        isAuthenticatedToAdd={BEAT_AREA_CREATE_ACTION_ALLOWED_USERS.includes(
          authUserRole
        )}
      />
      <div className={classes.content}>
        <BeatAreaTable
          beatAreas={beatAreasPayload}
          isbeatAreaLoading={isbeatAreaLoading}
          currentUserRole={authUserRole}
          currentUserId={authUserId}
        />
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    beatAreasPayload: getAllBeatAreas(state),
    isbeatAreaLoading: isBeatAreaRequestPending(state),
    isCancelledEvent: getCancelledState(state),
    authUserRole: currentUserRole(state),
    authUserId: currentUserId(state)
  };
};

const mapDispatchToProps = {
  fetchAllBeatAreasConnect: fetchAllBeatAreas,
  resetCancelConnect: resetCancel
};

BeatArea.defaultProps = {
  beatAreasPayload: []
};

export default connect(mapStateToProps, mapDispatchToProps)(BeatArea);
