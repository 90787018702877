import baseURL from '../shared/AxiosBaseUrl/baseURL';

class SuperStockist {
  static getAllSuperStockists(queryParams) {
    return baseURL.get('/api/v1/super-stockist/', {
      params: queryParams
    });
  }

  static getSuperStockist(id) {
    return baseURL.get(`/api/v1/super-stockist/${id}`);
  }

  static getSuperStockistInventory(id) {
    return baseURL.get(`/api/v1/super-stockist/${id}/inventory`);
  }

  static createSuperStockist(formValues) {
    return baseURL.post('/api/v1/super-stockist/', { ...formValues });
  }

  static createSuperStockistInventory(superStockistId, formValues) {
    return baseURL.post(`/api/v1/super-stockist/${superStockistId}/inventory`, {
      ...formValues
    });
  }

  static editSuperStockist(id, formValues) {
    return baseURL.patch(`/api/v1/super-stockist/${id}`, { ...formValues });
  }

  static editSuperStockistInventory(superStockistId, inventoryId, formValues) {
    return baseURL.patch(
      `/api/v1/super-stockist/${superStockistId}/inventory/${inventoryId}`,
      {
        ...formValues
      }
    );
  }

  static deleteSuperStockist(id) {
    return baseURL.delete(`/api/v1/super-stockist/${id}`);
  }
}

export default SuperStockist;
