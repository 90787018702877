import { makeStyles } from '@material-ui/core/styles';

const createSecondPrimaryOrderStyles = makeStyles((theme) => ({
  superStockistPickerContainer: {
    marginLeft: 16,
    marginRight: 16
  }
}));

export default createSecondPrimaryOrderStyles;
