import React from 'react';
import { get } from 'lodash';
import { Card, CardHeader } from '@material-ui/core';
import { formatInInr } from '../../shared/Utils/CommonUtils';
import { tableStyles } from '../../Styles/TableStyles';
import SummaryRowInfo from './SummaryRowInfo';
import ButtonColors from '../../shared/Constants/ColorConstants';

function SecondarySalesSummary(props) {
  const { entriesPayload = [] } = props;

  const commonClasses = tableStyles();

  const productiveCalls = entriesPayload.filter(
    (result) => result.activity === 'NEW_SECONDARY_ORDER'
  );

  const getTotalSales = () => {
    return entriesPayload.reduce((sum, entry) => {
      return (sum += get(entry, 'secondary_order.total_order_value', 0));
    }, 0);
  };

  return (
    <div style={{ marginTop: 10, marginBottom: 10, width: '100%' }}>
      <Card>
        <CardHeader
          title="Summary"
          className={commonClasses.heading}
          style={{ backgroundColor: ButtonColors.darkYellow }}
        />
        <div style={{ padding: 10 }}>
          <SummaryRowInfo
            title="Total Sales Calls:"
            value={entriesPayload.length || 0}
          />
          <SummaryRowInfo
            title="Total Productive Calls:"
            value={productiveCalls.length || 0}
          />
          <SummaryRowInfo
            title="Total Sales Value:"
            value={formatInInr(getTotalSales()) || 0}
          />
        </div>
      </Card>
    </div>
  );
}

export default SecondarySalesSummary;
