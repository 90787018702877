import _ from 'lodash';
import * as AreaActionTypes from './area.types';

const initialState = {
  data: {},
  isRequestPending: false
};

const areaDataReducer = (state = {}, action) => {
  const { payload, type } = action;

  switch (type) {
    case AreaActionTypes.FETCH_AREAS_SUCCESS:
      return { ..._.mapKeys(payload, 'id') };

    case AreaActionTypes.FETCH_AREA_SUCCESS:
      return { ...state, [payload.id]: payload };

    case AreaActionTypes.CREATE_AREA_SUCCESS:
      return { ...state, [payload.id]: payload };

    case AreaActionTypes.UPDATE_AREA_SUCCESS:
      return { ...state, [payload.id]: payload };

    case AreaActionTypes.DELETE_AREA_SUCCESS:
      return _.omit(state, payload);

    default:
      return state;
  }
};

const areaReducer = (state = initialState, action) => {
  const { type } = action;

  switch (type) {
    case AreaActionTypes.FETCH_AREAS_REQUEST:
      return {
        ...state,
        isRequestPending: true
      };

    case AreaActionTypes.FETCH_AREAS_SUCCESS:
      return {
        ...state,
        isRequestPending: false,
        data: areaDataReducer(state.data, action)
      };

    case AreaActionTypes.FETCH_AREAS_ERROR:
      return {
        ...state,
        isRequestPending: false
      };

    case AreaActionTypes.FETCH_AREA_REQUEST:
      return {
        ...state,
        isRequestPending: true
      };

    case AreaActionTypes.FETCH_AREA_SUCCESS:
      return {
        ...state,
        isRequestPending: false,
        data: areaDataReducer(state.data, action)
      };

    case AreaActionTypes.FETCH_AREA_ERROR:
      return {
        ...state,
        isRequestPending: false
      };

    case AreaActionTypes.CREATE_AREA_REQUEST:
      return {
        ...state,
        isRequestPending: true
      };

    case AreaActionTypes.CREATE_AREA_SUCCESS:
      return {
        ...state,
        isRequestPending: false,
        data: areaDataReducer(state.data, action)
      };

    case AreaActionTypes.CREATE_AREA_ERROR:
      return {
        ...state,
        isRequestPending: false
      };

    case AreaActionTypes.UPDATE_AREA_REQUEST:
      return {
        ...state,
        isRequestPending: true
      };

    case AreaActionTypes.UPDATE_AREA_SUCCESS:
      return {
        ...state,
        isRequestPending: false,
        data: areaDataReducer(state.data, action)
      };

    case AreaActionTypes.UPDATE_AREA_ERROR:
      return {
        ...state,
        isRequestPending: false
      };

    case AreaActionTypes.DELETE_AREA_REQUEST:
      return {
        ...state,
        isRequestPending: true
      };

    case AreaActionTypes.DELETE_AREA_SUCCESS:
      return {
        ...state,
        isRequestPending: false,
        data: areaDataReducer(state.data, action)
      };

    case AreaActionTypes.DELETE_AREA_ERROR:
      return {
        ...state,
        isRequestPending: false
      };

    default:
      return state;
  }
};

export default areaReducer;
