import React, { useState } from 'react';
import FormInput from '../FormInput';
import formStyles from '../Styles/FormStyles';
import FormCancelButton from '../FormCancelButton';
import FormSubmitButton from '../FormSubmitButton';
import useForm from '../../shared/Hooks/useForm';
import DistrictFormValidator from '../../pages/District/DistrictFormValidator';
import FormAutoComplete from '../FormAutoComplete';
import { isEqual, size } from 'lodash';

function EditDistrictForm({ district = {}, onSubmit, onCancel, zones = [] }) {
  const classes = formStyles();

  const [zoneItem, setZoneItem] = useState(district.zone);

  const normalizeFormValues = () => {
    const updatedValues = {};

    if (values.name && !isEqual(values.name, district.name)) {
      updatedValues.name = values.name;
    }

    if (zoneItem && !isEqual(zoneItem, district.zone)) {
      updatedValues.zone = zoneItem.id;
    }

    return updatedValues;
  };

  const onChangeZoneSelection = (e, value) => {
    e.persist();
    setZoneItem(value);
  };

  const handleFormSubmit = () => {
    onSubmit(normalizeFormValues(), resetValues);
  };

  const { values, errors, handleChange, handleSubmit, resetValues } = useForm(
    handleFormSubmit,
    DistrictFormValidator,
    {
      initialValues: {
        name: district.name
      }
    }
  );

  return (
    <form className={classes.form} noValidate autoComplete="off">
      <FormAutoComplete
        data={zones}
        label="Select Zone"
        name="zone"
        value={zoneItem}
        onChangeSelect={(e, newValue) => onChangeZoneSelection(e, newValue)}
        property="name"
        autoFocus
        required
      />

      <FormInput
        type="text"
        label="District"
        placeholder="Enter a District"
        name="name"
        value={values.name || ''}
        onChange={handleChange}
        helperText={errors.name || ''}
        error={!!errors.name}
        required
      />

      <div className={classes.buttonRow}>
        <FormCancelButton onClickCancel={onCancel} />
        <FormSubmitButton
          onClickSubmit={handleSubmit}
          disabled={!size(normalizeFormValues()) > 0}
        />
      </div>
    </form>
  );
}

export default React.memo(EditDistrictForm);
