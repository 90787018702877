import _ from 'lodash';
import * as DistrictActionTypes from './district.types';

const initialState = {
  data: {},
  isRequestPending: false
};

const districtDataReducer = (state = {}, action) => {
  const { payload, type } = action;

  switch (type) {
    case DistrictActionTypes.FETCH_DISTRICTS_SUCCESS:
      return { ..._.mapKeys(payload, 'id') };

    case DistrictActionTypes.FETCH_DISTRICT_SUCCESS:
      return { ...state, [payload.id]: payload };

    case DistrictActionTypes.CREATE_DISTRICT_SUCCESS:
      return { ...state, [payload.id]: payload };

    case DistrictActionTypes.UPDATE_DISTRICT_SUCCESS:
      return { ...state, [payload.id]: payload };

    case DistrictActionTypes.DELETE_DISTRICT_SUCCESS:
      return _.omit(state, payload);

    default:
      return state;
  }
};
const districtReducer = (state = initialState, action) => {
  const { type } = action;

  switch (type) {
    case DistrictActionTypes.FETCH_DISTRICTS_REQUEST:
      return {
        ...state,
        isRequestPending: true
      };

    case DistrictActionTypes.FETCH_DISTRICTS_SUCCESS:
      return {
        ...state,
        isRequestPending: false,
        data: districtDataReducer(state.data, action)
      };

    case DistrictActionTypes.FETCH_DISTRICTS_ERROR:
      return {
        ...state,
        isRequestPending: false
      };

    case DistrictActionTypes.FETCH_DISTRICT_REQUEST:
      return {
        ...state,
        isRequestPending: true
      };

    case DistrictActionTypes.FETCH_DISTRICT_SUCCESS:
      return {
        ...state,
        isRequestPending: false,
        data: districtDataReducer(state.data, action)
      };

    case DistrictActionTypes.FETCH_DISTRICT_ERROR:
      return {
        ...state,
        isRequestPending: false
      };

    case DistrictActionTypes.CREATE_DISTRICT_REQUEST:
      return {
        ...state,
        isRequestPending: true
      };

    case DistrictActionTypes.CREATE_DISTRICT_SUCCESS:
      return {
        ...state,
        isRequestPending: false,
        data: districtDataReducer(state.data, action)
      };

    case DistrictActionTypes.CREATE_DISTRICT_ERROR:
      return {
        ...state,
        isRequestPending: false
      };

    case DistrictActionTypes.UPDATE_DISTRICT_REQUEST:
      return {
        ...state,
        isRequestPending: true
      };

    case DistrictActionTypes.UPDATE_DISTRICT_SUCCESS:
      return {
        ...state,
        isRequestPending: false,
        data: districtDataReducer(state.data, action)
      };

    case DistrictActionTypes.UPDATE_DISTRICT_ERROR:
      return {
        ...state,
        isRequestPending: false
      };

    case DistrictActionTypes.DELETE_DISTRICT_REQUEST:
      return {
        ...state,
        isRequestPending: true
      };

    case DistrictActionTypes.DELETE_DISTRICT_SUCCESS:
      return {
        ...state,
        isRequestPending: false,
        data: districtDataReducer(state.data, action)
      };

    case DistrictActionTypes.DELETE_DISTRICT_ERROR:
      return {
        ...state,
        isRequestPending: false
      };

    default:
      return state;
  }
};

export default districtReducer;
