import React, { useState, useEffect, useCallback } from 'react';
import moment from 'moment';
import {
  Container,
  FormLabel,
  Grid,
  Divider,
  MenuItem
} from '@material-ui/core';
import { connect } from 'react-redux';
import FormSelect from '../../components/FormSelect';
import AntdDatePicker from '../../components/AntdDatePicker';
import UserAttendanceTable from '../../components/UserAttendanceTable';
import {
  fetchAllUsersAttendance,
  fetchAllUsers
} from '../../redux/user/user.actions';
import {
  getAllUsers,
  getAllUsersAttendanceData,
  isUserRequestPending
} from '../../redux/user/user.selector';
import { getCancelledState } from '../../redux/event/event.selector';
import { resetCancel } from '../../redux/event/event.actions';
import commonStyles from '../../Styles/CommonStyles';
import GoogleMapsMultiMarker from '../../components/GoogleMapsMultiMarker';
import { useJsApiLoader } from '@react-google-maps/api';
import { get } from 'lodash';

function UserAttendance(props) {
  const {
    fetchAllUsersConnect,
    fetchAllUsersAttendanceConnect,
    usersAttendancePayload,
    isUserLoading,
    usersPayload
  } = props;

  const commonClasses = commonStyles();
  const [userId, setUserId] = useState('ALL_USERS');
  const [selectedDate, setSelectedDate] = useState(moment().startOf('day'));
  const transformedUsersPayload = [
    {
      id: 'ALL_USERS',
      name: 'All Users'
    },
    ...usersPayload
  ];

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY
  });

  const getTransformedPayload = useCallback(() => {
    return usersAttendancePayload.reduce((acc, entry) => {
      const showStartAttendanceMarker =
        entry.attendance &&
        entry.attendance.start_location.coordinates &&
        entry.attendance.start_location &&
        entry.attendance.start_location.coordinates.length > 0;

      const showEndAttendanceMarker =
        entry.attendance &&
        entry.attendance.end_location &&
        entry.attendance.end_location.coordinates &&
        entry.attendance.end_location.coordinates.length > 0;

      const entries = [];

      if (showStartAttendanceMarker) {
        entries.push({
          showMarker: !!showStartAttendanceMarker,
          lng: get(entry, 'attendance.start_location.coordinates[0]', ''),
          lat: get(entry, 'attendance.start_location.coordinates[1]', ''),
          mapId: 'S',
          markerPrimaryText: 'Start Attendance',
          markerDateAndTime: get(entry, 'attendance.start_time', '')
        });
      }

      if (showEndAttendanceMarker) {
        entries.push({
          showMarker: !!showEndAttendanceMarker,
          lng: get(entry, 'attendance.end_location.coordinates[0]', ''),
          lat: get(entry, 'attendance.end_location.coordinates[1]', ''),
          mapId: 'E',
          markerPrimaryText: 'End Attendance',
          markerDateAndTime: get(entry, 'attendance.end_time', '')
        });
      }

      return entries;
    }, []);
  }, [usersAttendancePayload]);

  useEffect(() => {
    fetchAllUsersConnect();
  }, []);

  useEffect(() => {
    let mounted = true;
    const controller = new AbortController();

    const loadActivities = async () => {
      const query = {
        attendance_date: selectedDate.toISOString()
      };

      if (userId && userId !== 'ALL_USERS') {
        query.user = userId;
      }

      fetchAllUsersAttendanceConnect(query);
    };

    if (mounted) {
      loadActivities();
    }

    return () => {
      controller.abort();
      mounted = false;
    };
  }, [fetchAllUsersConnect, userId, selectedDate]);

  const handleUserId = useCallback((id) => {
    setUserId(id);
  }, []);

  const handleUserChange = (event) => {
    event.persist();

    const { value } = event.target;
    setUserId(value);
  };

  const entries = getTransformedPayload().filter((entry) => entry.showMarker);

  return (
    <Container className={commonClasses.root} maxWidth="lg">
      <Grid container spacing={3}>
        <Grid item lg={12} md={12} xs={12}>
          <Grid container spacing={3} direction="row">
            <Grid item lg={6} md={6} xs={12}>
              <FormSelect
                label="User Name"
                name="user"
                value={userId || ''}
                onChange={handleUserChange}
                showDefaultEmptyValue={false}
                showEmptyErrorMenuItem={!usersPayload.length}
                disabled={isUserLoading}
                required
              >
                {transformedUsersPayload.map((user) => {
                  const { name, id } = user;
                  return (
                    <MenuItem key={id} value={id}>
                      {name}
                    </MenuItem>
                  );
                })}
              </FormSelect>
            </Grid>
            <Grid
              item
              lg={6}
              md={6}
              xs={12}
              className={commonClasses.dailySalesDatePicker}
            >
              <FormLabel>Choose Time Period</FormLabel>
              <AntdDatePicker
                date={selectedDate}
                handleSelectedDate={setSelectedDate}
                disableFutureDates={true}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item md={12} xs={12} className={commonClasses.table}>
              <UserAttendanceTable
                attendances={usersAttendancePayload}
                isLoading={isUserLoading}
                handleUserId={handleUserId}
                showName={userId === 'ALL_USERS'}
                showRole={userId === 'ALL_USERS'}
                showDate={userId === 'ALL_USERS'}
                showStartTime
                showEndTime
                showWorkHours
                showLocation={userId === 'ALL_USERS'}
              />
            </Grid>
            {userId !== 'ALL_USERS' && entries.length > 0 && (
              <Grid
                lg={8}
                md={8}
                xs={12}
                className={commonClasses.mapContainer}
              >
                {isLoaded && (
                  <Grid container spacing={3}>
                    <Grid item md={12} xs={12}>
                      <GoogleMapsMultiMarker entries={entries} />
                    </Grid>
                  </Grid>
                )}
              </Grid>
            )}
          </Grid>
          <Divider />
        </Grid>
      </Grid>
    </Container>
  );
}

const mapStateToProps = (state) => {
  return {
    isUserLoading: isUserRequestPending(state),
    usersPayload: getAllUsers(state),
    usersAttendancePayload: getAllUsersAttendanceData(state),
    isCancelledEvent: getCancelledState(state)
  };
};

const mapDispatchToProps = {
  fetchAllUsersAttendanceConnect: fetchAllUsersAttendance,
  fetchAllUsersConnect: fetchAllUsers,
  resetCancelConnect: resetCancel
};

export default connect(mapStateToProps, mapDispatchToProps)(UserAttendance);
