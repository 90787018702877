import React from 'react';
import {
  INDIAN_CURRENCY,
  DISTRIBUTION_TYPES_TITLE
} from '../../shared/Constants/Constant';

function SkuDbrRetailItem(props) {
  const { priceList } = props;
  return (
    <>
      <p>{DISTRIBUTION_TYPES_TITLE.DISTRIBUTOR_RETAILER}:</p>
      <ul>
        <li>
          Factory Price:{' '}
          {Number(priceList.basePrice) > 0
            ? `${INDIAN_CURRENCY}${priceList.basePrice}`
            : 'NA'}
        </li>
        <li>
          DBR Price:{' '}
          {Number(priceList.distributorLandedPrice) > 0
            ? `${INDIAN_CURRENCY}${priceList.distributorLandedPrice}`
            : 'NA'}
        </li>
        <li>
          Retail Price:{' '}
          {Number(priceList.retailerLandedPrice) > 0
            ? `${INDIAN_CURRENCY}${priceList.retailerLandedPrice}`
            : 'NA'}
        </li>
      </ul>
    </>
  );
}

export default SkuDbrRetailItem;
