function UserFormValidator(values) {
  const errors = {};

  if (!values.name) {
    errors.name = '**User Name is required';
  }

  if (!values.user_name) {
    errors.user_name = '**Login User Name is required';
  }

  if (!values.mobile_number) {
    errors.mobile_number = '**Mobile Number is required';
  }

  if (!values.password) {
    errors.password = '**Password is required';
  }

  if (!values.role) {
    errors.role = '**User Role is required';
  }

  return errors;
}

export default UserFormValidator;
