export const FETCH_SECOND_PRIMARY_ORDERS_REQUEST =
  'FETCH_SECOND_PRIMARY_ORDERS_REQUEST';
export const FETCH_SECOND_PRIMARY_ORDER_REQUEST =
  'FETCH_SECOND_PRIMARY_ORDER_REQUEST';
export const CREATE_SECOND_PRIMARY_ORDER_REQUEST =
  'CREATE_SECOND_PRIMARY_ORDER_REQUEST';
export const UPDATE_SECOND_PRIMARY_ORDER_REQUEST =
  'UPDATE_SECOND_PRIMARY_ORDER_REQUEST';
export const DELETE_SECOND_PRIMARY_ORDER_REQUEST =
  'DELETE_SECOND_PRIMARY_ORDER_REQUEST';

export const FETCH_SECOND_PRIMARY_ORDERS_SUCCESS =
  'FETCH_SECOND_PRIMARY_ORDERS_SUCCESS';
export const FETCH_SECOND_PRIMARY_ORDER_SUCCESS =
  'FETCH_SECOND_PRIMARY_ORDER_SUCCESS';
export const CREATE_SECOND_PRIMARY_ORDER_SUCCESS =
  'CREATE_SECOND_PRIMARY_ORDER_SUCCESS';
export const UPDATE_SECOND_PRIMARY_ORDER_SUCCESS =
  'UPDATE_SECOND_PRIMARY_ORDER_SUCCESS';
export const DELETE_SECOND_PRIMARY_ORDER_SUCCESS =
  'DELETE_SECOND_PRIMARY_ORDER_SUCCESS';

export const FETCH_SECOND_PRIMARY_ORDERS_ERROR =
  'FETCH_SECOND_PRIMARY_ORDERS_ERROR';
export const FETCH_SECOND_PRIMARY_ORDER_ERROR =
  'FETCH_SECOND_PRIMARY_ORDER_ERROR';
export const CREATE_SECOND_PRIMARY_ORDER_ERROR =
  'CREATE_SECOND_PRIMARY_ORDER_ERROR';
export const UPDATE_SECOND_PRIMARY_ORDER_ERROR =
  'UPDATE_SECOND_PRIMARY_ORDER_ERROR';
export const DELETE_SECOND_PRIMARY_ORDER_ERROR =
  'DELETE_SECOND_PRIMARY_ORDER_ERROR';

export const ADD_SECOND_PRIMARY_ORDER_ITEM = 'ADD_SECOND_PRIMARY_ORDER_ITEM';
export const REMOVE_SECOND_PRIMARY_ORDER_ITEM =
  'REMOVE_SECOND_PRIMARY_ORDER_ITEM';
export const CLEAR_SECOND_PRIMARY_ORDER_ORDER_CART =
  'CLEAR_SECOND_PRIMARY_ORDER_ORDER_CART';

export const SECOND_PRIMARY_ORDER_PAGINATION =
  'SECOND_PRIMARY_ORDER_PAGINATION';
export const UPDATE_SECOND_PRIMARY_ORDER_PAGINATION_PAGE =
  'UPDATE_SECOND_PRIMARY_ORDER_PAGINATION_PAGE';

export const RESET_SECOND_PRIMARY_ORDER_DATA =
  'RESET_SECOND_PRIMARY_ORDER_DATA';

export const RESET_SECOND_PRIMARY_ORDER_ERROR =
  'RESET_SECOND_PRIMARY_ORDER_ERROR';

export const CLEAR_SECOND_PRIMARY_ORDER_CART =
  'CLEAR_SECOND_PRIMARY_ORDER_CART';

export const LOAD_SECOND_PRIMARY_ORDER_CUSTOMER =
  'LOAD_SECOND_PRIMARY_ORDER_CUSTOMER';
