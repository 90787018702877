import React, { useState } from 'react';
import { connect } from 'react-redux';
import InventoryTable from '../../../components/InventoryTable';
import {
  editSuperStockist,
  fetchSuperStockist,
  fetchSuperStockistInventory
} from '../../../redux/superStockist/superStockist.actions';
import {
  getSuperStockistById,
  isSuperStockistRequestPending,
  getSuperStockistSkuInventory
} from '../../../redux/superStockist/superStockist.selector';
import history from '../../../routes/history';
import { ROUTE_TO_SUPER_STOCKIST } from '../../../shared/Constants/RouteConstants';
import { setCancel } from '../../../redux/event/event.actions';
import BackdropWithLoader from '../../../components/BackdropWithLoader';
import useFetchReducerWithId from '../../../shared/Hooks/useFetchReducerWithId';
import useRefreshFetchReducerWithId from '../../../shared/Hooks/useRefreshFetchReducerWithId';
import { makeStyles } from '@material-ui/core';
import TableHeadingWithCreateButton from '../../../components/TableHeadingWithCreateButton';
import SuperStockistInventoryNewItemForm from './SuperStockistInventoryNewItemForm/SuperStockistInventoryNewItemForm';
import { fetchAllSkus } from '../../../redux/sku/sku.actions';
import {
  getAllSkus,
  isSkuRequestPending
} from '../../../redux/sku/sku.selector';
import SuperStockistInventoryEditItemForm from './SuperStockistInventoryEditItemForm';

const useStyles = makeStyles((theme) => ({
  content: {
    marginTop: theme.spacing(2)
  }
}));

const ManageSuperStockistInventory = (props) => {
  const {
    match: {
      params: { id }
    },
    superStockistSkuInventories,
    editSuperStockistConnect,
    fetchSuperStockistConnect,
    fetchSuperStockistInventoryConnect,
    superStockist = {},
    isSuperStockistLoading,
    setCancelConnect,
    fetchAllSkusConnect,
    skusPayload
  } = props;
  const superStockistId = id;
  const classes = useStyles();

  useFetchReducerWithId(
    fetchSuperStockistConnect,
    superStockistId,
    superStockist
  );

  useRefreshFetchReducerWithId(
    fetchSuperStockistInventoryConnect,
    superStockistId
  );

  const [openSkuForm, setOpenSkuForm] = useState(false);

  const handleClickOpen = () => {
    fetchAllSkusConnect();
    setOpenSkuForm(true);
  };

  const handleClose = () => {
    setOpenSkuForm(false);
  };

  const handleSubmit = (formValues) => {
    editSuperStockistConnect(superStockistId, formValues);
  };

  const handleCancel = () => {
    setCancelConnect();
    history.push(ROUTE_TO_SUPER_STOCKIST);
  };

  if (isSuperStockistLoading) {
    return <BackdropWithLoader />;
  }

  const getFilteredSkusPayload = () => {
    const superStockistSkuIds = superStockistSkuInventories.reduce(
      (allIds, sku) => {
        return [...allIds, sku.sku_id];
      },
      []
    );

    return skusPayload.filter((sku) => !superStockistSkuIds.includes(sku.id));
  };

  return (
    <>
      <TableHeadingWithCreateButton
        component={SuperStockistInventoryNewItemForm}
        open={openSkuForm}
        handleButtonClick={handleClickOpen}
        handleModalClose={handleClose}
        showCreateModal
        buttonText="ADD NEW SKU"
        headerTitle="SUPER_STOCKIST STOCK MANAGEMENT"
        skusPayload={getFilteredSkusPayload()}
        superStockistId={superStockistId}
      />
      <div className={classes.content}>
        <InventoryTable
          skus={superStockistSkuInventories}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
          EditItemComponent={SuperStockistInventoryEditItemForm}
          customerId={superStockistId}
        />
      </div>
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    superStockist: getSuperStockistById(state, ownProps),
    superStockistSkuInventories: getSuperStockistSkuInventory(state, ownProps),
    isSuperStockistLoading: isSuperStockistRequestPending(state),
    isSkuRequestPending: isSkuRequestPending(state),
    skusPayload: getAllSkus(state)
  };
};

const mapDispatchToProps = {
  fetchAllSkusConnect: fetchAllSkus,
  fetchSuperStockistConnect: fetchSuperStockist,
  fetchSuperStockistInventoryConnect: fetchSuperStockistInventory,
  editSuperStockistConnect: editSuperStockist,
  setCancelConnect: setCancel
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManageSuperStockistInventory);
