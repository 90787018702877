import React from 'react';
import { Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Avatar, Typography } from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import formStyles from '../../components/Styles/FormStyles';
import LoginForm from '../../components/LoginForm';
import { TITLES } from '../../shared/Constants/Constant';
import { userLogin } from '../../redux/auth/auth.actions';
import BackdropWithLoader from '../../components/BackdropWithLoader';

function Login() {
  const classes = formStyles();
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const isRequestPending = useSelector((state) => state.auth.isRequestPending);

  const handleLoginSubmit = (loginDetails) => {
    dispatch(userLogin(loginDetails));
  };

  if (isRequestPending) {
    return <BackdropWithLoader />;
  }

  if (isAuthenticated) {
    return <Redirect to="/home" />;
  }

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon color="primary" />
        </Avatar>
        <Typography component="h1" variant="h5">
          {TITLES.LOGIN}
        </Typography>

        <LoginForm onSubmit={handleLoginSubmit} />
      </div>
    </Container>
  );
}

export default Login;
