import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import useRefreshFetchReducer from '../../shared/Hooks/useRefreshFetchReducer';
import { fetchAllSecondaryOrders } from '../../redux/secondary-order/secondary-order.actions';
import {
  isSecondaryOrderRequestPending,
  getAllSecondaryOrders
} from '../../redux/secondary-order/secondary-order.selector';
import { getCancelledState } from '../../redux/event/event.selector';
import { resetCancel } from '../../redux/event/event.actions';
import SecondaryOrderTable from '../../components/SecondaryOrderTable';

const useStyles = makeStyles((theme) => ({
  content: {
    marginTop: theme.spacing(2)
  }
}));

function SecondaryOrder(props) {
  const {
    fetchAllSecondaryOrdersConnect,
    secondaryOrdersPayload,
    isSecondaryOrderLoading,
    resetCancelConnect,
    isCancelledEvent
  } = props;
  const classes = useStyles();

  useRefreshFetchReducer(
    fetchAllSecondaryOrdersConnect,
    resetCancelConnect,
    isCancelledEvent
  );

  return (
    <div className={classes.content}>
      <SecondaryOrderTable
        orders={secondaryOrdersPayload}
        isOrderLoading={isSecondaryOrderLoading}
      />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    isSecondaryOrderLoading: isSecondaryOrderRequestPending(state),
    secondaryOrdersPayload: getAllSecondaryOrders(state),
    isCancelledEvent: getCancelledState(state)
  };
};

const mapDispatchToProps = {
  fetchAllSecondaryOrdersConnect: fetchAllSecondaryOrders,
  resetCancelConnect: resetCancel
};

export default connect(mapStateToProps, mapDispatchToProps)(SecondaryOrder);
