const DISTRIBUTION_TYPES = {
  SUPERSTOCKIST_DISTRIBUTOR_RETAILER: 'SUPERSTOCKIST_DISTRIBUTOR_RETAILER',
  DISTRIBUTOR_RETAILER: 'DISTRIBUTOR_RETAILER'
};

const calculateLandedPrice = (price, marginPercentage) => {
  // costPrice = ( sellingPrice * 100 ) / ( 100 + percentageProfit).

  return (
    ((Number(price) * 100) / (Number(marginPercentage) + 100)).toFixed(2) || 0
  );
};

const calculateLandedPrices = (retailPrice, ...margins) => {
  const prices = {};
  let priceCount = margins.length || 0;

  (margins || []).reduce((acc, margin) => {
    return (prices[`price${priceCount--}`] = calculateLandedPrice(acc, margin));
  }, retailPrice); // {"price3": 100.00, "price2": 100.00, "price1": 100.00}
  return prices;
};

export const ssAndDbrAndRetailer = ({
  retailPrice,
  retailerMargin,
  distributorMargin,
  superStockistMargin,
  gst
}) => {
  // Factory -> Super Stockiest -> Distributor -> Retailer
  // Price 1 - Base Price from factory
  // Price 2 - Super Stockiest
  // Price 3 - Distributor
  // Price 4 - Retailer

  const { price1, price2, price3, price4 } = calculateLandedPrices(
    retailPrice,
    retailerMargin,
    distributorMargin,
    superStockistMargin,
    gst
  );

  return {
    distributionType: DISTRIBUTION_TYPES.SUPERSTOCKIST_DISTRIBUTOR_RETAILER,
    basePrice: price1,
    superStockistLandedPrice: price2,
    distributorLandedPrice: price3,
    retailerLandedPrice: price4
  };
};

export const dbrAndRetailer = ({
  retailPrice,
  retailerMargin,
  distributorMargin,
  gst
}) => {
  // Factory -> Distributor -> Retailer
  // Price 1 - Base Price from factory
  // Price 2 - Distributor
  // Price 3 - Retailer

  const { price1, price2, price3 } = calculateLandedPrices(
    retailPrice,
    retailerMargin,
    distributorMargin,
    gst
  );

  return {
    distributionType: DISTRIBUTION_TYPES.DISTRIBUTOR_RETAILER,
    basePrice: price1,
    distributorLandedPrice: price2,
    retailerLandedPrice: price3
  };
};

export const wholeSalerAndRetailer = (
  retailPrice,
  retailerMargin,
  wholeSalerMargin,
  gst
) => {
  // Factory -> WholeSaler -> Retailer
  // Price 1 - Base Price from factory
  // Price 2 - WholeSaler
  // Price 3 - Retailer

  const { price1, price2, price3 } = calculateLandedPrices(
    retailPrice,
    retailerMargin,
    wholeSalerMargin,
    gst
  );

  return {
    distributionType: DISTRIBUTION_TYPES.WHOLESALE_RETAILER,
    basePrice: price1,
    wholeSellerLandedPrice: price2,
    retailerLandedPrice: price3
  };
};

export const DirectRetailer = (retailPrice, retailerMargin, gst) => {
  // Factory -> Retailer
  // Price 1 - Base Price from factory
  // Price 2 - Retailer
  const { price1, price2 } = calculateLandedPrices(
    retailPrice,
    retailerMargin,
    gst
  );

  return {
    distributionType: DISTRIBUTION_TYPES.DIRECT_RETAILER,
    basePrice: price1,
    retailerPrice: price2
  };
};
