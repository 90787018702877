import SuperStockistApi from '../../apis/SuperStockistAPI';
import * as SuperStockistActionTypes from './superStockist.types';
import STATUS from '../../shared/Constants/apiStatus';
import { ROUTE_TO_SUPER_STOCKIST } from '../../shared/Constants/RouteConstants';

export const fetchAllSuperStockists = () => async (dispatch) => {
  dispatch({
    type: SuperStockistActionTypes.FETCH_SUPER_STOCKISTS_REQUEST
  });

  try {
    const response = await SuperStockistApi.getAllSuperStockists();

    if (response.data.status === STATUS.OK) {
      dispatch({
        type: SuperStockistActionTypes.FETCH_SUPER_STOCKISTS_SUCCESS,
        payload: response.data.superStockists,
        error: null,
        route: null,
        success: null
      });
    }
  } catch (error) {
    dispatch({
      type: SuperStockistActionTypes.FETCH_SUPER_STOCKISTS_ERROR,
      error: error.response.data,
      payload: null,
      route: null,
      success: null
    });
  }
};

export const fetchSuperStockist = (id) => async (dispatch) => {
  dispatch({
    type: SuperStockistActionTypes.FETCH_SUPER_STOCKIST_REQUEST
  });

  try {
    const response = await SuperStockistApi.getSuperStockist(id);
    if (response.data.status === STATUS.OK) {
      dispatch({
        type: SuperStockistActionTypes.FETCH_SUPER_STOCKIST_SUCCESS,
        payload: response.data.superStockist,
        error: null,
        route: null,
        success: null
      });
    }
  } catch (error) {
    dispatch({
      type: SuperStockistActionTypes.FETCH_SUPER_STOCKIST_ERROR,
      error: error.response.data,
      payload: null,
      route: ROUTE_TO_SUPER_STOCKIST,
      success: null
    });
  }
};

export const fetchSuperStockistInventory = (id) => async (dispatch) => {
  dispatch({
    type: SuperStockistActionTypes.FETCH_SUPER_STOCKIST_INVENTORY_REQUEST
  });

  try {
    const response = await SuperStockistApi.getSuperStockistInventory(id);
    if (response.data.status === STATUS.OK) {
      dispatch({
        type: SuperStockistActionTypes.FETCH_SUPER_STOCKIST_INVENTORY_SUCCESS,
        payload: response.data.superStockist,
        error: null,
        route: null,
        success: null
      });
    }
  } catch (error) {
    dispatch({
      type: SuperStockistActionTypes.FETCH_SUPER_STOCKIST_INVENTORY_ERROR,
      error: error.response.data,
      payload: null,
      route: ROUTE_TO_SUPER_STOCKIST,
      success: null
    });
  }
};

export const createSuperStockist = (formValues) => async (dispatch) => {
  dispatch({
    type: SuperStockistActionTypes.CREATE_SUPER_STOCKIST_REQUEST
  });

  try {
    const response = await SuperStockistApi.createSuperStockist(formValues);
    if (response.data.status === STATUS.OK) {
      dispatch({
        type: SuperStockistActionTypes.CREATE_SUPER_STOCKIST_SUCCESS,
        payload: response.data.superStockist,
        error: null,
        route: ROUTE_TO_SUPER_STOCKIST,
        success: response.data.message
      });
    }
  } catch (error) {
    dispatch({
      type: SuperStockistActionTypes.CREATE_SUPER_STOCKIST_ERROR,
      error: error.response.data,
      payload: null,
      route: ROUTE_TO_SUPER_STOCKIST,
      success: null
    });
  }
};

export const createSuperStockistInventory = (
  id,
  formValues,
  callback
) => async (dispatch) => {
  dispatch({
    type: SuperStockistActionTypes.CREATE_SUPER_STOCKIST_INVENTORY_REQUEST
  });

  try {
    const response = await SuperStockistApi.createSuperStockistInventory(
      id,
      formValues
    );
    if (response.data.status === STATUS.OK) {
      if (callback) {
        callback();
      }

      dispatch({
        type: SuperStockistActionTypes.CREATE_SUPER_STOCKIST_INVENTORY_SUCCESS,
        payload: response.data.superStockistInventory,
        error: null,
        route: null,
        success: null
      });
    }
  } catch (error) {
    dispatch({
      type: SuperStockistActionTypes.CREATE_SUPER_STOCKIST_INVENTORY_ERROR,
      error: error.response.data,
      payload: null,
      success: null
    });
  }
};

export const editSuperStockist = (id, formValues) => async (dispatch) => {
  dispatch({
    type: SuperStockistActionTypes.UPDATE_SUPER_STOCKIST_REQUEST
  });

  try {
    const response = await SuperStockistApi.editSuperStockist(id, formValues);
    if (response.data.status === STATUS.OK) {
      dispatch({
        type: SuperStockistActionTypes.UPDATE_SUPER_STOCKIST_SUCCESS,
        payload: response.data.superStockist,
        error: null,
        route: ROUTE_TO_SUPER_STOCKIST,
        success: response.data.message
      });
    }
  } catch (error) {
    dispatch({
      type: SuperStockistActionTypes.UPDATE_SUPER_STOCKIST_ERROR,
      error: error.response.data,
      payload: null,
      route: ROUTE_TO_SUPER_STOCKIST,
      success: null
    });
  }
};

export const editSuperStockistInventory = (
  superStockistId,
  inventoryId,
  formValues,
  callback
) => async (dispatch) => {
  dispatch({
    type: SuperStockistActionTypes.UPDATE_SUPER_STOCKIST_INVENTORY_REQUEST
  });

  try {
    const response = await SuperStockistApi.editSuperStockistInventory(
      superStockistId,
      inventoryId,
      formValues
    );
    if (response.data.status === STATUS.OK) {
      if (callback) {
        callback();
      }

      dispatch({
        type: SuperStockistActionTypes.UPDATE_SUPER_STOCKIST_INVENTORY_SUCCESS,
        payload: response.data.superStockistInventory,
        error: null,
        route: null,
        success: null
      });
    }
  } catch (error) {
    dispatch({
      type: SuperStockistActionTypes.UPDATE_SUPER_STOCKIST_INVENTORY_ERROR,
      error: error.response.data,
      payload: null,
      success: null
    });
  }
};

export const deleteSuperStockist = (id) => async (dispatch) => {
  dispatch({
    type: SuperStockistActionTypes.DELETE_SUPER_STOCKIST_REQUEST
  });

  try {
    const response = await SuperStockistApi.deleteSuperStockist(id);
    if (response.data.status === STATUS.OK) {
      dispatch({
        type: SuperStockistActionTypes.DELETE_SUPER_STOCKIST_SUCCESS,
        payload: id,
        error: null,
        route: ROUTE_TO_SUPER_STOCKIST,
        success: response.data.message
      });
    }
  } catch (error) {
    dispatch({
      type: SuperStockistActionTypes.DELETE_SUPER_STOCKIST_ERROR,
      payload: null,
      error: error.response.data,
      route: ROUTE_TO_SUPER_STOCKIST,
      success: null
    });
  }
};
