import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { makeStyles } from '@material-ui/core/styles';
import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Collapse
} from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import StarIcon from '@material-ui/icons/Star';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import history from '../../routes/history';

const useStyles = makeStyles((theme) => ({
  nested: {
    paddingLeft: theme.spacing(8)
  },
  nestedIcon: {
    color: 'rgba(0, 0, 0, 0.54)',
    display: 'flex'
  }
}));

export default function NestedRouteList(props) {
  const { primaryText, nestedRoutes, icon: Icon, currentUrl } = props;
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <>
      <ListItem button onClick={handleClick}>
        <ListItemIcon>
          <Icon />
        </ListItemIcon>
        <ListItemText primary={primaryText} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {nestedRoutes.map((route) => (
            <ListItem
              button
              className={classes.nested}
              key={uuidv4()}
              onClick={() => history.push(route.url)}
            >
              <div className={classes.nestedIcon}>
                {currentUrl && currentUrl === route.url ? (
                  <StarIcon color="primary" />
                ) : (
                  <StarBorderIcon />
                )}
                <ListItemText
                  primary={route.component}
                  style={{ marginLeft: '5px' }}
                />
              </div>
            </ListItem>
          ))}
        </List>
      </Collapse>
    </>
  );
}
