import React from 'react';
import { connect } from 'react-redux';
import tableStyles from '../../components/Styles/TableStyles';
import FactoryStockTable from '../../components/FactoryStockTable';
import { resetCancel } from '../../redux/event/event.actions';
import { fetchAllSkus } from '../../redux/sku/sku.actions';
import { getCancelledState } from '../../redux/event/event.selector';
import { isSkuRequestPending, getAllSkus } from '../../redux/sku/sku.selector';
import useRefreshFetchReducer from '../../shared/Hooks/useRefreshFetchReducer';
import { currentUserRole, currentUserId } from '../../redux/auth/auth.selector';

function FactoryStock(props) {
  const classes = tableStyles();
  const {
    skusPayload,
    isSkusLoading,
    fetchAllSkusConnect,
    resetCancelConnect,
    isCancelledEvent,
    authUserRole,
    authUserId
  } = props;

  useRefreshFetchReducer(
    fetchAllSkusConnect,
    resetCancelConnect,
    isCancelledEvent
  );

  return (
    <div className={classes.content}>
      <FactoryStockTable
        skus={skusPayload}
        isSkusLoading={isSkusLoading}
        currentUserRole={authUserRole}
        currentUserId={authUserId}
      />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    skusPayload: getAllSkus(state),
    isSkusLoading: isSkuRequestPending(state),
    isCancelledEvent: getCancelledState(state),
    authUserRole: currentUserRole(state),
    authUserId: currentUserId(state)
  };
};

const mapDispatchToProps = {
  fetchAllSkusConnect: fetchAllSkus,
  resetCancelConnect: resetCancel
};

export default connect(mapStateToProps, mapDispatchToProps)(FactoryStock);
