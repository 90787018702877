import UserApi from '../../apis/UserAPI';
import * as UserActionTypes from './user.types';
import STATUS from '../../shared/Constants/apiStatus';
import { ROUTE_TO_USER } from '../../shared/Constants/RouteConstants';

export const fetchAllUsers = () => async (dispatch) => {
  dispatch({
    type: UserActionTypes.FETCH_USERS_REQUEST
  });

  try {
    const response = await UserApi.getAllUsers();
    if (response.data.status === STATUS.OK) {
      dispatch({
        type: UserActionTypes.FETCH_USERS_SUCCESS,
        payload: response.data.users,
        error: null,
        route: null,
        success: null
      });
    }
  } catch (error) {
    dispatch({
      type: UserActionTypes.FETCH_USERS_ERROR,
      error: error.response.data,
      payload: null,
      route: null,
      success: null
    });
  }
};

export const fetchAllUsersAttendance = (query) => async (dispatch) => {
  dispatch({
    type: UserActionTypes.FETCH_USERS_ATTENDANCE_REQUEST
  });

  try {
    const response = await UserApi.getAllUsersAttendance(query);
    if (response.data.status === STATUS.OK) {
      dispatch({
        type: UserActionTypes.FETCH_USERS_ATTENDANCE_SUCCESS,
        payload: response.data.attendances,
        error: null,
        route: null,
        success: null
      });
    }
  } catch (error) {
    dispatch({
      type: UserActionTypes.FETCH_USERS_ATTENDANCE_ERROR,
      error: error.response.data,
      payload: null,
      route: null,
      success: null
    });
  }
};

export const fetchUser = (id) => async (dispatch) => {
  dispatch({
    type: UserActionTypes.FETCH_USER_REQUEST
  });

  try {
    const response = await UserApi.getUser(id);
    if (response.data.status === STATUS.OK) {
      dispatch({
        type: UserActionTypes.FETCH_USER_SUCCESS,
        payload: response.data.user,
        error: null,
        route: null,
        success: null
      });
    }
  } catch (error) {
    dispatch({
      type: UserActionTypes.FETCH_USER_ERROR,
      error: error.response.data,
      payload: null,
      route: ROUTE_TO_USER,
      success: null
    });
  }
};

export const createUser = (formValues) => async (dispatch) => {
  dispatch({
    type: UserActionTypes.CREATE_USER_REQUEST
  });

  try {
    const response = await UserApi.createUser(formValues);

    if (response.data.status === STATUS.OK) {
      dispatch({
        type: UserActionTypes.CREATE_USER_SUCCESS,
        payload: response.data.user,
        error: null,
        route: ROUTE_TO_USER,
        success: response.data.message
      });
    }
  } catch (error) {
    dispatch({
      type: UserActionTypes.CREATE_USER_ERROR,
      error: error.response.data,
      payload: null,
      route: ROUTE_TO_USER,
      success: null
    });
  }
};

export const editUser = (id, formValues) => async (dispatch) => {
  dispatch({
    type: UserActionTypes.UPDATE_USER_REQUEST
  });

  try {
    const response = await UserApi.editUser(id, formValues);
    if (response.data.status === STATUS.OK) {
      dispatch({
        type: UserActionTypes.UPDATE_USER_SUCCESS,
        payload: response.data.user,
        error: null,
        route: ROUTE_TO_USER,
        success: response.data.message
      });
    }
  } catch (error) {
    dispatch({
      type: UserActionTypes.UPDATE_USER_ERROR,
      error: error.response.data,
      payload: null,
      route: ROUTE_TO_USER,
      success: null
    });
  }
};

export const deleteUser = (id) => async (dispatch) => {
  dispatch({
    type: UserActionTypes.DELETE_USER_REQUEST
  });

  try {
    const response = await UserApi.deleteUser(id);
    if (response.data.status === STATUS.OK) {
      dispatch({
        type: UserActionTypes.DELETE_USER_SUCCESS,
        payload: id,
        error: null,
        route: ROUTE_TO_USER,
        success: response.data.message
      });
    }
  } catch (error) {
    dispatch({
      type: UserActionTypes.DELETE_USER_ERROR,
      payload: null,
      error: error.response.data,
      route: ROUTE_TO_USER,
      success: null
    });
  }
};

export const clearUserData = () => (dispatch) => {
  dispatch({
    type: UserActionTypes.CLEAR_USER_ATTENDANCE_DATA
  });
};
