import { makeStyles } from '@material-ui/core';

export const formCss = makeStyles({
  restrictTerroitoryRadio: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '10px',
    marginBottom: '10px'
  },
  allowedAppsCheckBoxGroup: {
    marginTop: '15px',
    marginBottom: '15px'
  }
});
