import { size } from 'lodash';
import { createSelector } from 'reselect';

const getSuperStockistselector = (state) => state.superStockists;
const getAllSuperStockistsSelector = (state) => state.superStockists.data;
const getSuperStockistByIdSelector = (state, props) => props.match.params.id;

export const isSuperStockistRequestPending = createSelector(
  [getSuperStockistselector],
  (superStockists) => superStockists.isRequestPending
);

export const isSuperStockistInventoryRequestPending = createSelector(
  [getSuperStockistselector],
  (superStockists) => superStockists.isSuperStockistInventoryModalRequestPending
);

export const getAllSuperStockists = createSelector(
  [getAllSuperStockistsSelector],
  (superStockists) => Object.values(superStockists)
);

export const getSuperStockistById = createSelector(
  getAllSuperStockistsSelector,
  getSuperStockistByIdSelector,
  (superStockists, id) => superStockists[id]
);

export const getSuperStockistSkuInventory = createSelector(
  getAllSuperStockistsSelector,
  getSuperStockistByIdSelector,
  (superStockists, id) => {
    const superStockist = superStockists[id] || {};

    if (size(superStockist) > 0 && size(superStockist.sku_items) > 0) {
      return Object.values(superStockist.sku_items);
    }

    return [];
  }
);
