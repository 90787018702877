import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { TableCell } from '@material-ui/core';

const StyledTableCell = withStyles({
  head: {
    backgroundColor: '#facd49',
    color: '#000036',
    fontSize: 14,
    whiteSpace: 'nowrap'
  },
  body: {
    fontSize: 14,
    whiteSpace: 'nowrap'
  }
})(TableCell);

export default React.memo(StyledTableCell);
