import React, { useState } from 'react';
import { connect } from 'react-redux';
import {
  Table as MuiTable,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper
} from '@material-ui/core';
import { StyledTableCell, tableStyles } from '../../../../Styles/TableStyles';
import MaterialButton from '../../../../components/MaterialButton';
import {
  addItem,
  removeItem
} from '../../../../redux/primary-order/primary-order.actions';
import PrimaryOrderCreateItemForm from '../PrimaryOrderCreateItemForm';
import PrimaryOrderCartTableItem from '../PrimaryOrderCartTableItem';
import {
  getPrimaryOrderCartItems,
  getFilteredSkusPayload,
  getPrimaryOrderCartTotalQtyInCases,
  getPrimaryOrderCartTotalQtyInPcs,
  getPrimaryOrderTotalCostQuantity
} from '../../../../redux/primary-order/primary-order.selector';
import { size } from 'lodash';
import { PRIMARY_ORDER_CART_HEADER_CONSTANTS } from '../../../../shared/Constants/TableHeaderConstants';

function PrimaryOrderCartTable(props) {
  const {
    skusPayload,
    addSkuItemConnect,
    cartItems,
    removeItemConnect,
    filteredSkusPayload,
    totalQtyInCases,
    totalQtyInPcs,
    totalCost,
    customer
  } = props;

  const [openSkuForm, setOpenSkuForm] = useState(false);

  const handleClickOpen = () => {
    setOpenSkuForm(true);
  };

  const handleClose = () => {
    setOpenSkuForm(false);
  };

  const classes = tableStyles();

  const getTotalSkusText = () => {
    const totalSku = size(cartItems);
    return `${totalSku} ${totalSku > 1 ? 'Skus' : 'Sku'}`;
  };

  const getSku = (cartItem) => {
    return skusPayload.find((sku) => sku.id === cartItem.skuId) || {};
  };

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <MuiTable stickyHeader>
          <TableHead>
            <TableRow>
              {Object.keys(PRIMARY_ORDER_CART_HEADER_CONSTANTS).map(
                (header) => (
                  <StyledTableCell
                    key={header}
                    style={{ position: 'sticky' }}
                    align="center"
                  >
                    {PRIMARY_ORDER_CART_HEADER_CONSTANTS[header]}
                  </StyledTableCell>
                )
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {cartItems.map((cartItem, index) => {
              return (
                <PrimaryOrderCartTableItem
                  key={cartItem.skuId}
                  skuItemIndex={index}
                  cartItem={cartItem}
                  handleRemoveSku={removeItemConnect}
                  skuDetails={getSku(cartItem)}
                  addSkuItem={addSkuItemConnect}
                />
              );
            })}
            {size(cartItems) > 0 && (
              <TableRow>
                <StyledTableCell align="center">Totals:</StyledTableCell>
                <StyledTableCell align="center">
                  {getTotalSkusText()}
                </StyledTableCell>
                <StyledTableCell align="center"></StyledTableCell>
                <StyledTableCell align="center">
                  {totalQtyInCases}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {totalQtyInPcs}
                </StyledTableCell>
                <StyledTableCell align="center"></StyledTableCell>
                <StyledTableCell align="center"></StyledTableCell>
                <StyledTableCell align="center">{totalCost}</StyledTableCell>
              </TableRow>
            )}
            <TableRow>
              <StyledTableCell align="center" colSpan={9}>
                <PrimaryOrderCreateItemForm
                  skusPayload={filteredSkusPayload}
                  open={openSkuForm}
                  handleClose={handleClose}
                  addSkuItem={addSkuItemConnect}
                  customer={customer}
                />
                <MaterialButton
                  onClick={handleClickOpen}
                  buttonText="+ Add a new Sku"
                  margin="normal"
                  size="small"
                />
              </StyledTableCell>
            </TableRow>
          </TableBody>
        </MuiTable>
      </TableContainer>
    </Paper>
  );
}

const mapStateToProps = (state) => {
  return {
    cartItems: getPrimaryOrderCartItems(state),
    totalQtyInCases: getPrimaryOrderCartTotalQtyInCases(state),
    totalQtyInPcs: getPrimaryOrderCartTotalQtyInPcs(state),
    totalCost: getPrimaryOrderTotalCostQuantity(state),
    filteredSkusPayload: getFilteredSkusPayload(state)
  };
};

const mapDispatchToProps = {
  addSkuItemConnect: addItem,
  removeItemConnect: removeItem
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PrimaryOrderCartTable);
