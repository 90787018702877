import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { isEmpty } from 'lodash';
import store from './redux/store';
import AppRouter from './routes';
import { loadCurrentUser } from './redux/auth/auth.actions';
import setAuthToken from './shared/AxiosBaseUrl/setAuthToken';
import 'antd/dist/antd.css';

const App = () => {
  const currentState = store.getState();

  useEffect(() => {
    if (!isEmpty(currentState.auth.token)) {
      setAuthToken(localStorage.token);
      store.dispatch(loadCurrentUser());
    }
  }, []);

  return (
    <Provider store={store}>
      <AppRouter />
    </Provider>
  );
};
ReactDOM.render(<App />, document.getElementById('root'));
