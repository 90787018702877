import React from 'react';
import { Button, Divider, MenuItem, FormHelperText } from '@material-ui/core';
import formStyles from '../Styles/FormStyles';
import FormInput from '../FormInput';
import FormSelect from '../FormSelect';
import FormSubHeader from '../FormSubHeader';
import {
  CUSTOMER_ROLES,
  ONLY_NUMBER_REGEX
} from '../../shared/Constants/Constant';
import { areValidAdditionalContacts } from '../../shared/Utils/CommonUtils';

function AdditionalContactsForm(props) {
  const {
    subHeaderTitle,
    contacts,
    handleInputChange,
    handleRemove,
    handleAdd,
    errorMessage
  } = props;
  const classes = formStyles();

  const handleNumericInputChange = (event, index) => {
    event.persist();
    const { value } = event.target;
    if (value === '' || value.match(ONLY_NUMBER_REGEX) != null) {
      handleInputChange(event, index);
    }
  };

  return (
    <>
      <FormSubHeader text={subHeaderTitle} />

      {contacts.map((contact, index) => {
        const id = `additional-contact-${index}`;
        return (
          <div key={id}>
            <FormInput
              type="text"
              label={`Contact Name ${index + 1}`}
              placeholder="Enter the Contact Name"
              name="contact_person_name"
              value={contact.contact_person_name || ''}
              onChange={(e) => handleInputChange(e, index)}
            />

            <FormInput
              type="text"
              label={`Contact Number ${index + 1}`}
              placeholder="Enter the Contact Number"
              name="contact_number"
              value={contact.contact_number || ''}
              onChange={(e) => handleNumericInputChange(e, index)}
            />

            <FormSelect
              label="Role"
              name="designation"
              value={contact.designation || ''}
              onChange={(e) => handleInputChange(e, index)}
              showDefaultEmptyValue={false}
            >
              {Object.keys(CUSTOMER_ROLES).map((role) => {
                const label = CUSTOMER_ROLES[role];
                return (
                  <MenuItem key={role} value={role}>
                    {label}
                  </MenuItem>
                );
              })}
            </FormSelect>

            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end'
              }}
            >
              <Button
                type="button"
                variant="contained"
                color="primary"
                margin="normal"
                size="small"
                onClick={() => handleRemove(index)}
                className={classes.dynamicFormRemoveContactButton}
              >
                Remove Contact
              </Button>
            </div>
            <Divider
              variant="middle"
              style={{ color: '#FF0000', margin: '10px' }}
            />
          </div>
        );
      })}

      <div>
        <Button
          type="button"
          variant="contained"
          align="left"
          margin="normal"
          color="primary"
          fullWidth
          onClick={handleAdd}
          disabled={!areValidAdditionalContacts(contacts)}
          className={classes.dynamicFormButton}
        >
          Click to Add a additional Contact
        </Button>
      </div>

      <FormHelperText style={{ color: '#d50000' }}>
        {Boolean(errorMessage) && errorMessage}
      </FormHelperText>
    </>
  );
}

export default AdditionalContactsForm;
