import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Avatar,
  CircularProgress,
  MenuItem
} from '@material-ui/core';
import { connect } from 'react-redux';
import { LocalShipping } from '@material-ui/icons';
import commonStyles from '../../../Styles/CommonStyles';
import FormSelect from '../../FormSelect';
import MaterialButton from '../../MaterialButton';
import { editSecondaryOrder } from '../../../redux/secondary-order/secondary-order.actions';
import { isSecondaryOrderModalRequestPending } from '../../../redux/secondary-order/secondary-order.selector';
import { isEqual } from 'lodash';
import {
  SECONDARY_ORDER_CONSTANTS,
  SECONDARY_ORDER_STATUS
} from '../../../shared/Constants/Constant';

function EditSecondaryOrderStatus(props) {
  const {
    open,
    handleClose,
    order,
    isRequestPending,
    editSecondaryOrderConnect,
    errorMessage
  } = props;
  const classes = commonStyles();
  const [orderStatus, setOrderStatus] = useState(props.order.status);

  const handleModalClose = () => {
    setOrderStatus(props.order.status);
    handleClose();
  };

  useEffect(() => {
    setOrderStatus(props.order.status);
  }, [props.order.status]);

  const isOrderStatusChanged = () => {
    return !isEqual(orderStatus, order.status);
  };

  const updateShipmentSubmit = () => {
    if (isOrderStatusChanged()) {
      editSecondaryOrderConnect(
        {
          secondaryOrderId: order.id,
          orderDetails: {
            status: orderStatus
          }
        },
        handleModalClose
      );
    }
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleModalClose}
        classes={{
          paperWidthSm: classes.paperWidthSm
        }}
      >
        <DialogTitle id="form-dialog-title">
          <Avatar className={classes.avatar}>
            <LocalShipping color="primary" />
          </Avatar>
          <Typography align="center">Update Secondary Order Status</Typography>

          {isRequestPending && (
            <div className={classes.modalSpinner}>
              <CircularProgress />
            </div>
          )}
        </DialogTitle>
        <DialogContent>
          <FormSelect
            label="Secondary Order Status"
            name="orderStatus"
            value={orderStatus}
            onChange={(e) => setOrderStatus(e.target.value)}
            showDefaultEmptyValue={false}
          >
            <MenuItem value={SECONDARY_ORDER_CONSTANTS.ORDERED} disabled>
              {SECONDARY_ORDER_STATUS.ORDERED}
            </MenuItem>
            <MenuItem value={SECONDARY_ORDER_CONSTANTS.ORDER_APPROVED}>
              {SECONDARY_ORDER_STATUS.ORDER_APPROVED}
            </MenuItem>
            <MenuItem
              value={SECONDARY_ORDER_CONSTANTS.ORDER_CANCELLED_BY_RETAILER}
            >
              {SECONDARY_ORDER_STATUS.ORDER_CANCELLED_BY_RETAILER}
            </MenuItem>
            <MenuItem
              value={SECONDARY_ORDER_CONSTANTS.ORDER_CANCELLED_BY_DISTRIBUTOR}
            >
              {SECONDARY_ORDER_STATUS.ORDER_CANCELLED_BY_DISTRIBUTOR}
            </MenuItem>

            {props.order.status !== SECONDARY_ORDER_CONSTANTS.ORDERED && (
              <MenuItem value={SECONDARY_ORDER_CONSTANTS.REACHED_DESTINATION}>
                {SECONDARY_ORDER_STATUS.REACHED_DESTINATION}
              </MenuItem>
            )}
          </FormSelect>

          {!!errorMessage && (
            <Typography variant="h5" style={{ color: '#d50000', fontSize: 13 }}>
              {`**${errorMessage}`}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <MaterialButton
            handleClick={handleModalClose}
            buttonText="Cancel"
            disabled={isRequestPending}
          />
          <MaterialButton
            handleClick={updateShipmentSubmit}
            buttonText="Confirm"
            disabled={isRequestPending || !isOrderStatusChanged()}
          />
        </DialogActions>
      </Dialog>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    isRequestPending: isSecondaryOrderModalRequestPending(state)
  };
};

const mapDispatchToProps = {
  editSecondaryOrderConnect: editSecondaryOrder
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditSecondaryOrderStatus);
