import React from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import useRefreshFetchReducer from '../../shared/Hooks/useRefreshFetchReducer';
import CreateToolBar from '../../shared/CreateToolBar';
import UserTable from '../../components/UserTable';
import { ROUTE_TO_CREATE_USER } from '../../shared/Constants/RouteConstants';
import { TITLES } from '../../shared/Constants/Constant';
import { fetchAllUsers, editUser } from '../../redux/user/user.actions';
import {
  getAllUsers,
  isUserRequestPending
} from '../../redux/user/user.selector';
import { getCancelledState } from '../../redux/event/event.selector';
import { resetCancel } from '../../redux/event/event.actions';
import { currentUserRole, currentUserId } from '../../redux/auth/auth.selector';
import { USER_CREATE_ACTION_ALLOWED_USERS } from '../../shared/Constants/UserPermissons';

const useStyles = makeStyles((theme) => ({
  content: {
    marginTop: theme.spacing(2)
  }
}));

function User(props) {
  const {
    fetchAllUsersConnect,
    usersPayload,
    isUserLoading,
    resetCancelConnect,
    isCancelledEvent,
    authUserRole,
    authUserId,
    editUserConnect
  } = props;
  const classes = useStyles();

  useRefreshFetchReducer(
    fetchAllUsersConnect,
    resetCancelConnect,
    isCancelledEvent
  );

  if (!USER_CREATE_ACTION_ALLOWED_USERS.includes(authUserRole)) {
    return <Redirect to="/home" />;
  }

  return (
    <>
      <CreateToolBar
        route={ROUTE_TO_CREATE_USER}
        buttonText={TITLES.CREATE_USER}
        showSpinner={isUserLoading}
        isAuthenticatedToAdd={USER_CREATE_ACTION_ALLOWED_USERS.includes(
          authUserRole
        )}
      />
      <div className={classes.content}>
        <UserTable
          users={usersPayload}
          isUserLoading={isUserLoading}
          currentUserRole={authUserRole}
          currentUserId={authUserId}
          editUser={editUserConnect}
        />
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    isUserLoading: isUserRequestPending(state),
    usersPayload: getAllUsers(state),
    isCancelledEvent: getCancelledState(state),
    authUserRole: currentUserRole(state),
    authUserId: currentUserId(state)
  };
};

const mapDispatchToProps = {
  fetchAllUsersConnect: fetchAllUsers,
  resetCancelConnect: resetCancel,
  editUserConnect: editUser
};

User.defaultProps = {
  usersPayload: []
};

export default connect(mapStateToProps, mapDispatchToProps)(User);
