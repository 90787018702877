import {
  upperCase,
  upperFirst,
  lowerCase,
  isEmpty,
  toLower,
  startCase,
  isEqual
} from 'lodash';
import { INR_SYMBOL } from '../Constants/Constant';

export const toUpperCase = (str) => {
  return upperCase(str).replace(/\s/g, '');
};

export const toSentenceCase = (str) => {
  if (!str) {
    return '';
  }

  return upperFirst(lowerCase(str));
};

export const toWordUpperFirstCase = (str) => {
  if (!str) {
    return '';
  }

  return startCase(toLower(str));
};

export const toConstantCase = (str) => {
  return upperCase(str).replace(/\s/g, '_');
};

export const toSentenceCaseWithRegex = (str) => {
  return upperFirst(lowerCase(str.replace(/_/, ' ')));
};

export const toCommaSeperated = (collection = []) => {
  if (collection.length) {
    return Object.keys(collection)
      .map((record) => {
        return collection[record].name;
      })
      .join(', ');
  }

  return 'NA';
};

export const areEqualObjectIds = (id1, id2) => {
  return id1.toString() === id2.toString();
};

export const getRecordFromPayload = (payload, id) => {
  return payload.find((doc) => doc.id.toString() === id.toString());
};

export const filterAdditionalContacts = (payload) => {
  return payload.filter(
    (contact) =>
      contact.contactPersonName || contact.contactNumber || contact.role
  );
};

export const filterCurrentBrandsDealing = (brands) => {
  return brands.filter(
    (brand) =>
      brand.brandName || brand.turnOverAmount || brand.brandDealershipPeriod
  );
};

export const formatInInr = (value) => {
  if (!value) {
    return `${INR_SYMBOL} ${Number(0).toFixed(2)}`;
  }

  return new Intl.NumberFormat('en-IN', {
    style: 'currency',
    currency: 'INR'
  }).format(value);
};

export const formatNumberWithSingleDecimal = (value) => {
  if (!value) {
    return Number(0).toFixed(1);
  }

  return Number(value).toFixed(1);
};

export const formatNumberWithTwoDecimal = (value) => {
  if (!value) {
    return Number(0).toFixed(2);
  }

  return Number(value).toFixed(2);
};

export const formatQuantityInUnits = (value, unit) => {
  if (!value) {
    return '';
  }

  return `${value}${unit}`;
};

export const isValidAdditionalContact = (contact) => {
  return (
    !isEmpty(contact) &&
    contact.contact_person_name &&
    contact.contact_number &&
    contact.designation
  );
};

export const areValidAdditionalContacts = (contacts) => {
  return contacts.every((contact) => isValidAdditionalContact(contact));
};

export const isValidCustomerBrand = (customerBrand) => {
  return (
    !isEmpty(customerBrand) &&
    customerBrand.brand_name &&
    (customerBrand.turn_over_amount || customerBrand.brand_dealing_time_period)
  );
};

export const areValidCustomerBrands = (customerBrands) => {
  return customerBrands.every((customerBrand) =>
    isValidCustomerBrand(customerBrand)
  );
};

export const getFilteredDistrictsPayload = (districts, zoneItem) => {
  return districts.filter((district) => {
    if (zoneItem) {
      return district.zone.id === zoneItem.id;
    }

    return false;
  });
};

export const getFilteredAreasPayload = (areas, districtItem) => {
  return areas.filter((area) => {
    if (districtItem) {
      return area.district.id === districtItem.id;
    }

    return false;
  });
};

export const getFilteredBeatsPayload = (beats, areaItem) => {
  return beats.filter((beat) => {
    if (areaItem) {
      return beat.area.id === areaItem.id;
    }

    return false;
  });
};

export const timeConversionFromMilliSeconds = (timeInMilliseconds) => {
  let hour = Math.floor(timeInMilliseconds / 1000 / 60 / 60);
  let minute = Math.floor((timeInMilliseconds / 1000 / 60 / 60 - hour) * 60);

  hour = `${hour < 10 ? '0' : ''}${hour}${hour <= 1 ? 'hr' : 'hrs'}`;
  minute = `${minute < 10 ? '0' : ''}${minute}${minute <= 1 ? 'min' : 'mins'}`;

  return `${hour} ${minute}`;
};

export const division = (num1, num2) => {
  return Number(num1) / Number(num2);
};

export const addition = (num1, num2) => {
  return Number(num1) + Number(num2);
};

export const checkIfArraysAreEqual = (array1, array2) => {
  return isEqual(array1.sort(), array2.sort());
};
