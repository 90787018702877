import React, { useState } from 'react';
import { isEqual, size } from 'lodash';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Avatar,
  CircularProgress,
  InputAdornment
} from '@material-ui/core';
import { Divider } from 'antd';
import moment from 'moment';
import MaterialButton from '../MaterialButton';
import FormInput from '../FormInput';
import { Storefront } from '@material-ui/icons';
import { connect } from 'react-redux';
import commonStyles from '../../Styles/CommonStyles';
import { editProduct } from '../../redux/product/product.actions';
import { isProductRequestPending } from '../../redux/product/product.selector';
import { ROUTE_TO_PACKAGING_MATERIAL_STOCK } from '../../shared/Constants/RouteConstants';

const OnlyNumberRegex = /^[-+]?[0-9]\d*$/;

function PackagingMaterialStockInputForm(props) {
  const {
    stockValue,
    open,
    handleClose,
    updateParentProductConnect,
    errorMessage,
    isUpdateRequestPending = false,
    date,
    parentProductName,
    parentProductId,
    updateCartonBoxStock = false,
    updateBottleStock = false
  } = props;

  console.log(isUpdateRequestPending);

  const classes = commonStyles();
  const [newInventoryOnHand, setInventoryOnHand] = useState('');
  const [adjustFactoryStock, setAdjustFactoryStock] = useState('');

  const newInventoryTotal = () => {
    let newFactoryInventoryLevel = Number(stockValue);

    if (newInventoryOnHand) {
      newFactoryInventoryLevel += Number(newInventoryOnHand);
    }

    if (adjustFactoryStock) {
      newFactoryInventoryLevel -= Number(adjustFactoryStock);
    }

    return newFactoryInventoryLevel;
  };

  const normalizeUpdateParentProduct = () => {
    const updatedFactorySkuValues = {};
    const newTotal = newInventoryTotal();

    if (updateCartonBoxStock && !isEqual(stockValue, newTotal)) {
      updatedFactorySkuValues.carton_box_stock = newTotal;
    }

    if (updateBottleStock && !isEqual(stockValue, newTotal)) {
      updatedFactorySkuValues.product_container_stock = newTotal;
    }

    return updatedFactorySkuValues;
  };

  const handleFormResetAndClose = () => {
    setInventoryOnHand('');
    setAdjustFactoryStock('');
    handleClose();
  };

  const stockInventoryUpdateFormSubmit = () => {
    if (size(normalizeUpdateParentProduct()) > 0) {
      updateParentProductConnect(
        parentProductId,
        normalizeUpdateParentProduct(),
        handleFormResetAndClose,
        ROUTE_TO_PACKAGING_MATERIAL_STOCK
      );
    }
  };

  const disableContinue = () => {
    return size(normalizeUpdateParentProduct()) === 0 || isUpdateRequestPending;
  };

  const handleNumericInputChange = (event, type = 'inventoryOnHand') => {
    event.persist();
    const { value } = event.target;
    if (value === '' || value.match(OnlyNumberRegex) != null) {
      if (type === 'adjustedValue' && Number(value) <= Number(stockValue)) {
        setAdjustFactoryStock(value);
      }
      if (type === 'inventoryOnHand') {
        setInventoryOnHand(value);
      }
    }
  };

  const showAdjustedStock = () => {
    return stockValue > 0;
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleFormResetAndClose}
        classes={{
          paperWidthSm: classes.paperWidthSm
        }}
      >
        <DialogTitle id="form-dialog-title">
          <Avatar className={classes.avatar}>
            <Storefront color="primary" />
          </Avatar>
          <Typography align="center">
            Packaging Stock Balance as on {moment(date).format('DD-MMM-YYYY')}
          </Typography>
          <div
            style={{
              marginTop: 5,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            {isUpdateRequestPending && <CircularProgress />}
          </div>
          <Divider />
          <Typography align="center">{parentProductName}</Typography>
          <Divider />
          <Typography align="center">
            {`Current ${
              updateCartonBoxStock ? 'Carton Box' : 'Bottle'
            } Stock: ${stockValue}`}
          </Typography>

          {!adjustFactoryStock && (
            <Typography align="center">
              {`Added New ${
                updateCartonBoxStock ? 'Carton Box' : 'Bottle'
              } Stock: ${newInventoryOnHand || 0}`}
            </Typography>
          )}

          {showAdjustedStock() && !newInventoryOnHand && (
            <Typography align="center">
              {`Adjusted Stock: ${-adjustFactoryStock || 0}`}
            </Typography>
          )}

          <Typography align="center">
            {`Total (New Current Stock): ${newInventoryTotal()}`}
          </Typography>
          <Divider />
        </DialogTitle>
        <DialogContent>
          <FormInput
            type="text"
            label="Add new Stock Qty"
            placeholder="Enter the new Stock Qty"
            name="inventoryOnHand"
            value={newInventoryOnHand}
            onChange={handleNumericInputChange}
            disabled={isUpdateRequestPending || !!adjustFactoryStock}
            autoFocus
          />

          {showAdjustedStock() && (
            <>
              <Divider />
              <FormInput
                type="text"
                label="Adjust Stock Qty"
                placeholder="Enter the adjusted value"
                name="adjustedValue"
                value={adjustFactoryStock}
                onChange={(e) => handleNumericInputChange(e, 'adjustedValue')}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">-</InputAdornment>
                  )
                }}
                disabled={isUpdateRequestPending || !!newInventoryOnHand}
              />
            </>
          )}

          {!!errorMessage && (
            <Typography variant="h5" style={{ color: '#d50000', fontSize: 13 }}>
              {`**${errorMessage}`}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <MaterialButton
            handleClick={handleFormResetAndClose}
            buttonText="Cancel"
            disabled={isUpdateRequestPending}
          />
          <MaterialButton
            handleClick={stockInventoryUpdateFormSubmit}
            buttonText="Confirm"
            disabled={disableContinue()}
          />
        </DialogActions>
      </Dialog>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    isUpdateRequestPending: isProductRequestPending(state)
  };
};

const mapDispatchToProps = {
  updateParentProductConnect: editProduct
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PackagingMaterialStockInputForm);
