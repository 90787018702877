export const FETCH_EMPLOYEE_SECONDARY_SALES_REQUEST =
  'FETCH_EMPLOYEE_SECONDARY_SALES_REQUEST';
export const UPDATE_EMPLOYEE_SECONDARY_SALES_REQUEST =
  'UPDATE_EMPLOYEE_SECONDARY_SALES_REQUEST';
export const DELETE_EMPLOYEE_SECONDARY_SALES_REQUEST =
  'DELETE_EMPLOYEE_SECONDARY_SALES_REQUEST';

export const FETCH_EMPLOYEE_SECONDARY_SALES_SUCCESS =
  'FETCH_EMPLOYEE_SECONDARY_SALES_SUCCESS';
export const UPDATE_EMPLOYEE_SECONDARY_SALES_SUCCESS =
  'UPDATE_EMPLOYEE_SECONDARY_SALES_SUCCESS';
export const DELETE_EMPLOYEE_SECONDARY_SALES_SUCCESS =
  'DELETE_EMPLOYEE_SECONDARY_SALES_SUCCESS';

export const FETCH_EMPLOYEE_SECONDARY_SALES_ERROR =
  'FETCH_EMPLOYEE_SECONDARY_SALES_ERROR';
export const UPDATE_EMPLOYEE_SECONDARY_SALES_ERROR =
  'UPDATE_EMPLOYEE_SECONDARY_SALES_ERROR';
export const DELETE_EMPLOYEE_SECONDARY_SALES_ERROR =
  'DELETE_EMPLOYEE_SECONDARY_SALES_ERROR';

export const RESET_EMPLOYEE_SECONDARY_SALES_ERROR =
  'RESET_EMPLOYEE_SECONDARY_SALES_ERROR';

export const RESET_EMPLOYEE_SECONDARY_SALES_DATA =
  'RESET_EMPLOYEE_SECONDARY_SALES_DATA';
