import React from 'react';
import { connect } from 'react-redux';
import tableStyles from '../../components/Styles/TableStyles';
import PackagingMaterialsStockTable from '../../components/PackagingMaterialsStockTable';
import { resetCancel } from '../../redux/event/event.actions';
import { fetchAllProducts } from '../../redux/product/product.actions';
import { getCancelledState } from '../../redux/event/event.selector';
import {
  isProductRequestPending,
  getAllProducts
} from '../../redux/product/product.selector';
import useRefreshFetchReducer from '../../shared/Hooks/useRefreshFetchReducer';
import { currentUserRole, currentUserId } from '../../redux/auth/auth.selector';

function PackagingMaterialsStock(props) {
  const classes = tableStyles();
  const {
    parentProductsPayload,
    isParentProductsLoading,
    fetchAllProductsConnect,
    resetCancelConnect,
    isCancelledEvent,
    authUserRole,
    authUserId
  } = props;

  useRefreshFetchReducer(
    fetchAllProductsConnect,
    resetCancelConnect,
    isCancelledEvent
  );

  return (
    <div className={classes.content}>
      <PackagingMaterialsStockTable
        parentProductsPayload={parentProductsPayload}
        isParentProductsLoading={isParentProductsLoading}
        currentUserRole={authUserRole}
        currentUserId={authUserId}
      />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    parentProductsPayload: getAllProducts(state),
    isParentProductsLoading: isProductRequestPending(state),
    isCancelledEvent: getCancelledState(state),
    authUserRole: currentUserRole(state),
    authUserId: currentUserId(state)
  };
};

const mapDispatchToProps = {
  fetchAllProductsConnect: fetchAllProducts,
  resetCancelConnect: resetCancel
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PackagingMaterialsStock);
