function SuperStockistFormValidator(values) {
  const errors = {};

  if (!values.name) {
    errors.name = '**Super Stockist Name is required';
  }

  if (!values.place) {
    errors.place = '**Place is required';
  }

  if (!values.street_address) {
    errors.street_address = '**Street Address is required';
  }

  if (!values.landmark) {
    errors.landmark = '**Landmark is required';
  }

  if (!values.zip_code) {
    errors.zip_code = '**Pin Code is required';
  }

  if (!values.contact_person_name) {
    errors.contact_person_name = '**Key Contact Person Name is required';
  }

  if (!values.contact_number || values.contact_number.length !== 10) {
    if (!values.contact_number) {
      errors.contact_number = '**Contact Number is required';
    } else {
      errors.contact_number = '**Enter a valid Contact Number';
    }
  }

  if (!values.existing_distributors_count) {
    errors.existing_distributors_count = '**Existing DBR count is required';
  }

  return errors;
}

export default SuperStockistFormValidator;
