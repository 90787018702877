import React from 'react';
import moment from 'moment';
import { StyledTableCell, StyledTableRow } from '../../Styles/TableStyles';
import { USER_ROLES } from '../../shared/Constants/Constant';
import { timeConversionFromMilliSeconds } from '../../shared/Utils/CommonUtils';
import { LocationOn } from '@material-ui/icons';
import { IconButton } from '@material-ui/core';
import commonStyles from '../../Styles/CommonStyles';

function UserAttendanceTableItem(props) {
  const classes = commonStyles();
  const { userAttendance = {}, handleUserId } = props;
  const {
    employee_name: employeeName,
    role,
    attendance,
    user_id: userId
  } = userAttendance;

  return (
    <StyledTableRow>
      {props.showName && (
        <StyledTableCell component="th" scope="row" align="center">
          {employeeName}
        </StyledTableCell>
      )}

      {props.showRole && (
        <StyledTableCell component="th" scope="row" align="center">
          {USER_ROLES[role]}
        </StyledTableCell>
      )}

      {props.showDate && (
        <StyledTableCell component="th" scope="row" align="center">
          {moment(attendance.attendance_date).format('DD-MMM-YYYY')}
        </StyledTableCell>
      )}

      {props.showStartTime && (
        <StyledTableCell component="th" scope="row" align="center">
          {attendance.start_time
            ? moment(attendance.start_time).format('hh:mm A')
            : 'NA'}
        </StyledTableCell>
      )}

      {props.showEndTime && (
        <StyledTableCell component="th" scope="row" align="center">
          {attendance.end_time
            ? moment(attendance.end_time).format('hh:mm A')
            : 'NA'}
        </StyledTableCell>
      )}

      {props.showWorkHours && (
        <StyledTableCell component="th" scope="row" align="center">
          {attendance.start_time && attendance.end_time
            ? timeConversionFromMilliSeconds(attendance.work_duration)
            : 'NA'}
        </StyledTableCell>
      )}

      {props.showLocation && (
        <div className={classes.divisonCenter}>
          <IconButton color="inherit" onClick={() => handleUserId(userId)}>
            <LocationOn />
          </IconButton>
        </div>
      )}
    </StyledTableRow>
  );
}

export default UserAttendanceTableItem;
