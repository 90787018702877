const BUTTON_ACTIONS_CONSTANTS = {
  EDIT: 'EDIT',
  DELETE: 'DELETE'
};

const BUTTON_ACTIONS = {
  EDIT: 'Edit',
  DELETE: 'Delete'
};

const INDIAN_CURRENCY = 'Rs.';
const INR_SYMBOL = '₹';

const BRANDS = {
  PAPPY: 'Pappy',
  UNIQUS: 'Uniqus'
};

const QUANTITY_UNITS = {
  ml: 'ml',
  l: 'l',
  g: 'g',
  kg: 'kg'
};

const CUSTOMER_ROLES = {
  OWNER: 'Owner',
  MANAGER: 'Manager',
  ACCOUNTANT: 'Accountant',
  OTHERS: 'Others'
};

const TITLES = {
  LOGIN: 'SIGN IN',
  CREATE_USER: 'CREATE USER',
  ASSIGN_TERRITORY: 'ASSIGN TERRITORY',
  NO_ASSIGNED_BEATS: 'CURRENTLY NO BEATS ASSIGNED TO THE USER',

  CREATE_PARENT_PRODUCT: 'CREATE PARENT PRODUCT',
  EDIT_PARENT_PRODUCT: 'EDIT PARENT PRODUCT',

  CREATE_SKU: 'CREATE SKU',
  EDIT_SKU: 'EDIT SKU',
  DELETE_SKU: 'DELETE SKU',

  CREATE_COMBO_SKU: 'CREATE COMBO SKU',
  EDIT_COMBO_SKU: 'EDIT COMBO SKU',
  DELETE_COMBO_SKU: 'DELETE COMBO SKU',

  CREATE_ZONE: 'CREATE ZONE',
  EDIT_ZONE: 'EDIT ZONE',
  DELETE_ZONE: 'DELETE ZONE',

  CREATE_DISTRICT: 'CREATE DISTRICT',
  EDIT_DISTRICT: 'EDIT DISTRICT',
  DELETE_DISTRICT: 'DELETE DISTRICT',

  CREATE_AREA: 'CREATE AREA',
  EDIT_AREA: 'EDIT AREA',
  DELETE_AREA: 'DELETE AREA',

  CREATE_BEATAREA: 'CREATE BEAT',
  EDIT_BEATAREA: 'EDIT BEAT',
  DELETE_BEATAREA: 'DELETE BEAT',

  CREATE_SUPER_STOCKIST: 'CREATE SUPER STOCKIST',
  EDIT_SUPER_STOCKIST: 'EDIT SUPER STOCKIST',
  DELETE_SUPER_STOCKIST: 'DELETE SUPER STOCKIST',

  CREATE_DISTRIBUTOR: 'CREATE DISTRIBUTOR',
  EDIT_DISTRIBUTOR: 'EDIT DISTRIBUTOR',
  DELETE_DISTRIBUTOR: 'DELETE DISTRIBUTOR',

  CREATE_WHOLESALER: 'CREATE WHOLESALER',
  EDIT_WHOLESALER: 'EDIT WHOLESALER',

  CREATE_DIRECT_RETAILER: 'CREATE DIRECT RETAILER',
  EDIT_DIRECT_RETAILER: 'EDIT DIRECT RETAILER',

  CREATE_RETAILER: 'CREATE RETAILER',
  EDIT_RETAILER: 'EDIT RETAILER',
  DELETE_RETAILER: 'DELETE RETAILER',

  CREATE_ADMIN_USER: 'CREATE ADMIN USER',
  EDIT_ADMIN_USER: 'EDIT ADMIN USER',
  DELETE_ADMIN_USER: 'DELETE ADMIN USER',

  CREATE_EMPLOYEE_REGIONAL_SALES_MANAGER: 'CREATE REGIONAL SALES MANAGER',
  EDIT_EMPLOYEE_REGIONAL_SALES_MANAGER: 'EDIT REGIONAL SALES MANAGER',
  DELETE_EMPLOYEE_REGIONAL_SALES_MANAGER: 'DELETE REGIONAL SALES MANAGER',

  CREATE_EMPLOYEE_AREA_SALES_MANAGER: 'CREATE AREA SALES MANAGER',
  EDIT_EMPLOYEE_AREA_SALES_MANAGER: 'EDIT AREA SALES MANAGER',
  DELETE_EMPLOYEE_AREA_SALES_MANAGER: 'DELETE AREA SALES MANAGER',

  CREATE_EMPLOYEE_SALES_OFFICER: 'CREATE SALES OFFICER',
  EDIT_EMPLOYEE_SALES_OFFICER: 'EDIT SALES OFFICER',
  DELETE_EMPLOYEE_SALES_OFFICER: 'DELETE SALES OFFICER',

  CREATE_EMPLOYEE_TERRITORY_SALES_INCHARGE: 'CREATE TERRITORY SALES INCHARGE',
  EDIT_EMPLOYEE_TERRITORY_SALES_INCHARGE: 'EDIT TERRITORY SALES INCHARGE',
  DELETE_EMPLOYEE_TERRITORY_SALES_INCHARGE: 'DELETE TERRITORY SALES INCHARGE'
};

const SKU = {
  SKU_NAME: 'Sku Name',
  PRODUCT_NAME: 'Product Name',
  BRAND_NAME: 'Brand Name',
  FRAGRANCE_NAME: 'Fragrance',
  QUANTIITY: 'Quantity',
  MRP: 'MRP',
  QUANTITY_PER_CARTON: 'Quantity/Carton',
  SPECIAL_PRICE: 'Special Price',
  SGST: 'SGST',
  CGST: 'CGST',
  IGST: 'IGST',
  SUPER_STOCKIST_MARGIN: 'Super Stockist Margin',
  DISTRIBUTOR_MARGIN: 'Distributor Margin',
  RETAILER_MARGIN: 'Retailer Margin',
  COMBO_SKU_NAME: 'Combo Sku Name',
  SKU: 'Sku'
};

const CUSTOMER_TYPES_CONSTANTS = {
  SUPER_STOCKIST: 'SUPER_STOCKIST',
  PRIMARY_DISTRIBUTOR: 'PRIMARY_DISTRIBUTOR',
  SECOND_PRIMARY_DISTRIBUTOR: 'SECOND_PRIMARY_DISTRIBUTOR',
  RETAILER: 'RETAILER'
};

const CUSTOMER_TYPES_TITLES = {
  SUPER_STOCKIST: 'PRIMARY SUPER STOCKIST',
  PRIMARY_DISTRIBUTOR: 'PRIMARY DISTRIBUTOR',
  SECOND_PRIMARY_DISTRIBUTOR: 'SECOND PRIMARY DISTRIBUTOR',
  RETAILER: 'SECONDARY RETAILER'
};

const CUSTOMER_TYPES = {
  SUPER_STOCKIST: 'Super Stockist',
  PRIMARY_DISTRIBUTOR: 'Distributor',
  SECOND_PRIMARY_DISTRIBUTOR: 'Distributor',
  RETAILER: 'Retailer'
};

const DISTRIBUTOR_DISTRIBUTION_CONSTANTS = {
  PRIMARY_DISTRIBUTOR: 'PRIMARY',
  SECOND_PRIMARY_DISTRIBUTOR: 'SECOND_PRIMARY'
};

const DISTRIBUTOR_DISTRIBUTION_PICKER = {
  PRIMARY_DISTRIBUTOR: 'Primary',
  SECOND_PRIMARY_DISTRIBUTOR: 'Second Primary'
};

const DISTRIBUTION_TYPES_CONSTANTS = {
  SUPERSTOCKIST_DISTRIBUTOR_RETAILER: 'SUPERSTOCKIST_DISTRIBUTOR_RETAILER',
  DISTRIBUTOR_RETAILER: 'DISTRIBUTOR_RETAILER',
  WHOLESALE_RETAILER: 'WHOLESALE_RETAILER',
  DIRECT_RETAILER: 'DIRECT_RETAILER'
};

const DISTRIBUTION_TYPES_PICKER = {
  SUPERSTOCKIST_DISTRIBUTOR_RETAILER: 'SuperStockist - Distributor - Retailer',
  DISTRIBUTOR_RETAILER: 'Distributor - Retailer'
};

const DISTRIBUTION_TYPES_TITLE = {
  SUPERSTOCKIST_DISTRIBUTOR_RETAILER:
    'SuperStockist - Distributor - Retailer Landed Prices',
  DISTRIBUTOR_RETAILER: 'Distributor - Retailer Landed Prices',
  WHOLESALE_RETAILER: 'WholeSeller-Retailer Landed Prices',
  DIRECT_RETAILER: 'Direct Retailer Landed Prices'
};

const LANDED_PRICES_CONSTANTS = {
  COMPANY_BASE_PRICE: 'Base Price',
  SUPER_STOCKIST_LANDED_PRICE: 'Super Stockist Landed Price',
  DISTRIBUTOR_LANDED_PRICE: 'Distributor Landed Price',
  WHOLESELLER_LANDED_PRICE: 'WholeSeller Landed Price',
  RETAILER_LANDED_PRICE: 'Retailer Landed Price'
};

const RETAILER_TYPE = {
  SMALL_SHOP: 'Small Shop (Upto 100 Sq.ft)',
  MEDIUM_SHOP: 'Medium Shop (101-300 Sq.ft)',
  LARGE_SHOP: 'Large Shop (101-500 Sq.ft)',
  DEPARTMENTAL_STORE: 'Departmental Store',
  CHAIN_STORE: 'Chain Store'
};

const USER_ROLES = {
  ADMIN: 'Admin',
  BACKOFFICE_ADMIN: 'Backoffice Admin',
  GENERAL_MANAGER: 'General Manager',
  REGIONAL_SALES_MANAGER: 'Regional Sales Manager',
  AREA_SALES_MANAGER: 'Area Sales Manager',
  SALES_OFFICER: 'Sales Officer',
  TERRITORY_SALES_INCHARGE: 'Territory Sales Incharge'
};

const USER_ROLES_CONSTANTS = {
  ADMIN: 'ADMIN',
  BACKOFFICE_ADMIN: 'BACKOFFICE_ADMIN',
  GENERAL_MANAGER: 'GENERAL_MANAGER',
  REGIONAL_SALES_MANAGER: 'REGIONAL_SALES_MANAGER',
  AREA_SALES_MANAGER: 'AREA_SALES_MANAGER',
  SALES_OFFICER: 'SALES_OFFICER',
  TERRITORY_SALES_INCHARGE: 'TERRITORY_SALES_INCHARGE'
};

const ADMIN_USER_ROLES = {
  REGIONAL_SALES_MANAGER: 'General Manager',
  BACKOFFICE_ADMIN: 'Backoffice Admin'
};

const ADMIN_USER_ROLES_CONSTANTS = {
  REGIONAL_SALES_MANAGER: 'REGIONAL_SALES_MANAGER',
  BACKOFFICE_ADMIN: 'BACKOFFICE_ADMIN'
};

const MANAGING_DIRECTOR_CONSTANT = 'MANAGING_DIRECTOR';
const MANAGING_DIRECTOR = 'Managing Director';

const SEVERITY = {
  SUCCESS: 'SUCCESS',
  FAIL: 'FAIL'
};

const FORM_SUB_HEADER_TITLES = {
  ADDRESS: 'Address:',
  TERRITORY: 'Territory:',
  CONTACT: 'Contact:',
  BRANDS_DEALING_WITH: 'Brands Dealing With (optional):',
  ADDITIONAL_CONTACTS: 'Additional Contacts (optional):',
  DISTRIBUTOR: 'Distributor:',
  DISTRIBUTION: 'Distribution'
};

const BUTTON_CONSTANTS = {
  CANCEL: 'CANCEL',
  SAVE: 'SAVE',
  SIGN_IN: 'SIGN IN'
};

const PICKER_CONSTANTS = {
  ALL_PRODUCTS: 'ALL_PRODUCTS'
};

const PICKER_VALUES = {
  ALL_PRODUCTS: 'All Products'
};

const ORDER_TYPES_TEXT = {
  PRIMARY_ORDER: 'Primary Order',
  SECOND_PRIMARY_ORDER: 'Second-Primary Order',
  SECONDARY_ORDER: 'Secondary Order'
};

const ORDER_TYPES = {
  PRIMARY_ORDER: 'PRIMARY_ORDER',
  SECONDARY_ORDER: 'SECONDARY_ORDER',
  SECOND_PRIMARY_ORDER: 'SECOND_PRIMARY_ORDER'
};

const SKU_TYPES = {
  single: 'Single',
  combo: 'Combo'
};

const PRIMARY_ORDER_CONSTANTS = {
  ORDERED: 'ORDERED',
  ORDER_APPROVED: 'ORDER_APPROVED',
  ORDER_CANCELLED_BY_FACTORY: 'ORDER_CANCELLED_BY_FACTORY',
  ORDER_CANCELLED_BY_SUPER_STOCKIST: 'ORDER_CANCELLED_BY_SUPER_STOCKIST',
  ORDER_CANCELLED_BY_DISTRIBUTOR: 'ORDER_CANCELLED_BY_DISTRIBUTOR',
  REACHED_DESTINATION: 'REACHED_DESTINATION'
};

const PRIMARY_ORDER_STATUS = {
  ORDERED: 'New',
  ORDER_APPROVED: 'Order Approved By Factory',
  ORDER_CANCELLED_BY_FACTORY: 'Order Cancelled By Factory',
  ORDER_CANCELLED_BY_SUPER_STOCKIST: 'Order Cancelled By SS',
  ORDER_CANCELLED_BY_DISTRIBUTOR: 'Order Cancelled By Distributor',
  REACHED_DESTINATION: 'Reached Customer'
};

const SECOND_PRIMARY_ORDER_CONSTANTS = {
  ORDERED: 'ORDERED',
  ORDER_APPROVED: 'ORDER_APPROVED',
  ORDER_CANCELLED_BY_SUPER_STOCKIST: 'ORDER_CANCELLED_BY_SUPER_STOCKIST',
  ORDER_CANCELLED_BY_DISTRIBUTOR: 'ORDER_CANCELLED_BY_DISTRIBUTOR',
  REACHED_DESTINATION: 'REACHED_DESTINATION'
};

const SECOND_PRIMARY_ORDER_STATUS = {
  ORDERED: 'New',
  ORDER_APPROVED: 'Order Approved',
  ORDER_CANCELLED_BY_SUPER_STOCKIST: 'Order Cancelled By SS',
  ORDER_CANCELLED_BY_DISTRIBUTOR: 'Order Cancelled By Distributor',
  REACHED_DESTINATION: 'Reached Distributor'
};

const SECONDARY_ORDER_CONSTANTS = {
  ORDERED: 'ORDERED',
  ORDER_APPROVED: 'ORDER_APPROVED',
  ORDER_CANCELLED_BY_RETAILER: 'ORDER_CANCELLED_BY_RETAILER',
  ORDER_CANCELLED_BY_DISTRIBUTOR: 'ORDER_CANCELLED_BY_DISTRIBUTOR',
  REACHED_DESTINATION: 'REACHED_DESTINATION'
};

const SECONDARY_ORDER_STATUS = {
  ORDERED: 'New',
  ORDER_APPROVED: 'Order Approved',
  ORDER_CANCELLED_BY_RETAILER: 'Order Cancelled By Retailer',
  ORDER_CANCELLED_BY_DISTRIBUTOR: 'Order Cancelled By Distributor',
  REACHED_DESTINATION: 'Reached Retailer'
};

const EMPLOYEE_SALES_ACTIVITIES = {
  DISTRIBUTOR_ISSUE: 'Distributor Issue',
  RETAIL_SHOP_CLOSED: 'Retail Shop Closed',
  ALREADY_STOCK_AVAILABLE: 'Already Stock Available',
  NEXT_VISIT_ORDER: 'Next Visit Order',
  NOT_INTERESTED: 'Not Interested',
  KEY_PERSON_NOT_AVAILABLE: 'Key Person Not Available',
  OTHERS: 'Others',
  NEW_SECONDARY_ORDER: 'New Order'
};

const ONLY_NUMBER_REGEX = /^[-+]?[0-9]\d*$/;
const DECIMAL_NUMBER_REGEX = /^\d*\.?\d*$/;

module.exports = {
  BUTTON_ACTIONS_CONSTANTS,
  BUTTON_ACTIONS,
  INDIAN_CURRENCY,
  INR_SYMBOL,
  BRANDS,
  QUANTITY_UNITS,
  CUSTOMER_ROLES,
  TITLES,
  SKU,
  CUSTOMER_TYPES,
  CUSTOMER_TYPES_CONSTANTS,
  CUSTOMER_TYPES_TITLES,
  DISTRIBUTION_TYPES_CONSTANTS,
  DISTRIBUTION_TYPES_PICKER,
  DISTRIBUTOR_DISTRIBUTION_CONSTANTS,
  DISTRIBUTOR_DISTRIBUTION_PICKER,
  DISTRIBUTION_TYPES_TITLE,
  LANDED_PRICES_CONSTANTS,
  RETAILER_TYPE,
  USER_ROLES,
  ADMIN_USER_ROLES,
  USER_ROLES_CONSTANTS,
  ADMIN_USER_ROLES_CONSTANTS,
  MANAGING_DIRECTOR_CONSTANT,
  MANAGING_DIRECTOR,
  SEVERITY,
  FORM_SUB_HEADER_TITLES,
  BUTTON_CONSTANTS,
  PICKER_CONSTANTS,
  PICKER_VALUES,
  ORDER_TYPES_TEXT,
  ORDER_TYPES,
  SKU_TYPES,
  PRIMARY_ORDER_CONSTANTS,
  PRIMARY_ORDER_STATUS,
  SECOND_PRIMARY_ORDER_CONSTANTS,
  SECOND_PRIMARY_ORDER_STATUS,
  SECONDARY_ORDER_CONSTANTS,
  SECONDARY_ORDER_STATUS,
  ONLY_NUMBER_REGEX,
  DECIMAL_NUMBER_REGEX,
  EMPLOYEE_SALES_ACTIVITIES
};
