import React, { useState } from 'react';
import FormInput from '../FormInput';
import formStyles from '../Styles/FormStyles';
import FormCancelButton from '../FormCancelButton';
import FormSubmitButton from '../FormSubmitButton';
import useForm from '../../shared/Hooks/useForm';
import DistrictFormValidator from '../../pages/District/DistrictFormValidator';
import FormAutoComplete from '../FormAutoComplete';
import {
  getFilteredAreasPayload,
  getFilteredDistrictsPayload
} from '../../shared/Utils/CommonUtils';

function CreateBeatForm({
  onSubmit,
  onCancel,
  zones = [],
  districts = [],
  areas = []
}) {
  const classes = formStyles();

  const [zoneItem, setZoneItem] = useState(null);

  const [districtItem, setDistrictItem] = useState(null);

  const [areaItem, setAreaItem] = useState(null);

  const normalizeFormData = () => {
    return {
      name: values.name,
      zone: zoneItem.id,
      district: districtItem.id,
      area: areaItem.id
    };
  };

  const onChangeZoneSelection = (e, value) => {
    e.persist();
    setZoneItem(value);
    setDistrictItem(null);
    setAreaItem(null);
  };

  const onChangeDistrictSelection = (e, value) => {
    e.persist();
    setDistrictItem(value);
    setAreaItem(null);
  };

  const onChangeAreaSelection = (e, value) => {
    e.persist();
    setAreaItem(value);
  };

  const handleFormSubmit = () => {
    onSubmit(normalizeFormData(), resetValues);
  };

  const { values, errors, handleChange, handleSubmit, resetValues } = useForm(
    handleFormSubmit,
    DistrictFormValidator
  );

  const isValidForm = () => {
    return values.name && zoneItem && districtItem && areaItem;
  };

  return (
    <form className={classes.form} noValidate autoComplete="off">
      <FormAutoComplete
        data={zones}
        label="Select Zone"
        name="zone"
        value={zoneItem}
        onChangeSelect={(e, newValue) => onChangeZoneSelection(e, newValue)}
        property="name"
        autoFocus
        required
      />

      <FormAutoComplete
        data={getFilteredDistrictsPayload(districts, zoneItem)}
        label="Select District"
        name="district"
        value={districtItem}
        onChangeSelect={(e, newValue) => onChangeDistrictSelection(e, newValue)}
        property="name"
        required
      />

      <FormAutoComplete
        data={getFilteredAreasPayload(areas, districtItem)}
        label="Select Area"
        name="area"
        value={areaItem}
        onChangeSelect={(e, newValue) => onChangeAreaSelection(e, newValue)}
        property="name"
        required
      />

      <FormInput
        type="text"
        label="Beat"
        placeholder="Enter a Beat"
        name="name"
        value={values.name || ''}
        onChange={handleChange}
        helperText={errors.name || ''}
        error={!!errors.name}
        required
      />

      <div className={classes.buttonRow}>
        <FormCancelButton onClickCancel={onCancel} />
        <FormSubmitButton
          onClickSubmit={handleSubmit}
          disabled={!isValidForm()}
        />
      </div>
    </form>
  );
}

export default React.memo(CreateBeatForm);
