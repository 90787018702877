import React from 'react';
import { connect } from 'react-redux';
import { Typography } from '@material-ui/core';
import OrderSkuTable from '../../../components/OrderSkuTable';
import { fetchSecondPrimaryOrder } from '../../../redux/second-primary-order/second-primary-order.actions';
import {
  getSecondPrimaryOrderById,
  isSecondPrimaryOrderRequestPending
} from '../../../redux/second-primary-order/second-primary-order.selector';
import useRefreshFetchReducerWithId from '../../../shared/Hooks/useRefreshFetchReducerWithId';
import {
  ORDER_TYPES_TEXT,
  SECOND_PRIMARY_ORDER_STATUS
} from '../../../shared/Constants/Constant';

function ViewSecondPrimaryOrder(props) {
  const {
    match: {
      params: { id: orderId }
    },
    fetchSecondPrimaryOrderConnect,
    orderDetails,
    isLoading
  } = props;

  useRefreshFetchReducerWithId(fetchSecondPrimaryOrderConnect, orderId);

  return (
    <>
      <Typography
        variant="h5"
        align="center"
        style={{ marginTop: 10, marginBottom: 10 }}
      >
        {`${ORDER_TYPES_TEXT.PRIMARY_ORDER} - ${orderDetails.invoice_number}`}
      </Typography>

      <Typography
        variant="h5"
        align="center"
        style={{ marginTop: 10, marginBottom: 10 }}
      >
        {`From - ${orderDetails.distributor.name}`}
      </Typography>

      <Typography
        variant="h5"
        align="center"
        style={{ marginTop: 10, marginBottom: 10 }}
      >
        {`To - ${orderDetails.super_stockist.name}`}
      </Typography>

      <Typography
        variant="h5"
        align="center"
        style={{ marginTop: 10, marginBottom: 10, color: '#d50000' }}
      >
        {`Status - ${SECOND_PRIMARY_ORDER_STATUS[orderDetails.status]}`}
      </Typography>

      <OrderSkuTable order={orderDetails} isOrderLoading={isLoading} />
    </>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    orderDetails: getSecondPrimaryOrderById(state, ownProps),
    isLoading: isSecondPrimaryOrderRequestPending(state)
  };
};

const mapDispatchToProps = {
  fetchSecondPrimaryOrderConnect: fetchSecondPrimaryOrder
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewSecondPrimaryOrder);
