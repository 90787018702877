import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Avatar,
  InputAdornment,
  CircularProgress
} from '@material-ui/core';
import MaterialButton from '../../../../components/MaterialButton';
import { FiberNew as FiberNewIcon } from '@material-ui/icons';
import FormInput from '../../../../components/FormInput';
import { ONLY_NUMBER_REGEX } from '../../../../shared/Constants/Constant';
import commonStyles from '../../../../Styles/CommonStyles';
import { connect } from 'react-redux';
import { editDistributorInventory } from '../../../../redux/distributor/distributor.actions';
import { isDistributorInventoryRequestPending } from '../../../../redux/distributor/distributor.selector';
import { isEqual } from 'lodash';

function DistributorInventoryEditItemForm(props) {
  const {
    open,
    handleClose,
    errorMessage,
    isLoading,
    editDistributorInventoryConnect,
    customerId,
    skuDetails
  } = props;
  const classes = commonStyles();
  const [quantityInPcs, setQuantityInPcs] = useState(
    skuDetails.current_inventory_level
  );

  const isRequestPending = isLoading;

  useEffect(() => {
    setQuantityInPcs(props.skuDetails.current_inventory_level);
  }, [props.skuDetails.current_inventory_level]);

  const normalizeForm = () => {
    return {
      current_inventory_level: quantityInPcs
    };
  };

  const handleModalClose = () => {
    setQuantityInPcs(props.skuDetails.current_inventory_level);
    handleClose();
  };

  const formSubmit = () => {
    editDistributorInventoryConnect(
      customerId,
      skuDetails.inventory_id,
      normalizeForm(),
      handleModalClose
    );
  };

  const handleQuantityInPcsChange = (event) => {
    const { value } = event.target;

    if (value === '' || value.match(ONLY_NUMBER_REGEX) != null) {
      setQuantityInPcs(value);
    }
  };

  const disableNext = () => {
    return isEqual(
      Number(skuDetails.current_inventory_level),
      Number(quantityInPcs)
    );
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleModalClose}
        classes={{
          paperWidthSm: classes.paperWidthSm
        }}
      >
        <DialogTitle id="form-dialog-title">
          <Avatar className={classes.avatar}>
            <FiberNewIcon color="primary" />
          </Avatar>
          <Typography align="center">
            Distributor Inventory - Edit Sku Item
          </Typography>
        </DialogTitle>
        {isRequestPending && (
          <div className={classes.modalSpinner}>
            <CircularProgress />
          </div>
        )}
        <DialogContent>
          <FormInput
            type="text"
            label="Sku Name"
            name="name"
            value={skuDetails.name || ''}
            disabled
          />

          <FormInput
            type="number"
            label="Current Stock"
            name="quantityInPcs"
            value={quantityInPcs || ''}
            onChange={handleQuantityInPcsChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">pcs</InputAdornment>
              )
            }}
          />

          {!!errorMessage && (
            <Typography variant="h5" style={{ color: '#d50000', fontSize: 13 }}>
              {`**${errorMessage}`}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <MaterialButton handleClick={handleModalClose} buttonText="Cancel" />
          <MaterialButton
            handleClick={formSubmit}
            disabled={disableNext()}
            buttonText="Confirm"
          />
        </DialogActions>
      </Dialog>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    isLoading: isDistributorInventoryRequestPending(state)
  };
};

const mapDispatchToProps = {
  editDistributorInventoryConnect: editDistributorInventory
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DistributorInventoryEditItemForm);
