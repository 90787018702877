import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import useRefreshFetchReducer from '../../shared/Hooks/useRefreshFetchReducer';
import CreateToolBar from '../../shared/CreateToolBar';
import ZoneTable from '../../components/ZoneTable';
import { ROUTE_TO_CREATE_ZONE } from '../../shared/Constants/RouteConstants';
import { TITLES } from '../../shared/Constants/Constant';
import { fetchAllZones } from '../../redux/zone/zone.actions';
import {
  getAllZones,
  isZoneRequestPending
} from '../../redux/zone/zone.selector';
import { getCancelledState } from '../../redux/event/event.selector';
import { resetCancel } from '../../redux/event/event.actions';
import { currentUserRole, currentUserId } from '../../redux/auth/auth.selector';
import { ZONE_CREATE_ACTION_ALLOWED_USERS } from '../../shared/Constants/UserPermissons';

const useStyles = makeStyles((theme) => ({
  content: {
    marginTop: theme.spacing(2)
  }
}));

function Zone(props) {
  const {
    fetchAllZonesConnect,
    zonesPayload,
    isZoneLoading,
    resetCancelConnect,
    isCancelledEvent,
    authUserRole,
    authUserId
  } = props;
  const classes = useStyles();

  useRefreshFetchReducer(
    fetchAllZonesConnect,
    resetCancelConnect,
    isCancelledEvent
  );

  return (
    <>
      <CreateToolBar
        route={ROUTE_TO_CREATE_ZONE}
        buttonText={TITLES.CREATE_ZONE}
        showSpinner={isZoneLoading}
        isAuthenticatedToAdd={ZONE_CREATE_ACTION_ALLOWED_USERS.includes(
          authUserRole
        )}
      />
      <div className={classes.content}>
        <ZoneTable
          zones={zonesPayload}
          isZoneLoading={isZoneLoading}
          currentUserRole={authUserRole}
          currentUserId={authUserId}
        />
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    isZoneLoading: isZoneRequestPending(state),
    zonesPayload: getAllZones(state),
    isCancelledEvent: getCancelledState(state),
    authUserRole: currentUserRole(state),
    authUserId: currentUserId(state)
  };
};

const mapDispatchToProps = {
  fetchAllZonesConnect: fetchAllZones,
  resetCancelConnect: resetCancel
};

Zone.defaultProps = {
  zonesPayload: []
};

export default connect(mapStateToProps, mapDispatchToProps)(Zone);
