import React, { useState } from 'react';
import { connect } from 'react-redux';
import {
  fetchAllSuperStockists,
  deleteSuperStockist
} from '../../../redux/superStockist/superStockist.actions';
import useFetchReducer from '../../../shared/Hooks/useFetchReducer';
import Modal from '../../../components/Modal';
import history from '../../../routes/history';
import { TITLES } from '../../../shared/Constants/Constant';
import {
  getAllSuperStockists,
  isSuperStockistRequestPending,
  getSuperStockistById
} from '../../../redux/superStockist/superStockist.selector';
import { ROUTE_TO_SUPER_STOCKIST } from '../../../shared/Constants/RouteConstants';
import BackdropWithLoader from '../../../components/BackdropWithLoader';
import { setCancel } from '../../../redux/event/event.actions';

function DeleteSuperStockist(props) {
  const [showDeleteModal, setShowDeleteModal] = useState(true);
  const {
    fetchAllSuperStockistsConnect,
    superStockistsPayload,
    deleteSuperStockistConnect,
    isSuperStockistLoading,
    superStockist = {},
    match: {
      params: { id }
    },
    setCancelConnect
  } = props;
  const superStockistId = id;

  useFetchReducer(fetchAllSuperStockistsConnect, superStockistsPayload);

  const handleDelete = async () => {
    await deleteSuperStockistConnect(superStockistId);
    setShowDeleteModal(false);
  };

  const handleCancel = () => {
    setShowDeleteModal(false);
    setCancelConnect();
    history.push(ROUTE_TO_SUPER_STOCKIST);
  };

  const actions = [
    {
      buttonText: 'Cancel',
      buttonAction: handleCancel,
      buttonStyle: { backgroundColor: '#4caf50', color: '#fff' }
    },
    {
      buttonText: 'Delete',
      buttonAction: handleDelete,
      buttonStyle: { backgroundColor: '#d50000', color: '#fff' }
    }
  ];

  const modelContent = () => {
    const { name } = superStockist;
    if (!superStockist) {
      return 'Are you sure you want to delete this SuperStockist?';
    }
    return `Are you sure you want 
      to delete ${name} SuperStockist?`;
  };

  if (isSuperStockistLoading) {
    return <BackdropWithLoader />;
  }

  return (
    <Modal
      title={TITLES.DELETE_SUPER_STOCKIST}
      isModalVisible={showDeleteModal}
      actions={actions}
      onClose={handleCancel}
      content={modelContent()}
    />
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    isSuperStockistLoading: isSuperStockistRequestPending(state),
    superStockistsPayload: getAllSuperStockists(state),
    superStockist: getSuperStockistById(state, ownProps)
  };
};

const mapDispatchToProps = {
  fetchAllSuperStockistsConnect: fetchAllSuperStockists,
  deleteSuperStockistConnect: deleteSuperStockist,
  setCancelConnect: setCancel
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeleteSuperStockist);
