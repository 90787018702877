export const FETCH_DISTRIBUTORS_REQUEST = 'FETCH_DISTRIBUTORS_REQUEST';
export const FETCH_DISTRIBUTOR_REQUEST = 'FETCH_DISTRIBUTOR_REQUEST';
export const FETCH_DISTRIBUTOR_INVENTORY_REQUEST =
  'FETCH_DISTRIBUTOR_INVENTORY_REQUEST';
export const CREATE_DISTRIBUTOR_REQUEST = 'CREATE_DISTRIBUTOR_REQUEST';
export const CREATE_DISTRIBUTOR_INVENTORY_REQUEST =
  'CREATE_DISTRIBUTOR_INVENTORY_REQUEST';
export const UPDATE_DISTRIBUTOR_REQUEST = 'UPDATE_DISTRIBUTOR_REQUEST';
export const UPDATE_DISTRIBUTOR_INVENTORY_REQUEST =
  'UPDATE_DISTRIBUTOR_INVENTORY_REQUEST';
export const DELETE_DISTRIBUTOR_REQUEST = 'DELETE_DISTRIBUTOR_REQUEST';

export const FETCH_DISTRIBUTORS_SUCCESS = 'FETCH_DISTRIBUTORS_SUCCESS';
export const FETCH_DISTRIBUTOR_SUCCESS = 'FETCH_DISTRIBUTOR_SUCCESS';
export const FETCH_DISTRIBUTOR_INVENTORY_SUCCESS =
  'FETCH_DISTRIBUTOR_INVENTORY_SUCCESS';
export const CREATE_DISTRIBUTOR_SUCCESS = 'CREATE_DISTRIBUTOR_SUCCESS';
export const CREATE_DISTRIBUTOR_INVENTORY_SUCCESS =
  'CREATE_DISTRIBUTOR_INVENTORY_SUCCESS';
export const UPDATE_DISTRIBUTOR_SUCCESS = 'UPDATE_DISTRIBUTOR_SUCCESS';
export const UPDATE_DISTRIBUTOR_INVENTORY_SUCCESS =
  'UPDATE_DISTRIBUTOR_INVENTORY_SUCCESS';
export const DELETE_DISTRIBUTOR_SUCCESS = 'DELETE_DISTRIBUTOR_SUCCESS';

export const FETCH_DISTRIBUTORS_ERROR = 'FETCH_DISTRIBUTORS_ERROR';
export const FETCH_DISTRIBUTOR_ERROR = 'FETCH_DISTRIBUTOR_ERROR';
export const FETCH_DISTRIBUTOR_INVENTORY_ERROR =
  'FETCH_DISTRIBUTOR_INVENTORY_ERROR';
export const CREATE_DISTRIBUTOR_ERROR = 'CREATE_DISTRIBUTOR_ERROR';
export const CREATE_DISTRIBUTOR_INVENTORY_ERROR =
  'CREATE_DISTRIBUTOR_INVENTORY_ERROR';
export const UPDATE_DISTRIBUTOR_ERROR = 'UPDATE_DISTRIBUTOR_ERROR';
export const UPDATE_DISTRIBUTOR_INVENTORY_ERROR =
  'UPDATE_DISTRIBUTOR_INVENTORY_ERROR';
export const DELETE_DISTRIBUTOR_ERROR = 'DELETE_DISTRIBUTOR_ERROR';
