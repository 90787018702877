export const FETCH_SKUS_REQUEST = 'FETCH_SKUS_REQUEST';
export const FETCH_SKU_REQUEST = 'FETCH_SKU_REQUEST';
export const CREATE_SKU_REQUEST = 'CREATE_SKU_REQUEST';
export const UPDATE_SKU_REQUEST = 'UPDATE_SKU_REQUEST';
export const DELETE_SKU_REQUEST = 'DELETE_SKU_REQUEST';

export const FETCH_SKUS_SUCCESS = 'FETCH_SKUS_SUCCESS';
export const FETCH_SKU_SUCCESS = 'FETCH_SKU_SUCCESS';
export const CREATE_SKU_SUCCESS = 'CREATE_SKU_SUCCESS';
export const UPDATE_SKU_SUCCESS = 'UPDATE_SKU_SUCCESS';
export const DELETE_SKU_SUCCESS = 'DELETE_SKU_SUCCESS';

export const FETCH_SKUS_ERROR = 'FETCH_SKUS_ERROR';
export const FETCH_SKU_ERROR = 'FETCH_SKU_ERROR';
export const CREATE_SKU_ERROR = 'CREATE_SKU_ERROR';
export const UPDATE_SKU_ERROR = 'UPDATE_SKU_ERROR';
export const DELETE_SKU_ERROR = 'DELETE_SKU_ERROR';
