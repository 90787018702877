import { size } from 'lodash';
import { createSelector } from 'reselect';

const getDistributorselector = (state) => state.distributors;
const getAllDistributorsSelector = (state) => state.distributors.data;
const getDistributorByIdSelector = (state, props) => props.match.params.id;

export const isDistributorRequestPending = createSelector(
  [getDistributorselector],
  (distributors) => distributors.isRequestPending
);

export const isDistributorInventoryRequestPending = createSelector(
  [getDistributorselector],
  (distributors) => distributors.isDistributorInventoryModalRequestPending
);

export const getAllDistributors = createSelector(
  [getAllDistributorsSelector],
  (distributors) => Object.values(distributors)
);

export const getDistributorById = createSelector(
  getAllDistributorsSelector,
  getDistributorByIdSelector,
  (distributors, id) => distributors[id]
);

export const getDistributorSkuInventory = createSelector(
  getAllDistributorsSelector,
  getDistributorByIdSelector,
  (distributors, id) => {
    const distributor = distributors[id] || {};

    if (size(distributor) > 0 && size(distributor.sku_items) > 0) {
      return Object.values(distributor.sku_items);
    }

    return [];
  }
);
