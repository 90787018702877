import React from 'react';
import { connect } from 'react-redux';
import CreateToolBar from '../../shared/CreateToolBar';
import DistributorTable from '../../components/DistributorTable';
import tableStyles from '../../components/Styles/TableStyles';
import useRefreshFetchReducer from '../../shared/Hooks/useRefreshFetchReducer';
import { ROUTE_TO_CREATE_DISTRIBUTOR } from '../../shared/Constants/RouteConstants';
import { TITLES } from '../../shared/Constants/Constant';
import { fetchAllDistributors } from '../../redux/distributor/distributor.actions';
import {
  getAllDistributors,
  isDistributorRequestPending
} from '../../redux/distributor/distributor.selector';
import { getCancelledState } from '../../redux/event/event.selector';
import { resetCancel } from '../../redux/event/event.actions';
import { currentUserRole, currentUserId } from '../../redux/auth/auth.selector';
import { DISTRIBUTOR_CREATE_ACTION_ALLOWED_USERS } from '../../shared/Constants/UserPermissons';

function Distributor(props) {
  const classes = tableStyles();
  const {
    fetchAllDistributorsConnect,
    isdistributorLoading,
    distributorsPayload,
    resetCancelConnect,
    isCancelledEvent,
    authUserRole,
    authUserId
  } = props;

  useRefreshFetchReducer(
    fetchAllDistributorsConnect,
    resetCancelConnect,
    isCancelledEvent
  );

  return (
    <>
      <CreateToolBar
        route={ROUTE_TO_CREATE_DISTRIBUTOR}
        buttonText={TITLES.CREATE_DISTRIBUTOR}
        showSpinner={isdistributorLoading}
        isAuthenticatedToAdd={DISTRIBUTOR_CREATE_ACTION_ALLOWED_USERS.includes(
          authUserRole
        )}
      />
      <div className={classes.content}>
        <DistributorTable
          distributors={distributorsPayload}
          isdistributorLoading={isdistributorLoading}
          currentUserRole={authUserRole}
          currentUserId={authUserId}
        />
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    distributorsPayload: getAllDistributors(state),
    isdistributorLoading: isDistributorRequestPending(state),
    isCancelledEvent: getCancelledState(state),
    authUserRole: currentUserRole(state),
    authUserId: currentUserId(state)
  };
};

const mapDispatchToProps = {
  fetchAllDistributorsConnect: fetchAllDistributors,
  resetCancelConnect: resetCancel
};

Distributor.defaultProps = {
  distributorsPayload: []
};

export default connect(mapStateToProps, mapDispatchToProps)(Distributor);
