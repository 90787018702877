import React from 'react';
import FormInput from '../FormInput';
import formStyles from '../Styles/FormStyles';
import FormCancelButton from '../FormCancelButton';
import FormSubmitButton from '../FormSubmitButton';
import useForm from '../../shared/Hooks/useForm';
import ZoneFormValidator from '../../pages/Zone/ZoneFormValidator';
import { isEqual, size } from 'lodash';

function EditZoneForm({ zone, onSubmit, onCancel }) {
  const classes = formStyles();

  const normalizeFormValues = () => {
    const updatedValues = {};

    if (values.name && !isEqual(values.name, zone.name)) {
      updatedValues.name = values.name;
    }

    return updatedValues;
  };

  const handleFormSubmit = () => {
    onSubmit(normalizeFormValues(), resetValues);
  };

  const { values, errors, handleChange, handleSubmit, resetValues } = useForm(
    handleFormSubmit,
    ZoneFormValidator,
    {
      initialValues: {
        name: zone.name
      }
    }
  );

  return (
    <form className={classes.form} noValidate autoComplete="off">
      <FormInput
        type="text"
        label="Zone"
        placeholder="Enter a Zone"
        name="name"
        value={values.name || ''}
        onChange={handleChange}
        helperText={errors.name || ''}
        error={!!errors.name}
        required
        autoFocus
      />

      <div className={classes.buttonRow}>
        <FormCancelButton onClickCancel={onCancel} />
        <FormSubmitButton
          onClickSubmit={handleSubmit}
          disabled={!size(normalizeFormValues()) > 0}
        />
      </div>
    </form>
  );
}

export default React.memo(EditZoneForm);
