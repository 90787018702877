import React from 'react';
import { CssBaseline } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Navbar from '../../components/Navbar/index';
import ErrorBoundary from '../../components/ErrorBoundary';
import Error from '../../components/Error';
import SuccessNotification from '../../components/SuccessNotification';
import Alerts from '../../shared/Alerts';

const useStyles = makeStyles((theme) => ({
  offset: theme.mixins.toolbar,
  root: {
    flexGrow: 1,
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',
    width: '100%'
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
    overflowX: 'auto'
  }
}));

function AppBarOffset() {
  const classes = useStyles();
  return <div className={classes.offset} />;
}

function MinimalLayout(props) {
  const classes = useStyles();
  const { children } = props;
  return (
    <div className={classes.root}>
      <Error />
      <SuccessNotification />
      <CssBaseline />
      <Navbar />
      <AppBarOffset />
      <main className={classes.content}>
        <div className={classes.offset} />
        <Alerts />
        <ErrorBoundary>{children}</ErrorBoundary>
      </main>
    </div>
  );
}

MinimalLayout.propTypes = {
  children: PropTypes.element.isRequired
};

export default MinimalLayout;
