import baseURL from '../shared/AxiosBaseUrl/baseURL';

class DistributorApi {
  static getAllDistributors(queryParams) {
    return baseURL.get('/api/v1/distributor/', {
      params: queryParams
    });
  }

  static getDistributor(id) {
    return baseURL.get(`/api/v1/distributor/${id}`);
  }

  static getDistributorInventory(id) {
    return baseURL.get(`/api/v1/distributor/${id}/inventory`);
  }

  static createDistributor(formValues) {
    return baseURL.post('/api/v1/distributor/', { ...formValues });
  }

  static createDistributorInventory(distributorId, formValues) {
    return baseURL.post(`/api/v1/distributor/${distributorId}/inventory`, {
      ...formValues
    });
  }

  static editDistributor(id, formValues) {
    return baseURL.patch(`/api/v1/distributor/${id}`, { ...formValues });
  }

  static editDistributorInventory(distributorId, inventoryId, formValues) {
    return baseURL.patch(
      `/api/v1/distributor/${distributorId}/inventory/${inventoryId}`,
      {
        ...formValues
      }
    );
  }

  static deleteDistributor(id) {
    return baseURL.delete(`/api/v1/distributor/${id}`);
  }
}

export default DistributorApi;
