import React, { useState } from 'react';
import { connect } from 'react-redux';
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper
} from '@material-ui/core';
import { StyledTableCell, tableStyles } from '../../../../Styles/TableStyles';
import MaterialButton from '../../../../components/MaterialButton';
import {
  addItem,
  removeItem
} from '../../../../redux/second-primary-order/second-primary-order.actions';
import CreateSecondPrimaryOrderNewItemForm from '../CreateSecondPrimaryOrderNewItemForm';
import CreateSecondPrimaryOrderCartTableItem from '../CreateSecondPrimaryOrderCartTableItem';
import {
  getSecondPrimaryOrderCartItems,
  getFilteredSkusPayload,
  getSecondPrimaryOrderCartTotalQtyInPcs,
  getSecondPrimaryOrderTotalCostQuantity
} from '../../../../redux/second-primary-order/second-primary-order.selector';
import { size } from 'lodash';

function CreateSecondPrimaryOrderCartTable(props) {
  const {
    skusPayload,
    addSkuItemConnect,
    cartItems,
    removeItemConnect,
    filteredSkusPayload,
    totalQtyInPcs,
    totalCost,
    customer
  } = props;

  const [openSkuForm, setOpenSkuForm] = useState(false);

  const handleClickOpen = () => {
    setOpenSkuForm(true);
  };

  const handleClose = () => {
    setOpenSkuForm(false);
  };

  const classes = tableStyles();

  const getTotalSkusText = () => {
    const totalSku = size(cartItems);
    return `${totalSku} ${totalSku > 1 ? 'Skus' : 'Sku'}`;
  };

  const getSku = (cartItem) => {
    return skusPayload.find((sku) => sku.id === cartItem.skuId) || {};
  };

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell align="center">
              Parent Product Name
            </StyledTableCell>
            <StyledTableCell align="center">Sku</StyledTableCell>
            <StyledTableCell align="center">Pcs Qty</StyledTableCell>
            <StyledTableCell align="center">Margin</StyledTableCell>
            <StyledTableCell align="center">Price/pc</StyledTableCell>
            <StyledTableCell align="center">Total Price</StyledTableCell>
            <StyledTableCell align="center">Remove</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {cartItems.map((cartItem, index) => {
            return (
              <CreateSecondPrimaryOrderCartTableItem
                key={cartItem.skuId}
                skuItemIndex={index}
                cartItem={cartItem}
                handleRemoveSku={removeItemConnect}
                skuDetails={getSku(cartItem)}
                addSkuItem={addSkuItemConnect}
              />
            );
          })}
          {size(cartItems) > 0 && (
            <TableRow>
              <StyledTableCell align="center">Totals:</StyledTableCell>
              <StyledTableCell align="center">
                {getTotalSkusText()}
              </StyledTableCell>
              <StyledTableCell align="center">{totalQtyInPcs}</StyledTableCell>
              <StyledTableCell align="center"></StyledTableCell>
              <StyledTableCell align="center"></StyledTableCell>
              <StyledTableCell align="center">{totalCost}</StyledTableCell>
            </TableRow>
          )}
          <TableRow>
            <StyledTableCell align="center" colSpan={9}>
              <CreateSecondPrimaryOrderNewItemForm
                skusPayload={filteredSkusPayload}
                open={openSkuForm}
                handleClose={handleClose}
                addSkuItem={addSkuItemConnect}
                customer={customer}
              />
              <MaterialButton
                onClick={handleClickOpen}
                buttonText="+ Add a new Sku"
                margin="normal"
                size="small"
              />
            </StyledTableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}

const mapStateToProps = (state) => {
  return {
    cartItems: getSecondPrimaryOrderCartItems(state),
    totalQtyInPcs: getSecondPrimaryOrderCartTotalQtyInPcs(state),
    totalCost: getSecondPrimaryOrderTotalCostQuantity(state),
    filteredSkusPayload: getFilteredSkusPayload(state)
  };
};

const mapDispatchToProps = {
  addSkuItemConnect: addItem,
  removeItemConnect: removeItem
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateSecondPrimaryOrderCartTable);
