import baseURL from '../shared/AxiosBaseUrl/baseURL';

class ProductApi {
  static getAllProducts(queryParams) {
    return baseURL.get('/api/v1/parent-product/', {
      params: queryParams
    });
  }

  static getProduct(id) {
    return baseURL.get(`/api/v1/parent-product/${id}`);
  }

  static createProduct(formValues) {
    return baseURL.post('/api/v1/parent-product/', { ...formValues });
  }

  static editProduct(id, formValues) {
    return baseURL.patch(`/api/v1/parent-product/${id}`, { ...formValues });
  }

  static deleteProduct(id) {
    return baseURL.delete(`/api/v1/parent-product/${id}`);
  }
}

export default ProductApi;
