import React, { useState } from 'react';
import {
  tableStyles,
  StyledTableCell,
  StyledTableRow
} from '../../../../Styles/TableStyles';
import { EditOutlined, DeleteOutlineOutlined } from '@material-ui/icons';
import { IconButton, Typography } from '@material-ui/core';
import CreateSecondaryOrderEditItemForm from '../CreateSecondaryOrderEditItemForm';
import { formatInInr } from '../../../../shared/Utils/CommonUtils';

function CreateSecondaryOrderCartTableItem(props) {
  const { cartItem, handleRemoveSku, addSkuItem, skuDetails } = props;
  const [openEditSkuForm, setOpenEditSkuForm] = useState(false);

  const handleClickOpen = () => {
    setOpenEditSkuForm(true);
  };

  const handleClose = () => {
    setOpenEditSkuForm(false);
  };

  const classes = tableStyles();

  return (
    <StyledTableRow>
      <StyledTableCell align="center">
        <div className={classes.truncateText}>
          <Typography align="center" display="block" noWrap variant="subtitle2">
            {cartItem.parentProductName}
          </Typography>
        </div>
      </StyledTableCell>
      <StyledTableCell align="center">{cartItem.name}</StyledTableCell>
      <StyledTableCell align="center">
        {cartItem.quantityInPcs || 0}
      </StyledTableCell>
      <StyledTableCell align="center">
        {`${cartItem.customizedMargin}%`}
      </StyledTableCell>
      <StyledTableCell align="center">
        {formatInInr(cartItem.retailerLandedPrice)}
      </StyledTableCell>
      <StyledTableCell align="center">
        {formatInInr(cartItem.totalSkuCost)}
      </StyledTableCell>
      <StyledTableCell align="center">
        <CreateSecondaryOrderEditItemForm
          open={openEditSkuForm}
          handleClose={handleClose}
          addSkuItem={addSkuItem}
          skuDetails={skuDetails}
          cartItem={cartItem}
        />
        <IconButton onClick={handleClickOpen}>
          <EditOutlined />
        </IconButton>
        <IconButton onClick={() => handleRemoveSku(cartItem.skuId)}>
          <DeleteOutlineOutlined />
        </IconButton>
      </StyledTableCell>
    </StyledTableRow>
  );
}

export default CreateSecondaryOrderCartTableItem;
