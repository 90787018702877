import React from 'react';
import moment from 'moment';
import 'antd/dist/antd.css';
import { DatePicker, Space } from 'antd';
const dateFormat = 'DD-MMM-YYYY';

function AntdDatePicker(props) {
  const {
    date,
    handleSelectedDate,
    disableFutureDates = true,
    enableDatesAfter,
    disablePreviousDates = false,
    ...rest
  } = props;

  const disabledDate = (current) => {
    if (disableFutureDates) {
      return current && current > moment().endOf('day');
    }

    if (disablePreviousDates) {
      return current && current < enableDatesAfter;
    }

    return false;
  };

  const formatSelectedDates = (changedDate) => {
    if (!changedDate) {
      return handleSelectedDate(moment().startOf('day'));
    }

    handleSelectedDate(changedDate.startOf('day'));
  };

  return (
    <Space direction="vertical" size={20}>
      <DatePicker
        value={date}
        format={dateFormat}
        size="large"
        allowClear
        disabledDate={disabledDate}
        onChange={formatSelectedDates}
        {...rest}
      />
    </Space>
  );
}

export default AntdDatePicker;
