import React from 'react';
import { Button, CircularProgress } from '@material-ui/core';

function MaterialButton(props) {
  const {
    handleClick,
    buttonText,
    variant,
    fullWidth = false,
    loading = false,
    ...rest
  } = props;
  return (
    <Button
      color="primary"
      onClick={handleClick}
      fullWidth={fullWidth}
      variant={variant}
      {...rest}
    >
      {loading && (
        <CircularProgress
          size={16}
          color="secondary"
          style={{ marginRight: 2 }}
        />
      )}
      {buttonText}
    </Button>
  );
}

export default MaterialButton;
