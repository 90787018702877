import { useEffect, useCallback } from 'react';

const useRefreshFetchReducerWithId = (
  effect,
  id,
  cancelledEffect,
  isCancel = false
) => {
  const effectFunction = useCallback(async (id) => {
    if (isCancel) {
      cancelledEffect();
    } else {
      await effect(id);
    }
  }, []);

  useEffect(() => {
    effectFunction(id);
  }, [effectFunction]);
};

export default useRefreshFetchReducerWithId;
