import React from 'react';
import { connect } from 'react-redux';
import CreateToolBar from '../../shared/CreateToolBar';
import DistrictTable from '../../components/DistrictTable';
import tableStyles from '../../components/Styles/TableStyles';
import useRefreshFetchReducer from '../../shared/Hooks/useRefreshFetchReducer';
import { ROUTE_TO_CREATE_DISTRICT } from '../../shared/Constants/RouteConstants';
import { TITLES } from '../../shared/Constants/Constant';
import { fetchAllDistricts } from '../../redux/district/district.actions';
import {
  getAllDistricts,
  isDistrictRequestPending
} from '../../redux/district/district.selector';
import { getCancelledState } from '../../redux/event/event.selector';
import { resetCancel } from '../../redux/event/event.actions';
import { currentUserRole, currentUserId } from '../../redux/auth/auth.selector';
import { DISTRICT_CREATE_ACTION_ALLOWED_USERS } from '../../shared/Constants/UserPermissons';

function District(props) {
  const classes = tableStyles();
  const {
    fetchAllDistrictsConnect,
    districtsPayload,
    isDistrictLoading,
    resetCancelConnect,
    isCancelledEvent,
    authUserRole,
    authUserId
  } = props;

  useRefreshFetchReducer(
    fetchAllDistrictsConnect,
    resetCancelConnect,
    isCancelledEvent
  );

  return (
    <>
      <CreateToolBar
        route={ROUTE_TO_CREATE_DISTRICT}
        buttonText={TITLES.CREATE_DISTRICT}
        showSpinner={isDistrictLoading}
        isAuthenticatedToAdd={DISTRICT_CREATE_ACTION_ALLOWED_USERS.includes(
          authUserRole
        )}
      />
      <div className={classes.content}>
        <DistrictTable
          districts={districtsPayload}
          isDistrictLoading={isDistrictLoading}
          currentUserRole={authUserRole}
          currentUserId={authUserId}
        />
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    isDistrictLoading: isDistrictRequestPending(state),
    districtsPayload: getAllDistricts(state),
    isCancelledEvent: getCancelledState(state),
    authUserRole: currentUserRole(state),
    authUserId: currentUserId(state)
  };
};

const mapDispatchToProps = {
  fetchAllDistrictsConnect: fetchAllDistricts,
  resetCancelConnect: resetCancel
};

District.defaultProps = {
  districtsPayload: []
};

export default connect(mapStateToProps, mapDispatchToProps)(District);
