import React from 'react';
import { connect } from 'react-redux';
import tableStyles from '../../components/Styles/TableStyles';
import CreateToolBar from '../../shared/CreateToolBar';
import ParentProductTable from '../../components/ParentProductTable';
import { ROUTE_TO_CREATE_PARENT_PRODUCT } from '../../shared/Constants/RouteConstants';
import { TITLES } from '../../shared/Constants/Constant';
import { fetchAllProducts } from '../../redux/product/product.actions';
import {
  getAllProducts,
  isProductRequestPending
} from '../../redux/product/product.selector';
import useRefreshFetchReducer from '../../shared/Hooks/useRefreshFetchReducer';
import { resetCancel } from '../../redux/event/event.actions';
import { getCancelledState } from '../../redux/event/event.selector';
import { ONLY_ADMIN } from '../../shared/Constants/UserPermissons';
import { currentUserRole, currentUserId } from '../../redux/auth/auth.selector';

function ParentProduct(props) {
  const classes = tableStyles();
  const {
    productsPayload,
    isProductsLoading,
    fetchAllProductsConnect,
    resetCancelConnect,
    isCancelledEvent,
    authUserRole,
    authUserId
  } = props;

  useRefreshFetchReducer(
    fetchAllProductsConnect,
    resetCancelConnect,
    isCancelledEvent
  );

  return (
    <>
      <CreateToolBar
        route={ROUTE_TO_CREATE_PARENT_PRODUCT}
        buttonText={TITLES.CREATE_PARENT_PRODUCT}
        showSpinner={isProductsLoading}
        isAuthenticatedToAdd={ONLY_ADMIN === authUserRole}
      />
      <div className={classes.content}>
        <ParentProductTable
          parentProducts={productsPayload}
          isProductsLoading={isProductsLoading}
          currentUserRole={authUserRole}
          currentUserId={authUserId}
        />
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    productsPayload: getAllProducts(state),
    isProductsLoading: isProductRequestPending(state),
    isCancelledEvent: getCancelledState(state),
    authUserRole: currentUserRole(state),
    authUserId: currentUserId(state)
  };
};

const mapDispatchToProps = {
  fetchAllProductsConnect: fetchAllProducts,
  resetCancelConnect: resetCancel
};

export default connect(mapStateToProps, mapDispatchToProps)(ParentProduct);
