import React from 'react';
import moment from 'moment';
import 'antd/dist/antd.css';
import { DatePicker, Space } from 'antd';
const { RangePicker } = DatePicker;
const dateFormat = 'DD-MMM-YYYY';

function DateRangePicker(props) {
  const { selectedDates, handleSelectedDates, ...rest } = props;

  const disabledDate = (current) => {
    return current && current > moment().endOf('day');
  };

  const formatSelectedDates = (rangeValues) => {
    if (!rangeValues) {
      return handleSelectedDates([
        moment().startOf('day'),
        moment().endOf('day')
      ]);
    }

    if (!rangeValues.includes(undefined)) {
      const [momentStartDate, momentEndDate] = rangeValues;
      handleSelectedDates([
        momentStartDate.startOf('day'),
        momentEndDate.endOf('day')
      ]);
    }
  };

  return (
    <Space direction="vertical" size={20}>
      <RangePicker
        placeholder={['From', 'To']}
        size="large"
        format={dateFormat}
        onCalendarChange={formatSelectedDates}
        value={selectedDates}
        disabledDate={disabledDate}
        allowClear
        ranges={{
          Today: [moment().startOf('day'), moment().endOf('day')],
          Yesterday: [
            moment().subtract(1, 'days').startOf('day'),
            moment().subtract(1, 'days').endOf('day')
          ],
          'Last 7 days': [
            moment().subtract(6, 'days').startOf('day'),
            moment().endOf('day')
          ],
          'Last 15 days': [
            moment().subtract(14, 'days').startOf('day'),
            moment().endOf('day')
          ],
          'This Month': [moment().startOf('month'), moment().endOf('day')],
          'Last 1 Month': [
            moment().subtract(1, 'months'),
            moment().endOf('day')
          ],
          'Last 3 Months': [
            moment().subtract(3, 'months'),
            moment().endOf('day')
          ],
          'Last 6 Months': [
            moment().subtract(6, 'months'),
            moment().endOf('day')
          ],
          'Last 1 Year': [moment().subtract(1, 'years'), moment().endOf('day')]
        }}
        {...rest}
      />
    </Space>
  );
}

export default DateRangePicker;
