import React from 'react';
import {
  Table as MuiTable,
  TableBody,
  TableHead,
  TableRow,
  Paper,
  TableContainer
} from '@material-ui/core';
import tableStyles from '../Styles/TableStyles';
import StyledTableCell from '../Styles/StyledTableCell';
import { RETAILER_HEADER_CONSTANTS } from '../../shared/Constants/TableHeaderConstants';
import RetailerTableItem from './RetailerTableItem';

function RetailerTable(props) {
  const classes = tableStyles();
  const { retailers = [], isRetailerLoading } = props;

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.tableWithTopButtonContainer}>
        <MuiTable stickyHeader>
          <TableHead>
            <TableRow>
              {Object.keys(RETAILER_HEADER_CONSTANTS).map((header) => (
                <StyledTableCell align="center" key={header}>
                  {RETAILER_HEADER_CONSTANTS[header]}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {retailers.map((retailer) => {
              return (
                <RetailerTableItem
                  key={retailer.id}
                  retailer={retailer}
                  isRetailerLoading={isRetailerLoading}
                />
              );
            })}
          </TableBody>
        </MuiTable>
      </TableContainer>
    </Paper>
  );
}

export default React.memo(RetailerTable);
