import React from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import history from '../../../routes/history';
import { CUSTOMER_TYPES_CONSTANTS } from '../../../shared/Constants/Constant';
import {
  ROUTE_TO_HOME,
  ROUTE_TO_CREATE_PRIMARY_ORDER,
  ROUTE_TO_CREATE_SECOND_PRIMARY_ORDER,
  ROUTE_TO_CREATE_SECONDARY_ORDER
} from '../../../shared/Constants/RouteConstants';
import { loadPrimaryOrderCustomer } from '../../../redux/primary-order/primary-order.actions';
import { loadSecondPrimaryOrderCustomer } from '../../../redux/second-primary-order/second-primary-order.actions';
import { loadSecondaryOrderCustomer } from '../../../redux/secondary-order/secondary-order.actions';
import MaterialButton from '../../MaterialButton';

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
    backgroundColor: '#facd49',
    color: '#000036',
    '&:hover': {
      backgroundColor: '#ffad00'
    }
  }
}));

function PlaceOrder(props) {
  const { customerDetails, customerType, disabled } = props;
  const classes = useStyles();
  const dispatch = useDispatch();

  const getNavigationState = () => {
    switch (customerType) {
      case CUSTOMER_TYPES_CONSTANTS.SUPER_STOCKIST:
        dispatch(
          loadPrimaryOrderCustomer({
            ...customerDetails,
            customerType: CUSTOMER_TYPES_CONSTANTS.SUPER_STOCKIST
          })
        );
        return ROUTE_TO_CREATE_PRIMARY_ORDER;

      case CUSTOMER_TYPES_CONSTANTS.PRIMARY_DISTRIBUTOR:
        dispatch(
          loadPrimaryOrderCustomer({
            ...customerDetails,
            customerType: CUSTOMER_TYPES_CONSTANTS.PRIMARY_DISTRIBUTOR
          })
        );
        return ROUTE_TO_CREATE_PRIMARY_ORDER;

      case CUSTOMER_TYPES_CONSTANTS.SECOND_PRIMARY_DISTRIBUTOR:
        dispatch(
          loadSecondPrimaryOrderCustomer({
            ...customerDetails,
            customerType: CUSTOMER_TYPES_CONSTANTS.SECOND_PRIMARY_DISTRIBUTOR
          })
        );
        return ROUTE_TO_CREATE_SECOND_PRIMARY_ORDER;

      case CUSTOMER_TYPES_CONSTANTS.RETAILER:
        dispatch(
          loadSecondaryOrderCustomer({
            ...customerDetails,
            customerType: CUSTOMER_TYPES_CONSTANTS.RETAILER
          })
        );
        return ROUTE_TO_CREATE_SECONDARY_ORDER;

      default:
        return ROUTE_TO_HOME;
    }
  };

  const handlePlaceOrderAction = () => {
    history.push(getNavigationState());
  };

  return (
    <MaterialButton
      buttonText="Place Order"
      handleClick={handlePlaceOrderAction}
      disabled={disabled}
      className={classes.button}
    />
  );
}

export default PlaceOrder;
