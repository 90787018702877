import * as EventActionTypes from './event.types';

const initState = {
  cancelledState: false
};

const eventReducer = (state = initState, action) => {
  const { type } = action;
  switch (type) {
    case EventActionTypes.SET_CANCEL:
      return { ...state, cancelledState: true };

    case EventActionTypes.RESET_CANCEL:
      return { ...state, cancelledState: false };

    default:
      return state;
  }
};

export default eventReducer;
