import React from 'react';
import {
  Table as MuiTable,
  TableBody,
  TableHead,
  TableRow,
  Paper,
  TableContainer
} from '@material-ui/core';
import tableStyles from '../Styles/TableStyles';
import StyledTableCell from '../Styles/StyledTableCell';
import { size } from 'lodash';
import ParentProductItem from './ParentProductItem';
import { PARENT_PRODUCT_TABLE_HEADER_CONSTANTS } from '../../shared/Constants/TableHeaderConstants';

function ParentProductTable(props) {
  const classes = tableStyles();
  const { parentProducts = [] } = props;

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.tableWithTopButtonContainer}>
        <MuiTable stickyHeader>
          <TableHead>
            <TableRow>
              {Object.keys(PARENT_PRODUCT_TABLE_HEADER_CONSTANTS).map(
                (header) => (
                  <StyledTableCell
                    key={header}
                    style={{ position: 'sticky' }}
                    align="center"
                  >
                    {PARENT_PRODUCT_TABLE_HEADER_CONSTANTS[header]}
                  </StyledTableCell>
                )
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {size(parentProducts) > 0 ? (
              parentProducts.map((parentProduct, index) => (
                <ParentProductItem
                  key={parentProduct.id}
                  index={index}
                  parentProduct={parentProduct}
                />
              ))
            ) : (
              <TableRow>
                <StyledTableCell align="center" colSpan={8}>
                  No Parent Products Found in the database
                </StyledTableCell>
              </TableRow>
            )}
          </TableBody>
        </MuiTable>
      </TableContainer>
    </Paper>
  );
}

export default React.memo(ParentProductTable);
