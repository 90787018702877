import SkuApi from '../../apis/SkuAPI';
import * as SkuActionTypes from './sku.types';
import STATUS from '../../shared/Constants/apiStatus';
import {
  ROUTE_TO_SKU,
  ROUTE_TO_COMBO_SKU
} from '../../shared/Constants/RouteConstants';

export const fetchAllSkus = () => async (dispatch) => {
  dispatch({
    type: SkuActionTypes.FETCH_SKUS_REQUEST
  });

  try {
    const response = await SkuApi.getAllSkus();
    if (response.data.status === STATUS.OK) {
      dispatch({
        type: SkuActionTypes.FETCH_SKUS_SUCCESS,
        payload: response.data.skus,
        error: null,
        route: null,
        success: null
      });
    }
  } catch (error) {
    dispatch({
      type: SkuActionTypes.FETCH_SKUS_ERROR,
      error: error.response.data,
      payload: null,
      route: null,
      success: null
    });
  }
};

export const fetchSku = (id) => async (dispatch) => {
  dispatch({
    type: SkuActionTypes.FETCH_SKU_REQUEST
  });

  try {
    const response = await SkuApi.getSku(id);
    if (response.data.status === STATUS.OK) {
      dispatch({
        type: SkuActionTypes.FETCH_SKU_SUCCESS,
        payload: response.data.sku,
        error: null,
        route: null,
        success: null
      });
    }
  } catch (error) {
    dispatch({
      type: SkuActionTypes.FETCH_SKU_ERROR,
      error: error.response.data,
      payload: null,
      route: ROUTE_TO_SKU,
      success: null
    });
  }
};

export const fetchComboSku = (id) => async (dispatch) => {
  dispatch({
    type: SkuActionTypes.FETCH_SKU_REQUEST
  });

  try {
    const response = await SkuApi.getComboSku(id);
    if (response.data.status === STATUS.OK) {
      dispatch({
        type: SkuActionTypes.FETCH_SKU_SUCCESS,
        payload: response.data.sku,
        error: null,
        route: null,
        success: null
      });
    }
  } catch (error) {
    dispatch({
      type: SkuActionTypes.FETCH_SKU_ERROR,
      error: error.response.data,
      payload: null,
      route: ROUTE_TO_COMBO_SKU,
      success: null
    });
  }
};

export const createSku = (formValues) => async (dispatch) => {
  dispatch({
    type: SkuActionTypes.CREATE_SKU_REQUEST
  });

  try {
    const response = await SkuApi.createSku(formValues);

    if (response.data.status === STATUS.OK) {
      dispatch({
        type: SkuActionTypes.CREATE_SKU_SUCCESS,
        payload: response.data.sku,
        error: null,
        route: ROUTE_TO_SKU,
        success: response.data.message
      });
    }
  } catch (error) {
    dispatch({
      type: SkuActionTypes.CREATE_SKU_ERROR,
      error: error.response.data,
      payload: null,
      route: ROUTE_TO_SKU,
      success: null
    });
  }
};

export const createComboSku = (formValues) => async (dispatch) => {
  dispatch({
    type: SkuActionTypes.CREATE_SKU_REQUEST
  });

  try {
    const response = await SkuApi.createComboSku(formValues);

    if (response.data.status === STATUS.OK) {
      dispatch({
        type: SkuActionTypes.CREATE_SKU_SUCCESS,
        payload: response.data.sku,
        error: null,
        route: ROUTE_TO_COMBO_SKU,
        success: response.data.message
      });
    }
  } catch (error) {
    dispatch({
      type: SkuActionTypes.CREATE_SKU_ERROR,
      error: error.response.data,
      payload: null,
      route: ROUTE_TO_COMBO_SKU,
      success: null
    });
  }
};

export const editSku =
  (id, formValues, callback, routeName) => async (dispatch) => {
    dispatch({
      type: SkuActionTypes.UPDATE_SKU_REQUEST
    });

    try {
      const response = await SkuApi.editSku(id, formValues);

      if (response.data.status === STATUS.OK) {
        dispatch({
          type: SkuActionTypes.UPDATE_SKU_SUCCESS,
          payload: response.data.sku,
          error: null,
          route: routeName || ROUTE_TO_SKU,
          success: response.data.message
        });
      }

      if (callback) {
        callback();
      }
    } catch (error) {
      dispatch({
        type: SkuActionTypes.UPDATE_SKU_ERROR,
        error: error.response.data,
        payload: null,
        route: ROUTE_TO_SKU,
        success: null
      });
    }
  };

export const editComboSku = (id, formValues) => async (dispatch) => {
  dispatch({
    type: SkuActionTypes.UPDATE_SKU_REQUEST
  });

  try {
    const response = await SkuApi.editComboSku(id, formValues);
    if (response.data.status === STATUS.OK) {
      dispatch({
        type: SkuActionTypes.UPDATE_SKU_SUCCESS,
        payload: response.data.sku,
        error: null,
        route: ROUTE_TO_COMBO_SKU,
        success: response.data.message
      });
    }
  } catch (error) {
    dispatch({
      type: SkuActionTypes.UPDATE_SKU_ERROR,
      error: error.response.data,
      payload: null,
      route: ROUTE_TO_COMBO_SKU,
      success: null
    });
  }
};

export const deleteSku = (id) => async (dispatch) => {
  dispatch({
    type: SkuActionTypes.DELETE_SKU_REQUEST
  });

  try {
    const response = await SkuApi.deleteSku(id);
    if (response.data.status === STATUS.OK) {
      dispatch({
        type: SkuActionTypes.DELETE_SKU_SUCCESS,
        payload: id,
        error: null,
        route: ROUTE_TO_SKU,
        success: response.data.message
      });
    }
  } catch (error) {
    dispatch({
      type: SkuActionTypes.DELETE_SKU_ERROR,
      payload: null,
      error: error.response.data,
      route: ROUTE_TO_SKU,
      success: null
    });
  }
};

export const deleteComboSku = (id) => async (dispatch) => {
  dispatch({
    type: SkuActionTypes.DELETE_SKU_REQUEST
  });

  try {
    const response = await SkuApi.deleteComboSku(id);
    if (response.data.status === STATUS.OK) {
      dispatch({
        type: SkuActionTypes.DELETE_SKU_SUCCESS,
        payload: id,
        error: null,
        route: ROUTE_TO_COMBO_SKU,
        success: response.data.message
      });
    }
  } catch (error) {
    dispatch({
      type: SkuActionTypes.DELETE_SKU_ERROR,
      payload: null,
      error: error.response.data,
      route: ROUTE_TO_COMBO_SKU,
      success: null
    });
  }
};
