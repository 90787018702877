export const FETCH_ZONES_REQUEST = 'FETCH_ZONES_REQUEST';
export const FETCH_ZONE_REQUEST = 'FETCH_ZONE_REQUEST';
export const CREATE_ZONE_REQUEST = 'CREATE_ZONE_REQUEST';
export const UPDATE_ZONE_REQUEST = 'UPDATE_ZONE_REQUEST';
export const DELETE_ZONE_REQUEST = 'DELETE_ZONE_REQUEST';

export const FETCH_ZONES_SUCCESS = 'FETCH_ZONES_SUCCESS';
export const FETCH_ZONE_SUCCESS = 'FETCH_ZONE_SUCCESS';
export const CREATE_ZONE_SUCCESS = 'CREATE_ZONE_SUCCESS';
export const UPDATE_ZONE_SUCCESS = 'UPDATE_ZONE_SUCCESS';
export const DELETE_ZONE_SUCCESS = 'DELETE_ZONE_SUCCESS';

export const FETCH_ZONES_ERROR = 'FETCH_ZONES_ERROR';
export const FETCH_ZONE_ERROR = 'FETCH_ZONE_ERROR';
export const CREATE_ZONE_ERROR = 'CREATE_ZONE_ERROR';
export const UPDATE_ZONE_ERROR = 'UPDATE_ZONE_ERROR';
export const DELETE_ZONE_ERROR = 'DELETE_ZONE_ERROR';
