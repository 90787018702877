import { get, isEqual, sortBy, set } from 'lodash';

export const reformatDistributorFormValues = (distributor = {}) => {
  return {
    name: get(distributor, 'name', ''),

    gstin: get(distributor, 'gstin', ''),

    existing_retailers_count: get(distributor, 'existing_retailers_count', ''),

    delivery_vehicles_count: get(distributor, 'delivery_vehicles_count', ''),

    distribution_type: get(distributor, 'distribution_type', ''),

    street_address: get(distributor, 'address.street_address', ''),

    landmark: get(distributor, 'address.landmark', ''),

    place: get(distributor, 'address.place', ''),

    zip_code: get(distributor, 'address.zip_code', ''),

    contact_person_name: get(distributor, 'contact.contact_person_name', ''),

    contact_number: get(distributor, 'contact.contact_number', ''),

    additional_contact_number: get(
      distributor,
      'contact.additional_contact_number',
      ''
    ),

    email: get(distributor, 'contact.email', '')
  };
};

export const getAssignedZoneIds = (appointed_areas = []) => {
  const zones = appointed_areas.reduce((allIds, area) => {
    return {
      ...allIds,
      [area.zone.id]: {
        name: area.zone.name,
        id: area.zone.id
      }
    };
  }, {});

  return Object.keys(zones);
};

export const getAssignedDistrictIds = (appointed_areas = []) => {
  const districts = appointed_areas.reduce((allIds, area) => {
    return {
      ...allIds,
      [area.district.id]: {
        name: area.district.name,
        id: area.district.id
      }
    };
  }, {});

  return Object.keys(districts);
};

export const getAssignedAreaIds = (appointed_areas = []) => {
  return appointed_areas.map((area) => area.id);
};

export const exportNormalizedFormValues = (
  {
    values,
    distributionType,
    assignedAreas,
    additionalContacts,
    currentBrandsDealing,
    superStockist
  },
  dbr
) => {
  const updatedValues = {};

  if (values.name && !isEqual(dbr.name, values.name)) {
    updatedValues.name = values.name;
  }

  if (!isEqual(dbr.gstin, values.gstin)) {
    updatedValues.gstin = values.gstin;
  }

  if (distributionType && !isEqual(dbr.distribution_type, distributionType)) {
    updatedValues.distribution_type = distributionType;
  }

  if (
    values.existing_retailers_count &&
    !isEqual(dbr.existing_retailers_count, values.existing_retailers_count)
  ) {
    updatedValues.existing_retailers_count = values.existing_retailers_count;
  }

  if (
    values.delivery_vehicles_count &&
    !isEqual(dbr.delivery_vehicles_count, values.delivery_vehicles_count)
  ) {
    updatedValues.delivery_vehicles_count = values.delivery_vehicles_count;
  }

  if (
    !isEqual(
      sortBy(assignedAreas),
      sortBy(getAssignedAreaIds(dbr.appointed_areas))
    )
  ) {
    updatedValues.appointed_areas = assignedAreas;
  }

  if (
    !isEqual(get(dbr, 'super_stockist.id', ''), get(superStockist, 'id', ''))
  ) {
    updatedValues.super_stockist = get(superStockist, 'id', '');
  }

  if (
    values.street_address &&
    !isEqual(dbr.address.street_address, values.street_address)
  ) {
    set(updatedValues, 'address.street_address', values.street_address);
  }

  if (values.landmark && !isEqual(dbr.address.landmark, values.landmark)) {
    set(updatedValues, 'address.landmark', values.landmark);
  }

  if (values.place && !isEqual(dbr.address.place, values.place)) {
    set(updatedValues, 'address.place', values.place);
  }

  if (values.zip_code && !isEqual(dbr.address.zip_code, values.zip_code)) {
    set(updatedValues, 'address.zip_code', values.zip_code);
  }

  if (
    values.contact_person_name &&
    !isEqual(dbr.contact.contact_person_name, values.contact_person_name)
  ) {
    set(
      updatedValues,
      'contact.contact_person_name',
      values.contact_person_name
    );
  }

  if (
    values.contact_number &&
    !isEqual(dbr.contact.contact_number, values.contact_number)
  ) {
    set(updatedValues, 'contact.contact_number', values.contact_number);
  }

  if (
    values.additional_contact_number &&
    !isEqual(
      dbr.contact.additional_contact_number,
      values.additional_contact_number
    )
  ) {
    set(
      updatedValues,
      'contact.additional_contact_number',
      values.additional_contact_number
    );
  }

  if (values.email && !isEqual(dbr.contact.email, values.email)) {
    set(updatedValues, 'contact.email', values.email);
  }

  if (!isEqual(dbr.additional_contacts, additionalContacts)) {
    set(updatedValues, 'additional_contacts', additionalContacts);
  }

  if (!isEqual(dbr.other_brands_dealing_experience, currentBrandsDealing)) {
    set(updatedValues, 'other_brands_dealing_experience', currentBrandsDealing);
  }

  return updatedValues;
};
