import * as EventActionTypes from './event.types';

export const setCancel = () => (dispatch) => {
  dispatch({
    type: EventActionTypes.SET_CANCEL
  });
};

export const resetCancel = () => (dispatch) => {
  dispatch({
    type: EventActionTypes.RESET_CANCEL
  });
};
