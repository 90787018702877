function ZoneFormValidator(values) {
  const errors = {};

  if (!values.name) {
    errors.name = '**Zone Name is required';
  }

  return errors;
}

export default ZoneFormValidator;
