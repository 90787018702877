import _ from 'lodash';
import * as ProductActionTypes from './product.types';

const initialState = {
  data: {},
  isRequestPending: false
};

const productDataReducer = (state = {}, action) => {
  const { payload, type } = action;

  switch (type) {
    case ProductActionTypes.FETCH_PRODUCTS_SUCCESS:
      return { ..._.mapKeys(payload, 'id') };

    case ProductActionTypes.FETCH_PRODUCT_SUCCESS:
      return { ...state, [payload.id]: payload };

    case ProductActionTypes.CREATE_PRODUCT_SUCCESS:
      return { ...state, [payload.id]: payload };

    case ProductActionTypes.UPDATE_PRODUCT_SUCCESS:
      return { ...state, [payload.id]: payload };

    case ProductActionTypes.DELETE_PRODUCT_SUCCESS:
      return _.omit(state, payload);

    default:
      return state;
  }
};

const productReducer = (state = initialState, action) => {
  const { type } = action;

  switch (type) {
    case ProductActionTypes.FETCH_PRODUCTS_REQUEST:
      return {
        ...state,
        isRequestPending: true
      };

    case ProductActionTypes.FETCH_PRODUCTS_SUCCESS:
      return {
        ...state,
        isRequestPending: false,
        data: productDataReducer(state.data, action)
      };

    case ProductActionTypes.FETCH_PRODUCTS_ERROR:
      return {
        ...state,
        isRequestPending: false
      };

    case ProductActionTypes.FETCH_PRODUCT_REQUEST:
      return {
        ...state,
        isRequestPending: true
      };

    case ProductActionTypes.FETCH_PRODUCT_SUCCESS:
      return {
        ...state,
        isRequestPending: false,
        data: productDataReducer(state.data, action)
      };

    case ProductActionTypes.FETCH_PRODUCT_ERROR:
      return {
        ...state,
        isRequestPending: false
      };

    case ProductActionTypes.CREATE_PRODUCT_REQUEST:
      return {
        ...state,
        isRequestPending: true
      };

    case ProductActionTypes.CREATE_PRODUCT_SUCCESS:
      return {
        ...state,
        isRequestPending: false,
        data: productDataReducer(state.data, action)
      };

    case ProductActionTypes.CREATE_PRODUCT_ERROR:
      return {
        ...state,
        isRequestPending: false
      };

    case ProductActionTypes.UPDATE_PRODUCT_REQUEST:
      return {
        ...state,
        isRequestPending: true
      };

    case ProductActionTypes.UPDATE_PRODUCT_SUCCESS:
      return {
        ...state,
        isRequestPending: false,
        data: productDataReducer(state.data, action)
      };

    case ProductActionTypes.UPDATE_PRODUCT_ERROR:
      return {
        ...state,
        isRequestPending: false
      };

    case ProductActionTypes.DELETE_PRODUCT_REQUEST:
      return {
        ...state,
        isRequestPending: true
      };

    case ProductActionTypes.DELETE_PRODUCT_SUCCESS:
      return {
        ...state,
        isRequestPending: false,
        data: productDataReducer(state.data, action)
      };

    case ProductActionTypes.DELETE_PRODUCT_ERROR:
      return {
        ...state,
        isRequestPending: false
      };

    default:
      return state;
  }
};

export default productReducer;
