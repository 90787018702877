import React from 'react';
import { Switch, Redirect } from 'react-router-dom';
import RouteWithLayout from '../layouts/RouteWithLayout';
import PrivateRouteWithLayout from '../layouts/PrivateRouteWithLayout';
import pages from './pages';
import pagesWithNoAuth from './pagesWithNoAuth';

const Routes = () => {
  return (
    <Switch>
      <Redirect exact from="/" to="/home" />
      {pagesWithNoAuth.map((page) => (
        <RouteWithLayout
          key={page.path}
          component={page.component}
          exact
          layout={page.layout}
          path={page.path}
        />
      ))}
      {pages.map((page) => (
        <PrivateRouteWithLayout
          key={page.path}
          component={page.component}
          exact
          layout={page.layout}
          path={page.path}
        />
      ))}
      <Redirect to="/not-found" />
    </Switch>
  );
};

export default Routes;
