import React from 'react';
import { Typography } from '@material-ui/core';

function FormSubHeader({ text }) {
  return (
    <Typography variant="h5" align="center" style={{ margin: '30px' }}>
      {text}
    </Typography>
  );
}

export default FormSubHeader;
