import React from 'react';
import { connect } from 'react-redux';
import { Container, Avatar, Typography } from '@material-ui/core';
import { Place } from '@material-ui/icons';
import formStyles from '../../../components/Styles/FormStyles';
import { TITLES } from '../../../shared/Constants/Constant';
import { editUser } from '../../../redux/user/user.actions';
import { setCancel } from '../../../redux/event/event.actions';
import history from '../../../routes/history';
import { ROUTE_TO_USER } from '../../../shared/Constants/RouteConstants';
import {
  isUserRequestPending,
  getUserById
} from '../../../redux/user/user.selector';
import BackdropWithLoader from '../../../components/BackdropWithLoader';
import useRefreshFetchReducer from '../../../shared/Hooks/useRefreshFetchReducer';
import { fetchAllZonesAndDistrictsAndAreasAndBeats } from '../../../redux/territory/territory.actions';
import {
  isBeatAreaRequestPending,
  getAllBeatAreas
} from '../../../redux/beatArea/beatArea.selector';
import {
  getAllAreas,
  isAreaRequestPending
} from '../../../redux/area/area.selector';
import {
  getAllDistricts,
  isDistrictRequestPending
} from '../../../redux/district/district.selector';
import {
  getAllZones,
  isZoneRequestPending
} from '../../../redux/zone/zone.selector';
import AssignUserTerritoryForm from '../../../components/AssignUserTerritoryForm';

function AssignUserTerritory(props) {
  const {
    resetCancelConnect,
    editUserConnect,
    isUserRequestPending,
    fetchTerritoryConnect,
    zonesPayload,
    isZonesLoading,
    districtsPayload,
    isDistrictsLoading,
    areasPayload,
    isAreasLoading,
    isBeatAreasLoading,
    beatAreasPayload,
    match: {
      params: { id }
    },
    user
  } = props;

  const classes = formStyles();

  const isLoading =
    isUserRequestPending ||
    isZonesLoading ||
    isDistrictsLoading ||
    isAreasLoading ||
    isBeatAreasLoading;

  useRefreshFetchReducer(fetchTerritoryConnect);

  const handleCancel = () => {
    resetCancelConnect();
    history.push(ROUTE_TO_USER);
  };

  const handleEditUser = (userData) => {
    editUserConnect(id, userData);
  };

  if (isLoading) {
    return <BackdropWithLoader />;
  }

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <Place color="primary" />
        </Avatar>
        <Typography component="h1" variant="h5">
          {TITLES.ASSIGN_TERRITORY}
        </Typography>

        <AssignUserTerritoryForm
          onSubmit={handleEditUser}
          onCancel={handleCancel}
          zonesPayload={zonesPayload}
          districtsPayload={districtsPayload}
          areasPayload={areasPayload}
          beatsPayload={beatAreasPayload}
          user={user}
        />
      </div>
    </Container>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    isUserRequestPending: isUserRequestPending(state),
    isZonesLoading: isZoneRequestPending(state),
    zonesPayload: getAllZones(state),
    isDistrictsLoading: isDistrictRequestPending(state),
    districtsPayload: getAllDistricts(state),
    isAreasLoading: isAreaRequestPending(state),
    areasPayload: getAllAreas(state),
    beatAreasPayload: getAllBeatAreas(state),
    isBeatAreasLoading: isBeatAreaRequestPending(state),
    user: getUserById(state, ownProps)
  };
};

const mapDispatchToProps = {
  editUserConnect: editUser,
  resetCancelConnect: setCancel,
  fetchTerritoryConnect: fetchAllZonesAndDistrictsAndAreasAndBeats
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AssignUserTerritory);
