import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Select,
  InputLabel,
  FormControl,
  MenuItem,
  FormHelperText
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '100%',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  }
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

function FormSelect(props) {
  const classes = useStyles();
  const {
    label,
    name,
    value,
    onChange,
    children,
    showDefaultEmptyValue = true,
    showEmptyErrorMenuItem = false,
    defaultValue,
    helperText,
    error,
    ...otherProps
  } = props;

  return (
    <FormControl variant="outlined" className={classes.formControl}>
      <InputLabel>{label}</InputLabel>
      <Select
        name={name}
        value={value}
        onChange={onChange}
        fullWidth
        MenuProps={MenuProps}
        label={label}
        error={error}
        {...otherProps}
      >
        {showDefaultEmptyValue && (
          <MenuItem value="">
            <em>Select One</em>
          </MenuItem>
        )}
        {showEmptyErrorMenuItem && (
          <MenuItem disabled>
            <em>No values found</em>
          </MenuItem>
        )}
        {children}
      </Select>
      <FormHelperText style={{ color: '#d50000' }}>
        {error && helperText}
      </FormHelperText>
    </FormControl>
  );
}

export default React.memo(FormSelect);
