import DistrictApi from '../../apis/DistrictAPI';
import * as DistrictActionTypes from './district.types';
import STATUS from '../../shared/Constants/apiStatus';
import { ROUTE_TO_DISTRICT } from '../../shared/Constants/RouteConstants';

export const fetchAllDistricts = () => async (dispatch) => {
  dispatch({
    type: DistrictActionTypes.FETCH_DISTRICTS_REQUEST
  });

  try {
    const response = await DistrictApi.getAllDistricts();

    if (response.data.status === STATUS.OK) {
      dispatch({
        type: DistrictActionTypes.FETCH_DISTRICTS_SUCCESS,
        payload: response.data.districts,
        error: null,
        route: null,
        success: null
      });
    }
  } catch (error) {
    dispatch({
      type: DistrictActionTypes.FETCH_DISTRICTS_ERROR,
      error: error.response.data,
      payload: null,
      route: null,
      success: null
    });
  }
};

export const fetchDistrict = (id) => async (dispatch) => {
  dispatch({
    type: DistrictActionTypes.FETCH_DISTRICT_REQUEST
  });

  try {
    const response = await DistrictApi.getDistrict(id);
    if (response.data.status === STATUS.OK) {
      dispatch({
        type: DistrictActionTypes.FETCH_DISTRICT_SUCCESS,
        payload: response.data.district,
        error: null,
        route: null,
        success: null
      });
    }
  } catch (error) {
    dispatch({
      type: DistrictActionTypes.FETCH_DISTRICT_ERROR,
      error: error.response.data,
      payload: null,
      route: ROUTE_TO_DISTRICT,
      success: null
    });
  }
};

export const createDistrict = (formValues) => async (dispatch) => {
  dispatch({
    type: DistrictActionTypes.CREATE_DISTRICT_REQUEST
  });

  try {
    const response = await DistrictApi.createDistrict(formValues);
    if (response.data.status === STATUS.OK) {
      dispatch({
        type: DistrictActionTypes.CREATE_DISTRICT_SUCCESS,
        payload: response.data.district,
        error: null,
        route: ROUTE_TO_DISTRICT,
        success: response.data.message
      });
    }
  } catch (error) {
    dispatch({
      type: DistrictActionTypes.CREATE_DISTRICT_ERROR,
      error: error.response.data,
      payload: null,
      route: ROUTE_TO_DISTRICT,
      success: null
    });
  }
};

export const editDistrict = (id, formValues) => async (dispatch) => {
  dispatch({
    type: DistrictActionTypes.UPDATE_DISTRICT_REQUEST
  });

  try {
    const response = await DistrictApi.editDistrict(id, formValues);
    if (response.data.status === STATUS.OK) {
      dispatch({
        type: DistrictActionTypes.UPDATE_DISTRICT_SUCCESS,
        payload: response.data.district,
        error: null,
        route: ROUTE_TO_DISTRICT,
        success: response.data.message
      });
    }
  } catch (error) {
    dispatch({
      type: DistrictActionTypes.UPDATE_DISTRICT_ERROR,
      error: error.response.data,
      payload: null,
      route: ROUTE_TO_DISTRICT,
      success: null
    });
  }
};

export const deleteDistrict = (id) => async (dispatch) => {
  dispatch({
    type: DistrictActionTypes.DELETE_DISTRICT_REQUEST
  });

  try {
    const response = await DistrictApi.deleteDistrict(id);
    if (response.data.status === STATUS.OK) {
      dispatch({
        type: DistrictActionTypes.DELETE_DISTRICT_SUCCESS,
        payload: id,
        error: null,
        route: ROUTE_TO_DISTRICT,
        success: response.data.message
      });
    }
  } catch (error) {
    dispatch({
      type: DistrictActionTypes.DELETE_DISTRICT_ERROR,
      payload: null,
      error: error.response.data,
      route: ROUTE_TO_DISTRICT,
      success: null
    });
  }
};
