import React, { useState } from 'react';
import { MenuItem, ListItemText, Checkbox } from '@material-ui/core';
import FormInput from '../FormInput';
import FormMultiSelect from '../FormMultiSelect';
import FormSelect from '../FormSelect';
import FormSubHeader from '../FormSubHeader';
import formStyles from '../Styles/FormStyles';
import FormCancelButton from '../FormCancelButton';
import FormSubmitButton from '../FormSubmitButton';
import AdditionalContactsForm from '../AdditionalContactsForm';
import CurrentBrandsDealingForm from '../CurrentBrandsDealingForm';
import {
  FORM_SUB_HEADER_TITLES,
  DISTRIBUTOR_DISTRIBUTION_PICKER,
  DISTRIBUTOR_DISTRIBUTION_CONSTANTS
} from '../../shared/Constants/Constant';
import useForm from '../../shared/Hooks/useForm';
import DistributorFormValidator from '../../pages/Distributor/DistributorFormValidator';
import { set } from 'lodash';
import {
  areValidAdditionalContacts,
  areValidCustomerBrands
} from '../../shared/Utils/CommonUtils';

// Additional Contact Details
const blankAdditionalContact = {
  contact_person_name: '',
  contact_number: '',
  designation: ''
};

// SS Brands Details
const blankCurrentBrandsDealing = {
  brand_name: '',
  turn_over_amount: '',
  brand_dealing_time_period: ''
};

function CreateDistributorForm(props) {
  const {
    zonesPayload = [],
    districtsPayload = [],
    areasPayload = [],
    onCancel
  } = props;
  const classes = formStyles();

  const [additionalContacts, setAdditionalContacts] = useState([]);

  const [additionalContactsError, setAdditionalContactsError] = useState('');

  const [currentBrandsDealing, setCurrentBrandsDealing] = useState([]);

  const [currentBrandsDealingError, setCurrentBrandsDealingError] = useState(
    ''
  );

  const [assignedZones, setAssignedZones] = useState([]);

  const [assignedDistricts, setAssignedDistricts] = useState([]);

  const [assignedAreas, setAssignedAreas] = useState([]);

  const [areaError, setAreaError] = useState('');

  const [distributionType, setDistributionType] = useState(
    DISTRIBUTOR_DISTRIBUTION_CONSTANTS.PRIMARY
  );

  const addAdditionalContact = () => {
    setAdditionalContacts((prevAdditionalContact) => [
      ...prevAdditionalContact,
      { ...blankAdditionalContact }
    ]);
  };

  const removeAdditionalContactByIndex = (index) => {
    const duplicateAdditionalContacts = [...additionalContacts];
    duplicateAdditionalContacts.splice(index, 1);

    setAdditionalContacts([...duplicateAdditionalContacts]);
  };

  const handleAdditionalContactChange = (e, index) => {
    e.persist();
    const duplicateAdditionalContacts = [...additionalContacts];
    duplicateAdditionalContacts[index][e.target.name] = e.target.value;

    if (additionalContactsError) {
      setAdditionalContactsError('');
    }

    setAdditionalContacts(duplicateAdditionalContacts);
  };

  const addCurrentBrandsDealing = () => {
    setCurrentBrandsDealing((prevCurrentBrandsDealing) => [
      ...prevCurrentBrandsDealing,
      { ...blankCurrentBrandsDealing }
    ]);
  };

  const removeCurrentBrandsDealing = (index) => {
    const duplicateCurrentBrandsDealing = [...currentBrandsDealing];
    duplicateCurrentBrandsDealing.splice(index, 1);

    setCurrentBrandsDealing([...duplicateCurrentBrandsDealing]);
  };

  const handleCurrentBrandsDealingChange = (e, index) => {
    e.persist();
    const duplicateCurrentBrandsDealing = [...currentBrandsDealing];
    duplicateCurrentBrandsDealing[index][e.target.name] = e.target.value;

    if (currentBrandsDealingError) {
      setCurrentBrandsDealingError('');
    }

    setCurrentBrandsDealing(duplicateCurrentBrandsDealing);
  };

  const onChangeZoneAssign = (e) => {
    e.persist();
    const { value } = e.target;

    if (!value.length) {
      setAssignedZones([]);
      setAssignedDistricts([]);
      setAssignedAreas([]);
      return;
    }

    let districtIds = [];
    let areaIds = [];

    setAssignedZones(value);

    value.map((zoneId) => {
      const filterAlreadyAssignedDistricts = districtsPayload
        .filter(
          (district) =>
            assignedDistricts.includes(district.id) &&
            district.zone.id === zoneId
        )
        .map((filteredDistrict) => filteredDistrict.id);
      districtIds = districtIds.concat(filterAlreadyAssignedDistricts);

      const filterAlreadyAssignedAreas = areasPayload
        .filter(
          (area) => assignedAreas.includes(area.id) && area.zone.id === zoneId
        )
        .map((filteredArea) => filteredArea.id);

      areaIds = areaIds.concat(filterAlreadyAssignedAreas);
      return value;
    });

    setAssignedDistricts(districtIds);
    setAssignedAreas(areaIds);
  };

  const onChangeDistrictAssign = (e) => {
    e.persist();
    const { value } = e.target;

    if (!value.length) {
      setAssignedDistricts([]);
      setAssignedAreas([]);
      return;
    }

    setAssignedDistricts(value);
    let areaIds = [];

    value.map((districtId) => {
      const filterAlreadyAssignedAreas = areasPayload
        .filter(
          (area) =>
            assignedAreas.includes(area.id) && area.district.id === districtId
        )
        .map((filteredArea) => filteredArea.id);

      areaIds = areaIds.concat(filterAlreadyAssignedAreas);
      return value;
    });

    setAssignedAreas(areaIds);
  };

  const onChangeAreaAssign = (e) => {
    e.persist();
    const { value } = e.target;

    if (areaError) {
      setAreaError('');
    }

    if (!value.length) {
      setAssignedAreas([]);
      return;
    }

    setAssignedAreas(value);
  };

  const handleDistributionChange = (e) => {
    e.persist();
    const { value } = e.target;

    setDistributionType(value);
  };

  const normalizeFormData = () => {
    const formData = {
      name: values.name,
      gstin: values.gstin,
      distribution_type: distributionType,
      existing_retailers_count: values.existing_retailers_count,
      delivery_vehicles_count: values.delivery_vehicles_count,
      appointed_areas: assignedAreas
    };

    if (additionalContacts.length) {
      set(formData, 'additional_contacts', additionalContacts);
    }

    if (currentBrandsDealing.length) {
      set(formData, 'other_brands_dealing_experience', currentBrandsDealing);
    }

    set(formData, 'address.street_address', values.street_address);
    set(formData, 'address.landmark', values.landmark);
    set(formData, 'address.place', values.place);
    set(formData, 'address.zip_code', values.zip_code);

    set(formData, 'contact.contact_person_name', values.contact_person_name);
    set(formData, 'contact.contact_number', values.contact_number);

    if (values.additional_contact_number) {
      set(
        formData,
        'contact.additional_contact_number',
        values.additional_contact_number
      );
    }

    if (values.email) {
      set(formData, 'contact.email', values.email);
    }

    return formData;
  };

  const handleFormSubmit = () => {
    if (!assignedAreas.length) {
      return setAreaError('** Area is required');
    }

    if (!areValidAdditionalContacts(additionalContacts)) {
      return setAdditionalContactsError(
        '** Additional Contacts entry is incomplete'
      );
    }

    if (!areValidCustomerBrands(currentBrandsDealing)) {
      return setCurrentBrandsDealingError(
        '** Current Brands Dealing entry is incomplete'
      );
    }

    props.onSubmit(normalizeFormData(), resetValues);
  };

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    handleNumericInputChange,
    resetValues
  } = useForm(handleFormSubmit, DistributorFormValidator);

  return (
    <form className={classes.form} noValidate autoComplete="off">
      <FormInput
        type="text"
        label="Distributor Name"
        placeholder="Enter the Distributor Name"
        name="name"
        value={values.name || ''}
        onChange={handleChange}
        helperText={errors.name || ''}
        error={!!errors.name}
        required
        autoFocus
      />

      <FormInput
        type="text"
        label="Place"
        placeholder="Enter the Place"
        name="place"
        value={values.place || ''}
        onChange={handleChange}
        helperText={errors.place || ''}
        error={!!errors.place}
        required
      />

      <FormInput
        type="text"
        label="GST Number"
        placeholder="Enter the GST Number"
        name="gstin"
        value={values.gstin || ''}
        onChange={handleChange}
      />

      <FormSubHeader text={FORM_SUB_HEADER_TITLES.TERRITORY} />

      <FormMultiSelect
        label="Choose Zones"
        value={assignedZones}
        payload={zonesPayload}
        onChange={onChangeZoneAssign}
      >
        {zonesPayload.map((zone) => {
          const { id } = zone;
          return (
            <MenuItem key={id} value={id}>
              <Checkbox
                checked={assignedZones.indexOf(id) > -1}
                color="primary"
              />
              <ListItemText primary={zone.name} />
            </MenuItem>
          );
        })}
      </FormMultiSelect>

      <FormMultiSelect
        label="Choose Districts"
        value={assignedDistricts}
        payload={districtsPayload}
        onChange={onChangeDistrictAssign}
      >
        {assignedZones.length &&
          districtsPayload
            .filter((district) => assignedZones.includes(district.zone.id))
            .map((district) => {
              const { id } = district;
              return (
                <MenuItem key={id} value={id}>
                  <Checkbox
                    checked={assignedDistricts.indexOf(id) > -1}
                    color="primary"
                  />
                  <ListItemText primary={district.name} />
                </MenuItem>
              );
            })}
      </FormMultiSelect>

      <FormMultiSelect
        label="Choose Areas"
        value={assignedAreas}
        payload={areasPayload}
        onChange={onChangeAreaAssign}
        error={!!areaError}
        helperText={areaError}
      >
        {assignedDistricts.length &&
          areasPayload
            .filter((area) => assignedDistricts.includes(area.district.id))
            .map((area) => {
              const { id } = area;
              return (
                <MenuItem key={id} value={id}>
                  <Checkbox
                    checked={assignedAreas.indexOf(id) > -1}
                    color="primary"
                  />
                  <ListItemText primary={area.name} />
                </MenuItem>
              );
            })}
      </FormMultiSelect>

      <FormSubHeader text={FORM_SUB_HEADER_TITLES.DISTRIBUTION} />

      <FormSelect
        label="Select Distribution Type"
        name="distribution_type"
        value={distributionType || ''}
        onChange={handleDistributionChange}
        showDefaultEmptyValue={false}
      >
        {Object.keys(DISTRIBUTOR_DISTRIBUTION_PICKER).map((type) => {
          const label = DISTRIBUTOR_DISTRIBUTION_PICKER[type];
          return (
            <MenuItem key={type} value={type}>
              {label}
            </MenuItem>
          );
        })}
      </FormSelect>

      <FormSubHeader text={FORM_SUB_HEADER_TITLES.ADDRESS} />

      <FormInput
        type="text"
        label="Street Address"
        placeholder="Enter the Address"
        name="street_address"
        value={values.street_address || ''}
        onChange={handleChange}
        helperText={errors.street_address || ''}
        error={!!errors.street_address}
        required
      />

      <FormInput
        type="text"
        label="Landmark"
        placeholder="Enter the Landmark"
        name="landmark"
        value={values.landmark || ''}
        onChange={handleChange}
        helperText={errors.landmark || ''}
        error={!!errors.landmark}
        required
      />

      <FormInput
        type="text"
        label="Pin Code"
        placeholder="Enter the Pin Code"
        name="zip_code"
        value={values.zip_code || ''}
        onChange={handleNumericInputChange}
        helperText={errors.zip_code || ''}
        error={!!errors.zip_code}
        required
      />

      <FormSubHeader text={FORM_SUB_HEADER_TITLES.CONTACT} />

      <FormInput
        type="text"
        label="Key Contact Person Name"
        placeholder="Enter the Key Contact Name"
        name="contact_person_name"
        value={values.contact_person_name || ''}
        onChange={handleChange}
        required
        helperText={errors.contact_person_name || ''}
        error={!!errors.contact_person_name}
      />

      <FormInput
        type="number"
        label="Mobile Number"
        placeholder="Enter the Contact Number"
        name="contact_number"
        value={values.contact_number || ''}
        onChange={handleNumericInputChange}
        required
        helperText={errors.contact_number || ''}
        error={!!errors.contact_number}
      />

      <FormInput
        type="number"
        label="WhatsApp Number"
        placeholder="Enter the WhatsApp Number"
        name="additional_contact_number"
        value={values.additional_contact_number || ''}
        onChange={handleNumericInputChange}
      />

      <FormInput
        type="email"
        label="Email Address"
        placeholder="Enter the Email Address"
        name="email"
        value={values.email || ''}
        onChange={handleChange}
      />

      <AdditionalContactsForm
        subHeaderTitle={FORM_SUB_HEADER_TITLES.ADDITIONAL_CONTACTS}
        contacts={additionalContacts}
        handleInputChange={
          (e, index) => handleAdditionalContactChange(e, index)
          // eslint-disable-next-line react/jsx-curly-newline
        }
        handleRemove={(index) => removeAdditionalContactByIndex(index)}
        handleAdd={addAdditionalContact}
        errorMessage={additionalContactsError}
      />

      <CurrentBrandsDealingForm
        subHeaderTitle={FORM_SUB_HEADER_TITLES.BRANDS_DEALING_WITH}
        brands={currentBrandsDealing}
        handleRemove={(index) => removeCurrentBrandsDealing(index)}
        handleInputChange={(e, index) =>
          handleCurrentBrandsDealingChange(e, index)
        }
        handleAdd={addCurrentBrandsDealing}
        errorMessage={currentBrandsDealingError}
      />

      <FormInput
        type="number"
        label="No of Delivery Vehicles"
        placeholder="Enter the Delivery Vehicles Count"
        name="delivery_vehicles_count"
        value={values.delivery_vehicles_count || ''}
        onChange={handleChange}
      />

      <FormInput
        type="number"
        label="No of Existing Retailers"
        placeholder="Enter the Number of Existing Retailers"
        name="existing_retailers_count"
        value={values.existing_retailers_count || ''}
        onChange={handleChange}
      />

      <div className={classes.buttonRow}>
        <FormCancelButton onClickCancel={onCancel} />
        <FormSubmitButton onClickSubmit={handleSubmit} />
      </div>
    </form>
  );
}

CreateDistributorForm.defaultProps = {
  zonesPayload: [],
  districtsPayload: [],
  areasPayload: []
};

export default React.memo(CreateDistributorForm);
