import * as AuthActionTypes from './auth.types';
import AuthApi from '../../apis/AuthAPI';
import setAuthToken from '../../shared/AxiosBaseUrl/setAuthToken';
import { ROUTE_TO_LOGIN } from '../../shared/Constants/RouteConstants';
import history from '../../routes/history';

export const authRequest = () => {
  return {
    type: AuthActionTypes.AUTH_REQUEST
  };
};

// Load User
export const loadCurrentUser = () => async (dispatch) => {
  dispatch(authRequest());

  try {
    const res = await AuthApi.getCurrentUser();

    dispatch({
      type: AuthActionTypes.CURRENT_USER_LOADED,
      payload: res.data.user
    });
  } catch (err) {
    setAuthToken(); /* Removes the auth token */
    history.push(ROUTE_TO_LOGIN);

    dispatch({
      type: AuthActionTypes.AUTH_ERROR,
      error: err.response.data,
      payload: null
    });
  }
};

export const userLogin = (formValues) => async (dispatch) => {
  dispatch(authRequest());

  try {
    const response = await AuthApi.userLogin({
      ...formValues,
      loggedInFrom: 'dashboard'
    });

    setAuthToken(response.data.token);

    dispatch({
      type: AuthActionTypes.AUTH_SUCCESS,
      payload: response.data.token
    });

    const currentUserResponse = await AuthApi.getCurrentUser();

    dispatch({
      type: AuthActionTypes.CURRENT_USER_LOADED,
      payload: currentUserResponse.data.user
    });
  } catch (err) {
    dispatch({
      type: AuthActionTypes.AUTH_ERROR,
      error: err.response.data,
      payload: null
    });
  }
};

export const userLogout = () => {
  localStorage.removeItem('token');
  return {
    type: AuthActionTypes.AUTH_LOGOUT
  };
};
