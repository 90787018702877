import { createSelector } from 'reselect';

export const employeeSecondarySalesSelector = (state) =>
  state.employeeSecondarySales;

export const employeeSecondarySalesDataSelector = (state) =>
  state.employeeSecondarySales.data;

export const isEmployeeSecondarySalesRequestPending = (state) =>
  state.employeeSecondarySales.isRequestPending;

export const isEmployeeSecondarySalesRequestForModal = (state) =>
  state.employeeSecondarySales.isRequestPendingForModal;

export const getErrorMessage = (state) =>
  state.employeeSecondarySales.error.message;

export const getEmployeeSecondarySales = createSelector(
  employeeSecondarySalesDataSelector,
  (employeeSecondarySales) => Object.values(employeeSecondarySales)
);
