import { createSelector } from 'reselect';

export const getProductsSelector = (state) => state.products;
export const getAllProductsDataSelector = (state) => state.products.data;
export const getProductByIdSelector = (state, props) => props.match.params.id;

export const isProductRequestPending = createSelector(
  [getProductsSelector],
  (products) => products.isRequestPending
);

export const getAllProducts = createSelector(
  getAllProductsDataSelector,
  (products) => Object.values(products)
);

export const getProductById = createSelector(
  getAllProductsDataSelector,
  getProductByIdSelector,
  (products, id) => products[id]
);
