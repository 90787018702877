import React from 'react';
import { connect } from 'react-redux';
import { Typography } from '@material-ui/core';
import OrderSkuTable from '../../../components/OrderSkuTable';
import { fetchSecondaryOrder } from '../../../redux/secondary-order/secondary-order.actions';
import {
  getSecondaryOrderById,
  isSecondaryOrderRequestPending
} from '../../../redux/secondary-order/secondary-order.selector';
import useRefreshFetchReducerWithId from '../../../shared/Hooks/useRefreshFetchReducerWithId';
import {
  ORDER_TYPES_TEXT,
  SECONDARY_ORDER_STATUS
} from '../../../shared/Constants/Constant';

function ViewSecondaryOrder(props) {
  const {
    match: {
      params: { id: orderId }
    },
    fetchSecondaryOrderConnect,
    orderDetails,
    isLoading
  } = props;

  useRefreshFetchReducerWithId(fetchSecondaryOrderConnect, orderId);

  return (
    <>
      <Typography
        variant="h5"
        align="center"
        style={{ marginTop: 10, marginBottom: 10 }}
      >
        {`${ORDER_TYPES_TEXT.SECONDARY_ORDER} - ${orderDetails.invoice_number}`}
      </Typography>

      <Typography
        variant="h5"
        align="center"
        style={{ marginTop: 10, marginBottom: 10 }}
      >
        {`From - ${orderDetails.retailer.name}`}
      </Typography>

      <Typography
        variant="h5"
        align="center"
        style={{ marginTop: 10, marginBottom: 10 }}
      >
        {`To - ${orderDetails.distributor.name}`}
      </Typography>

      <Typography
        variant="h5"
        align="center"
        style={{ marginTop: 10, marginBottom: 10 }}
      >
        {`Beat - ${orderDetails.beat.name} / Area - ${orderDetails.area.name}`}
      </Typography>

      <Typography
        variant="h5"
        align="center"
        style={{ marginTop: 10, marginBottom: 10, color: '#d50000' }}
      >
        {`Status - ${SECONDARY_ORDER_STATUS[orderDetails.status]}`}
      </Typography>

      <OrderSkuTable order={orderDetails} isOrderLoading={isLoading} />
    </>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    orderDetails: getSecondaryOrderById(state, ownProps),
    isLoading: isSecondaryOrderRequestPending(state)
  };
};

const mapDispatchToProps = {
  fetchSecondaryOrderConnect: fetchSecondaryOrder
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewSecondaryOrder);
