const ONLY_ADMIN = 'ADMIN';

const ZONE_CREATE_ACTION_ALLOWED_USERS = [
  'ADMIN',
  'BACKOFFICE_ADMIN',
  'GENERAL_MANAGER'
];

const ZONE_EDIT_ACTION_ALLOWED_USERS = [
  'ADMIN',
  'BACKOFFICE_ADMIN',
  'GENERAL_MANAGER'
];

const ZONE_DELETE_ACTION_ALLOWED_USERS = ['ADMIN', 'BACKOFFICE_ADMIN'];

const DISTRICT_CREATE_ACTION_ALLOWED_USERS = [
  'ADMIN',
  'BACKOFFICE_ADMIN',
  'GENERAL_MANAGER'
];

const DISTRICT_EDIT_ACTION_ALLOWED_USERS = [
  'ADMIN',
  'BACKOFFICE_ADMIN',
  'GENERAL_MANAGER'
];

const DISTRICT_DELETE_ACTION_ALLOWED_USERS = ['ADMIN', 'BACKOFFICE_ADMIN'];

const AREA_CREATE_ACTION_ALLOWED_USERS = [
  'ADMIN',
  'BACKOFFICE_ADMIN',
  'GENERAL_MANAGER',
  'REGIONAL_SALES_MANAGER',
  'AREA_SALES_MANAGER'
];

const AREA_EDIT_ACTION_ALLOWED_USERS = [
  'ADMIN',
  'BACKOFFICE_ADMIN',
  'GENERAL_MANAGER'
];

const AREA_DELETE_ACTION_ALLOWED_USERS = ['ADMIN', 'BACKOFFICE_ADMIN'];

const BEAT_AREA_CREATE_ACTION_ALLOWED_USERS = [
  'ADMIN',
  'BACKOFFICE_ADMIN',
  'GENERAL_MANAGER',
  'REGIONAL_SALES_MANAGER',
  'AREA_SALES_MANAGER',
  'SALES_OFFICER',
  'TERRITORY_SALES_INCHARGE'
];

const BEAT_AREA_EDIT_ACTION_ALLOWED_USERS = [
  'ADMIN',
  'BACKOFFICE_ADMIN',
  'GENERAL_MANAGER'
];

const BEAT_AREA_DELETE_ACTION_ALLOWED_USERS = ['ADMIN', 'BACKOFFICE_ADMIN'];

const SUPER_STOCKIST_CREATE_ACTION_ALLOWED_USERS = [
  'ADMIN',
  'BACKOFFICE_ADMIN',
  'GENERAL_MANAGER',
  'REGIONAL_SALES_MANAGER',
  'AREA_SALES_MANAGER'
];

const SUPER_STOCKIST_EDIT_ACTION_ALLOWED_USERS = [
  'ADMIN',
  'BACKOFFICE_ADMIN',
  'GENERAL_MANAGER'
];

const SUPER_STOCKIST_DELETE_ACTION_ALLOWED_USERS = [
  'ADMIN',
  'BACKOFFICE_ADMIN'
];

const SUPER_STOCKIST_ORDER_CREATION_ALLOWED_USERS = [
  'ADMIN',
  'BACKOFFICE_ADMIN',
  'GENERAL_MANAGER',
  'REGIONAL_SALES_MANAGER',
  'AREA_SALES_MANAGER'
];

const DISTRIBUTOR_CREATE_ACTION_ALLOWED_USERS = [
  'ADMIN',
  'BACKOFFICE_ADMIN',
  'GENERAL_MANAGER',
  'REGIONAL_SALES_MANAGER',
  'AREA_SALES_MANAGER',
  'SALES_OFFICER',
  'TERRITORY_SALES_INCHARGE'
];

const DISTRIBUTOR_EDIT_ACTION_ALLOWED_USERS = [
  'ADMIN',
  'BACKOFFICE_ADMIN',
  'GENERAL_MANAGER'
];

const DISTRIBUTOR_DELETE_ACTION_ALLOWED_USERS = ['ADMIN', 'BACKOFFICE_ADMIN'];

const RETAILER_CREATE_ACTION_ALLOWED_USERS = [
  'ADMIN',
  'BACKOFFICE_ADMIN',
  'GENERAL_MANAGER',
  'REGIONAL_SALES_MANAGER',
  'AREA_SALES_MANAGER',
  'SALES_OFFICER',
  'TERRITORY_SALES_INCHARGE'
];

const RETAILER_EDIT_ACTION_ALLOWED_USERS = [
  'ADMIN',
  'BACKOFFICE_ADMIN',
  'GENERAL_MANAGER'
];

const RETAILER_DELETE_ACTION_ALLOWED_USERS = ['ADMIN', 'BACKOFFICE_ADMIN'];

const USER_CREATE_ACTION_ALLOWED_USERS = ['ADMIN', 'BACKOFFICE_ADMIN'];

const USER_EDIT_ACTION_ALLOWED_USERS = ['ADMIN', 'BACKOFFICE_ADMIN'];

const USER_DELETE_ACTION_ALLOWED_USERS = ['ADMIN', 'BACKOFFICE_ADMIN'];

const ALL_ORDERS_VIEW_ALLOWED_USERS = ['ADMIN', 'BACKOFFICE_ADMIN'];

module.exports = {
  ONLY_ADMIN,
  ZONE_CREATE_ACTION_ALLOWED_USERS,
  ZONE_EDIT_ACTION_ALLOWED_USERS,
  ZONE_DELETE_ACTION_ALLOWED_USERS,
  DISTRICT_CREATE_ACTION_ALLOWED_USERS,
  DISTRICT_EDIT_ACTION_ALLOWED_USERS,
  DISTRICT_DELETE_ACTION_ALLOWED_USERS,
  AREA_CREATE_ACTION_ALLOWED_USERS,
  AREA_EDIT_ACTION_ALLOWED_USERS,
  AREA_DELETE_ACTION_ALLOWED_USERS,
  BEAT_AREA_CREATE_ACTION_ALLOWED_USERS,
  BEAT_AREA_EDIT_ACTION_ALLOWED_USERS,
  BEAT_AREA_DELETE_ACTION_ALLOWED_USERS,
  SUPER_STOCKIST_CREATE_ACTION_ALLOWED_USERS,
  SUPER_STOCKIST_EDIT_ACTION_ALLOWED_USERS,
  SUPER_STOCKIST_DELETE_ACTION_ALLOWED_USERS,
  SUPER_STOCKIST_ORDER_CREATION_ALLOWED_USERS,
  DISTRIBUTOR_CREATE_ACTION_ALLOWED_USERS,
  DISTRIBUTOR_EDIT_ACTION_ALLOWED_USERS,
  DISTRIBUTOR_DELETE_ACTION_ALLOWED_USERS,
  RETAILER_CREATE_ACTION_ALLOWED_USERS,
  RETAILER_EDIT_ACTION_ALLOWED_USERS,
  RETAILER_DELETE_ACTION_ALLOWED_USERS,
  USER_CREATE_ACTION_ALLOWED_USERS,
  USER_EDIT_ACTION_ALLOWED_USERS,
  USER_DELETE_ACTION_ALLOWED_USERS,
  ALL_ORDERS_VIEW_ALLOWED_USERS
};
