function SkuFormValidator(values) {
  const errors = {};

  if (!values.sku) {
    errors.sku = '**Sku is required';
  }

  if (!values.name) {
    errors.name = '**Name is required';
  }

  if (!values.mrp) {
    errors.mrp = '**MRP is required';
  }

  if (!values.pieces_per_carton) {
    errors.pieces_per_carton = '**Pieces/Ctn is required';
  }

  if (!values.sgst) {
    errors.sgst = '**SGST is required';
  }

  if (!values.cgst) {
    errors.cgst = '**CGST is required';
  }

  if (!values.igst) {
    errors.igst = '**IGST is required';
  }

  if (!values.super_stockist_margin) {
    errors.super_stockist_margin = '**Super Stockist Margin is required';
  }
  if (!values.distributor_margin) {
    errors.distributor_margin = '**Distributor Margin is required';
  }

  if (!values.retailer_margin) {
    errors.retailer_margin = '**Retailer Margin is required';
  }

  return errors;
}

export default SkuFormValidator;
