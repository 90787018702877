import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import useRefreshFetchReducer from '../../shared/Hooks/useRefreshFetchReducer';
import { fetchAllSecondPrimaryOrders } from '../../redux/second-primary-order/second-primary-order.actions';
import {
  isSecondPrimaryOrderRequestPending,
  getAllSecondPrimaryOrders
} from '../../redux/second-primary-order/second-primary-order.selector';
import { getCancelledState } from '../../redux/event/event.selector';
import { resetCancel } from '../../redux/event/event.actions';
import SecondPrimaryOrderTable from '../../components/SecondPrimaryOrderTable';

const useStyles = makeStyles((theme) => ({
  content: {
    marginTop: theme.spacing(2)
  }
}));

function SecondPrimaryOrder(props) {
  const {
    fetchAllSecondPrimaryOrdersConnect,
    secondPrimaryOrdersPayload,
    isSecondPrimaryOrderLoading,
    resetCancelConnect,
    isCancelledEvent
  } = props;
  const classes = useStyles();

  useRefreshFetchReducer(
    fetchAllSecondPrimaryOrdersConnect,
    resetCancelConnect,
    isCancelledEvent
  );

  return (
    <div className={classes.content}>
      <SecondPrimaryOrderTable
        orders={secondPrimaryOrdersPayload}
        isOrderLoading={isSecondPrimaryOrderLoading}
      />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    isSecondPrimaryOrderLoading: isSecondPrimaryOrderRequestPending(state),
    secondPrimaryOrdersPayload: getAllSecondPrimaryOrders(state),
    isCancelledEvent: getCancelledState(state)
  };
};

const mapDispatchToProps = {
  fetchAllSecondPrimaryOrdersConnect: fetchAllSecondPrimaryOrders,
  resetCancelConnect: resetCancel
};

export default connect(mapStateToProps, mapDispatchToProps)(SecondPrimaryOrder);
