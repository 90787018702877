import { size } from 'lodash';
import { createSelector } from 'reselect';

const getRetailerselector = (state) => state.retailers;
const getAllRetailersSelector = (state) => state.retailers.data;
const getRetailerByIdSelector = (state, props) => props.match.params.id;

export const isRetailerRequestPending = createSelector(
  [getRetailerselector],
  (retailers) => retailers.isRequestPending
);

export const isRetailerInventoryRequestPending = createSelector(
  [getRetailerselector],
  (retailers) => retailers.isRetailerInventoryModalRequestPending
);

export const getAllRetailers = createSelector(
  [getAllRetailersSelector],
  (retailers) => Object.values(retailers)
);

export const getRetailerById = createSelector(
  getAllRetailersSelector,
  getRetailerByIdSelector,
  (retailers, id) => retailers[id]
);

export const getRetailerSkuInventory = createSelector(
  getAllRetailersSelector,
  getRetailerByIdSelector,
  (retailers, id) => {
    const retailer = retailers[id] || {};

    if (size(retailer) > 0 && size(retailer.sku_items) > 0) {
      return Object.values(retailer.sku_items);
    }

    return [];
  }
);
