import { makeStyles } from '@material-ui/core/styles';

const tableStyles = makeStyles({
  root: {
    minWidth: 700,
    '& > *': {
      borderBottom: 'unset'
    }
  },
  container: {
    maxHeight: 700
  },
  content: {
    marginTop: '16px'
  },
  deleteButton: {
    backgroundColor: '#787878',
    '&:hover': {
      backgroundColor: '#902020',
      color: '#FFF'
    }
  },
  tableFitContainer: {
    maxHeight: 600
  },
  tableWithTopButtonContainer: {
    maxHeight: 550
  }
});

export default tableStyles;
