import React from 'react';
import { connect } from 'react-redux';
import { Container, Avatar, Typography } from '@material-ui/core';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import formStyles from '../../../components/Styles/FormStyles';
import UserForm from '../../../components/UserForm';
import { TITLES } from '../../../shared/Constants/Constant';
import { createUser } from '../../../redux/user/user.actions';
import { setCancel } from '../../../redux/event/event.actions';
import history from '../../../routes/history';
import { ROUTE_TO_USER } from '../../../shared/Constants/RouteConstants';
import { isUserRequestPending } from '../../../redux/user/user.selector';
import BackdropWithLoader from '../../../components/BackdropWithLoader';

function CreateUserForm(props) {
  const { resetCancelConnect, createUserConnect, isUserRequestPending } = props;
  const classes = formStyles();
  const handleCreateUser = (userData) => {
    createUserConnect(userData);
  };

  const isLoading = isUserRequestPending;

  const handleCancel = () => {
    resetCancelConnect();
    history.push(ROUTE_TO_USER);
  };

  if (isLoading) {
    return <BackdropWithLoader />;
  }

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <AccountCircleIcon color="primary" />
        </Avatar>
        <Typography component="h1" variant="h5">
          {TITLES.CREATE_USER}
        </Typography>

        <UserForm onSubmit={handleCreateUser} onCancel={handleCancel} />
      </div>
    </Container>
  );
}

const mapStateToProps = (state) => {
  return {
    isUserRequestPending: isUserRequestPending(state)
  };
};

const mapDispatchToProps = {
  createUserConnect: createUser,
  resetCancelConnect: setCancel
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateUserForm);
