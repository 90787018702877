import React from 'react';
import { connect } from 'react-redux';
import CreateToolBar from '../../shared/CreateToolBar';
import RetailerTable from '../../components/RetailerTable';
import tableStyles from '../../components/Styles/TableStyles';
import useRefreshFetchReducer from '../../shared/Hooks/useRefreshFetchReducer';
import { ROUTE_TO_CREATE_RETAILER } from '../../shared/Constants/RouteConstants';
import { TITLES } from '../../shared/Constants/Constant';
import { fetchAllRetailers } from '../../redux/retailer/retailer.actions';
import {
  getAllRetailers,
  isRetailerRequestPending
} from '../../redux/retailer/retailer.selector';
import { getCancelledState } from '../../redux/event/event.selector';
import { resetCancel } from '../../redux/event/event.actions';
import { currentUserRole, currentUserId } from '../../redux/auth/auth.selector';
import { RETAILER_CREATE_ACTION_ALLOWED_USERS } from '../../shared/Constants/UserPermissons';

function Retailer(props) {
  const classes = tableStyles();
  const {
    fetchAllRetailersConnect,
    isRetailerLoading,
    retailersPayload,
    resetCancelConnect,
    isCancelledEvent,
    authUserRole,
    authUserId
  } = props;

  useRefreshFetchReducer(
    fetchAllRetailersConnect,
    resetCancelConnect,
    isCancelledEvent
  );

  return (
    <>
      <CreateToolBar
        route={ROUTE_TO_CREATE_RETAILER}
        buttonText={TITLES.CREATE_RETAILER}
        showSpinner={isRetailerLoading}
        isAuthenticatedToAdd={RETAILER_CREATE_ACTION_ALLOWED_USERS.includes(
          authUserRole
        )}
      />
      <div className={classes.content}>
        <RetailerTable
          retailers={retailersPayload}
          isRetailerLoading={isRetailerLoading}
          currentUserRole={authUserRole}
          currentUserId={authUserId}
        />
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    retailersPayload: getAllRetailers(state),
    isRetailerLoading: isRetailerRequestPending(state),
    isCancelledEvent: getCancelledState(state),
    authUserRole: currentUserRole(state),
    authUserId: currentUserId(state)
  };
};

const mapDispatchToProps = {
  fetchAllRetailersConnect: fetchAllRetailers,
  resetCancelConnect: resetCancel
};

Retailer.defaultProps = {
  retailersPayload: []
};

export default connect(mapStateToProps, mapDispatchToProps)(Retailer);
