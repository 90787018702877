import React, { useCallback } from 'react';
import { StyledTableCell, StyledTableRow } from '../../Styles/TableStyles';
import MaterialButton from '../../components/MaterialButton';
import { Popover, Button } from 'antd';
import history from '../../routes/history';
import { ROUTE_TO_EDIT_PARENT_PRODUCT } from '../../shared/Constants/RouteConstants';

function ParentProductItem(props) {
  const { parentProduct } = props;

  const navigateToEditParentProduct = useCallback((id) => {
    history.push(`${ROUTE_TO_EDIT_PARENT_PRODUCT}${id}`);
  }, []);

  const parentProductPopoverContent = (
    parentProduct,
    navigateToEditParentProduct
  ) => {
    return (
      <div>
        <p style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button
            type="primary"
            onClick={() => navigateToEditParentProduct(parentProduct.id)}
          >
            Edit
          </Button>
          <Button type="danger" onClick={() => false}>
            Delete
          </Button>
        </p>
      </div>
    );
  };

  return (
    <StyledTableRow>
      <StyledTableCell align="center">
        <Popover
          placement="right"
          title={parentProduct.name}
          content={parentProductPopoverContent(
            parentProduct,
            navigateToEditParentProduct
          )}
          trigger="hover"
        >
          <MaterialButton buttonText={parentProduct.name} />
        </Popover>
      </StyledTableCell>
      <StyledTableCell align="center">{parentProduct.brand}</StyledTableCell>
    </StyledTableRow>
  );
}

export default ParentProductItem;
