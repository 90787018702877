import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import Loader from '../../components/BackdropWithLoader';

const PrivateRouteWithLayout = ({
  layout: Layout,
  component: Component,
  auth: { isAuthenticated, isRequestPending },
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) => {
      if (isRequestPending) {
        return <Loader />;
      }
      if (isAuthenticated) {
        return (
          <Layout>
            <Component {...props} />
          </Layout>
        );
      }
      return <Redirect to="/login" />;
    }}
  />
);

const mapStateToProps = (state) => ({
  auth: state.auth
});

export default connect(mapStateToProps)(PrivateRouteWithLayout);
