import _ from 'lodash';
import { SET_ALERT, REMOVE_ALERT } from './alert.types';

const initialState = {};

const alertReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_ALERT:
      return { ...state, [payload.id]: payload };
    case REMOVE_ALERT:
      return _.omit(state, payload);
    default:
      return state;
  }
};

export default alertReducer;
