import React from 'react';
import {
  Table as MuiTable,
  TableBody,
  TableHead,
  TableRow,
  Paper,
  TableContainer
} from '@material-ui/core';
import tableStyles from '../Styles/TableStyles';
import StyledTableCell from '../Styles/StyledTableCell';
import { INVENTORY_HEADER_CONSTANTS } from '../../shared/Constants/TableHeaderConstants';
import { size } from 'lodash';
import InventoryTableItem from './InventoryTableItem';

function InventoryTable(props) {
  const classes = tableStyles();
  const { skus = [] } = props;

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <MuiTable stickyHeader>
          <TableHead>
            <TableRow>
              {Object.keys(INVENTORY_HEADER_CONSTANTS).map((header) => (
                <StyledTableCell
                  key={header}
                  style={{ position: 'sticky' }}
                  align="center"
                >
                  {INVENTORY_HEADER_CONSTANTS[header]}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {size(skus) > 0 ? (
              skus.map((sku, index) => (
                <InventoryTableItem
                  key={sku.sku_id}
                  index={index}
                  sku={sku}
                  EditItemComponent={props.EditItemComponent}
                  customerId={props.retailerId}
                />
              ))
            ) : (
              <TableRow>
                <StyledTableCell align="center" colSpan={5}>
                  No Sku Stock found
                </StyledTableCell>
              </TableRow>
            )}
          </TableBody>
        </MuiTable>
      </TableContainer>
    </Paper>
  );
}

export default React.memo(InventoryTable);
