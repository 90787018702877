import React, { useCallback } from 'react';
import { StyledTableCell, StyledTableRow } from '../../Styles/TableStyles';
import MaterialButton from '../../components/MaterialButton';
import { Button, Popover } from 'antd';
import {
  ROUTE_TO_EDIT_SUPER_STOCKIST,
  ROUTE_TO_DELETE_SUPER_STOCKIST,
  ROUTE_TO_MANAGE_STOCK_SUPER_STOCKIST
} from '../../shared/Constants/RouteConstants';
import {
  CUSTOMER_TYPES_CONSTANTS,
  FORM_SUB_HEADER_TITLES
} from '../../shared/Constants/Constant';
import PlaceOrder from '../TableRowAction/PlaceOrder';
import history from '../../routes/history';

function SuperStockistTableItem({ superStockist, isSuperStockistLoading }) {
  const {
    id: superStockistId,
    name,
    address: { place },
    appointed_districts = [],
    contact
  } = superStockist;

  const pageNavigate = useCallback((route, id) => {
    history.push(`${route}${id}`);
  }, []);

  const superStockistPopoverContent = (superStockist, pageNavigate) => {
    return (
      <div>
        <p>{FORM_SUB_HEADER_TITLES.ADDRESS}</p>
        <p>{superStockist.address.street_address},</p>
        <p>{superStockist.address.landmark},</p>
        <p>{superStockist.address.place}</p>
        <p>{superStockist.address.zip_code}</p>
        <p style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button
            type="primary"
            onClick={() =>
              pageNavigate(ROUTE_TO_EDIT_SUPER_STOCKIST, superStockistId)
            }
          >
            Edit
          </Button>
          <Button
            type="danger"
            onClick={() =>
              pageNavigate(ROUTE_TO_DELETE_SUPER_STOCKIST, superStockistId)
            }
          >
            Delete
          </Button>
        </p>
      </div>
    );
  };

  const appointedDistrictsPopoverContent = (districts) => {
    return (
      <div>
        <p>APPOINTED DISTRICTS:</p>
        {districts.length ? (
          districts.map((district) => <p key={district.id}>{district.name}</p>)
        ) : (
          <p>NONE</p>
        )}
      </div>
    );
  };

  return (
    <StyledTableRow>
      <StyledTableCell component="th" scope="row" align="center">
        <Popover
          placement="right"
          title={name}
          content={superStockistPopoverContent(superStockist, pageNavigate)}
          trigger="hover"
        >
          <MaterialButton buttonText={name} />
        </Popover>
      </StyledTableCell>

      <StyledTableCell component="th" scope="row" align="center">
        {place}
      </StyledTableCell>

      <StyledTableCell component="th" scope="row" align="center">
        <Popover
          placement="right"
          title={name}
          content={appointedDistrictsPopoverContent(appointed_districts)}
          trigger="hover"
        >
          <MaterialButton buttonText={appointed_districts.length} />
        </Popover>
      </StyledTableCell>

      <StyledTableCell component="th" scope="row" align="center">
        {contact.contact_person_name}
      </StyledTableCell>

      <StyledTableCell component="th" scope="row" align="center">
        {contact.contact_number}
      </StyledTableCell>

      <StyledTableCell component="th" scope="row" align="center">
        <PlaceOrder
          customerDetails={superStockist}
          customerType={CUSTOMER_TYPES_CONSTANTS.SUPER_STOCKIST}
          disabled={isSuperStockistLoading}
        />
        <MaterialButton
          buttonText="Stock"
          handleClick={() =>
            pageNavigate(ROUTE_TO_MANAGE_STOCK_SUPER_STOCKIST, superStockistId)
          }
          disabled={isSuperStockistLoading}
        />
      </StyledTableCell>
    </StyledTableRow>
  );
}

export default SuperStockistTableItem;
