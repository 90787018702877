import React from 'react';
import { TextField } from '@material-ui/core';

function FormInput({
  type,
  label,
  placeholder,
  name,
  value,
  onChange,
  ...otherProps
}) {
  return (
    <TextField
      type={type}
      label={label}
      placeholder={placeholder}
      name={name}
      value={value}
      onChange={onChange}
      fullWidth
      variant="outlined"
      margin="normal"
      {...otherProps}
    />
  );
}

export default React.memo(FormInput);
