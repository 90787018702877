import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Avatar,
  CircularProgress,
  MenuItem
} from '@material-ui/core';
import { connect } from 'react-redux';
import { LocalShipping } from '@material-ui/icons';
import commonStyles from '../../../Styles/CommonStyles';
import FormSelect from '../../FormSelect';
import MaterialButton from '../../MaterialButton';
import { editPrimaryOrder } from '../../../redux/primary-order/primary-order.actions';
import { isPrimaryOrderModalRequestPending } from '../../../redux/primary-order/primary-order.selector';
import { isEqual } from 'lodash';
import {
  PRIMARY_ORDER_STATUS,
  PRIMARY_ORDER_CONSTANTS,
  CUSTOMER_TYPES_CONSTANTS
} from '../../../shared/Constants/Constant';

function EditPrimaryOrderStatus(props) {
  const {
    open,
    handleClose,
    order,
    isRequestPending,
    editPrimaryOrderConnect,
    errorMessage
  } = props;
  const classes = commonStyles();
  const [orderStatus, setOrderStatus] = useState(props.order.status);

  const handleModalClose = () => {
    setOrderStatus(props.order.status);
    handleClose();
  };

  useEffect(() => {
    setOrderStatus(props.order.status);
  }, [props.order.status]);

  const isOrderStatusChanged = () => {
    return !isEqual(orderStatus, order.status);
  };

  const updateShipmentSubmit = () => {
    if (isOrderStatusChanged()) {
      editPrimaryOrderConnect(
        {
          primaryOrderId: order.id,
          orderDetails: {
            status: orderStatus
          }
        },
        handleModalClose
      );
    }
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleModalClose}
        classes={{
          paperWidthSm: classes.paperWidthSm
        }}
      >
        <DialogTitle id="form-dialog-title">
          <Avatar className={classes.avatar}>
            <LocalShipping color="primary" />
          </Avatar>
          <Typography align="center">Update Primary Order Status</Typography>

          {isRequestPending && (
            <div className={classes.modalSpinner}>
              <CircularProgress />
            </div>
          )}
        </DialogTitle>
        <DialogContent>
          <FormSelect
            label="Primary Order Status"
            name="orderStatus"
            value={orderStatus}
            onChange={(e) => setOrderStatus(e.target.value)}
            showDefaultEmptyValue={false}
          >
            <MenuItem value={PRIMARY_ORDER_CONSTANTS.ORDERED} disabled>
              {PRIMARY_ORDER_STATUS.ORDERED}
            </MenuItem>
            <MenuItem value={PRIMARY_ORDER_CONSTANTS.ORDER_APPROVED}>
              {PRIMARY_ORDER_STATUS.ORDER_APPROVED}
            </MenuItem>
            <MenuItem
              value={PRIMARY_ORDER_CONSTANTS.ORDER_CANCELLED_BY_FACTORY}
            >
              {PRIMARY_ORDER_STATUS.ORDER_CANCELLED_BY_FACTORY}
            </MenuItem>
            {order.customer_type === CUSTOMER_TYPES_CONSTANTS.SUPER_STOCKIST ? (
              <MenuItem
                value={
                  PRIMARY_ORDER_CONSTANTS.ORDER_CANCELLED_BY_SUPER_STOCKIST
                }
              >
                {PRIMARY_ORDER_STATUS.ORDER_CANCELLED_BY_SUPER_STOCKIST}
              </MenuItem>
            ) : (
              <MenuItem
                value={PRIMARY_ORDER_CONSTANTS.ORDER_CANCELLED_BY_DISTRIBUTOR}
              >
                {PRIMARY_ORDER_STATUS.ORDER_CANCELLED_BY_DISTRIBUTOR}
              </MenuItem>
            )}
            {props.order.status !== PRIMARY_ORDER_CONSTANTS.ORDERED && (
              <MenuItem value={PRIMARY_ORDER_CONSTANTS.REACHED_DESTINATION}>
                {PRIMARY_ORDER_STATUS.REACHED_DESTINATION}
              </MenuItem>
            )}
          </FormSelect>

          {!!errorMessage && (
            <Typography variant="h5" style={{ color: '#d50000', fontSize: 13 }}>
              {`**${errorMessage}`}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <MaterialButton
            handleClick={handleModalClose}
            buttonText="Cancel"
            disabled={isRequestPending}
          />
          <MaterialButton
            handleClick={updateShipmentSubmit}
            buttonText="Confirm"
            disabled={isRequestPending || !isOrderStatusChanged()}
          />
        </DialogActions>
      </Dialog>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    isRequestPending: isPrimaryOrderModalRequestPending(state)
  };
};

const mapDispatchToProps = {
  editPrimaryOrderConnect: editPrimaryOrder
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditPrimaryOrderStatus);
