import React, { useState } from 'react';
import {
  MenuItem,
  Checkbox,
  ListItemText,
  Typography
} from '@material-ui/core';
import formStyles from '../Styles/FormStyles';
import FormCancelButton from '../FormCancelButton';
import FormSubmitButton from '../FormSubmitButton';
import FormMultiSelect from '../FormMultiSelect';
import {
  getAssignedZoneIds,
  getAssignedDistrictIds,
  getAssignedAreaIds
} from './Utils';
import { get, isEmpty, size } from 'lodash';
import { checkIfArraysAreEqual } from '../../shared/Utils/CommonUtils';

const NO_BEATS_ASSIGNED = 'Currently No Beats assigned to the user';

function AssignUserTerritoryForm(props) {
  const {
    onSubmit,
    onCancel,
    zonesPayload = [],
    districtsPayload = [],
    areasPayload = [],
    beatsPayload = [],
    user = {}
  } = props;

  const classes = formStyles();

  const [assignedZones, setAssignedZones] = useState(
    getAssignedZoneIds(user.assigned_beats, beatsPayload)
  );

  const [assignedDistricts, setAssignedDistricts] = useState(
    getAssignedDistrictIds(user.assigned_beats, beatsPayload)
  );

  const [assignedAreas, setAssignedAreas] = useState(
    getAssignedAreaIds(user.assigned_beats, beatsPayload)
  );

  const [assignedBeats, setAssignedBeats] = useState(
    get(user, 'assigned_beats', [])
  );

  const [beatError, setBeatError] = useState('');

  const handleSubmitDisabledState = () => {
    return checkIfArraysAreEqual(
      assignedBeats,
      get(user, 'assigned_beats', [])
    );
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const userData = {
      assigned_beats: [...assignedBeats]
    };
    onSubmit(userData);
  };

  const onChangeZoneAssign = (e) => {
    e.persist();
    const { value } = e.target;

    if (!value.length) {
      setAssignedZones([]);
      setAssignedDistricts([]);
      setAssignedAreas([]);
      setAssignedBeats([]);
      return;
    }

    let districtIds = [];
    let areaIds = [];
    let beatIds = [];

    setAssignedZones(value);

    value.map((zoneId) => {
      const filterAlreadyAssignedDistricts = districtsPayload
        .filter(
          (district) =>
            assignedDistricts.includes(district.id) &&
            district.zone.id === zoneId
        )
        .map((filteredDistrict) => filteredDistrict.id);
      districtIds = districtIds.concat(filterAlreadyAssignedDistricts);

      const filterAlreadyAssignedAreas = areasPayload
        .filter(
          (area) => assignedAreas.includes(area.id) && area.zone.id === zoneId
        )
        .map((filteredArea) => filteredArea.id);

      areaIds = areaIds.concat(filterAlreadyAssignedAreas);

      const filterAlreadyAssignedBeats = beatsPayload
        .filter(
          (beat) => assignedBeats.includes(beat.id) && beat.zone.id === zoneId
        )
        .map((filteredBeat) => filteredBeat.id);
      beatIds = beatIds.concat(filterAlreadyAssignedBeats);

      return value;
    });

    setAssignedDistricts(districtIds);
    setAssignedAreas(areaIds);
    setAssignedBeats(beatIds);
  };

  const onChangeDistrictAssign = (e) => {
    e.persist();
    const { value } = e.target;

    if (!value.length) {
      setAssignedDistricts([]);
      setAssignedAreas([]);
      setAssignedBeats([]);
      return;
    }

    setAssignedDistricts(value);
    let areaIds = [];
    let beatIds = [];

    value.map((districtId) => {
      const filterAlreadyAssignedAreas = areasPayload
        .filter(
          (area) =>
            assignedAreas.includes(area.id) && area.district.id === districtId
        )
        .map((filteredArea) => filteredArea.id);

      areaIds = areaIds.concat(filterAlreadyAssignedAreas);

      const filterAlreadyAssignedBeats = beatsPayload
        .filter(
          (beat) =>
            assignedBeats.includes(beat.id) && beat.district.id === districtId
        )
        .map((filteredBeat) => filteredBeat.id);
      beatIds = beatIds.concat(filterAlreadyAssignedBeats);

      return value;
    });

    setAssignedAreas(areaIds);
    setAssignedBeats(beatIds);
  };

  const onChangeAreaAssign = (e) => {
    e.persist();
    const { value } = e.target;

    if (!value.length) {
      setAssignedAreas([]);
      setAssignedBeats([]);
      return;
    }
    let beatIds = [];

    value.map((areaId) => {
      const filterAlreadyAssignedBeats = beatsPayload
        .filter((beat) => beat.area.id === areaId)
        .map((filteredBeat) => filteredBeat.id);

      beatIds = beatIds.concat(filterAlreadyAssignedBeats);

      return areaId;
    });

    setAssignedAreas(value);
    setAssignedBeats(beatIds);
  };

  const onChangeBeatAssign = (e) => {
    e.persist();
    const { value } = e.target;

    if (beatError) {
      setBeatError('');
    }

    if (!value.length) {
      setAssignedBeats([]);
      return;
    }

    setAssignedBeats(value);
  };

  return (
    <form className={classes.form} noValidate autoComplete="off">
      {!isEmpty(user) ? (
        <Typography align="center" color="primary" variant="h6">
          {`Employee Name: ${user.name}`}
        </Typography>
      ) : null}

      <FormMultiSelect
        label="Choose Zones"
        value={assignedZones}
        payload={zonesPayload}
        onChange={onChangeZoneAssign}
      >
        {zonesPayload.map((zone) => {
          const { id } = zone;
          return (
            <MenuItem key={id} value={id}>
              <Checkbox
                checked={assignedZones.indexOf(id) > -1}
                color="primary"
              />
              <ListItemText primary={zone.name} />
            </MenuItem>
          );
        })}
      </FormMultiSelect>

      <FormMultiSelect
        label="Choose Districts"
        value={assignedDistricts}
        payload={districtsPayload}
        onChange={onChangeDistrictAssign}
      >
        {assignedZones.length &&
          districtsPayload
            .filter((district) => assignedZones.includes(district.zone.id))
            .map((district) => {
              const { id } = district;
              return (
                <MenuItem key={id} value={id}>
                  <Checkbox
                    checked={assignedDistricts.indexOf(id) > -1}
                    color="primary"
                  />
                  <ListItemText primary={district.name} />
                </MenuItem>
              );
            })}
      </FormMultiSelect>

      <FormMultiSelect
        label="Choose Areas"
        value={assignedAreas}
        payload={areasPayload}
        onChange={onChangeAreaAssign}
      >
        {assignedDistricts.length &&
          areasPayload
            .filter((area) => assignedDistricts.includes(area.district.id))
            .map((area) => {
              const { id } = area;
              return (
                <MenuItem key={id} value={id}>
                  <Checkbox
                    checked={assignedAreas.indexOf(id) > -1}
                    color="primary"
                  />
                  <ListItemText primary={area.name} />
                </MenuItem>
              );
            })}
      </FormMultiSelect>

      <FormMultiSelect
        label="Choose Beats"
        value={assignedBeats}
        payload={beatsPayload}
        onChange={onChangeBeatAssign}
        error={!!beatError}
        helperText={beatError}
      >
        {assignedAreas.length &&
          beatsPayload
            .filter((beat) => assignedAreas.includes(beat.area.id))
            .map((beat) => {
              const { id } = beat;
              return (
                <MenuItem key={id} value={id}>
                  <Checkbox
                    checked={assignedBeats.indexOf(id) > -1}
                    color="primary"
                  />
                  <ListItemText primary={beat.name} />
                </MenuItem>
              );
            })}
      </FormMultiSelect>

      {!size(assignedBeats) ? (
        <Typography align="center" color="primary" variant="h6">
          {`** ${NO_BEATS_ASSIGNED}`}
        </Typography>
      ) : null}

      <div
        className={classes.buttonRow}
        style={{ marginTop: beatError ? 30 : 0 }}
      >
        <FormCancelButton onClickCancel={onCancel} />
        <FormSubmitButton
          onClickSubmit={handleFormSubmit}
          disabled={handleSubmitDisabledState()}
        />
      </div>
    </form>
  );
}

export default AssignUserTerritoryForm;
