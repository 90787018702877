import React, { useCallback } from 'react';
import {
  Table as MuiTable,
  TableBody,
  TableHead,
  TableRow,
  Paper,
  TableContainer,
  IconButton
} from '@material-ui/core';
import { LockOpen, Place, Lock } from '@material-ui/icons';
import tableStyles from '../Styles/TableStyles';
import { StyledTableCell, StyledTableRow } from '../../Styles/TableStyles';
import { USER_HEADER_CONSTANTS } from '../../shared/Constants/TableHeaderConstants';
import { USER_ROLES } from '../../shared/Constants/Constant';
import commonStyles from '../../Styles/CommonStyles';
import history from '../../routes/history';

function UserTable(props) {
  const classes = tableStyles();
  const commonClasses = commonStyles();
  const { users } = props;

  const navigateToAssignTerritory = useCallback((id) => {
    history.push(`/user/assign-territory/${id}`);
  }, []);

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.tableWithTopButtonContainer}>
        <MuiTable stickyHeader>
          <TableHead>
            <TableRow>
              {Object.keys(USER_HEADER_CONSTANTS).map((header) => (
                <StyledTableCell
                  key={header}
                  style={{ position: 'sticky' }}
                  align="center"
                >
                  {USER_HEADER_CONSTANTS[header]}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user) => {
              const {
                id: userId,
                name,
                user_name: userName,
                mobile_number: mobile,
                role,
                allowed_apps: apps,
                is_inactive: isInactive
              } = user;

              return (
                <StyledTableRow key={userId}>
                  <StyledTableCell align="center">{name}</StyledTableCell>
                  <StyledTableCell align="center">{`${userName} ${
                    isInactive ? '[Inactive]' : ''
                  }`}</StyledTableCell>
                  <StyledTableCell align="center">{mobile}</StyledTableCell>
                  <StyledTableCell align="center">
                    {USER_ROLES[role]}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {apps
                      .map((app) => app.charAt(0).toUpperCase() + app.substr(1))
                      .join(', ')}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <div className={commonClasses.divisonCenter}>
                      {
                        <IconButton
                          color="inherit"
                          onClick={() => navigateToAssignTerritory(userId)}
                        >
                          <Place />
                        </IconButton>
                      }
                    </div>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <div className={commonClasses.divisonCenter}>
                      {isInactive ? (
                        <IconButton
                          color="inherit"
                          onClick={() =>
                            props.editUser(userId, { is_inactive: false })
                          }
                        >
                          <LockOpen />
                        </IconButton>
                      ) : (
                        <IconButton
                          color="inherit"
                          onClick={() =>
                            props.editUser(userId, {
                              is_inactive: true
                            })
                          }
                        >
                          <Lock />
                        </IconButton>
                      )}
                    </div>
                  </StyledTableCell>
                </StyledTableRow>
              );
            })}
          </TableBody>
        </MuiTable>
      </TableContainer>
    </Paper>
  );
}

export default React.memo(UserTable);
