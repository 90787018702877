import React from 'react';
import {
  Table as MuiTable,
  TableBody,
  TableHead,
  TableRow,
  Paper,
  TableContainer
} from '@material-ui/core';

import tableStyles from '../Styles/TableStyles';
import StyledTableCell from '../Styles/StyledTableCell';
import { USER_ATTENDANCE_HEADER_CONSTANTS } from '../../shared/Constants/TableHeaderConstants';
import UserAttendanceTableItem from './UserAttendanceTableItem';

function UserAttendanceTable(props) {
  const classes = tableStyles();
  const { attendances = [], isLoading } = props;

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <MuiTable stickyHeader>
          <TableHead>
            <TableRow>
              {props.showName && (
                <StyledTableCell align="center">
                  {USER_ATTENDANCE_HEADER_CONSTANTS.NAME}
                </StyledTableCell>
              )}
              {props.showRole && (
                <StyledTableCell align="center">
                  {USER_ATTENDANCE_HEADER_CONSTANTS.ROLE}
                </StyledTableCell>
              )}
              {props.showDate && (
                <StyledTableCell align="center">
                  {USER_ATTENDANCE_HEADER_CONSTANTS.DATE}
                </StyledTableCell>
              )}
              {props.showStartTime && (
                <StyledTableCell align="center">
                  {USER_ATTENDANCE_HEADER_CONSTANTS.STATE_TIME}
                </StyledTableCell>
              )}
              {props.showEndTime && (
                <StyledTableCell align="center">
                  {USER_ATTENDANCE_HEADER_CONSTANTS.END_TIME}
                </StyledTableCell>
              )}
              {props.showWorkHours && (
                <StyledTableCell align="center">
                  {USER_ATTENDANCE_HEADER_CONSTANTS.WORK_HRS}
                </StyledTableCell>
              )}
              {props.showLocation && (
                <StyledTableCell align="center">
                  {USER_ATTENDANCE_HEADER_CONSTANTS.LOCATION}
                </StyledTableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {attendances.map((attendance) => {
              return (
                <UserAttendanceTableItem
                  key={attendance.attendance_id}
                  userAttendance={attendance}
                  isLoading={isLoading}
                  handleUserId={props.handleUserId}
                  showName={props.showName}
                  showRole={props.showRole}
                  showDate={props.showDate}
                  showStartTime={props.showStartTime}
                  showEndTime={props.showEndTime}
                  showWorkHours={props.showWorkHours}
                  showLocation={props.showLocation}
                />
              );
            })}
          </TableBody>
        </MuiTable>
      </TableContainer>
    </Paper>
  );
}

export default React.memo(UserAttendanceTable);
