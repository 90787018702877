import React from 'react';
import { connect } from 'react-redux';
import tableStyles from '../../components/Styles/TableStyles';
import CreateToolBar from '../../shared/CreateToolBar';
import SkuTable from '../../components/SkuTable';
import { ROUTE_TO_CREATE_SKU } from '../../shared/Constants/RouteConstants';
import { TITLES } from '../../shared/Constants/Constant';
import { resetCancel } from '../../redux/event/event.actions';
import { fetchAllSkus } from '../../redux/sku/sku.actions';
import { getCancelledState } from '../../redux/event/event.selector';
import { isSkuRequestPending, getAllSkus } from '../../redux/sku/sku.selector';
import useRefreshFetchReducer from '../../shared/Hooks/useRefreshFetchReducer';
import { ONLY_ADMIN } from '../../shared/Constants/UserPermissons';
import { currentUserRole, currentUserId } from '../../redux/auth/auth.selector';

function Sku(props) {
  const classes = tableStyles();
  const {
    skusPayload,
    isSkusLoading,
    fetchAllSkusConnect,
    resetCancelConnect,
    isCancelledEvent,
    authUserRole,
    authUserId
  } = props;

  useRefreshFetchReducer(
    fetchAllSkusConnect,
    resetCancelConnect,
    isCancelledEvent
  );

  return (
    <>
      <CreateToolBar
        route={ROUTE_TO_CREATE_SKU}
        buttonText={TITLES.CREATE_SKU}
        showSpinner={isSkusLoading}
        isAuthenticatedToAdd={ONLY_ADMIN === authUserRole}
      />
      <div className={classes.content}>
        <SkuTable
          skus={skusPayload}
          isSkusLoading={isSkusLoading}
          currentUserRole={authUserRole}
          currentUserId={authUserId}
        />
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    skusPayload: getAllSkus(state),
    isSkusLoading: isSkuRequestPending(state),
    isCancelledEvent: getCancelledState(state),
    authUserRole: currentUserRole(state),
    authUserId: currentUserId(state)
  };
};

const mapDispatchToProps = {
  fetchAllSkusConnect: fetchAllSkus,
  resetCancelConnect: resetCancel
};

export default connect(mapStateToProps, mapDispatchToProps)(Sku);
