import _ from 'lodash';
import * as DistributorActionTypes from './distributor.types';

const initialState = {
  data: {},
  isRequestPending: false,
  isDistributorInventoryModalRequestPending: false
};

const distributorDataReducer = (state = {}, action) => {
  const { payload, type } = action;

  switch (type) {
    case DistributorActionTypes.FETCH_DISTRIBUTORS_SUCCESS:
      return { ..._.mapKeys(payload, 'id') };

    case DistributorActionTypes.FETCH_DISTRIBUTOR_SUCCESS:
      return { ...state, [payload.id]: payload };

    case DistributorActionTypes.FETCH_DISTRIBUTOR_INVENTORY_SUCCESS:
      return {
        ...state,
        [payload.id]: {
          ...state[payload.id],
          sku_items: { ..._.mapKeys(payload.sku_items, 'inventory_id') }
        }
      };

    case DistributorActionTypes.CREATE_DISTRIBUTOR_INVENTORY_SUCCESS:
    case DistributorActionTypes.UPDATE_DISTRIBUTOR_INVENTORY_SUCCESS:
      return {
        ...state,
        [payload.distributor]: {
          ...state[payload.distributor],
          sku_items: {
            ...state[payload.distributor].sku_items,
            [payload.inventory_id]: payload
          }
        }
      };

    case DistributorActionTypes.CREATE_DISTRIBUTOR_SUCCESS:
      return { ...state, [payload.id]: payload };

    case DistributorActionTypes.UPDATE_DISTRIBUTOR_SUCCESS:
      return { ...state, [payload.id]: payload };

    case DistributorActionTypes.DELETE_DISTRIBUTOR_SUCCESS:
      return _.omit(state, payload);

    default:
      return state;
  }
};

const distributorReducer = (state = initialState, action) => {
  const { type } = action;

  switch (type) {
    case DistributorActionTypes.FETCH_DISTRIBUTORS_REQUEST:
      return {
        ...state,
        isRequestPending: true
      };

    case DistributorActionTypes.FETCH_DISTRIBUTORS_SUCCESS:
      return {
        ...state,
        isRequestPending: false,
        data: distributorDataReducer(state.data, action)
      };

    case DistributorActionTypes.FETCH_DISTRIBUTORS_ERROR:
      return {
        ...state,
        isRequestPending: false
      };

    case DistributorActionTypes.FETCH_DISTRIBUTOR_REQUEST:
      return {
        ...state,
        isRequestPending: true
      };

    case DistributorActionTypes.FETCH_DISTRIBUTOR_SUCCESS:
      return {
        ...state,
        isRequestPending: false,
        data: distributorDataReducer(state.data, action)
      };

    case DistributorActionTypes.FETCH_DISTRIBUTOR_ERROR:
      return {
        ...state,
        isRequestPending: false
      };

    case DistributorActionTypes.FETCH_DISTRIBUTOR_INVENTORY_REQUEST:
      return {
        ...state,
        isRequestPending: true
      };

    case DistributorActionTypes.FETCH_DISTRIBUTOR_INVENTORY_SUCCESS:
      return {
        ...state,
        isRequestPending: false,
        data: distributorDataReducer(state.data, action)
      };

    case DistributorActionTypes.FETCH_DISTRIBUTOR_INVENTORY_ERROR:
      return {
        ...state,
        isRequestPending: false
      };

    case DistributorActionTypes.CREATE_DISTRIBUTOR_REQUEST:
      return {
        ...state,
        isRequestPending: true
      };

    case DistributorActionTypes.CREATE_DISTRIBUTOR_SUCCESS:
      return {
        ...state,
        isRequestPending: false,
        data: distributorDataReducer(state.data, action)
      };

    case DistributorActionTypes.CREATE_DISTRIBUTOR_ERROR:
      return {
        ...state,
        isRequestPending: false
      };

    case DistributorActionTypes.CREATE_DISTRIBUTOR_INVENTORY_REQUEST:
      return {
        ...state,
        isDistributorInventoryModalRequestPending: true
      };

    case DistributorActionTypes.CREATE_DISTRIBUTOR_INVENTORY_SUCCESS:
      return {
        ...state,
        isDistributorInventoryModalRequestPending: false,
        data: distributorDataReducer(state.data, action)
      };

    case DistributorActionTypes.CREATE_DISTRIBUTOR_INVENTORY_ERROR:
      return {
        ...state,
        isDistributorInventoryModalRequestPending: false
      };

    case DistributorActionTypes.UPDATE_DISTRIBUTOR_REQUEST:
      return {
        ...state,
        isRequestPending: true
      };

    case DistributorActionTypes.UPDATE_DISTRIBUTOR_SUCCESS:
      return {
        ...state,
        isRequestPending: false,
        data: distributorDataReducer(state.data, action)
      };

    case DistributorActionTypes.UPDATE_DISTRIBUTOR_ERROR:
      return {
        ...state,
        isRequestPending: false
      };

    case DistributorActionTypes.UPDATE_DISTRIBUTOR_INVENTORY_REQUEST:
      return {
        ...state,
        isDistributorInventoryModalRequestPending: true
      };

    case DistributorActionTypes.UPDATE_DISTRIBUTOR_INVENTORY_SUCCESS:
      return {
        ...state,
        isDistributorInventoryModalRequestPending: false,
        data: distributorDataReducer(state.data, action)
      };

    case DistributorActionTypes.UPDATE_DISTRIBUTOR_INVENTORY_ERROR:
      return {
        ...state,
        isDistributorInventoryModalRequestPending: false
      };

    case DistributorActionTypes.DELETE_DISTRIBUTOR_REQUEST:
      return {
        ...state,
        isRequestPending: true
      };

    case DistributorActionTypes.DELETE_DISTRIBUTOR_SUCCESS:
      return {
        ...state,
        isRequestPending: false,
        data: distributorDataReducer(state.data, action)
      };

    case DistributorActionTypes.DELETE_DISTRIBUTOR_ERROR:
      return {
        ...state,
        isRequestPending: false
      };

    default:
      return state;
  }
};

export default distributorReducer;
