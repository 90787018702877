import { get, has } from 'lodash';
import { createSelector } from 'reselect';
import { formatInInr } from '../../shared/Utils/CommonUtils';

export const secondaryOrderSelector = (state) => state.secondaryOrders.data;
export const secondaryOrderCartSelector = (state) => state.secondaryOrders.cart;
export const secondaryOrderDataSelector = (state) => state.secondaryOrders.data;
export const secondaryOrderByIdSelector = (state, props) =>
  props.match.params.id;
export const skuSelector = (state) => state.skus.data;

export const getAllSecondaryOrders = createSelector(
  secondaryOrderSelector,
  (secondaryOrders) => Object.values(secondaryOrders)
);

export const isSecondaryOrderRequestPending = (state) =>
  state.secondaryOrders.isRequestPending;

export const isSecondaryOrderModalRequestPending = (state) =>
  state.secondaryOrders.isModalUpdateRequestPending;

export const secondaryOrderPaginationSelector = (state) =>
  state.secondaryOrders.pagination;

export const isNextPageSecondaryOrder = createSelector(
  secondaryOrderPaginationSelector,
  (pagination) => has(pagination, 'next.page')
);

export const nextPageSecondaryOrder = createSelector(
  secondaryOrderPaginationSelector,
  (pagination) => get(pagination, 'next.page', 1)
);

export const getCurrentPaginationPage = (state) =>
  state.secondaryOrders.secondaryOrderPaginationPage;

export const getErrorMessage = (state) => state.secondaryOrders.error.message;

export const getSecondaryOrderById = createSelector(
  [secondaryOrderSelector, secondaryOrderByIdSelector],
  (secondaryOrders, id) => secondaryOrders[id]
);

export const getSecondaryOrderCartItems = createSelector(
  [secondaryOrderCartSelector],
  (secondaryOrderCartItems) => Object.values(secondaryOrderCartItems)
);

export const getSecondaryOrderCartTotalQtyInPcs = createSelector(
  [getSecondaryOrderCartItems],
  (secondaryOrderCartItems) => {
    return secondaryOrderCartItems.reduce((total, cartItem) => {
      return (total += Number(cartItem.quantityInPcs));
    }, 0);
  }
);

export const getSecondaryOrderTotalCostQuantity = createSelector(
  [getSecondaryOrderCartItems],
  (secondaryOrderCartItems) => {
    return formatInInr(
      secondaryOrderCartItems.reduce((total, cartItem) => {
        return (total += Number(cartItem.totalSkuCost));
      }, 0)
    );
  }
);

export const getFilteredSkusPayload = createSelector(
  [skuSelector, secondaryOrderCartSelector],
  (skusPayload, cartItems) => {
    return Object.values(skusPayload).filter(
      (sku) => !Object.keys(cartItems).includes(sku.id)
    );
  }
);

export const getSecondaryOrderCustomer = (state) =>
  state.secondaryOrders.customer;
