import React from 'react';
import { connect } from 'react-redux';
import { Container, Avatar, Typography } from '@material-ui/core';
import CategoryIcon from '@material-ui/icons/Category';
import formStyles from '../../../components/Styles/FormStyles';
import { TITLES } from '../../../shared/Constants/Constant';
import { createProduct } from '../../../redux/product/product.actions';
import { ROUTE_TO_PARENT_PRODUCT } from '../../../shared/Constants/RouteConstants';
import { setCancel } from '../../../redux/event/event.actions';
import { isProductRequestPending } from '../../../redux/product/product.selector';
import history from '../../../routes/history';
import { getCancelledState } from '../../../redux/event/event.selector';
import BackdropWithLoader from '../../../components/BackdropWithLoader';
import CreateParentProductForm from '../../../components/CreateParentProductForm';

const CreateParentProduct = (props) => {
  const { createProductConnect, setCancelConnect, isProductsLoading } = props;
  const classes = formStyles();

  const handleSubmit = (formValues) => {
    createProductConnect(formValues);
  };

  const handleCancel = () => {
    setCancelConnect();
    history.push(ROUTE_TO_PARENT_PRODUCT);
  };

  if (isProductsLoading) {
    return <BackdropWithLoader />;
  }

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <CategoryIcon color="primary" />
        </Avatar>
        <Typography component="h1" variant="h5">
          {TITLES.CREATE_PARENT_PRODUCT}
        </Typography>
        <CreateParentProductForm
          onSubmit={handleSubmit}
          onCancel={handleCancel}
        />
      </div>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    isProductsLoading: isProductRequestPending(state),
    isCancelledEvent: getCancelledState(state)
  };
};

const mapDispatchToProps = {
  createProductConnect: createProduct,
  setCancelConnect: setCancel
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateParentProduct);
