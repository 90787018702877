import * as SuccessActionTypes from './success.types';

const initState = {
  successMessage: null,
  showSuccessNotification: false
};

const successReducer = (state = initState, action) => {
  const { success } = action;

  if (success) {
    return {
      successMessage: success,
      showSuccessNotification: true
    };
  }

  if (action.type === SuccessActionTypes.HIDE_SUCCESS) {
    return {
      successMessage: null,
      showSuccessNotification: false
    };
  }
  return state;
};

export default successReducer;
