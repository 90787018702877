export const FETCH_SUPER_STOCKISTS_REQUEST = 'FETCH_SUPER_STOCKISTS_REQUEST';
export const FETCH_SUPER_STOCKIST_REQUEST = 'FETCH_SUPER_STOCKIST_REQUEST';
export const FETCH_SUPER_STOCKIST_INVENTORY_REQUEST =
  'FETCH_SUPER_STOCKIST_INVENTORY_REQUEST';
export const CREATE_SUPER_STOCKIST_REQUEST = 'CREATE_SUPER_STOCKIST_REQUEST';
export const CREATE_SUPER_STOCKIST_INVENTORY_REQUEST =
  'CREATE_SUPER_STOCKIST_INVENTORY_REQUEST';
export const UPDATE_SUPER_STOCKIST_REQUEST = 'UPDATE_SUPER_STOCKIST_REQUEST';
export const UPDATE_SUPER_STOCKIST_INVENTORY_REQUEST =
  'UPDATE_SUPER_STOCKIST_INVENTORY_REQUEST';
export const DELETE_SUPER_STOCKIST_REQUEST = 'DELETE_SUPER_STOCKIST_REQUEST';

export const FETCH_SUPER_STOCKISTS_SUCCESS = 'FETCH_SUPER_STOCKISTS_SUCCESS';
export const FETCH_SUPER_STOCKIST_SUCCESS = 'FETCH_SUPER_STOCKIST_SUCCESS';
export const FETCH_SUPER_STOCKIST_INVENTORY_SUCCESS =
  'FETCH_SUPER_STOCKIST_INVENTORY_SUCCESS';
export const CREATE_SUPER_STOCKIST_SUCCESS = 'CREATE_SUPER_STOCKIST_SUCCESS';
export const CREATE_SUPER_STOCKIST_INVENTORY_SUCCESS =
  'CREATE_SUPER_STOCKIST_INVENTORY_SUCCESS';
export const UPDATE_SUPER_STOCKIST_SUCCESS = 'UPDATE_SUPER_STOCKIST_SUCCESS';
export const UPDATE_SUPER_STOCKIST_INVENTORY_SUCCESS =
  'UPDATE_SUPER_STOCKIST_INVENTORY_SUCCESS';
export const DELETE_SUPER_STOCKIST_SUCCESS = 'DELETE_SUPER_STOCKIST_SUCCESS';

export const FETCH_SUPER_STOCKISTS_ERROR = 'FETCH_SUPER_STOCKISTS_ERROR';
export const FETCH_SUPER_STOCKIST_ERROR = 'FETCH_SUPER_STOCKIST_ERROR';
export const FETCH_SUPER_STOCKIST_INVENTORY_ERROR =
  'FETCH_SUPER_STOCKIST_INVENTORY_ERROR';
export const CREATE_SUPER_STOCKIST_ERROR = 'CREATE_SUPER_STOCKIST_ERROR';
export const CREATE_SUPER_STOCKIST_INVENTORY_ERROR =
  'CREATE_SUPER_STOCKIST_INVENTORY_ERROR';
export const UPDATE_SUPER_STOCKIST_ERROR = 'UPDATE_SUPER_STOCKIST_ERROR';
export const UPDATE_SUPER_STOCKIST_INVENTORY_ERROR =
  'UPDATE_SUPER_STOCKIST_INVENTORY_ERROR';
export const DELETE_SUPER_STOCKIST_ERROR = 'DELETE_SUPER_STOCKIST_ERROR';
