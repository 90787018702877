import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Button, CircularProgress, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  spacer: {
    flexGrow: 1
  },
  spinner: {
    marginRight: '20px'
  },
  refreshText: {
    marginRight: '20px'
  },
  toolBarButton: {
    backgroundColor: '#787878',
    color: '#FFF',
    '&:hover': {
      backgroundColor: '#585858'
    }
  }
}));

function CreateToolBar(props) {
  const classes = useStyles();
  const {
    showSpinner = false,
    route,
    buttonText,
    isAuthenticatedToAdd
  } = props;

  return (
    <div className={classes.row}>
      <span className={classes.spacer} />
      {showSpinner && (
        <>
          <div className={classes.spinner}>
            <CircularProgress />
          </div>
          <div className={classes.refreshText}>
            <Typography variant="h6">Refreshing...</Typography>
          </div>
        </>
      )}

      {isAuthenticatedToAdd && (
        <Button
          variant="contained"
          component={Link}
          to={route}
          disabled={showSpinner}
          className={classes.toolBarButton}
        >
          {buttonText}
        </Button>
      )}
    </div>
  );
}

export default React.memo(CreateToolBar);
