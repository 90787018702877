import RetailerApi from '../../apis/RetailerAPI';
import * as RetailerActionTypes from './retailer.types';
import STATUS from '../../shared/Constants/apiStatus';
import { ROUTE_TO_RETAILER } from '../../shared/Constants/RouteConstants';

export const fetchAllRetailers = () => async (dispatch) => {
  dispatch({
    type: RetailerActionTypes.FETCH_RETAILERS_REQUEST
  });

  try {
    const response = await RetailerApi.getAllRetailers();

    if (response.data.status === STATUS.OK) {
      dispatch({
        type: RetailerActionTypes.FETCH_RETAILERS_SUCCESS,
        payload: response.data.retailers,
        error: null,
        route: null,
        success: null
      });
    }
  } catch (error) {
    dispatch({
      type: RetailerActionTypes.FETCH_RETAILERS_ERROR,
      error: error.response.data,
      payload: null,
      route: null,
      success: null
    });
  }
};

export const fetchRetailer = (id) => async (dispatch) => {
  dispatch({
    type: RetailerActionTypes.FETCH_RETAILER_REQUEST
  });

  try {
    const response = await RetailerApi.getRetailer(id);
    if (response.data.status === STATUS.OK) {
      dispatch({
        type: RetailerActionTypes.FETCH_RETAILER_SUCCESS,
        payload: response.data.retailer,
        error: null,
        route: null,
        success: null
      });
    }
  } catch (error) {
    dispatch({
      type: RetailerActionTypes.FETCH_RETAILER_ERROR,
      error: error.response.data,
      payload: null,
      route: ROUTE_TO_RETAILER,
      success: null
    });
  }
};

export const fetchRetailerInventory = (id) => async (dispatch) => {
  dispatch({
    type: RetailerActionTypes.FETCH_RETAILER_INVENTORY_REQUEST
  });

  try {
    const response = await RetailerApi.getRetailerInventory(id);
    if (response.data.status === STATUS.OK) {
      dispatch({
        type: RetailerActionTypes.FETCH_RETAILER_INVENTORY_SUCCESS,
        payload: response.data.retailer,
        error: null,
        route: null,
        success: null
      });
    }
  } catch (error) {
    dispatch({
      type: RetailerActionTypes.FETCH_RETAILER_INVENTORY_ERROR,
      error: error.response.data,
      payload: null,
      route: ROUTE_TO_RETAILER,
      success: null
    });
  }
};

export const createRetailer = (formValues) => async (dispatch) => {
  dispatch({
    type: RetailerActionTypes.CREATE_RETAILER_REQUEST
  });

  try {
    const response = await RetailerApi.createRetailer(formValues);
    if (response.data.status === STATUS.OK) {
      dispatch({
        type: RetailerActionTypes.CREATE_RETAILER_SUCCESS,
        payload: response.data.retailer,
        error: null,
        route: ROUTE_TO_RETAILER,
        success: response.data.message
      });
    }
  } catch (error) {
    dispatch({
      type: RetailerActionTypes.CREATE_RETAILER_ERROR,
      error: error.response.data,
      payload: null,
      route: ROUTE_TO_RETAILER,
      success: null
    });
  }
};

export const createRetailerInventory = (id, formValues, callback) => async (
  dispatch
) => {
  dispatch({
    type: RetailerActionTypes.CREATE_RETAILER_INVENTORY_REQUEST
  });

  try {
    const response = await RetailerApi.createRetailerInventory(id, formValues);
    if (response.data.status === STATUS.OK) {
      if (callback) {
        callback();
      }

      dispatch({
        type: RetailerActionTypes.CREATE_RETAILER_INVENTORY_SUCCESS,
        payload: response.data.retailerInventory,
        error: null,
        route: null,
        success: null
      });
    }
  } catch (error) {
    dispatch({
      type: RetailerActionTypes.CREATE_RETAILER_INVENTORY_ERROR,
      error: error.response.data,
      payload: null,
      success: null
    });
  }
};

export const editRetailer = (id, formValues) => async (dispatch) => {
  dispatch({
    type: RetailerActionTypes.UPDATE_RETAILER_REQUEST
  });

  try {
    const response = await RetailerApi.editRetailer(id, formValues);
    if (response.data.status === STATUS.OK) {
      dispatch({
        type: RetailerActionTypes.UPDATE_RETAILER_SUCCESS,
        payload: response.data.retailer,
        error: null,
        route: ROUTE_TO_RETAILER,
        success: response.data.message
      });
    }
  } catch (error) {
    dispatch({
      type: RetailerActionTypes.UPDATE_RETAILER_ERROR,
      error: error.response.data,
      payload: null,
      route: ROUTE_TO_RETAILER,
      success: null
    });
  }
};

export const editRetailerInventory = (
  retailerId,
  inventoryId,
  formValues,
  callback
) => async (dispatch) => {
  dispatch({
    type: RetailerActionTypes.UPDATE_RETAILER_INVENTORY_REQUEST
  });

  try {
    const response = await RetailerApi.editRetailerInventory(
      retailerId,
      inventoryId,
      formValues
    );
    if (response.data.status === STATUS.OK) {
      if (callback) {
        callback();
      }

      dispatch({
        type: RetailerActionTypes.UPDATE_RETAILER_INVENTORY_SUCCESS,
        payload: response.data.retailerInventory,
        error: null,
        route: null,
        success: null
      });
    }
  } catch (error) {
    dispatch({
      type: RetailerActionTypes.UPDATE_RETAILER_INVENTORY_ERROR,
      error: error.response.data,
      payload: null,
      success: null
    });
  }
};

export const deleteRetailer = (id) => async (dispatch) => {
  dispatch({
    type: RetailerActionTypes.DELETE_RETAILER_REQUEST
  });

  try {
    const response = await RetailerApi.deleteRetailer(id);
    if (response.data.status === STATUS.OK) {
      dispatch({
        type: RetailerActionTypes.DELETE_RETAILER_SUCCESS,
        payload: id,
        error: null,
        route: ROUTE_TO_RETAILER,
        success: response.data.message
      });
    }
  } catch (error) {
    dispatch({
      type: RetailerActionTypes.DELETE_RETAILER_ERROR,
      payload: null,
      error: error.response.data,
      route: ROUTE_TO_RETAILER,
      success: null
    });
  }
};
