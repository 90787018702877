import React from 'react';
import { connect } from 'react-redux';
import { Container, Avatar, Typography } from '@material-ui/core';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import formStyles from '../../../components/Styles/FormStyles';
import EditAreaForm from '../../../components/EditAreaForm';
import { editArea, fetchArea } from '../../../redux/area/area.actions';
import {
  getAreaById,
  getAllAreas,
  isAreaRequestPending
} from '../../../redux/area/area.selector';
import {
  getAllDistricts,
  isDistrictRequestPending
} from '../../../redux/district/district.selector';
import {
  getAllZones,
  isZoneRequestPending
} from '../../../redux/zone/zone.selector';
import BackdropWithLoader from '../../../components/BackdropWithLoader';
import { TITLES } from '../../../shared/Constants/Constant';
import history from '../../../routes/history';
import { ROUTE_TO_BEAT_AREA } from '../../../shared/Constants/RouteConstants';
import { setCancel } from '../../../redux/event/event.actions';
import useFetchReducerWithId from '../../../shared/Hooks/useFetchReducerWithId';
import useRefreshFetchReducer from '../../../shared/Hooks/useRefreshFetchReducer';
import { fetchAllZonesAndDistricts } from '../../../redux/territory/territory.actions';

const EditArea = (props) => {
  const classes = formStyles();

  const {
    fetchAllZonesAndDistrictsConnect,
    isZonesLoading,
    zonesPayload,
    isDistrictsLoading,
    districtsPayload,
    area = {},
    isAreasLoading,
    fetchAreaConnect,
    editAreaConnect,
    match: {
      params: { id }
    },
    setCancelConnect
  } = props;
  const areaId = id;

  useFetchReducerWithId(fetchAreaConnect, areaId, area);
  useRefreshFetchReducer(fetchAllZonesAndDistrictsConnect);

  const handleSubmit = (formValues) => {
    editAreaConnect(areaId, formValues);
  };

  const handleCancel = () => {
    setCancelConnect();
    history.push(ROUTE_TO_BEAT_AREA);
  };

  if (isAreasLoading || isDistrictsLoading || isZonesLoading) {
    return <BackdropWithLoader />;
  }

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <AccountCircleIcon color="primary" />
        </Avatar>
        <Typography component="h1" variant="h5">
          {TITLES.EDIT_area}
        </Typography>
        <EditAreaForm
          areaId={areaId}
          area={area}
          zones={zonesPayload}
          districts={districtsPayload}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
        />
      </div>
    </Container>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    isZonesLoading: isZoneRequestPending(state),
    zonesPayload: getAllZones(state),
    isDistrictsLoading: isDistrictRequestPending(state),
    districtsPayload: getAllDistricts(state),
    area: getAreaById(state, ownProps),
    isAreasLoading: isAreaRequestPending(state),
    areasPayload: getAllAreas(state)
  };
};

const mapDispatchToProps = {
  fetchAreaConnect: fetchArea,
  fetchAllZonesAndDistrictsConnect: fetchAllZonesAndDistricts,
  editAreaConnect: editArea,
  setCancelConnect: setCancel
};

export default connect(mapStateToProps, mapDispatchToProps)(EditArea);
