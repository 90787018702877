import baseURL from '../shared/AxiosBaseUrl/baseURL';

class EmployeeSecondarySalesAPI {
  static getEmployeeSecondarySales(userId, queryParams) {
    return baseURL.get(`/api/v1/user/${userId}/daily-sales-report`, {
      params: queryParams
    });
  }

  static editEmployeeSecondarySales(id, formValues) {
    return baseURL.patch(`/api/v1/beat/${id}`, { ...formValues });
  }

  static deleteEmployeeSecondarySales(id) {
    return baseURL.delete(`/api/v1/beat/${id}`);
  }
}

export default EmployeeSecondarySalesAPI;
