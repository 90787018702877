import _ from 'lodash';
import * as BeatAreaActionTypes from './beatArea.types';

const initialState = {
  data: {},
  isRequestPending: false
};

const beatAreaDataReducer = (state = {}, action) => {
  const { payload, type } = action;

  switch (type) {
    case BeatAreaActionTypes.FETCH_BEAT_AREAS_SUCCESS:
      return { ..._.mapKeys(payload, 'id') };

    case BeatAreaActionTypes.FETCH_BEAT_AREA_SUCCESS:
      return { ...state, [payload.id]: payload };

    case BeatAreaActionTypes.CREATE_BEAT_AREA_SUCCESS:
      return { ...state, [payload.id]: payload };

    case BeatAreaActionTypes.UPDATE_BEAT_AREA_SUCCESS:
      return { ...state, [payload.id]: payload };

    case BeatAreaActionTypes.DELETE_BEAT_AREA_SUCCESS:
      return _.omit(state, payload);

    default:
      return state;
  }
};

const beatAreaReducer = (state = initialState, action) => {
  const { type } = action;

  switch (type) {
    case BeatAreaActionTypes.FETCH_BEAT_AREAS_REQUEST:
      return {
        ...state,
        isRequestPending: true
      };

    case BeatAreaActionTypes.FETCH_BEAT_AREAS_SUCCESS:
      return {
        ...state,
        isRequestPending: false,
        data: beatAreaDataReducer(state.data, action)
      };

    case BeatAreaActionTypes.FETCH_BEAT_AREAS_ERROR:
      return {
        ...state,
        isRequestPending: false
      };

    case BeatAreaActionTypes.FETCH_BEAT_AREA_REQUEST:
      return {
        ...state,
        isRequestPending: true
      };

    case BeatAreaActionTypes.FETCH_BEAT_AREA_SUCCESS:
      return {
        ...state,
        isRequestPending: false,
        data: beatAreaDataReducer(state.data, action)
      };

    case BeatAreaActionTypes.FETCH_BEAT_AREA_ERROR:
      return {
        ...state,
        isRequestPending: false
      };

    case BeatAreaActionTypes.CREATE_BEAT_AREA_REQUEST:
      return {
        ...state,
        isRequestPending: true
      };

    case BeatAreaActionTypes.CREATE_BEAT_AREA_SUCCESS:
      return {
        ...state,
        isRequestPending: false,
        data: beatAreaDataReducer(state.data, action)
      };

    case BeatAreaActionTypes.CREATE_BEAT_AREA_ERROR:
      return {
        ...state,
        isRequestPending: false
      };

    case BeatAreaActionTypes.UPDATE_BEAT_AREA_REQUEST:
      return {
        ...state,
        isRequestPending: true
      };

    case BeatAreaActionTypes.UPDATE_BEAT_AREA_SUCCESS:
      return {
        ...state,
        isRequestPending: false,
        data: beatAreaDataReducer(state.data, action)
      };

    case BeatAreaActionTypes.UPDATE_BEAT_AREA_ERROR:
      return {
        ...state,
        isRequestPending: false
      };

    case BeatAreaActionTypes.DELETE_BEAT_AREA_REQUEST:
      return {
        ...state,
        isRequestPending: true
      };

    case BeatAreaActionTypes.DELETE_BEAT_AREA_SUCCESS:
      return {
        ...state,
        isRequestPending: false,
        data: beatAreaDataReducer(state.data, action)
      };

    case BeatAreaActionTypes.DELETE_BEAT_AREA_ERROR:
      return {
        ...state,
        isRequestPending: false
      };

    default:
      return state;
  }
};

export default beatAreaReducer;
