import ZoneApi from '../../apis/ZoneAPI';
import * as ZoneActionTypes from './zone.types';
import STATUS from '../../shared/Constants/apiStatus';
import { ROUTE_TO_ZONE } from '../../shared/Constants/RouteConstants';

export const fetchAllZones = () => async (dispatch) => {
  dispatch({
    type: ZoneActionTypes.FETCH_ZONES_REQUEST
  });

  try {
    const response = await ZoneApi.getAllZones();
    if (response.data.status === STATUS.OK) {
      dispatch({
        type: ZoneActionTypes.FETCH_ZONES_SUCCESS,
        payload: response.data.zones,
        error: null,
        route: null,
        success: null
      });
    }
  } catch (error) {
    dispatch({
      type: ZoneActionTypes.FETCH_ZONES_ERROR,
      error: error.response.data,
      payload: null,
      route: null,
      success: null
    });
  }
};

export const fetchZone = (id) => async (dispatch) => {
  dispatch({
    type: ZoneActionTypes.FETCH_ZONE_REQUEST
  });

  try {
    const response = await ZoneApi.getZone(id);
    if (response.data.status === STATUS.OK) {
      dispatch({
        type: ZoneActionTypes.FETCH_ZONE_SUCCESS,
        payload: response.data.zone,
        error: null,
        route: null,
        success: null
      });
    }
  } catch (error) {
    dispatch({
      type: ZoneActionTypes.FETCH_ZONE_ERROR,
      error: error.response.data,
      payload: null,
      route: ROUTE_TO_ZONE,
      success: null
    });
  }
};

export const createZone = (formValues) => async (dispatch) => {
  dispatch({
    type: ZoneActionTypes.CREATE_ZONE_REQUEST
  });

  try {
    const response = await ZoneApi.createZone(formValues);

    if (response.data.status === STATUS.OK) {
      dispatch({
        type: ZoneActionTypes.CREATE_ZONE_SUCCESS,
        payload: response.data.zone,
        error: null,
        route: ROUTE_TO_ZONE,
        success: response.data.message
      });
    }
  } catch (error) {
    dispatch({
      type: ZoneActionTypes.CREATE_ZONE_ERROR,
      error: error.response.data,
      payload: null,
      route: ROUTE_TO_ZONE,
      success: null
    });
  }
};

export const editZone = (id, formValues) => async (dispatch) => {
  dispatch({
    type: ZoneActionTypes.UPDATE_ZONE_REQUEST
  });

  try {
    const response = await ZoneApi.editZone(id, formValues);
    if (response.data.status === STATUS.OK) {
      dispatch({
        type: ZoneActionTypes.UPDATE_ZONE_SUCCESS,
        payload: response.data.zone,
        error: null,
        route: ROUTE_TO_ZONE,
        success: response.data.message
      });
    }
  } catch (error) {
    dispatch({
      type: ZoneActionTypes.UPDATE_ZONE_ERROR,
      error: error.response.data,
      payload: null,
      route: ROUTE_TO_ZONE,
      success: null
    });
  }
};

export const deleteZone = (id) => async (dispatch) => {
  dispatch({
    type: ZoneActionTypes.DELETE_ZONE_REQUEST
  });

  try {
    const response = await ZoneApi.deleteZone(id);
    if (response.data.status === STATUS.OK) {
      dispatch({
        type: ZoneActionTypes.DELETE_ZONE_SUCCESS,
        payload: id,
        error: null,
        route: ROUTE_TO_ZONE,
        success: response.data.message
      });
    }
  } catch (error) {
    dispatch({
      type: ZoneActionTypes.DELETE_ZONE_ERROR,
      payload: null,
      error: error.response.data,
      route: ROUTE_TO_ZONE,
      success: null
    });
  }
};
