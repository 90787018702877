import { get, isEqual, set } from 'lodash';

export const reformatRetailerFormValues = (retailer = {}) => {
  return {
    name: get(retailer, 'name', ''),

    gstin: get(retailer, 'gstin', ''),

    retail_type: get(retailer, 'retail_type', ''),

    street_address: get(retailer, 'address.street_address', ''),

    landmark: get(retailer, 'address.landmark', ''),

    place: get(retailer, 'address.place', ''),

    zip_code: get(retailer, 'address.zip_code', ''),

    contact_person_name: get(retailer, 'contact.contact_person_name', ''),

    contact_number: get(retailer, 'contact.contact_number', ''),

    additional_contact_number: get(
      retailer,
      'contact.additional_contact_number',
      ''
    ),

    email: get(retailer, 'contact.email', '')
  };
};

export const exportNormalizedFormValues = (
  { values, beat, distributor, additionalContacts },
  retailer
) => {
  const updatedValues = {};

  if (values.name && !isEqual(retailer.name, values.name)) {
    updatedValues.name = values.name;
  }

  if (!isEqual(retailer.gstin, values.gstin)) {
    updatedValues.gstin = values.gstin;
  }

  if (
    values.retail_type &&
    !isEqual(retailer.retail_type, values.retail_type)
  ) {
    updatedValues.retail_type = values.retail_type;
  }

  if (!isEqual(retailer.beat.id, get(beat, 'id', ''))) {
    updatedValues.beat = get(beat, 'id', '');
  }

  if (!isEqual(retailer.distributor.id, get(distributor, 'id', ''))) {
    updatedValues.distributor = get(distributor, 'id', '');
  }

  if (
    values.street_address &&
    !isEqual(retailer.address.street_address, values.street_address)
  ) {
    set(updatedValues, 'address.street_address', values.street_address);
  }

  if (values.landmark && !isEqual(retailer.address.landmark, values.landmark)) {
    set(updatedValues, 'address.landmark', values.landmark);
  }

  if (values.place && !isEqual(retailer.address.place, values.place)) {
    set(updatedValues, 'address.place', values.place);
  }

  if (values.zip_code && !isEqual(retailer.address.zip_code, values.zip_code)) {
    set(updatedValues, 'address.zip_code', values.zip_code);
  }

  if (
    values.contact_person_name &&
    !isEqual(retailer.contact.contact_person_name, values.contact_person_name)
  ) {
    set(
      updatedValues,
      'contact.contact_person_name',
      values.contact_person_name
    );
  }

  if (
    values.contact_number &&
    !isEqual(retailer.contact.contact_number, values.contact_number)
  ) {
    set(updatedValues, 'contact.contact_number', values.contact_number);
  }

  if (
    values.additional_contact_number &&
    !isEqual(
      retailer.contact.additional_contact_number,
      values.additional_contact_number
    )
  ) {
    set(
      updatedValues,
      'contact.additional_contact_number',
      values.additional_contact_number
    );
  }

  if (values.email && !isEqual(retailer.contact.email, values.email)) {
    set(updatedValues, 'contact.email', values.email);
  }

  if (!isEqual(retailer.additional_contacts, additionalContacts)) {
    set(updatedValues, 'additional_contacts', additionalContacts);
  }

  return updatedValues;
};
