import React from 'react';
import { TableRow } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import StyledTableCell from '../Styles/StyledTableCell';

function CustomSkeleton(props) {
  return (
    <>
      {Array(props.totalRows || 10)
        .fill()
        .map((item, index) => (
          <TableRow key={index}>
            <StyledTableCell align="center" colSpan={props.colSpan}>
              <Skeleton variant="rect" height={25} />
            </StyledTableCell>
          </TableRow>
        ))}
    </>
  );
}

export default CustomSkeleton;
