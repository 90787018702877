import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Drawer, Toolbar, List, Divider } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import SidebarRoutes from '../../routes/SidebarRoutes';
import NestedRouteList from './NestedList';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: '#faf9f9'
  },
  drawerContainer: {
    width: drawerWidth,
    backgroundColor: '#faf9f9',
    overflow: 'auto',
    [theme.breakpoints.up('lg')]: {
      height: 'calc(100% - 64px)'
    }
  },
  drawerList: {
    width: '100%',
    backgroundColor: '#faf9f9'
  }
}));

function Sidebar(props) {
  const classes = useStyles();

  const {
    location: { pathname },
    variant,
    onClose,
    open
  } = props;

  return (
    <Drawer
      anchor="left"
      variant={variant}
      onClose={onClose}
      open={open}
      classes={{
        paper: classes.drawerPaper
      }}
      ModalProps={{
        keepMounted: true // Better open performance on mobile.
      }}
    >
      <Toolbar />
      <div className={classes.drawerContainer}>
        <List
          component="nav"
          aria-labelledby="nested-list-subheader"
          className={classes.drawerList}
        >
          {SidebarRoutes.map((parentRoute) => {
            return (
              <div key={uuidv4()}>
                <NestedRouteList
                  key={uuidv4()}
                  primaryText={parentRoute.component}
                  nestedRoutes={parentRoute.routes}
                  icon={parentRoute.icon}
                  currentUrl={pathname}
                />
                <Divider />
              </div>
            );
          })}
        </List>
      </div>
    </Drawer>
  );
}

Sidebar.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired
  }).isRequired
};

export default withRouter(Sidebar);
