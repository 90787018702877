import React, { useState } from 'react';
import { StyledTableCell, StyledTableRow } from '../../Styles/TableStyles';
import MaterialButton from '../MaterialButton';
import PackagingMaterialStockInputForm from '../PackagingMaterialStockInputForm/PackagingMaterialStockInputForm';

function PackagingMaterialsStockTableItem(props) {
  const { product } = props;
  const [openCasesStockInputForm, setOpenCasesStockInputForm] = useState(false);
  const [openBottleStockInputForm, setBottleCasesStockInputForm] =
    useState(false);

  return (
    <StyledTableRow>
      <StyledTableCell align="center">{product.name || 'NA'}</StyledTableCell>

      <StyledTableCell align="center">
        <div>
          <MaterialButton
            buttonText={product.carton_box_stock}
            handleClick={() => setOpenCasesStockInputForm(true)}
          />
          <PackagingMaterialStockInputForm
            open={openCasesStockInputForm}
            handleClose={() => setOpenCasesStockInputForm(false)}
            stockValue={product.carton_box_stock}
            parentProductId={product.id}
            parentProductName={product.name}
            date={new Date()}
            updateCartonBoxStock
          />
        </div>
      </StyledTableCell>
      <StyledTableCell align="center">
        <div>
          <MaterialButton
            buttonText={product.product_container_stock || 'NA'}
            handleClick={() => setBottleCasesStockInputForm(true)}
          />
          <PackagingMaterialStockInputForm
            open={openBottleStockInputForm}
            handleClose={() => setBottleCasesStockInputForm(false)}
            stockValue={product.product_container_stock}
            parentProductId={product.id}
            parentProductName={product.name}
            date={new Date()}
            updateBottleStock
          />
        </div>
      </StyledTableCell>
    </StyledTableRow>
  );
}

export default PackagingMaterialsStockTableItem;
