import React from 'react';
import { Button } from '@material-ui/core';
import formStyles from '../Styles/FormStyles';
import { BUTTON_CONSTANTS } from '../../shared/Constants/Constant';

function FormCancelButton(props) {
  const classes = formStyles();
  const { onClickCancel } = props;
  return (
    <Button
      type="button"
      variant="contained"
      align="left"
      margin="normal"
      fullWidth
      className={classes.cancelButtonStyle}
      onClick={onClickCancel}
    >
      {BUTTON_CONSTANTS.CANCEL}
    </Button>
  );
}

export default React.memo(FormCancelButton);
