import { createSelector } from 'reselect';

const getDistrictselector = (state) => state.districts;
const getAllDistrictsSelector = (state) => state.districts.data;
const getDistrictByIdSelector = (state, props) => props.match.params.id;

export const isDistrictRequestPending = createSelector(
  [getDistrictselector],
  (districts) => districts.isRequestPending
);

export const getAllDistricts = createSelector(
  [getAllDistrictsSelector],
  (districts) => Object.values(districts)
);

export const getDistrictById = createSelector(
  getAllDistrictsSelector,
  getDistrictByIdSelector,
  (districts, id) => districts[id]
);
