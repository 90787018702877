import { createSelector } from 'reselect';

export const getAreasSelector = (state) => state.areas;
export const getAllAreasDataSelector = (state) => state.areas.data;
export const getAreaIdSelector = (state, props) => props.match.params.id;

export const isAreaRequestPending = createSelector(
  [getAreasSelector],
  (areas) => areas.isRequestPending
);

export const getAllAreas = createSelector(getAllAreasDataSelector, (areas) =>
  Object.values(areas)
);

export const getAreaById = createSelector(
  [getAllAreasDataSelector, getAreaIdSelector],
  (areas, id) => areas[id]
);
