import React, { useState } from 'react';
import { get, size } from 'lodash';
import { MenuItem } from '@material-ui/core';
import FormInput from '../FormInput';
import FormSelect from '../FormSelect';
import formStyles from '../Styles/FormStyles';
import FormCancelButton from '../FormCancelButton';
import FormSubmitButton from '../FormSubmitButton';
import FormSubHeader from '../FormSubHeader';
import AdditionalContactsForm from '../AdditionalContactsForm';
import {
  FORM_SUB_HEADER_TITLES,
  RETAILER_TYPE
} from '../../shared/Constants/Constant';
import useForm from '../../shared/Hooks/useForm';
import RetailerFormValidator from '../../pages/Retailer/RetailerFormValidator';
import FormAutoComplete from '../FormAutoComplete';
import { areValidAdditionalContacts } from '../../shared/Utils/CommonUtils';
import {
  reformatRetailerFormValues,
  exportNormalizedFormValues
} from './EditRetailerForm.utils';

// Additional Contact Details
const blankAdditionalContact = {
  contact_person_name: '',
  contact_number: '',
  designation: ''
};

function EditRetailerForm(props) {
  const {
    retailer = {},
    zonesPayload = [],
    districtsPayload = [],
    areasPayload = [],
    beatsPayload = [],
    onCancel
  } = props;

  const classes = formStyles();

  const [zoneItem, setZoneItem] = useState(get(retailer, 'zone', null));

  const filteredDistrictsPayload = districtsPayload.filter((district) => {
    if (zoneItem) {
      return district.zone.id === zoneItem.id;
    }

    return false;
  });

  const [districtItem, setDistrictItem] = useState(
    get(retailer, 'district', null)
  );

  const filteredAreasPayload = areasPayload.filter((area) => {
    if (districtItem) {
      return area.district.id === districtItem.id;
    }

    return false;
  });

  const [areaItem, setAreaItem] = useState(get(retailer, 'area', null));

  const filteredBeatsPayload = beatsPayload.filter((beat) => {
    if (areaItem) {
      return beat.area.id === areaItem.id;
    }

    return false;
  });

  const [beatItem, setBeatItem] = useState(get(retailer, 'beat', null));

  const [beatError, setBeatError] = useState('');

  const [additionalContacts, setAdditionalContacts] = useState(
    get(retailer, 'additional_contacts', [])
  );

  const [additionalContactsError, setAdditionalContactsError] = useState('');

  const addAdditionalContact = () => {
    setAdditionalContacts((prevAdditionalContact) => [
      ...prevAdditionalContact,
      { ...blankAdditionalContact }
    ]);
  };

  const removeAdditionalContactByIndex = (index) => {
    const duplicateAdditionalContacts = [...additionalContacts];
    duplicateAdditionalContacts.splice(index, 1);

    setAdditionalContacts([...duplicateAdditionalContacts]);
  };

  const handleAdditionalContactChange = (e, index) => {
    e.persist();
    const duplicateAdditionalContacts = [...additionalContacts];
    duplicateAdditionalContacts[index][e.target.name] = e.target.value;

    if (additionalContactsError) {
      setAdditionalContactsError('');
    }

    setAdditionalContacts(duplicateAdditionalContacts);
  };

  const onChangeZoneSelection = (e, value) => {
    e.persist();
    setZoneItem(value);
    setDistrictItem(null);
    setAreaItem(null);
    setBeatItem(null);
  };

  const onChangeDistrictSelection = (e, value) => {
    e.persist();
    setDistrictItem(value);
    setAreaItem(null);
    setBeatItem(null);
  };

  const onChangeAreaSelection = (e, value) => {
    e.persist();
    setAreaItem(value);
    setBeatItem(null);
  };

  const onChangeBeatAreaSelection = (e, value) => {
    e.persist();

    if (beatError) {
      setBeatError('');
    }

    setBeatItem(value);
  };

  const handleFormSubmit = () => {
    if (!beatItem) {
      return setBeatError('** Beat is required');
    }

    if (!areValidAdditionalContacts(additionalContacts)) {
      return setAdditionalContactsError(
        '** Additional Contacts entry is incomplete'
      );
    }

    const updatedValues = exportNormalizedFormValues(
      {
        values,
        beat: beatItem,
        additionalContacts
      },
      retailer
    );

    if (size(updatedValues) > 0) {
      props.onSubmit(updatedValues, resetValues);
    }
  };

  const reFormatDetails = (retailer) => {
    return reformatRetailerFormValues(retailer);
  };

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    handleNumericInputChange,
    resetValues
  } = useForm(handleFormSubmit, RetailerFormValidator, {
    initialValues: reFormatDetails(retailer)
  });

  return (
    <form className={classes.form} noValidate autoComplete="off">
      <FormInput
        type="text"
        label="Retailer Name"
        placeholder="Enter Retailer Name"
        name="name"
        value={values.name || ''}
        onChange={handleChange}
        helperText={errors.name || ''}
        error={!!errors.name}
        required
        autoFocus
      />

      <FormInput
        type="text"
        label="Place"
        placeholder="Enter the Place"
        name="place"
        value={values.place || ''}
        onChange={handleChange}
        helperText={errors.place || ''}
        error={!!errors.place}
        required
      />

      <FormInput
        type="text"
        label="GST Number"
        placeholder="Enter the GST Number"
        name="gstin"
        value={values.gstin || ''}
        onChange={handleChange}
      />

      <FormSelect
        label="Outlet Type"
        name="retail_type"
        value={values.retail_type || ''}
        onChange={handleChange}
        helperText={errors.retail_type || ''}
        error={!!errors.retail_type}
        required
      >
        {Object.keys(RETAILER_TYPE).map((type) => {
          const label = RETAILER_TYPE[type];
          return (
            <MenuItem key={type} value={type}>
              {label}
            </MenuItem>
          );
        })}
      </FormSelect>

      <FormSubHeader text={FORM_SUB_HEADER_TITLES.TERRITORY} />

      <FormAutoComplete
        data={zonesPayload}
        label="Select Zone"
        name="zone"
        value={zoneItem}
        onChangeSelect={(e, newValue) => onChangeZoneSelection(e, newValue)}
        property="name"
      />

      <FormAutoComplete
        data={filteredDistrictsPayload}
        label="Select District"
        name="district"
        value={districtItem}
        onChangeSelect={(e, newValue) => onChangeDistrictSelection(e, newValue)}
        disabled={!zoneItem}
        property="name"
      />

      <FormAutoComplete
        data={filteredAreasPayload}
        label="Select Area"
        name="area"
        value={areaItem}
        disabled={!districtItem}
        onChangeSelect={(e, newValue) => onChangeAreaSelection(e, newValue)}
        property="name"
      />

      <FormAutoComplete
        data={filteredBeatsPayload}
        label="Select Beat"
        name="beat"
        value={beatItem}
        disabled={!areaItem}
        onChangeSelect={(e, newValue) => onChangeBeatAreaSelection(e, newValue)}
        property="name"
        error={!!beatError}
        helperText={beatError}
      />

      <FormSubHeader text={FORM_SUB_HEADER_TITLES.ADDRESS} />

      <FormInput
        type="text"
        label="Street Address"
        placeholder="Enter the Address"
        name="street_address"
        value={values.street_address || ''}
        onChange={handleChange}
        helperText={errors.street_address || ''}
        error={!!errors.street_address}
        required
      />

      <FormInput
        type="text"
        label="Landmark"
        placeholder="Enter the Landmark"
        name="landmark"
        value={values.landmark || ''}
        onChange={handleChange}
        helperText={errors.landmark || ''}
        error={!!errors.landmark}
        required
      />

      <FormInput
        type="text"
        label="Pin Code"
        placeholder="Enter the Pin Code"
        name="zip_code"
        value={values.zip_code || ''}
        onChange={handleNumericInputChange}
        helperText={errors.zip_code || ''}
        error={!!errors.zip_code}
        required
      />

      <FormSubHeader text={FORM_SUB_HEADER_TITLES.CONTACT} />

      <FormInput
        type="text"
        label="Key Contact Person Name"
        placeholder="Enter the Key Contact Name"
        name="contact_person_name"
        value={values.contact_person_name || ''}
        onChange={handleChange}
        required
        helperText={errors.contact_person_name || ''}
        error={!!errors.contact_person_name}
      />

      <FormInput
        type="number"
        label="Mobile Number"
        placeholder="Enter the Contact Number"
        name="contact_number"
        value={values.contact_number || ''}
        onChange={handleNumericInputChange}
        required
        helperText={errors.contact_number || ''}
        error={!!errors.contact_number}
      />

      <FormInput
        type="number"
        label="WhatsApp Number"
        placeholder="Enter the WhatsApp Number"
        name="additional_contact_number"
        value={values.additional_contact_number || ''}
        onChange={handleNumericInputChange}
      />

      <FormInput
        type="email"
        label="Email Address"
        placeholder="Enter the Email Address"
        name="email"
        value={values.email || ''}
        onChange={handleChange}
      />

      <AdditionalContactsForm
        subHeaderTitle={FORM_SUB_HEADER_TITLES.ADDITIONAL_CONTACTS}
        contacts={additionalContacts}
        handleInputChange={(e, index) =>
          handleAdditionalContactChange(e, index)
        }
        handleRemove={(index) => removeAdditionalContactByIndex(index)}
        handleAdd={addAdditionalContact}
        errorMessage={additionalContactsError}
      />

      <div className={classes.buttonRow}>
        <FormCancelButton onClickCancel={onCancel} />
        <FormSubmitButton onClickSubmit={handleSubmit} />
      </div>
    </form>
  );
}

EditRetailerForm.defaultProps = {
  zonesPayload: [],
  districtsPayload: [],
  areasPayload: [],
  beatsPayload: []
};

export default React.memo(EditRetailerForm);
