import {
  ShoppingBasket,
  Landscape,
  Group,
  LocalGroceryStore,
  AccountCircle,
  Storage
} from '@material-ui/icons';
import * as RouteConstants from '../shared/Constants/RouteConstants';

const SidebarRoutes = [
  {
    component: 'Catalogue',
    icon: ShoppingBasket,
    routes: [
      {
        component: 'Parent Product',
        url: RouteConstants.ROUTE_TO_PARENT_PRODUCT
      },
      {
        component: 'Sku',
        url: RouteConstants.ROUTE_TO_SKU
      }
    ]
  },
  {
    component: 'Inventory',
    icon: Storage,
    routes: [
      {
        component: 'Factory Stock',
        url: RouteConstants.ROUTE_TO_FACTORY_STOCK
      },
      {
        component: 'Box/Bottle Stock',
        url: RouteConstants.ROUTE_TO_PACKAGING_MATERIAL_STOCK
      }
    ]
  },
  {
    component: 'Territory',
    icon: Landscape,
    routes: [
      {
        component: 'Zones',
        url: '/zone'
      },
      {
        component: 'District',
        url: '/district'
      },
      {
        component: 'Area',
        url: '/area'
      },
      {
        component: 'Beat',
        url: '/beatarea'
      }
    ]
  },
  {
    component: 'Customers',
    icon: Group,
    routes: [
      {
        component: 'Super Stockist',
        url: '/superstockist'
      },
      {
        component: 'Distributor',
        url: '/distributor'
      },
      {
        component: 'Retailer',
        url: '/retailer'
      }
    ]
  },
  {
    component: 'Orders',
    icon: LocalGroceryStore,
    routes: [
      {
        component: 'Primary',
        url: '/order/primary-order'
      },
      {
        component: 'Second-Primary',
        url: '/order/second-primary-order'
      },
      {
        component: 'Secondary',
        url: '/order/secondary-order'
      }
    ]
  },
  {
    component: 'Users',
    icon: AccountCircle,
    routes: [
      {
        component: 'All Users',
        url: '/user'
      },
      {
        component: 'Attendance',
        url: RouteConstants.ROUTE_TO_EMPLOYEE_ATTENDANCE
      },
      {
        component: 'Secondary Sales (Day)',
        url: RouteConstants.ROUTE_TO_EMPLOYEE_SECONDARY_SALES_DAY_WISE
      },
      {
        component: 'Secondary Sales (Range)',
        url: RouteConstants.ROUTE_TO_EMPLOYEE_SECONDARY_SALES_RANGE_WISE
      }
    ]
  }
];

export default SidebarRoutes;
