import React from 'react';
import FormInput from '../FormInput';
import formStyles from '../Styles/FormStyles';
import FormCancelButton from '../FormCancelButton';
import FormSubmitButton from '../FormSubmitButton';
import useForm from '../../shared/Hooks/useForm';
import ZoneFormValidator from '../../pages/Zone/ZoneFormValidator';

function CreateZoneForm({ onSubmit, onCancel }) {
  const classes = formStyles();

  const handleFormSubmit = () => {
    onSubmit({ name: values.name }, resetValues);
  };

  const { values, errors, handleChange, handleSubmit, resetValues } = useForm(
    handleFormSubmit,
    ZoneFormValidator
  );

  const isValidForm = () => {
    return values.name;
  };

  return (
    <form className={classes.form} noValidate autoComplete="off">
      <FormInput
        type="text"
        label="Zone"
        placeholder="Enter a Zone"
        name="name"
        value={values.name || ''}
        onChange={handleChange}
        helperText={errors.name || ''}
        error={!!errors.name}
        required
        autoFocus
      />

      <div className={classes.buttonRow}>
        <FormCancelButton onClickCancel={onCancel} />
        <FormSubmitButton
          onClickSubmit={handleSubmit}
          disabled={!isValidForm()}
        />
      </div>
    </form>
  );
}

export default React.memo(CreateZoneForm);
