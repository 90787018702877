import React from 'react';
import { MenuItem } from '@material-ui/core';
import FormInput from '../FormInput';
import FormSelect from '../FormSelect';
import formStyles from '../Styles/FormStyles';
import FormCancelButton from '../FormCancelButton';
import FormSubmitButton from '../FormSubmitButton';
import { BRANDS } from '../../shared/Constants/Constant';
import useForm from '../../shared/Hooks/useForm';
import ParentProductFormValidator from '../../pages/ParentProduct/ParentProductFormValidator';

function CreateParentProductForm(props) {
  const { onCancel, onSubmit } = props;

  const classes = formStyles();

  const handleFormSubmit = () => {
    const parentProductData = {
      brand: values.brand,
      name: values.name
    };

    onSubmit(parentProductData);
    resetValues();
  };

  const { values, errors, handleChange, handleSubmit, resetValues } = useForm(
    handleFormSubmit,
    ParentProductFormValidator
  );

  return (
    <form className={classes.form} noValidate autoComplete="off">
      <FormSelect
        label="Select Brand Name"
        name="brand"
        value={values.brand || ''}
        helperText={errors.brand || ''}
        error={!!errors.brand}
        showDefaultEmptyValue={false}
        onChange={handleChange}
        autoFocus
      >
        {Object.keys(BRANDS).map((brand) => {
          const label = BRANDS[brand];
          return (
            <MenuItem key={brand} value={brand}>
              {label}
            </MenuItem>
          );
        })}
      </FormSelect>

      <FormInput
        type="text"
        label="Parent Product Name"
        placeholder="Enter a Parent Product Name"
        name="name"
        value={values.name || ''}
        onChange={handleChange}
        helperText={errors.name || ''}
        error={!!errors.name}
      />

      <div className={classes.buttonRow}>
        <FormCancelButton onClickCancel={onCancel} />
        <FormSubmitButton onClickSubmit={handleSubmit} />
      </div>
    </form>
  );
}

export default React.memo(CreateParentProductForm);
