import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Container,
  Grid,
  CardContent,
  Typography,
  Divider,
  Card,
  Box,
  CardHeader
} from '@material-ui/core';
import {
  clearCart,
  createPrimaryOrder,
  resetPrimaryOrderData,
  resetPrimaryOrderError
} from '../../../redux/primary-order/primary-order.actions';
import {
  isPrimaryOrderRequestPending,
  getErrorMessage,
  getPrimaryOrderCartItems,
  getPrimaryOrderCustomer
} from '../../../redux/primary-order/primary-order.selector';
import MaterialButton from '../../../components/MaterialButton';
import { isEmpty, size } from 'lodash';
import {
  getAllSkus,
  isSkuRequestPending
} from '../../../redux/sku/sku.selector';
import { fetchAllSkus } from '../../../redux/sku/sku.actions';
import PrimaryOrderCartTable from './PrimaryOrderCartTable';
import { notification } from 'antd';
import BackdropWithLoader from '../../../components/BackdropWithLoader';
import commonStyles from '../../../Styles/CommonStyles';
import {
  CUSTOMER_TYPES_TITLES,
  CUSTOMER_TYPES_CONSTANTS
} from '../../../shared/Constants/Constant';

const CreatePrimaryOrder = (props) => {
  const commonClasses = commonStyles();
  const {
    isPrimaryOrderRequestPending,
    isSkuRequestPending,
    resetPrimaryOrderErrorConnect,
    errorMessage,
    skusPayload,
    clearCartConnect,
    cartSkuPayload,
    fetchAllSkusConnect,
    resetPrimaryOrderDataConnect,
    customer,
    createPrimaryOrderConnect
  } = props;

  const isRequestPending = isPrimaryOrderRequestPending || isSkuRequestPending;
  useEffect(() => {
    fetchAllSkusConnect();

    return () => {
      clearCartConnect();
      resetPrimaryOrderErrorConnect();
      resetPrimaryOrderDataConnect();
    };
  }, []);

  const openErrorNotification = (type, placement, error) => {
    notification[type]({
      message: 'Oops! Something went wrong',
      description: error,
      placement,
      duration: 3.5,
      onClose: resetPrimaryOrderErrorConnect
    });
  };

  if (!isEmpty(errorMessage)) {
    openErrorNotification('error', 'bottomRight', errorMessage);
  }

  const disableContinue = () => {
    return isRequestPending || size(cartSkuPayload) === 0;
  };

  const Heading = `CREATE ${
    CUSTOMER_TYPES_TITLES[customer.customerType]
  } ORDER`;

  const normalizeSkuItems = () => {
    return cartSkuPayload.map((sku) => {
      return {
        sku: sku.skuId,
        name: sku.name,
        ordered_quantity: sku.quantityInPcs,
        margin: sku.customizedMargin,
        landed_cost: sku.customerLandedPrice,
        total_cost: sku.totalSkuCost,
        pieces_per_carton: sku.piecesPerCarton
      };
    });
  };

  const normalizeOrderValues = () => {
    const formValues = {};

    if (customer.customerType === CUSTOMER_TYPES_CONSTANTS.SUPER_STOCKIST) {
      formValues.super_stockist = customer.id;
    }

    if (
      customer.customerType === CUSTOMER_TYPES_CONSTANTS.PRIMARY_DISTRIBUTOR
    ) {
      formValues.distributor = customer.id;
    }

    return {
      ...formValues,
      customer_type: customer.customerType,
      sku_items: normalizeSkuItems()
    };
  };

  const handleFormSubmit = () => {
    createPrimaryOrderConnect(normalizeOrderValues());
  };

  return (
    <Container className={commonClasses.root} maxWidth="lg">
      <Grid container spacing={3}>
        <Grid item lg={12} md={12} xs={12}>
          <Card>
            <CardHeader title={Heading} className={commonClasses.heading} />
            <CardHeader
              title={customer.name}
              className={commonClasses.heading}
            />
            <Typography component="h5" className={commonClasses.heading}>
              GSTIN: {customer.gstin || 'NA'}
            </Typography>
            {isRequestPending ? (
              <BackdropWithLoader />
            ) : (
              <>
                <Divider />
                <CardContent>
                  <Grid container spacing={3}>
                    <Grid item md={12} xs={12} className={commonClasses.table}>
                      <PrimaryOrderCartTable
                        skusPayload={skusPayload}
                        customer={customer}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
                <Divider />
                <Box display="flex" justifyContent="flex-end" p={2}>
                  <MaterialButton
                    buttonText="Create Purchase Order"
                    handleClick={handleFormSubmit}
                    variant="contained"
                    disabled={disableContinue()}
                  />
                </Box>
              </>
            )}
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    isPrimaryOrderRequestPending: isPrimaryOrderRequestPending(state),
    isSkuRequestPending: isSkuRequestPending(state),
    errorMessage: getErrorMessage(state),
    skusPayload: getAllSkus(state),
    cartSkuPayload: getPrimaryOrderCartItems(state),
    customer: getPrimaryOrderCustomer(state)
  };
};

const mapDispatchToProps = {
  fetchAllSkusConnect: fetchAllSkus,
  createPrimaryOrderConnect: createPrimaryOrder,
  resetPrimaryOrderErrorConnect: resetPrimaryOrderError,
  resetPrimaryOrderDataConnect: resetPrimaryOrderData,
  clearCartConnect: clearCart
};

export default connect(mapStateToProps, mapDispatchToProps)(CreatePrimaryOrder);
