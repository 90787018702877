import React from 'react';
import {
  Table as MuiTable,
  TableBody,
  TableHead,
  TableRow,
  Paper,
  TableContainer
} from '@material-ui/core';
import tableStyles from '../Styles/TableStyles';
import StyledTableCell from '../Styles/StyledTableCell';
import { StyledTableRow } from '../../Styles/TableStyles';
import { VIEW_ORDER_HEADER_CONSTANTS } from '../../shared/Constants/TableHeaderConstants';
import { size } from 'lodash';
import OrderSkuTableItem from '../OrderSkuTableItem';
import CustomSkeleton from '../CustomSkeleton';
import MaterialButton from '../MaterialButton';
import { formatInInr } from '../../shared/Utils/CommonUtils';

function OrderSkuTable(props) {
  const classes = tableStyles();
  const { order = {}, isOrderLoading } = props;
  const { sku_items: lineItems } = order;

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <MuiTable stickyHeader>
          <TableHead>
            <TableRow>
              {Object.keys(VIEW_ORDER_HEADER_CONSTANTS).map((header) => (
                <StyledTableCell
                  key={header}
                  style={{ position: 'sticky' }}
                  align="center"
                >
                  {VIEW_ORDER_HEADER_CONSTANTS[header]}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {isOrderLoading ? (
              <CustomSkeleton totalRows={5} colSpan={11} />
            ) : size(lineItems) > 0 ? (
              <>
                {lineItems.map((sku, index) => (
                  <OrderSkuTableItem key={sku.id} index={index} sku={sku} />
                ))}
                <StyledTableRow>
                  <StyledTableCell align="center">
                    <MaterialButton buttonText="Totals" variant="outlined" />
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <MaterialButton
                      buttonText={`${size(lineItems)} ${
                        size(lineItems) > 1 ? 'Skus' : 'Sku'
                      }`}
                      variant="outlined"
                    />
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <MaterialButton
                      buttonText={order.total_ordered_quantities || 0}
                      variant="outlined"
                    />
                  </StyledTableCell>
                  <StyledTableCell align="center"></StyledTableCell>
                  <StyledTableCell align="center"></StyledTableCell>
                  <StyledTableCell align="center">
                    <MaterialButton
                      buttonText={formatInInr(order.total_order_value)}
                      variant="outlined"
                    />
                  </StyledTableCell>
                </StyledTableRow>
              </>
            ) : (
              <TableRow>
                <StyledTableCell align="center" colSpan={11}>
                  No Skus Ordered!
                </StyledTableCell>
              </TableRow>
            )}
          </TableBody>
        </MuiTable>
      </TableContainer>
    </Paper>
  );
}

export default React.memo(OrderSkuTable);
