import { get, isEqual } from 'lodash';

export const reformatSuperStockistFormValues = (sku = {}) => {
  return {
    parent_product: get(sku, 'parent_product', null),
    sku: get(sku, 'sku', ''),
    name: get(sku, 'name', ''),
    mrp: get(sku, 'mrp', ''),
    special_selling_price: get(sku, 'special_selling_price', ''),
    pieces_per_carton: get(sku, 'pieces_per_carton', ''),
    super_stockist_margin: get(sku, 'margin.super_stockist', ''),
    distributor_margin: get(sku, 'margin.distributor', ''),
    retailer_margin: get(sku, 'margin.retailer', ''),
    cgst: get(sku, 'tax.cgst', ''),
    sgst: get(sku, 'tax.sgst', ''),
    igst: get(sku, 'tax.igst', '')
  };
};

export const exportNormalizedFormValues = (
  { values, skuType, parentProductItem },
  sku
) => {
  const updatedValues = {};

  if (values.sku && !isEqual(sku.sku, values.sku)) {
    updatedValues.sku = values.sku;
  }

  if (skuType && !isEqual(sku.sku_type, skuType)) {
    updatedValues.sku_type = skuType;
  }

  if (!isEqual(parentProductItem.id, sku.parent_product.id)) {
    updatedValues.parent_product = parentProductItem.id;
  }

  if (values.name && !isEqual(sku.name, values.name)) {
    updatedValues.name = values.name;
  }

  if (values.mrp && !isEqual(sku.mrp, values.mrp)) {
    updatedValues.mrp = values.mrp;
  }

  if (
    values.special_selling_price &&
    !isEqual(sku.special_selling_price, values.special_selling_price)
  ) {
    updatedValues.special_selling_price = values.special_selling_price;
  }

  if (
    values.pieces_per_carton &&
    !isEqual(sku.pieces_per_carton, values.pieces_per_carton)
  ) {
    updatedValues.pieces_per_carton = values.pieces_per_carton;
  }

  if (
    values.super_stockist_margin &&
    !isEqual(sku.margin.super_stockist, values.super_stockist_margin)
  ) {
    updatedValues.super_stockist_margin = values.super_stockist_margin;
  }

  if (
    values.distributor_margin &&
    !isEqual(sku.margin.distributor, values.distributor_margin)
  ) {
    updatedValues.distributor_margin = values.distributor_margin;
  }

  if (
    values.retailer_margin &&
    !isEqual(sku.margin.retailer, values.retailer_margin)
  ) {
    updatedValues.retailer_margin = values.retailer_margin;
  }

  if (values.cgst && !isEqual(sku.tax.cgst, values.cgst)) {
    updatedValues.cgst = values.cgst;
  }

  if (values.sgst && !isEqual(sku.tax.sgst, values.sgst)) {
    updatedValues.sgst = values.sgst;
  }

  if (values.igst && !isEqual(sku.tax.igst, values.igst)) {
    updatedValues.igst = values.igst;
  }

  return updatedValues;
};
