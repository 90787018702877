import { useEffect, useCallback } from 'react';
import { size } from 'lodash';

const useFetchReducerWithId = (effect, id, record) => {
  const effectFunction = useCallback(async () => {
    await effect(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (size(record) === 0) {
      effectFunction();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [effectFunction]);
};

export default useFetchReducerWithId;
