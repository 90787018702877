import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import BackdropWithMessage from '../BackdropWithMessage';
import history from '../../routes/history';
import { SEVERITY } from '../../shared/Constants/Constant';

function SuccessNotification() {
  const isOpen = useSelector((state) => state.success.showSuccessNotification);
  const successMessage = useSelector((state) => state.success.successMessage);
  const route = useSelector((state) => state.route.nextLocation);

  const dispatch = useDispatch();

  function handleClose() {
    dispatch({ type: 'HIDE_SUCCESS' });
    dispatch({ type: 'CLEAR_ROUTE' });
    if (route) {
      history.push(route);
    }
  }

  return (
    isOpen &&
    successMessage && (
      <BackdropWithMessage
        open={isOpen}
        type={SEVERITY.SUCCESS}
        text={successMessage}
        handleClose={handleClose}
      />
    )
  );
}

export default SuccessNotification;
