import React, { useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Avatar,
  InputAdornment
} from '@material-ui/core';
import MaterialButton from '../../../../components/MaterialButton';
import FormAutoComplete from '../../../../components/FormAutoComplete';
import { FiberNew as FiberNewIcon } from '@material-ui/icons';
import FormInput from '../../../../components/FormInput';
import { get } from 'lodash';
import {
  DECIMAL_NUMBER_REGEX,
  ONLY_NUMBER_REGEX
} from '../../../../shared/Constants/Constant';
import {
  getDistributorSkuPrice,
  getSkuTotalCost
} from '../../../../redux/second-primary-order/second-primary-order.utils';
import commonStyles from '../../../../Styles/CommonStyles';

function CreateSecondPrimaryOrderNewItemForm(props) {
  const { open, handleClose, errorMessage, skusPayload, addSkuItem } = props;
  const classes = commonStyles();
  const [selectedSkuItem, setSelectedSkuItem] = useState(null);
  const [quantityInPcs, setQuantityInPcs] = useState('');
  const [customizedMargin, setCustomizedMargin] = useState('');

  const normalizeForm = () => {
    const distributorLandedPrice = getDistributorSkuPrice(
      selectedSkuItem,
      customizedMargin
    );

    return {
      skuId: selectedSkuItem.id,
      name: selectedSkuItem.name,
      parentProductName: get(selectedSkuItem, 'parent_product_name', 'NA'),
      quantityInPcs: quantityInPcs,
      customizedMargin: Number(customizedMargin).toFixed(2),
      distributorLandedPrice,
      totalSkuCost: getSkuTotalCost({
        quantity: quantityInPcs,
        distributorLandedPrice
      })
    };
  };

  const handleModalClose = () => {
    setSelectedSkuItem(null);
    setCustomizedMargin('');
    setQuantityInPcs('');
    handleClose();
  };

  const formSubmit = () => {
    addSkuItem(normalizeForm(), handleModalClose);
  };

  const handleSelectedSkuItem = (value) => {
    setSelectedSkuItem(value);
    setCustomizedMargin(Number(get(value, 'margin.distributor', 0)).toFixed(2));
  };

  const handleQuantityInPcsChange = (event) => {
    const { value } = event.target;

    if (value === '' || value.match(ONLY_NUMBER_REGEX) != null) {
      setQuantityInPcs(value);
    }
  };

  const handleMarginInputChange = (event) => {
    event.persist();
    const { value } = event.target;

    if (value === '' || value.match(DECIMAL_NUMBER_REGEX) != null) {
      setCustomizedMargin(Number(value));
    }
  };

  const onBlurDecimalHandle = (event) => {
    event.persist();

    setCustomizedMargin((prevValue) => Number(prevValue).toFixed(2));
  };

  const disableQuantityInput = () => {
    if (!selectedSkuItem) {
      return true;
    }

    return false;
  };

  const disableNext = () => {
    if (!selectedSkuItem) {
      return true;
    } else if (Number(quantityInPcs) < 1) {
      return true;
    }
    return !quantityInPcs;
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleModalClose}
        classes={{
          paperWidthSm: classes.paperWidthSm
        }}
      >
        <DialogTitle id="form-dialog-title">
          <Avatar className={classes.avatar}>
            <FiberNewIcon color="primary" />
          </Avatar>
          <Typography align="center">
            Second Primary Order - Sku Item
          </Typography>
        </DialogTitle>
        <DialogContent>
          <FormAutoComplete
            data={skusPayload}
            label="Sku Name"
            name="skuName"
            value={selectedSkuItem}
            onChangeSelect={(e, newValue) => handleSelectedSkuItem(newValue)}
            property="name"
          />

          <FormInput
            type="number"
            label="Margin"
            placeholder="Enter the Margin"
            name="customizedMargin"
            value={customizedMargin || ''}
            onChange={handleMarginInputChange}
            onBlur={onBlurDecimalHandle}
            InputProps={{
              endAdornment: <InputAdornment position="start">%</InputAdornment>
            }}
            disabled={disableQuantityInput()}
          />

          <FormInput
            type="number"
            label="Order Quantity(in pcs)"
            name="quantityInPcs"
            value={quantityInPcs || ''}
            onChange={handleQuantityInPcsChange}
            disabled={disableQuantityInput()}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">pcs</InputAdornment>
              )
            }}
          />

          {!!errorMessage && (
            <Typography variant="h5" style={{ color: '#d50000', fontSize: 13 }}>
              {`**${errorMessage}`}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <MaterialButton handleClick={handleModalClose} buttonText="Cancel" />
          <MaterialButton
            handleClick={formSubmit}
            disabled={disableNext()}
            buttonText="Confirm"
          />
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default CreateSecondPrimaryOrderNewItemForm;
