import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import formAutoCompleteStyles from './FormAutoComplete.Styles';

function FormAutoComplete(props) {
  const {
    data = [],
    label,
    value,
    name,
    onChangeSelect,
    property,
    error = false,
    helperText = '',
    disabled = false,
    required = false
  } = props;
  const [open, setOpen] = useState(false);
  const classes = formAutoCompleteStyles();

  return (
    <Autocomplete
      className={classes.container}
      autoComplete
      name={name}
      value={value}
      onChange={(event, newValue) => {
        onChangeSelect(event, newValue);
      }}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      getOptionLabel={(option) => option[property] || ''}
      getOptionSelected={(option, value) => option.id === value.id}
      options={data}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant="outlined"
          InputProps={{
            ...params.InputProps
          }}
          helperText={helperText}
          error={error}
        />
      )}
      disabled={disabled}
      required={required}
    />
  );
}

export default FormAutoComplete;
