function DistrictFormValidator(values) {
  const errors = {};

  if (!values.name) {
    errors.name = '**Zone Name is required';
  }

  return errors;
}

export default DistrictFormValidator;
