import _ from 'lodash';
import * as SecondPrimaryOrderActionTypes from './second-primary-order.types';
import {
  addItemToCart,
  removeItemFromCart
} from './second-primary-order.utils';

const initialState = {
  data: {},
  isRequestPending: false,
  isModalUpdateRequestPending: false,
  error: {},
  cart: {},
  secondPrimaryOrderPaginationPage: 1,
  pagination: {},
  customer: {}
};

const secondPrimaryOrderDataReducer = (state = {}, action) => {
  const { payload, type } = action;

  switch (type) {
    case SecondPrimaryOrderActionTypes.FETCH_SECOND_PRIMARY_ORDERS_SUCCESS:
      return { ..._.mapKeys(payload, 'id') };

    case SecondPrimaryOrderActionTypes.FETCH_SECOND_PRIMARY_ORDER_SUCCESS:
    case SecondPrimaryOrderActionTypes.CREATE_SECOND_PRIMARY_ORDER_SUCCESS:
    case SecondPrimaryOrderActionTypes.UPDATE_SECOND_PRIMARY_ORDER_SUCCESS:
      return { ...state, [payload.id]: payload };

    case SecondPrimaryOrderActionTypes.DELETE_SECOND_PRIMARY_ORDER_SUCCESS:
      return _.omit(state, payload);

    default:
      return state;
  }
};

const secondPrimaryOrderReducer = (state = initialState, action) => {
  const { type } = action;

  switch (type) {
    case SecondPrimaryOrderActionTypes.FETCH_SECOND_PRIMARY_ORDERS_REQUEST:
    case SecondPrimaryOrderActionTypes.FETCH_SECOND_PRIMARY_ORDER_REQUEST:
    case SecondPrimaryOrderActionTypes.CREATE_SECOND_PRIMARY_ORDER_REQUEST:
    case SecondPrimaryOrderActionTypes.DELETE_SECOND_PRIMARY_ORDER_REQUEST:
      return {
        ...state,
        isRequestPending: true
      };

    case SecondPrimaryOrderActionTypes.UPDATE_SECOND_PRIMARY_ORDER_REQUEST:
      return {
        ...state,
        isModalUpdateRequestPending: true
      };

    case SecondPrimaryOrderActionTypes.LOAD_SECOND_PRIMARY_ORDER_CUSTOMER:
      return {
        ...state,
        customer: action.payload
      };

    case SecondPrimaryOrderActionTypes.CLEAR_SECOND_PRIMARY_ORDER_ORDER_CART:
      return {
        ...state,
        cart: {}
      };

    case SecondPrimaryOrderActionTypes.FETCH_SECOND_PRIMARY_ORDERS_SUCCESS:
    case SecondPrimaryOrderActionTypes.FETCH_SECOND_PRIMARY_ORDER_SUCCESS:
    case SecondPrimaryOrderActionTypes.CREATE_SECOND_PRIMARY_ORDER_SUCCESS:
    case SecondPrimaryOrderActionTypes.DELETE_SECOND_PRIMARY_ORDER_SUCCESS:
      return {
        ...state,
        isRequestPending: false,
        data: secondPrimaryOrderDataReducer(state.data, action)
      };

    case SecondPrimaryOrderActionTypes.UPDATE_SECOND_PRIMARY_ORDER_SUCCESS:
      return {
        ...state,
        isModalUpdateRequestPending: false,
        data: secondPrimaryOrderDataReducer(state.data, action)
      };

    case SecondPrimaryOrderActionTypes.FETCH_SECOND_PRIMARY_ORDERS_ERROR:
    case SecondPrimaryOrderActionTypes.FETCH_SECOND_PRIMARY_ORDER_ERROR:
    case SecondPrimaryOrderActionTypes.CREATE_SECOND_PRIMARY_ORDER_ERROR:
    case SecondPrimaryOrderActionTypes.DELETE_SECOND_PRIMARY_ORDER_ERROR:
      return {
        ...state,
        isRequestPending: false,
        error: action.error
      };

    case SecondPrimaryOrderActionTypes.UPDATE_SECOND_PRIMARY_ORDER_ERROR:
      return {
        ...state,
        isModalUpdateRequestPending: false,
        error: action.error
      };

    case SecondPrimaryOrderActionTypes.RESET_SECOND_PRIMARY_ORDER_ERROR:
      return {
        ...state,
        isRequestPending: false,
        error: initialState.error
      };

    case SecondPrimaryOrderActionTypes.RESET_SECOND_PRIMARY_ORDER_DATA:
      return {
        ...state,
        data: {}
      };

    case SecondPrimaryOrderActionTypes.SECOND_PRIMARY_ORDER_PAGINATION:
      return { ...state, pagination: action.payload };

    case SecondPrimaryOrderActionTypes.UPDATE_SECOND_PRIMARY_ORDER_PAGINATION_PAGE:
      return {
        ...state,
        secondPrimaryOrderPaginationPage: action.payload
      };

    case SecondPrimaryOrderActionTypes.ADD_SECOND_PRIMARY_ORDER_ITEM: {
      return {
        ...state,
        cart: addItemToCart(state.cart, action.payload)
      };
    }

    case SecondPrimaryOrderActionTypes.REMOVE_SECOND_PRIMARY_ORDER_ITEM: {
      return {
        ...state,
        cart: removeItemFromCart(state.cart, action.payload)
      };
    }

    case SecondPrimaryOrderActionTypes.CLEAR_SECOND_PRIMARY_ORDER_CART: {
      return {
        ...state,
        cart: initialState.cart
      };
    }

    default:
      return state;
  }
};

export default secondPrimaryOrderReducer;
