import _ from 'lodash';
import * as PrimaryOrderActionTypes from './primary-order.types';
import { addItemToCart, removeItemFromCart } from './primary-order.utils';

const initialState = {
  data: {},
  isRequestPending: false,
  isModalUpdateRequestPending: false,
  error: {},
  cart: {},
  primaryOrderPaginationPage: 1,
  pagination: {},
  customer: {}
};

const primaryOrderDataReducer = (state = {}, action) => {
  const { payload, type } = action;

  switch (type) {
    case PrimaryOrderActionTypes.FETCH_PRIMARY_ORDERS_SUCCESS:
      return { ..._.mapKeys(payload, 'id') };

    case PrimaryOrderActionTypes.FETCH_PRIMARY_ORDER_SUCCESS:
    case PrimaryOrderActionTypes.CREATE_PRIMARY_ORDER_SUCCESS:
    case PrimaryOrderActionTypes.UPDATE_PRIMARY_ORDER_SUCCESS:
      return { ...state, [payload.id]: payload };

    case PrimaryOrderActionTypes.DELETE_PRIMARY_ORDER_SUCCESS:
      return _.omit(state, payload);

    default:
      return state;
  }
};

const primaryOrderReducer = (state = initialState, action) => {
  const { type } = action;

  switch (type) {
    case PrimaryOrderActionTypes.FETCH_PRIMARY_ORDERS_REQUEST:
    case PrimaryOrderActionTypes.FETCH_PRIMARY_ORDER_REQUEST:
    case PrimaryOrderActionTypes.CREATE_PRIMARY_ORDER_REQUEST:
    case PrimaryOrderActionTypes.DELETE_PRIMARY_ORDER_REQUEST:
      return {
        ...state,
        isRequestPending: true
      };

    case PrimaryOrderActionTypes.UPDATE_PRIMARY_ORDER_REQUEST:
      return {
        ...state,
        isModalUpdateRequestPending: true
      };

    case PrimaryOrderActionTypes.LOAD_PRIMARY_ORDER_CUSTOMER:
      return {
        ...state,
        customer: action.payload
      };

    case PrimaryOrderActionTypes.CLEAR_PRIMARY_ORDER_ORDER_CART:
      return {
        ...state,
        cart: {}
      };

    case PrimaryOrderActionTypes.FETCH_PRIMARY_ORDERS_SUCCESS:
    case PrimaryOrderActionTypes.FETCH_PRIMARY_ORDER_SUCCESS:
    case PrimaryOrderActionTypes.CREATE_PRIMARY_ORDER_SUCCESS:
    case PrimaryOrderActionTypes.DELETE_PRIMARY_ORDER_SUCCESS:
      return {
        ...state,
        isRequestPending: false,
        data: primaryOrderDataReducer(state.data, action)
      };

    case PrimaryOrderActionTypes.UPDATE_PRIMARY_ORDER_SUCCESS:
      return {
        ...state,
        isModalUpdateRequestPending: false,
        data: primaryOrderDataReducer(state.data, action)
      };

    case PrimaryOrderActionTypes.FETCH_PRIMARY_ORDERS_ERROR:
    case PrimaryOrderActionTypes.FETCH_PRIMARY_ORDER_ERROR:
    case PrimaryOrderActionTypes.CREATE_PRIMARY_ORDER_ERROR:
    case PrimaryOrderActionTypes.DELETE_PRIMARY_ORDER_ERROR:
      return {
        ...state,
        isRequestPending: false,
        error: action.error
      };

    case PrimaryOrderActionTypes.UPDATE_PRIMARY_ORDER_ERROR:
      return {
        ...state,
        isModalUpdateRequestPending: false,
        error: action.error
      };

    case PrimaryOrderActionTypes.RESET_PRIMARY_ORDER_ERROR:
      return {
        ...state,
        isRequestPending: false,
        error: initialState.error
      };

    case PrimaryOrderActionTypes.RESET_PRIMARY_ORDER_DATA:
      return {
        ...state,
        data: {}
      };

    case PrimaryOrderActionTypes.PRIMARY_ORDER_PAGINATION:
      return { ...state, pagination: action.payload };

    case PrimaryOrderActionTypes.UPDATE_PRIMARY_ORDER_PAGINATION_PAGE:
      return {
        ...state,
        primaryOrderPaginationPage: action.payload
      };

    case PrimaryOrderActionTypes.ADD_PRIMARY_ORDER_ITEM: {
      return {
        ...state,
        cart: addItemToCart(state.cart, action.payload)
      };
    }

    case PrimaryOrderActionTypes.REMOVE_PRIMARY_ORDER_ITEM: {
      return {
        ...state,
        cart: removeItemFromCart(state.cart, action.payload)
      };
    }

    case PrimaryOrderActionTypes.CLEAR_PRIMARY_ORDER_CART: {
      return {
        ...state,
        cart: initialState.cart
      };
    }

    default:
      return state;
  }
};

export default primaryOrderReducer;
