import * as ErrorActionTypes from './error.types';
import STATUS from '../../shared/Constants/apiStatus';

const initState = {
  errorMessage: null,
  errorType: null,
  showError: false
};

const errorReducer = (state = initState, action) => {
  const { error } = action;

  if (error && Object.keys(error).length > 0 && error.status === STATUS.ERROR) {
    return {
      ...state,
      errorMessage: error.message,
      errorType: error.error,
      showError: true
    };
  }

  if (action.type === ErrorActionTypes.HIDE_ERROR) {
    return {
      ...state,
      errorMessage: null,
      errorType: null,
      showError: false
    };
  }
  return state;
};

export default errorReducer;
