import React from 'react';
import { Typography } from '@material-ui/core';
import { get } from 'lodash';
import { connect } from 'react-redux';
import OrderSkuTable from '../../../components/OrderSkuTable';
import { fetchPrimaryOrder } from '../../../redux/primary-order/primary-order.actions';
import {
  getPrimaryOrderById,
  isPrimaryOrderRequestPending
} from '../../../redux/primary-order/primary-order.selector';
import useRefreshFetchReducerWithId from '../../../shared/Hooks/useRefreshFetchReducerWithId';
import {
  ORDER_TYPES_TEXT,
  CUSTOMER_TYPES,
  PRIMARY_ORDER_STATUS
} from '../../../shared/Constants/Constant';

function ViewPrimaryOrder(props) {
  const {
    match: {
      params: { id: orderId }
    },
    fetchPrimaryOrderConnect,
    orderDetails,
    isLoading
  } = props;

  useRefreshFetchReducerWithId(fetchPrimaryOrderConnect, orderId);

  const getCustomerName = () => {
    return (
      get(orderDetails, 'super_stockist.name', '') ||
      get(orderDetails, 'distributor.name', 'NA')
    );
  };

  return (
    <>
      <Typography
        variant="h5"
        align="center"
        style={{ marginTop: 10, marginBottom: 10 }}
      >
        {`${ORDER_TYPES_TEXT.PRIMARY_ORDER} - ${orderDetails.invoice_number}`}
      </Typography>

      <Typography
        variant="h5"
        align="center"
        style={{ marginTop: 10, marginBottom: 10 }}
      >
        {`${CUSTOMER_TYPES[orderDetails.customer_type]} - ${getCustomerName()}`}
      </Typography>

      <Typography
        variant="h5"
        align="center"
        style={{ marginTop: 10, marginBottom: 10, color: '#d50000' }}
      >
        {`Status - ${PRIMARY_ORDER_STATUS[orderDetails.status]}`}
      </Typography>

      <OrderSkuTable order={orderDetails} isOrderLoading={isLoading} />
    </>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    orderDetails: getPrimaryOrderById(state, ownProps),
    isLoading: isPrimaryOrderRequestPending(state)
  };
};

const mapDispatchToProps = {
  fetchPrimaryOrderConnect: fetchPrimaryOrder
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewPrimaryOrder);
