import React, { useState } from 'react';
import { connect } from 'react-redux';
import { fetchAllAreas, deleteArea } from '../../../redux/area/area.actions';
import {
  getAreaById,
  getAllAreas,
  isAreaRequestPending
} from '../../../redux/area/area.selector';
import useFetchReducer from '../../../shared/Hooks/useFetchReducer';
import Modal from '../../../components/Modal';
import history from '../../../routes/history';
import { TITLES } from '../../../shared/Constants/Constant';
import { ROUTE_TO_AREA } from '../../../shared/Constants/RouteConstants';
import BackdropWithLoader from '../../../components/BackdropWithLoader';
import { setCancel } from '../../../redux/event/event.actions';

function DeleteArea(props) {
  const [showDeleteModal, setShowDeleteModal] = useState(true);
  const {
    fetchAllAreasConnect,
    areasPayload,
    deleteAreaConnect,
    isAreaLoading,
    area = {},
    match: {
      params: { id }
    },
    setCancelConnect
  } = props;
  const areaId = id;

  useFetchReducer(fetchAllAreasConnect, areasPayload);

  const handleDelete = async () => {
    await deleteAreaConnect(areaId);
    setShowDeleteModal(false);
  };

  const handleClose = () => {
    setShowDeleteModal(false);
    setCancelConnect();
    history.push(ROUTE_TO_AREA);
  };

  const actions = [
    {
      buttonText: 'Cancel',
      buttonAction: handleClose,
      buttonStyle: { backgroundColor: '#4caf50', color: '#fff' }
    },
    {
      buttonText: 'Delete',
      buttonAction: handleDelete,
      buttonStyle: { backgroundColor: '#d50000', color: '#fff' }
    }
  ];

  const modelContent = () => {
    const { name, district = {}, zone = {} } = area;

    if (!area) {
      return 'Are you sure you want to delete this area?';
    }
    return `Are you sure you want 
      to delete Area ${name} from District: ${district.name} - Zone: ${zone.name}`;
  };

  if (isAreaLoading) {
    return <BackdropWithLoader />;
  }

  return (
    <Modal
      title={TITLES.DELETE_AREA}
      isModalVisible={showDeleteModal}
      actions={actions}
      onClose={handleClose}
      content={modelContent()}
    />
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    isAreaLoading: isAreaRequestPending(state),
    areasPayload: getAllAreas(state),
    area: getAreaById(state, ownProps)
  };
};

const mapDispatchToProps = {
  fetchAllAreasConnect: fetchAllAreas,
  deleteAreaConnect: deleteArea,
  setCancelConnect: setCancel
};

export default connect(mapStateToProps, mapDispatchToProps)(DeleteArea);
