import React from 'react';
import {
  Table as MuiTable,
  TableBody,
  TableHead,
  TableRow,
  Paper,
  TableContainer
} from '@material-ui/core';
import tableStyles from '../Styles/TableStyles';
import StyledTableCell from '../Styles/StyledTableCell';
import TableRowAction from '../TableRowAction';
import { BUTTON_ACTIONS_CONSTANTS } from '../../shared/Constants/Constant';
import {
  ROUTE_TO_EDIT_ZONE,
  ROUTE_TO_DELETE_ZONE
} from '../../shared/Constants/RouteConstants';
import { ZONE_HEADER_CONSTANTS } from '../../shared/Constants/TableHeaderConstants';

function ZoneTable(props) {
  const classes = tableStyles();
  const { zones = [], isZoneLoading } = props;

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.tableWithTopButtonContainer}>
        <MuiTable stickyHeader>
          <TableHead>
            <TableRow>
              {Object.keys(ZONE_HEADER_CONSTANTS).map((header) => (
                <StyledTableCell
                  key={header}
                  style={{ position: 'sticky' }}
                  align="center"
                >
                  {ZONE_HEADER_CONSTANTS[header]}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {zones.map((zone) => {
              const { id: zoneId, name = '', districtsCount } = zone;

              return (
                <TableRow key={zoneId}>
                  <StyledTableCell component="th" scope="row" align="center">
                    <TableRowAction
                      buttonText={BUTTON_ACTIONS_CONSTANTS.EDIT}
                      urlLink={`${ROUTE_TO_EDIT_ZONE}${zoneId}`}
                      color="primary"
                      disabled={isZoneLoading}
                    />
                    <TableRowAction
                      buttonText={BUTTON_ACTIONS_CONSTANTS.DELETE}
                      urlLink={`${ROUTE_TO_DELETE_ZONE}${zoneId}`}
                      disabled={isZoneLoading}
                    />
                  </StyledTableCell>

                  <StyledTableCell align="center">{name}</StyledTableCell>

                  <StyledTableCell align="center">
                    {districtsCount}
                  </StyledTableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </MuiTable>
      </TableContainer>
    </Paper>
  );
}

export default React.memo(ZoneTable);
