import React, { useCallback, useState } from 'react';
import moment from 'moment';
import { StyledTableRow, StyledTableCell } from '../../Styles/TableStyles';
import MaterialButton from '../MaterialButton';
import { Popover, Button } from 'antd';
import history from '../../routes/history';
import {
  ROUTE_TO_VIEW_SECONDARY_ORDER_DETAILS,
  ROUTE_TO_DELETE_SECONDARY_ORDER
} from '../../shared/Constants/RouteConstants';
import {
  SECONDARY_ORDER_CONSTANTS,
  SECONDARY_ORDER_STATUS,
  BUTTON_ACTIONS
} from '../../shared/Constants/Constant';
import { get } from 'lodash';
import EditSecondaryOrderStatus from './EditSecondaryOrderStatus';
import { formatInInr } from '../../shared/Utils/CommonUtils';

function SecondaryOrderTableItem(props) {
  const { order } = props;

  const [openUpdateStatusForm, setOpenUpdateStatusForm] = useState(false);

  const pageNavigate = useCallback((route, id) => {
    history.push(`${route}${id}`);
  }, []);

  const orderPopoverContent = (order) => {
    return (
      <div>
        <p style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button
            type="primary"
            onClick={() =>
              pageNavigate(ROUTE_TO_VIEW_SECONDARY_ORDER_DETAILS, order.id)
            }
          >
            {BUTTON_ACTIONS.EDIT}
          </Button>
          <Button
            type="danger"
            onClick={() =>
              pageNavigate(ROUTE_TO_DELETE_SECONDARY_ORDER, order.id)
            }
            disabled={
              order.status === SECONDARY_ORDER_CONSTANTS.REACHED_DESTINATION
            }
          >
            {BUTTON_ACTIONS.DELETE}
          </Button>
        </p>
      </div>
    );
  };

  return (
    <StyledTableRow>
      <StyledTableCell align="center">
        <Popover
          placement="right"
          title={order.invoice_number}
          content={orderPopoverContent(order)}
          trigger="hover"
        >
          <MaterialButton buttonText={order.invoice_number} />
        </Popover>
      </StyledTableCell>
      <StyledTableCell align="center">
        {get(order, 'retailer.name', '')}
      </StyledTableCell>
      <StyledTableCell align="center">
        {get(order, 'beat.name', 'NA')}
      </StyledTableCell>
      <StyledTableCell align="center">
        {get(order, 'area.name', 'NA')}
      </StyledTableCell>
      <StyledTableCell align="center">
        {get(order, 'distributor.name', 'NA')}
      </StyledTableCell>
      <StyledTableCell align="center">
        {formatInInr(order.total_order_value)}
      </StyledTableCell>
      <StyledTableCell align="center">{order.orderTakenBy}</StyledTableCell>
      <StyledTableCell align="center">
        {moment(order.createdAt).format('DD-MMM-YYYY')}
      </StyledTableCell>
      <StyledTableCell align="center">
        {moment(order.createdAt).format('hh:mm A')}
      </StyledTableCell>
      <StyledTableCell align="center">
        {SECONDARY_ORDER_STATUS[order.status]}
      </StyledTableCell>
      <StyledTableCell align="center">
        <div>
          <MaterialButton
            buttonText="Update"
            handleClick={() => setOpenUpdateStatusForm(true)}
            disabled={
              order.status === SECONDARY_ORDER_CONSTANTS.REACHED_DESTINATION
            }
          />
          <EditSecondaryOrderStatus
            open={openUpdateStatusForm}
            handleClose={() => setOpenUpdateStatusForm(false)}
            order={order}
          />
        </div>
      </StyledTableCell>
    </StyledTableRow>
  );
}

export default SecondaryOrderTableItem;
