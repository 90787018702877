import React, { useState } from 'react';
import { StyledTableCell, StyledTableRow } from '../../Styles/TableStyles';
import MaterialButton from '../../components/MaterialButton';
import { SKU_TYPES } from '../../shared/Constants/Constant';

function InventoryTableItem(props) {
  const { sku, EditItemComponent } = props;
  const [openEditStockForm, setOpenEditStockForm] = useState(false);

  return (
    <StyledTableRow>
      <StyledTableCell align="center">
        <MaterialButton buttonText={sku.sku} />
      </StyledTableCell>
      <StyledTableCell align="center">
        {sku.parent_product_name || 'NA'}
      </StyledTableCell>
      <StyledTableCell align="center">{sku.name || 'NA'}</StyledTableCell>
      <StyledTableCell align="center">
        {SKU_TYPES[sku.sku_type]}
      </StyledTableCell>
      <StyledTableCell align="center">
        {sku.current_inventory_level}
      </StyledTableCell>
      {EditItemComponent && (
        <StyledTableCell align="center">
          <div>
            <MaterialButton
              buttonText="Update"
              handleClick={() => setOpenEditStockForm(true)}
            />
            <EditItemComponent
              open={openEditStockForm}
              handleClose={() => setOpenEditStockForm(false)}
              skuDetails={sku}
              customerId={props.retailerId}
            />
          </div>
        </StyledTableCell>
      )}
    </StyledTableRow>
  );
}

export default InventoryTableItem;
