import React from 'react';
import { useDispatch, connect } from 'react-redux';
import { Container, Avatar, Typography } from '@material-ui/core';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import formStyles from '../../../components/Styles/FormStyles';
import CreateZoneForm from '../../../components/CreateZoneForm';
import { TITLES } from '../../../shared/Constants/Constant';
import history from '../../../routes/history';
import { ROUTE_TO_ZONE } from '../../../shared/Constants/RouteConstants';
import { setCancel } from '../../../redux/event/event.actions';
import { createZone } from '../../../redux/zone/zone.actions';
import { isZoneRequestPending } from '../../../redux/zone/zone.selector';
import BackdropWithLoader from '../../../components/BackdropWithLoader';

function CreateZone(props) {
  const { createZoneConnect, isZoneLoading } = props;
  const classes = formStyles();
  const dispatch = useDispatch();

  const handleSubmit = async (formValues) => {
    await createZoneConnect(formValues);
  };

  const handleCancel = () => {
    dispatch(setCancel());
    history.push(ROUTE_TO_ZONE);
  };

  if (isZoneLoading) {
    return <BackdropWithLoader />;
  }

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <AccountCircleIcon color="primary" />
        </Avatar>
        <Typography component="h1" variant="h5">
          {TITLES.CREATE_ZONE}
        </Typography>

        <CreateZoneForm onSubmit={handleSubmit} onCancel={handleCancel} />
      </div>
    </Container>
  );
}

const mapStateToProps = (state) => {
  return {
    isZoneLoading: isZoneRequestPending(state)
  };
};

const mapDispatchToProps = {
  createZoneConnect: createZone,
  setCancelConnect: setCancel
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateZone);
