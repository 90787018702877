import React, { useState } from 'react';
import clsx from 'clsx';
import { CssBaseline, useMediaQuery } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/styles';
import PropTypes from 'prop-types';
import Navbar from '../../components/Navbar/index';
import Sidebar from '../../components/Sidebar/index';
import ErrorBoundary from '../../components/ErrorBoundary';
import Error from '../../components/Error';
import SuccessNotification from '../../components/SuccessNotification';
import Alerts from '../../shared/Alerts';

const useStyles = makeStyles((theme) => ({
  offset: theme.mixins.toolbar,
  root: {
    flexGrow: 1,
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',
    width: '100%'
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(1),
    overflowX: 'auto',
    height: '100%'
  },
  shiftContent: {
    paddingLeft: 240
  }
}));

function AppBarOffset() {
  const classes = useStyles();
  return <div className={classes.offset} />;
}

function MainLayout(props) {
  const classes = useStyles();
  const { children } = props;

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
    defaultMatches: true
  });

  const [openSidebar, setOpenSidebar] = useState(false);

  const handleSidebarOpen = () => {
    setOpenSidebar(true);
  };

  const handleSidebarClose = () => {
    setOpenSidebar(false);
  };

  const shouldOpenSidebar = isDesktop ? true : openSidebar;

  return (
    <div
      className={clsx({
        [classes.root]: true,
        [classes.shiftContent]: isDesktop
      })}
    >
      <Error />
      <SuccessNotification />
      <CssBaseline />
      <Navbar onSidebarOpen={handleSidebarOpen} />
      <AppBarOffset />
      <Sidebar
        onClose={handleSidebarClose}
        open={shouldOpenSidebar}
        variant={isDesktop ? 'persistent' : 'temporary'}
      />
      <main className={classes.content}>
        <div className={classes.offset} />
        <Alerts />
        <ErrorBoundary>{children}</ErrorBoundary>
      </main>
    </div>
  );
}

MainLayout.propTypes = {
  children: PropTypes.element.isRequired
};

export default MainLayout;
