import React from 'react';
import {
  Table as MuiTable,
  TableBody,
  TableHead,
  TableRow,
  Paper,
  TableContainer
} from '@material-ui/core';
import tableStyles from '../Styles/TableStyles';
import StyledTableCell from '../Styles/StyledTableCell';
import { PRIMARY_ORDER_HEADER_CONSTANTS } from '../../shared/Constants/TableHeaderConstants';
import { size } from 'lodash';
import PrimaryOrderTableItem from './PrimaryOrderTableItem';
import CustomSkeleton from '../CustomSkeleton';

function PrimaryOrderTable(props) {
  const classes = tableStyles();
  const { orders = [], isOrderLoading } = props;

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.tableFitContainer}>
        <MuiTable stickyHeader>
          <TableHead>
            <TableRow>
              {Object.keys(PRIMARY_ORDER_HEADER_CONSTANTS).map((header) => (
                <StyledTableCell
                  key={header}
                  style={{ position: 'sticky' }}
                  align="center"
                >
                  {PRIMARY_ORDER_HEADER_CONSTANTS[header]}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {isOrderLoading ? (
              <CustomSkeleton colSpan={9} />
            ) : size(orders) > 0 ? (
              orders.map((order, index) => (
                <PrimaryOrderTableItem
                  key={order.id}
                  index={index}
                  order={order}
                />
              ))
            ) : (
              <TableRow>
                <StyledTableCell align="center" colSpan={9}>
                  No Primary Orders Found in the database
                </StyledTableCell>
              </TableRow>
            )}
          </TableBody>
        </MuiTable>
      </TableContainer>
    </Paper>
  );
}

export default React.memo(PrimaryOrderTable);
