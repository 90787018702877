import TerritoryApi from '../../apis/TerritoryAPI';
import * as ZoneActionTypes from '../zone/zone.types';
import * as DistrictActionTypes from '../district/district.types';
import * as AreaActionTypes from '../area/area.types';
import * as BeatActionTypes from '../beatArea/beatArea.types';
import STATUS from '../../shared/Constants/apiStatus';

export const fetchAllZonesAndDistricts = () => async (dispatch) => {
  dispatch({
    type: ZoneActionTypes.FETCH_ZONES_REQUEST
  });

  dispatch({
    type: DistrictActionTypes.FETCH_DISTRICTS_REQUEST
  });

  try {
    const response = await TerritoryApi.getTerritoryData({
      select: 'zones,districts'
    });
    if (response.data.status === STATUS.OK) {
      dispatch({
        type: ZoneActionTypes.FETCH_ZONES_SUCCESS,
        payload: response.data.zones,
        error: null,
        route: null,
        success: null
      });
      dispatch({
        type: DistrictActionTypes.FETCH_DISTRICTS_SUCCESS,
        payload: response.data.districts,
        error: null,
        route: null,
        success: null
      });
    }
  } catch (error) {
    dispatch({
      type: ZoneActionTypes.FETCH_ZONES_ERROR,
      error: error.response.data,
      payload: null,
      route: null,
      success: null
    });
    dispatch({
      type: DistrictActionTypes.FETCH_DISTRICTS_ERROR,
      error: error.response.data,
      payload: null,
      route: null,
      success: null
    });
  }
};

export const fetchAllZonesAndDistrictsAndAreas = () => async (dispatch) => {
  dispatch({
    type: ZoneActionTypes.FETCH_ZONES_REQUEST
  });

  dispatch({
    type: DistrictActionTypes.FETCH_DISTRICTS_REQUEST
  });

  dispatch({
    type: AreaActionTypes.FETCH_AREAS_REQUEST
  });

  try {
    const response = await TerritoryApi.getTerritoryData({
      select: 'zones,districts,areas'
    });
    if (response.data.status === STATUS.OK) {
      dispatch({
        type: ZoneActionTypes.FETCH_ZONES_SUCCESS,
        payload: response.data.zones,
        error: null,
        route: null,
        success: null
      });
      dispatch({
        type: DistrictActionTypes.FETCH_DISTRICTS_SUCCESS,
        payload: response.data.districts,
        error: null,
        route: null,
        success: null
      });
      dispatch({
        type: AreaActionTypes.FETCH_AREAS_SUCCESS,
        payload: response.data.areas,
        error: null,
        route: null,
        success: null
      });
    }
  } catch (error) {
    dispatch({
      type: ZoneActionTypes.FETCH_ZONES_ERROR,
      error: error.response.data,
      payload: null,
      route: null,
      success: null
    });
    dispatch({
      type: DistrictActionTypes.FETCH_DISTRICTS_ERROR,
      error: error.response.data,
      payload: null,
      route: null,
      success: null
    });
    dispatch({
      type: AreaActionTypes.FETCH_AREA_ERROR,
      error: error.response.data,
      payload: null,
      route: null,
      success: null
    });
  }
};

export const fetchAllZonesAndDistrictsAndAreasAndBeats = () => async (
  dispatch
) => {
  dispatch({
    type: ZoneActionTypes.FETCH_ZONES_REQUEST
  });

  dispatch({
    type: DistrictActionTypes.FETCH_DISTRICTS_REQUEST
  });

  dispatch({
    type: AreaActionTypes.FETCH_AREAS_REQUEST
  });

  dispatch({
    type: BeatActionTypes.FETCH_BEAT_AREAS_REQUEST
  });

  try {
    const response = await TerritoryApi.getTerritoryData({
      select: 'zones,districts,areas,beats'
    });
    if (response.data.status === STATUS.OK) {
      dispatch({
        type: ZoneActionTypes.FETCH_ZONES_SUCCESS,
        payload: response.data.zones,
        error: null,
        route: null,
        success: null
      });
      dispatch({
        type: DistrictActionTypes.FETCH_DISTRICTS_SUCCESS,
        payload: response.data.districts,
        error: null,
        route: null,
        success: null
      });
      dispatch({
        type: AreaActionTypes.FETCH_AREAS_SUCCESS,
        payload: response.data.areas,
        error: null,
        route: null,
        success: null
      });
      dispatch({
        type: BeatActionTypes.FETCH_BEAT_AREAS_SUCCESS,
        payload: response.data.beats,
        error: null,
        route: null,
        success: null
      });
    }
  } catch (error) {
    dispatch({
      type: ZoneActionTypes.FETCH_ZONES_ERROR,
      error: error.response.data,
      payload: null,
      route: null,
      success: null
    });
    dispatch({
      type: DistrictActionTypes.FETCH_DISTRICTS_ERROR,
      error: error.response.data,
      payload: null,
      route: null,
      success: null
    });
    dispatch({
      type: AreaActionTypes.FETCH_AREA_ERROR,
      error: error.response.data,
      payload: null,
      route: null,
      success: null
    });
    dispatch({
      type: BeatActionTypes.FETCH_BEAT_AREA_ERROR,
      error: error.response.data,
      payload: null,
      route: null,
      success: null
    });
  }
};
