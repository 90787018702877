import { createMuiTheme } from '@material-ui/core';

import palette from './Palette';
import typography from './Typography';

const theme = createMuiTheme({
  palette,
  typography,
  zIndex: {
    appBar: 1200,
    drawer: 1100
  }
});

export default theme;
