import React from 'react';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import history from '../../routes/history';
import { BUTTON_ACTIONS_CONSTANTS } from '../../shared/Constants/Constant';

const styles = makeStyles(
  {
    deleteButton: {
      backgroundColor: '#787878',
      '&:hover': {
        backgroundColor: '#585858',
        color: '#FFF'
      }
    }
  },
  { index: 1 }
);

function TableRowAction({
  buttonText,
  urlLink,
  buttonStyle,
  disabled = false,
  ...otherProps
}) {
  const classes = styles();
  return (
    <Button
      className={
        buttonText === BUTTON_ACTIONS_CONSTANTS.DELETE
          ? classes.deleteButton
          : null
      }
      variant="contained"
      size="small"
      style={{
        color: '#fff',
        margin: '2px'
      }}
      onClick={() => history.push(urlLink)}
      disabled={disabled}
      {...otherProps}
    >
      {buttonText}
    </Button>
  );
}

export default React.memo(TableRowAction);
