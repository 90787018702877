import React, { useState } from 'react';
import { connect } from 'react-redux';
import InventoryTable from '../../../components/InventoryTable';
import {
  editDistributor,
  fetchDistributor,
  fetchDistributorInventory
} from '../../../redux/distributor/distributor.actions';
import {
  getDistributorById,
  isDistributorRequestPending,
  getDistributorSkuInventory
} from '../../../redux/distributor/distributor.selector';
import history from '../../../routes/history';
import { ROUTE_TO_DISTRIBUTOR } from '../../../shared/Constants/RouteConstants';
import { setCancel } from '../../../redux/event/event.actions';
import BackdropWithLoader from '../../../components/BackdropWithLoader';
import useFetchReducerWithId from '../../../shared/Hooks/useFetchReducerWithId';
import useRefreshFetchReducerWithId from '../../../shared/Hooks/useRefreshFetchReducerWithId';
import { makeStyles } from '@material-ui/core';
import TableHeadingWithCreateButton from '../../../components/TableHeadingWithCreateButton';
import DistributorInventoryNewItemForm from './DistributorInventoryNewItemForm/DistributorInventoryNewItemForm';
import { fetchAllSkus } from '../../../redux/sku/sku.actions';
import {
  getAllSkus,
  isSkuRequestPending
} from '../../../redux/sku/sku.selector';
import DistributorInventoryEditItemForm from './DistributorInventoryEditItemForm';

const useStyles = makeStyles((theme) => ({
  content: {
    marginTop: theme.spacing(2)
  }
}));

const ManageDistributorInventory = (props) => {
  const {
    match: {
      params: { id }
    },
    distributorSkuInventories,
    editDistributorConnect,
    fetchDistributorConnect,
    fetchDistributorInventoryConnect,
    distributor = {},
    isDistributorLoading,
    setCancelConnect,
    fetchAllSkusConnect,
    skusPayload
  } = props;
  const distributorId = id;
  const classes = useStyles();

  useFetchReducerWithId(fetchDistributorConnect, distributorId, distributor);

  useRefreshFetchReducerWithId(fetchDistributorInventoryConnect, distributorId);

  const [openSkuForm, setOpenSkuForm] = useState(false);

  const handleClickOpen = () => {
    fetchAllSkusConnect();
    setOpenSkuForm(true);
  };

  const handleClose = () => {
    setOpenSkuForm(false);
  };

  const handleSubmit = (formValues) => {
    editDistributorConnect(distributorId, formValues);
  };

  const handleCancel = () => {
    setCancelConnect();
    history.push(ROUTE_TO_DISTRIBUTOR);
  };

  if (isDistributorLoading) {
    return <BackdropWithLoader />;
  }

  const getFilteredSkusPayload = () => {
    const distributorSkuIds = distributorSkuInventories.reduce(
      (allIds, sku) => {
        return [...allIds, sku.sku_id];
      },
      []
    );

    return skusPayload.filter((sku) => !distributorSkuIds.includes(sku.id));
  };

  return (
    <>
      <TableHeadingWithCreateButton
        component={DistributorInventoryNewItemForm}
        open={openSkuForm}
        handleButtonClick={handleClickOpen}
        handleModalClose={handleClose}
        showCreateModal
        buttonText="ADD NEW SKU"
        headerTitle="DISTRIBUTOR STOCK MANAGEMENT"
        skusPayload={getFilteredSkusPayload()}
        distributorId={distributorId}
      />
      <div className={classes.content}>
        <InventoryTable
          skus={distributorSkuInventories}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
          EditItemComponent={DistributorInventoryEditItemForm}
          customerId={distributorId}
        />
      </div>
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    distributor: getDistributorById(state, ownProps),
    distributorSkuInventories: getDistributorSkuInventory(state, ownProps),
    isDistributorLoading: isDistributorRequestPending(state),
    isSkuRequestPending: isSkuRequestPending(state),
    skusPayload: getAllSkus(state)
  };
};

const mapDispatchToProps = {
  fetchAllSkusConnect: fetchAllSkus,
  fetchDistributorConnect: fetchDistributor,
  fetchDistributorInventoryConnect: fetchDistributorInventory,
  editDistributorConnect: editDistributor,
  setCancelConnect: setCancel
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManageDistributorInventory);
