import React, { useState } from 'react';
import {
  MenuItem,
  Checkbox,
  FormLabel,
  FormControlLabel,
  FormGroup
} from '@material-ui/core';
import FormInput from '../FormInput';
import formStyles from '../Styles/FormStyles';
import FormSelect from '../FormSelect';
import FormCancelButton from '../FormCancelButton';
import FormSubmitButton from '../FormSubmitButton';
import {
  USER_ROLES_CONSTANTS,
  USER_ROLES
} from '../../shared/Constants/Constant';
import useForm from '../../shared/Hooks/useForm';
import UserFormValidator from '../../pages/User/UserFormValidator';
import { formCss } from './form.css';

function UserForm(props) {
  const { onSubmit, onCancel } = props;

  const classes = formStyles();
  const customClasses = formCss();

  const [allowedApps, setAllowedApps] = useState({
    mobile: true,
    dashboard: false
  });

  const handleAllowedApps = (event) => {
    if (event.target.name === 'mobile') {
      setAllowedApps({
        ...allowedApps,
        mobile: true
      });
    } else {
      setAllowedApps({
        ...allowedApps,
        [event.target.name]: event.target.checked
      });
    }
  };

  const handleFormSubmit = () => {
    const userData = {
      name: values.name,
      user_name: values.user_name,
      mobile_number: values.mobile_number,
      password: values.password,
      role: values.role,
      restrict_by_territory: true,
      allowed_apps: Object.keys(allowedApps).reduce((acc, app) => {
        if (allowedApps[app]) {
          return [...acc, app];
        }

        return acc;
      }, [])
    };

    onSubmit(userData, resetValues);
  };

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    handleNumericInputChange,
    resetValues
  } = useForm(handleFormSubmit, UserFormValidator);

  return (
    <form className={classes.form} noValidate autoComplete="off">
      <FormInput
        type="text"
        label="Name"
        placeholder="Enter User Name"
        name="name"
        value={values.name || ''}
        onChange={handleChange}
        helperText={errors.name || ''}
        error={!!errors.name}
        required
        autoFocus
      />

      <FormInput
        type="text"
        label="Login User Name"
        placeholder="Enter Login User Name"
        name="user_name"
        value={values.user_name || ''}
        onChange={handleChange}
        helperText={errors.user_name || ''}
        error={!!errors.user_name}
        required
      />

      <FormInput
        type="text"
        label="Mobile Number"
        placeholder="Enter Mobile Number"
        name="mobile_number"
        value={values.mobile_number || ''}
        onChange={handleNumericInputChange}
        helperText={errors.mobile_number || ''}
        error={!!errors.mobile_number}
        required
      />

      <FormInput
        type="text"
        label="Password"
        placeholder="Enter Password"
        name="password"
        value={values.password || ''}
        onChange={handleChange}
        helperText={errors.password || ''}
        error={!!errors.password}
        required
      />

      <FormSelect
        label="Role"
        name="role"
        value={values.role || ''}
        onChange={handleChange}
        helperText={errors.role || ''}
        error={!!errors.role}
        required
      >
        {Object.keys(USER_ROLES_CONSTANTS).map((userRole) => {
          const label = USER_ROLES[userRole];
          return (
            <MenuItem key={userRole} value={userRole}>
              {label}
            </MenuItem>
          );
        })}
      </FormSelect>

      <div className={customClasses.allowedAppsCheckBoxGroup}>
        <FormLabel component="legend">Allowed Apps?</FormLabel>
        <FormGroup row>
          <FormControlLabel
            control={
              <Checkbox
                checked={allowedApps.mobile}
                onChange={handleAllowedApps}
                name="mobile"
                color="primary"
              />
            }
            label="Mobile App"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={allowedApps.dashboard}
                onChange={handleAllowedApps}
                name="dashboard"
                color="primary"
              />
            }
            label="Web Dashboard"
          />
        </FormGroup>
      </div>

      <div className={classes.buttonRow}>
        <FormCancelButton onClickCancel={onCancel} />
        <FormSubmitButton onClickSubmit={handleSubmit} />
      </div>
    </form>
  );
}

export default UserForm;
