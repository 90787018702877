import { get, has } from 'lodash';
import { createSelector } from 'reselect';
import { formatInInr } from '../../shared/Utils/CommonUtils';

export const secondPrimaryOrderSelector = (state) =>
  state.secondPrimaryOrders.data;
export const secondPrimaryOrderCartSelector = (state) =>
  state.secondPrimaryOrders.cart;
export const secondPrimaryOrderDataSelector = (state) =>
  state.secondPrimaryOrders.data;
export const secondPrimaryOrderByIdSelector = (state, props) =>
  props.match.params.id;
export const skuSelector = (state) => state.skus.data;

export const getAllSecondPrimaryOrders = createSelector(
  secondPrimaryOrderSelector,
  (secondPrimaryOrders) => Object.values(secondPrimaryOrders)
);

export const isSecondPrimaryOrderRequestPending = (state) =>
  state.secondPrimaryOrders.isRequestPending;

export const isSecondPrimaryOrderModalRequestPending = (state) =>
  state.secondPrimaryOrders.isModalUpdateRequestPending;

export const secondPrimaryOrderPaginationSelector = (state) =>
  state.secondPrimaryOrders.pagination;

export const isNextPageSecondPrimaryOrder = createSelector(
  secondPrimaryOrderPaginationSelector,
  (pagination) => has(pagination, 'next.page')
);

export const nextPageSecondPrimaryOrder = createSelector(
  secondPrimaryOrderPaginationSelector,
  (pagination) => get(pagination, 'next.page', 1)
);

export const getCurrentPaginationPage = (state) =>
  state.secondPrimaryOrders.secondPrimaryOrderPaginationPage;

export const getErrorMessage = (state) =>
  state.secondPrimaryOrders.error.message;

export const getSecondPrimaryOrderById = createSelector(
  [secondPrimaryOrderSelector, secondPrimaryOrderByIdSelector],
  (secondPrimaryOrders, id) => secondPrimaryOrders[id]
);

export const getSecondPrimaryOrderCartItems = createSelector(
  [secondPrimaryOrderCartSelector],
  (secondPrimaryOrderCartItems) => Object.values(secondPrimaryOrderCartItems)
);

export const getSecondPrimaryOrderCartTotalQtyInPcs = createSelector(
  [getSecondPrimaryOrderCartItems],
  (secondPrimaryOrderCartItems) => {
    return secondPrimaryOrderCartItems.reduce((total, cartItem) => {
      return (total += Number(cartItem.quantityInPcs));
    }, 0);
  }
);

export const getSecondPrimaryOrderTotalCostQuantity = createSelector(
  [getSecondPrimaryOrderCartItems],
  (secondPrimaryOrderCartItems) => {
    return formatInInr(
      secondPrimaryOrderCartItems.reduce((total, cartItem) => {
        return (total += Number(cartItem.totalSkuCost));
      }, 0)
    );
  }
);

export const getFilteredSkusPayload = createSelector(
  [skuSelector, secondPrimaryOrderCartSelector],
  (skusPayload, cartItems) => {
    return Object.values(skusPayload).filter(
      (sku) => !Object.keys(cartItems).includes(sku.id)
    );
  }
);

export const getSecondPrimaryOrderCustomer = (state) =>
  state.secondPrimaryOrders.customer;
