export const FETCH_DISTRICTS_REQUEST = 'FETCH_DISTRICTS_REQUEST';
export const FETCH_DISTRICT_REQUEST = 'FETCH_DISTRICT_REQUEST';
export const CREATE_DISTRICT_REQUEST = 'CREATE_DISTRICT_REQUEST';
export const UPDATE_DISTRICT_REQUEST = 'UPDATE_DISTRICT_REQUEST';
export const DELETE_DISTRICT_REQUEST = 'DELETE_DISTRICT_REQUEST';

export const FETCH_DISTRICTS_SUCCESS = 'FETCH_DISTRICTS_SUCCESS';
export const FETCH_DISTRICT_SUCCESS = 'FETCH_DISTRICT_SUCCESS';
export const CREATE_DISTRICT_SUCCESS = 'CREATE_DISTRICT_SUCCESS';
export const UPDATE_DISTRICT_SUCCESS = 'UPDATE_DISTRICT_SUCCESS';
export const DELETE_DISTRICT_SUCCESS = 'DELETE_DISTRICT_SUCCESS';

export const FETCH_DISTRICTS_ERROR = 'FETCH_DISTRICTS_ERROR';
export const FETCH_DISTRICT_ERROR = 'FETCH_DISTRICT_ERROR';
export const CREATE_DISTRICT_ERROR = 'CREATE_DISTRICT_ERROR';
export const UPDATE_DISTRICT_ERROR = 'UPDATE_DISTRICT_ERROR';
export const DELETE_DISTRICT_ERROR = 'DELETE_DISTRICT_ERROR';
