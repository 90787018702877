import _ from 'lodash';
import * as ZoneActionTypes from './zone.types';

const initialState = {
  data: {},
  isRequestPending: false
};

const zoneDataReducer = (state = {}, action) => {
  const { payload, type } = action;

  switch (type) {
    case ZoneActionTypes.FETCH_ZONES_SUCCESS:
      return { ..._.mapKeys(payload, 'id') };

    case ZoneActionTypes.FETCH_ZONE_SUCCESS:
      return { ...state, [payload.id]: payload };

    case ZoneActionTypes.CREATE_ZONE_SUCCESS:
      return { ...state, [payload.id]: payload };

    case ZoneActionTypes.UPDATE_ZONE_SUCCESS:
      return { ...state, [payload.id]: payload };

    case ZoneActionTypes.DELETE_ZONE_SUCCESS:
      return _.omit(state, payload);

    default:
      return state;
  }
};

const zoneReducer = (state = initialState, action) => {
  const { type } = action;

  switch (type) {
    case ZoneActionTypes.FETCH_ZONES_REQUEST:
      return {
        ...state,
        isRequestPending: true
      };

    case ZoneActionTypes.FETCH_ZONES_SUCCESS:
      return {
        ...state,
        isRequestPending: false,
        data: zoneDataReducer(state.data, action)
      };

    case ZoneActionTypes.FETCH_ZONES_ERROR:
      return {
        ...state,
        isRequestPending: false
      };

    case ZoneActionTypes.FETCH_ZONE_REQUEST:
      return {
        ...state,
        isRequestPending: true
      };

    case ZoneActionTypes.FETCH_ZONE_SUCCESS:
      return {
        ...state,
        isRequestPending: false,
        data: zoneDataReducer(state.data, action)
      };

    case ZoneActionTypes.FETCH_ZONE_ERROR:
      return {
        ...state,
        isRequestPending: false
      };

    case ZoneActionTypes.CREATE_ZONE_REQUEST:
      return {
        ...state,
        isRequestPending: true
      };

    case ZoneActionTypes.CREATE_ZONE_SUCCESS:
      return {
        ...state,
        isRequestPending: false,
        data: zoneDataReducer(state.data, action)
      };

    case ZoneActionTypes.CREATE_ZONE_ERROR:
      return {
        ...state,
        isRequestPending: false
      };

    case ZoneActionTypes.UPDATE_ZONE_REQUEST:
      return {
        ...state,
        isRequestPending: true
      };

    case ZoneActionTypes.UPDATE_ZONE_SUCCESS:
      return {
        ...state,
        isRequestPending: false,
        data: zoneDataReducer(state.data, action)
      };

    case ZoneActionTypes.UPDATE_ZONE_ERROR:
      return {
        ...state,
        isRequestPending: false
      };

    case ZoneActionTypes.DELETE_ZONE_REQUEST:
      return {
        ...state,
        isRequestPending: true
      };

    case ZoneActionTypes.DELETE_ZONE_SUCCESS:
      return {
        ...state,
        isRequestPending: false,
        data: zoneDataReducer(state.data, action)
      };

    case ZoneActionTypes.DELETE_ZONE_ERROR:
      return {
        ...state,
        isRequestPending: false
      };

    default:
      return state;
  }
};

export default zoneReducer;
