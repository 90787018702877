import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import useRefreshFetchReducer from '../../shared/Hooks/useRefreshFetchReducer';
import { fetchAllPrimaryOrders } from '../../redux/primary-order/primary-order.actions';
import {
  isPrimaryOrderRequestPending,
  getAllPrimaryOrders
} from '../../redux/primary-order/primary-order.selector';
import { getCancelledState } from '../../redux/event/event.selector';
import { resetCancel } from '../../redux/event/event.actions';
import PrimaryOrderTable from '../../components/PrimaryOrderTable';

const useStyles = makeStyles((theme) => ({
  content: {
    marginTop: theme.spacing(2)
  }
}));

function PrimaryOrder(props) {
  const {
    fetchAllPrimaryOrdersConnect,
    primaryOrdersPayload,
    isPrimaryOrderLoading,
    resetCancelConnect,
    isCancelledEvent
  } = props;
  const classes = useStyles();

  useRefreshFetchReducer(
    fetchAllPrimaryOrdersConnect,
    resetCancelConnect,
    isCancelledEvent
  );

  return (
    <div className={classes.content}>
      <PrimaryOrderTable
        orders={primaryOrdersPayload}
        isOrderLoading={isPrimaryOrderLoading}
      />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    isPrimaryOrderLoading: isPrimaryOrderRequestPending(state),
    primaryOrdersPayload: getAllPrimaryOrders(state),
    isCancelledEvent: getCancelledState(state)
  };
};

const mapDispatchToProps = {
  fetchAllPrimaryOrdersConnect: fetchAllPrimaryOrders,
  resetCancelConnect: resetCancel
};

export default connect(mapStateToProps, mapDispatchToProps)(PrimaryOrder);
