import * as AuthActionTypes from './auth.types';

const initialState = {
  token: localStorage.getItem('token'),
  isAuthenticated: null,
  isRequestPending: false,
  currentUser: null
};

const authReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case AuthActionTypes.AUTH_REQUEST:
      return {
        ...state,
        isRequestPending: true
      };

    case AuthActionTypes.AUTH_SUCCESS:
      return {
        ...state,
        token: payload,
        isAuthenticated: true,
        isRequestPending: false
      };

    case AuthActionTypes.CURRENT_USER_LOADED:
      return {
        ...state,
        isAuthenticated: true,
        isRequestPending: false,
        currentUser: payload
      };

    case AuthActionTypes.AUTH_ERROR:
    case AuthActionTypes.AUTH_LOGOUT:
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        isRequestPending: false,
        currentUser: null
      };
    default:
      return state;
  }
};

export default authReducer;
