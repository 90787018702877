import _ from 'lodash';
import * as RetailerActionTypes from './retailer.types';

const initialState = {
  data: {},
  isRequestPending: false,
  isRetailerInventoryModalRequestPending: false
};

const retailerDataReducer = (state = {}, action) => {
  const { payload, type } = action;

  switch (type) {
    case RetailerActionTypes.FETCH_RETAILERS_SUCCESS:
      return { ..._.mapKeys(payload, 'id') };

    case RetailerActionTypes.FETCH_RETAILER_SUCCESS:
      return { ...state, [payload.id]: payload };

    case RetailerActionTypes.FETCH_RETAILER_INVENTORY_SUCCESS:
      return {
        ...state,
        [payload.id]: {
          ...state[payload.id],
          sku_items: { ..._.mapKeys(payload.sku_items, 'inventory_id') }
        }
      };

    case RetailerActionTypes.CREATE_RETAILER_INVENTORY_SUCCESS:
    case RetailerActionTypes.UPDATE_RETAILER_INVENTORY_SUCCESS:
      return {
        ...state,
        [payload.retailer]: {
          ...state[payload.retailer],
          sku_items: {
            ...state[payload.retailer].sku_items,
            [payload.inventory_id]: payload
          }
        }
      };

    case RetailerActionTypes.CREATE_RETAILER_SUCCESS:
      return { ...state, [payload.id]: payload };

    case RetailerActionTypes.UPDATE_RETAILER_SUCCESS:
      return { ...state, [payload.id]: payload };

    case RetailerActionTypes.DELETE_RETAILER_SUCCESS:
      return _.omit(state, payload);

    default:
      return state;
  }
};
const retailerReducer = (state = initialState, action) => {
  const { type } = action;

  switch (type) {
    case RetailerActionTypes.FETCH_RETAILERS_REQUEST:
      return {
        ...state,
        isRequestPending: true
      };

    case RetailerActionTypes.FETCH_RETAILERS_SUCCESS:
      return {
        ...state,
        isRequestPending: false,
        data: retailerDataReducer(state.data, action)
      };

    case RetailerActionTypes.FETCH_RETAILERS_ERROR:
      return {
        ...state,
        isRequestPending: false
      };

    case RetailerActionTypes.FETCH_RETAILER_REQUEST:
      return {
        ...state,
        isRequestPending: true
      };

    case RetailerActionTypes.FETCH_RETAILER_SUCCESS:
      return {
        ...state,
        isRequestPending: false,
        data: retailerDataReducer(state.data, action)
      };

    case RetailerActionTypes.FETCH_RETAILER_ERROR:
      return {
        ...state,
        isRequestPending: false
      };

    case RetailerActionTypes.FETCH_RETAILER_INVENTORY_REQUEST:
      return {
        ...state,
        isRequestPending: true
      };

    case RetailerActionTypes.FETCH_RETAILER_INVENTORY_SUCCESS:
      return {
        ...state,
        isRequestPending: false,
        data: retailerDataReducer(state.data, action)
      };

    case RetailerActionTypes.FETCH_RETAILER_INVENTORY_ERROR:
      return {
        ...state,
        isRequestPending: false
      };

    case RetailerActionTypes.CREATE_RETAILER_REQUEST:
      return {
        ...state,
        isRequestPending: true
      };

    case RetailerActionTypes.CREATE_RETAILER_SUCCESS:
      return {
        ...state,
        isRequestPending: false,
        data: retailerDataReducer(state.data, action)
      };

    case RetailerActionTypes.CREATE_RETAILER_ERROR:
      return {
        ...state,
        isRequestPending: false
      };

    case RetailerActionTypes.CREATE_RETAILER_INVENTORY_REQUEST:
      return {
        ...state,
        isRetailerInventoryModalRequestPending: true
      };

    case RetailerActionTypes.CREATE_RETAILER_INVENTORY_SUCCESS:
      return {
        ...state,
        isRetailerInventoryModalRequestPending: false,
        data: retailerDataReducer(state.data, action)
      };

    case RetailerActionTypes.CREATE_RETAILER_INVENTORY_ERROR:
      return {
        ...state,
        isRetailerInventoryModalRequestPending: false
      };

    case RetailerActionTypes.UPDATE_RETAILER_REQUEST:
      return {
        ...state,
        isRequestPending: true
      };

    case RetailerActionTypes.UPDATE_RETAILER_SUCCESS:
      return {
        ...state,
        isRequestPending: false,
        data: retailerDataReducer(state.data, action)
      };

    case RetailerActionTypes.UPDATE_RETAILER_ERROR:
      return {
        ...state,
        isRequestPending: false
      };

    case RetailerActionTypes.UPDATE_RETAILER_INVENTORY_REQUEST:
      return {
        ...state,
        isRetailerInventoryModalRequestPending: true
      };

    case RetailerActionTypes.UPDATE_RETAILER_INVENTORY_SUCCESS:
      return {
        ...state,
        isRetailerInventoryModalRequestPending: false,
        data: retailerDataReducer(state.data, action)
      };

    case RetailerActionTypes.UPDATE_RETAILER_INVENTORY_ERROR:
      return {
        ...state,
        isRetailerInventoryModalRequestPending: false
      };

    case RetailerActionTypes.DELETE_RETAILER_REQUEST:
      return {
        ...state,
        isRequestPending: true
      };

    case RetailerActionTypes.DELETE_RETAILER_SUCCESS:
      return {
        ...state,
        isRequestPending: false,
        data: retailerDataReducer(state.data, action)
      };

    case RetailerActionTypes.DELETE_RETAILER_ERROR:
      return {
        ...state,
        isRequestPending: false
      };

    default:
      return state;
  }
};

export default retailerReducer;
