import _ from 'lodash';
import * as EmployeeSecondarySalesTypes from './employee-secondary-sales.types';

const initialState = {
  data: {},
  total_secondary_calls: 0,
  total_secondary_productive_calls: 0,
  isRequestPending: false,
  isRequestPendingForModal: false,
  error: {}
};

const employeeSecondarySalesDataReducer = (state = {}, action) => {
  const { payload, type } = action;

  switch (type) {
    case EmployeeSecondarySalesTypes.FETCH_EMPLOYEE_SECONDARY_SALES_SUCCESS:
      return { ..._.mapKeys(payload.secondary_activities, 'id') };

    case EmployeeSecondarySalesTypes.UPDATE_EMPLOYEE_SECONDARY_SALES_SUCCESS:
      return { ...state, [payload.id]: payload };

    case EmployeeSecondarySalesTypes.DELETE_EMPLOYEE_SECONDARY_SALES_SUCCESS:
      return _.omit(state, payload);

    default:
      return state;
  }
};

const employeeSecondarySalesReducer = (state = initialState, action) => {
  const { type } = action;

  switch (type) {
    case EmployeeSecondarySalesTypes.FETCH_EMPLOYEE_SECONDARY_SALES_REQUEST:
    case EmployeeSecondarySalesTypes.DELETE_EMPLOYEE_SECONDARY_SALES_REQUEST:
      return {
        ...state,
        isRequestPending: true
      };

    case EmployeeSecondarySalesTypes.FETCH_EMPLOYEE_SECONDARY_SALES_SUCCESS:
      return {
        ...state,
        isRequestPending: false,
        data: employeeSecondarySalesDataReducer(state.data, action)
      };

    case EmployeeSecondarySalesTypes.FETCH_EMPLOYEE_SECONDARY_SALES_ERROR:
    case EmployeeSecondarySalesTypes.DELETE_EMPLOYEE_SECONDARY_SALES_ERROR:
      return {
        ...state,
        isRequestPending: false,
        error: action.error
      };

    case EmployeeSecondarySalesTypes.UPDATE_EMPLOYEE_SECONDARY_SALES_REQUEST:
      return {
        ...state,
        isRequestPendingForModal: true
      };

    case EmployeeSecondarySalesTypes.UPDATE_EMPLOYEE_SECONDARY_SALES_SUCCESS:
      return {
        ...state,
        isRequestPendingForModal: true,
        data: employeeSecondarySalesDataReducer(state.data, action)
      };

    case EmployeeSecondarySalesTypes.UPDATE_EMPLOYEE_SECONDARY_SALES_ERROR:
      return {
        ...state,
        isRequestPendingForModal: false,
        error: action.error
      };

    case EmployeeSecondarySalesTypes.RESET_EMPLOYEE_SECONDARY_SALES_DATA:
      return {
        ...state,
        data: {}
      };

    case EmployeeSecondarySalesTypes.RESET_EMPLOYEE_SECONDARY_SALES_ERROR:
      return {
        ...state,
        isRequestPending: false,
        isRequestPendingForModal: false,
        error: {}
      };

    default:
      return state;
  }
};

export default employeeSecondarySalesReducer;
