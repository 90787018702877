import React, { useState } from 'react';
import { get, isEmpty, size } from 'lodash';
import FormInput from '../FormInput';
import formStyles from '../Styles/FormStyles';
import FormCancelButton from '../FormCancelButton';
import FormSubmitButton from '../FormSubmitButton';
import useForm from '../../shared/Hooks/useForm';
import SkuFormValidator from '../../pages/Sku/SkuFormValidator';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  InputAdornment,
  RadioGroup
} from '@material-ui/core';
import { INR_SYMBOL } from '../../shared/Constants/Constant';
import { GreenRadio } from '../Styles/ComponentStyles';
import createSkuFormStyles from '../CreateSkuForm/CreateSkuForm.css';
import {
  exportNormalizedFormValues,
  reformatSuperStockistFormValues
} from './EditSkuForm.utils';
import FormAutoComplete from '../FormAutoComplete';

const getParentProductItem = (sku) => {
  return isEmpty(sku.parent_product) ? null : get(sku, 'parent_product', null);
};

function EditSkuForm(props) {
  const { sku, onCancel, onSubmit, parentProductsPayload = [] } = props;

  const classes = formStyles();
  const componentClasses = createSkuFormStyles();
  const [selectedParentProductItem, setSelectedParentProductItem] = useState(
    getParentProductItem(sku)
  );
  const [parentProductError, setParentProductError] = useState('');
  const [skuType, setSkuType] = useState(sku.sku_type);

  const onRadioValueChange = (event) => {
    const { value } = event.target;
    if (parentProductError) {
      setParentProductError('');
    }
    setSkuType(value);
  };

  const handleFormSubmit = () => {
    if (!selectedParentProductItem && skuType === 'single') {
      return setParentProductError('** Parent Product is required');
    }
    const formValues = exportNormalizedFormValues(
      {
        values,
        skuType,
        parentProductItem: selectedParentProductItem
      },
      sku
    );
    console.log(formValues);
    if (size(formValues) > 0) {
      onSubmit(formValues);
    }
  };

  const reFormatDetails = (sku) => {
    return reformatSuperStockistFormValues(sku);
  };

  const handleParentProductSelection = (value) => {
    if (parentProductError) {
      setParentProductError('');
    }

    setSelectedParentProductItem(value);
  };

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    handleDecimalInputChange,
    handleNumericInputChange
  } = useForm(handleFormSubmit, SkuFormValidator, {
    initialValues: reFormatDetails(sku)
  });

  return (
    <form className={classes.form} noValidate autoComplete="off">
      <FormInput
        type="text"
        label="Sku"
        placeholder="Enter a Sku Number"
        name="sku"
        value={values.sku || ''}
        onChange={handleChange}
        error={!!errors.sku}
        helperText={errors.sku || ''}
      />

      <FormAutoComplete
        data={parentProductsPayload}
        label="Parent Product"
        name="parentProduct"
        value={selectedParentProductItem}
        onChangeSelect={(e, newValue) => handleParentProductSelection(newValue)}
        error={!!parentProductError}
        helperText={parentProductError}
        property="name"
      />

      <FormInput
        type="text"
        label="Name"
        placeholder="Enter the Name"
        name="name"
        value={values.name || ''}
        onChange={handleChange}
        helperText={errors.name || ''}
        error={!!errors.name}
      />

      <div className={componentClasses.skuTypeRadioContainer}>
        <FormLabel>Sku Type</FormLabel>
        <FormControl component="fieldset">
          <RadioGroup
            aria-label="skuType"
            name="skuType"
            onChange={onRadioValueChange}
            value={skuType}
            row
          >
            <FormControlLabel
              checked={skuType === 'single'}
              value="single"
              control={<GreenRadio />}
              label="Single"
            />
            <FormControlLabel
              checked={skuType === 'combo'}
              value="combo"
              control={<GreenRadio />}
              label="Combo"
            />
          </RadioGroup>
        </FormControl>
      </div>

      <FormInput
        type="text"
        label="MRP"
        placeholder="Enter the MRP Price"
        name="mrp"
        value={values.mrp || ''}
        onChange={handleDecimalInputChange}
        helperText={errors.mrp || ''}
        error={!!errors.mrp}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">{INR_SYMBOL}</InputAdornment>
          )
        }}
      />

      <FormInput
        type="text"
        label="Special Price"
        placeholder="Enter the Special Price"
        name="special_selling_price"
        value={values.special_selling_price || ''}
        onChange={handleDecimalInputChange}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">{INR_SYMBOL}</InputAdornment>
          )
        }}
      />

      <FormInput
        type="text"
        label="Pieces per Carton"
        placeholder="Enter the Pieces/Ctn"
        name="pieces_per_carton"
        value={values.pieces_per_carton || ''}
        onChange={handleNumericInputChange}
        helperText={errors.pieces_per_carton || ''}
        error={!!errors.pieces_per_carton}
        InputProps={{
          endAdornment: <InputAdornment position="end">per case</InputAdornment>
        }}
      />

      <FormInput
        type="text"
        label="Super Stockist Margin"
        placeholder="Enter the Super Stockist Margin in %"
        name="super_stockist_margin"
        value={values.super_stockist_margin || ''}
        onChange={handleDecimalInputChange}
        helperText={errors.super_stockist_margin || ''}
        error={!!errors.super_stockist_margin}
        InputProps={{
          endAdornment: <InputAdornment position="end">%</InputAdornment>
        }}
      />

      <FormInput
        type="text"
        label="Distributor Margin"
        placeholder="Enter the Distributor Margin in %"
        name="distributor_margin"
        value={values.distributor_margin || ''}
        onChange={handleDecimalInputChange}
        helperText={errors.distributor_margin || ''}
        error={!!errors.distributor_margin}
        InputProps={{
          endAdornment: <InputAdornment position="end">%</InputAdornment>
        }}
      />

      <FormInput
        type="text"
        label="Retailer Margin"
        placeholder="Enter the Retailer Margin in %"
        name="retailer_margin"
        value={values.retailer_margin || ''}
        onChange={handleDecimalInputChange}
        helperText={errors.retailer_margin || ''}
        error={!!errors.retailer_margin}
        InputProps={{
          endAdornment: <InputAdornment position="end">%</InputAdornment>
        }}
      />

      <FormInput
        type="text"
        label="SGST"
        placeholder="Enter the SGST in %"
        name="sgst"
        value={values.sgst || ''}
        onChange={handleDecimalInputChange}
        helperText={errors.sgst || ''}
        error={!!errors.sgst}
        InputProps={{
          endAdornment: <InputAdornment position="end">%</InputAdornment>
        }}
      />

      <FormInput
        type="text"
        label="CGST"
        placeholder="Enter the CGST in %"
        name="cgst"
        value={values.cgst || ''}
        onChange={handleDecimalInputChange}
        helperText={errors.cgst || ''}
        error={!!errors.cgst}
        InputProps={{
          endAdornment: <InputAdornment position="end">%</InputAdornment>
        }}
      />

      <FormInput
        type="text"
        label="IGST"
        placeholder="Enter the IGST in %"
        name="igst"
        value={values.igst || ''}
        onChange={handleDecimalInputChange}
        helperText={errors.igst || ''}
        error={!!errors.igst}
        InputProps={{
          endAdornment: <InputAdornment position="end">%</InputAdornment>
        }}
      />

      <div className={classes.buttonRow}>
        <FormCancelButton onClickCancel={onCancel} />
        <FormSubmitButton onClickSubmit={handleSubmit} />
      </div>
    </form>
  );
}

export default React.memo(EditSkuForm);
