import React, { useState } from 'react';
import FormInput from '../FormInput';
import formStyles from '../Styles/FormStyles';
import FormCancelButton from '../FormCancelButton';
import FormSubmitButton from '../FormSubmitButton';
import useForm from '../../shared/Hooks/useForm';
import DistrictFormValidator from '../../pages/District/DistrictFormValidator';
import FormAutoComplete from '../FormAutoComplete';

function CreateDistrictForm({ onSubmit, onCancel, zones = [] }) {
  const classes = formStyles();

  const [zoneItem, setZoneItem] = useState(null);

  const normalizeFormData = () => {
    return {
      name: values.name,
      zone: zoneItem.id
    };
  };

  const onChangeZoneSelection = (e, value) => {
    e.persist();
    setZoneItem(value);
  };

  const handleFormSubmit = () => {
    onSubmit(normalizeFormData(), resetValues);
  };

  const { values, errors, handleChange, handleSubmit, resetValues } = useForm(
    handleFormSubmit,
    DistrictFormValidator
  );

  const isValidForm = () => {
    return values.name && zoneItem;
  };

  return (
    <form className={classes.form} noValidate autoComplete="off">
      <FormAutoComplete
        data={zones}
        label="Select Zone"
        name="zone"
        value={zoneItem}
        onChangeSelect={(e, newValue) => onChangeZoneSelection(e, newValue)}
        property="name"
        autoFocus
        required
      />

      <FormInput
        type="text"
        label="District"
        placeholder="Enter a District"
        name="name"
        value={values.name || ''}
        onChange={handleChange}
        helperText={errors.name || ''}
        error={!!errors.name}
        required
      />

      <div className={classes.buttonRow}>
        <FormCancelButton onClickCancel={onCancel} />
        <FormSubmitButton
          onClickSubmit={handleSubmit}
          disabled={!isValidForm()}
        />
      </div>
    </form>
  );
}

export default React.memo(CreateDistrictForm);
