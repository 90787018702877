import React from 'react';
import { connect } from 'react-redux';
import CreateToolBar from '../../shared/CreateToolBar';
import AreaTable from '../../components/AreaTable';
import tableStyles from '../../components/Styles/TableStyles';
import useRefreshFetchReducer from '../../shared/Hooks/useRefreshFetchReducer';
import { ROUTE_TO_CREATE_AREA } from '../../shared/Constants/RouteConstants';
import { TITLES } from '../../shared/Constants/Constant';
import { fetchAllAreas } from '../../redux/area/area.actions';
import {
  getAllAreas,
  isAreaRequestPending
} from '../../redux/area/area.selector';
import { getCancelledState } from '../../redux/event/event.selector';
import { resetCancel } from '../../redux/event/event.actions';
import { currentUserRole, currentUserId } from '../../redux/auth/auth.selector';
import { AREA_CREATE_ACTION_ALLOWED_USERS } from '../../shared/Constants/UserPermissons';

function Area(props) {
  const classes = tableStyles();
  const {
    areasPayload,
    fetchAllAreasConnect,
    isAreaLoading,
    resetCancelConnect,
    isCancelledEvent,
    authUserRole,
    authUserId
  } = props;

  useRefreshFetchReducer(
    fetchAllAreasConnect,
    resetCancelConnect,
    isCancelledEvent
  );

  return (
    <>
      <CreateToolBar
        route={ROUTE_TO_CREATE_AREA}
        buttonText={TITLES.CREATE_AREA}
        showSpinner={isAreaLoading}
        isAuthenticatedToAdd={AREA_CREATE_ACTION_ALLOWED_USERS.includes(
          authUserRole
        )}
      />
      <div className={classes.content}>
        <AreaTable
          areas={areasPayload}
          isAreaLoading={isAreaLoading}
          currentUserRole={authUserRole}
          currentUserId={authUserId}
        />
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    areasPayload: getAllAreas(state),
    isAreaLoading: isAreaRequestPending(state),
    isCancelledEvent: getCancelledState(state),
    authUserRole: currentUserRole(state),
    authUserId: currentUserId(state)
  };
};

const mapDispatchToProps = {
  fetchAllAreasConnect: fetchAllAreas,
  resetCancelConnect: resetCancel
};

export default connect(mapStateToProps, mapDispatchToProps)(Area);
