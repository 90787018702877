import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Modal from '../Modal';
import history from '../../routes/history';

function Error() {
  const isOpen = useSelector((state) => state.error.showError);
  const errorMessage = useSelector((state) => state.error.errorMessage);
  const errorType = useSelector((state) => state.error.errorType);
  const route = useSelector((state) => state.route.nextLocation);

  const dispatch = useDispatch();

  function handleClose() {
    dispatch({ type: 'HIDE_ERROR' });
    dispatch({ type: 'CLEAR_ROUTE' });
    if (
      (errorType === 'NOT_FOUND' || errorType === 'INVALID_OPERATION') &&
      route
    ) {
      history.push(route);
    }
  }

  const errorActions = [
    {
      buttonText: 'Ok',
      buttonAction: handleClose,
      buttonStyle: { backgroundColor: '#4caf50', color: '#fff' }
    }
  ];

  return (
    isOpen &&
    errorMessage && (
      <Modal
        title="ERROR"
        isModalVisible={isOpen}
        actions={errorActions}
        content={errorMessage}
      />
    )
  );
}

export default Error;
