import { createSelector } from 'reselect';

const getUsersState = (state) => state.users;
const getAllUsersDataSelector = (state) => state.users.data;
const getUserByIdSelector = (state, props) => props.match.params.id;

export const getAllUsers = createSelector(getAllUsersDataSelector, (users) =>
  Object.values(users)
);

export const getAllUsersAttendanceData = createSelector(
  getUsersState,
  (users) => Object.values(users.attendanceData)
);

export const isUserRequestPending = createSelector(
  [getUsersState],
  (users) => users.isRequestPending
);

export const getUserById = createSelector(
  getAllUsersDataSelector,
  getUserByIdSelector,
  (users, id) => users[id]
);
