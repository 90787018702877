import React from 'react';
import { Radio } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/styles';

const formStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1)
  },
  dynamicFormButton: {
    margin: theme.spacing(3, 0, 1)
  },
  dynamicFormRemoveContactButton: {
    backgroundColor: '#f44336',
    margin: '5px',
    '&:hover': {
      backgroundColor: '#902020',
      color: '#FFF'
    }
  },
  fragranceList: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap'
  },
  buttonRow: {
    marginTop: '10px',
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: ' space-between'
  },
  buttonStyle: {
    margin: '2px',
    textDecoration: 'none'
  },
  cancelButtonStyle: {
    margin: '2px',
    textDecoration: 'none',
    color: '#FFF',
    backgroundColor: '#4caf50',
    '&:hover': {
      backgroundColor: '#507a18',
      color: '#FFF'
    }
  },
  fullWidthButton: {
    submit: {
      margin: theme.spacing(3, 0, 2)
    }
  }
}));

export default formStyles;
