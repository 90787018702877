import baseURL from '../shared/AxiosBaseUrl/baseURL';

class SkuAPI {
  static getAllSkus() {
    return baseURL.get('/api/v1/sku');
  }

  static getSku(id) {
    return baseURL.get(`/api/v1/sku/${id}`);
  }

  static getComboSku(id) {
    return baseURL.get(`/api/v1/sku/combo/${id}`);
  }

  static createSku(formValues) {
    return baseURL.post('/api/v1/sku', { ...formValues });
  }

  static createComboSku(formValues) {
    return baseURL.post('/api/v1/sku/combo', { ...formValues });
  }

  static editSku(id, formValues) {
    return baseURL.patch(`/api/v1/sku/${id}`, { ...formValues });
  }

  static editComboSku(id, formValues) {
    return baseURL.patch(`/api/v1/sku/combo/${id}`, { ...formValues });
  }

  static deleteSku(id) {
    return baseURL.delete(`/api/v1/sku/${id}`);
  }

  static deleteComboSku(id) {
    return baseURL.delete(`/api/v1/sku/combo/${id}`);
  }
}

export default SkuAPI;
