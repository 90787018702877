import React from 'react';
import {
  Button,
  Divider,
  InputAdornment,
  FormHelperText
} from '@material-ui/core';
import formStyles from '../Styles/FormStyles';
import FormInput from '../FormInput';
import FormSubHeader from '../FormSubHeader';
import { ONLY_NUMBER_REGEX } from '../../shared/Constants/Constant';
import { areValidCustomerBrands } from '../../shared/Utils/CommonUtils';

function CurrentBrandsDealingForm(props) {
  const {
    brands,
    handleInputChange,
    handleRemove,
    handleAdd,
    subHeaderTitle,
    errorMessage
  } = props;
  const classes = formStyles();

  const handleNumericInputChange = (event, index) => {
    event.persist();
    const { value } = event.target;
    if (value === '' || value.match(ONLY_NUMBER_REGEX) != null) {
      handleInputChange(event, index);
    }
  };

  return (
    <>
      <FormSubHeader text={subHeaderTitle} />
      {brands.map((brand, index) => {
        const id = `current-brands-dealing-with-${index}`;
        return (
          <div key={id}>
            <FormInput
              type="text"
              label="Brand Name"
              placeholder="Enter the Brand Name"
              name="brand_name"
              value={brand.brand_name || ''}
              onChange={(e) => handleInputChange(e, index)}
            />

            <FormInput
              type="text"
              label="Turn Over per month"
              placeholder="Enter the turn over per month"
              name="turn_over_amount"
              value={brand.turn_over_amount || ''}
              onChange={(e) => handleNumericInputChange(e, index)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">lakhs/month</InputAdornment>
                )
              }}
            />

            <FormInput
              type="text"
              label="Brand Dealing Time"
              placeholder="Enter the brand dealing period"
              name="brand_dealing_time_period"
              value={brand.brand_dealing_time_period || ''}
              onChange={(e) => handleNumericInputChange(e, index)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">months</InputAdornment>
                )
              }}
              helperText={`** ${(
                Number(brand.brand_dealing_time_period || 0) / 12
              ).toFixed(2)} years`}
            />

            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end'
              }}
            >
              <Button
                type="button"
                variant="contained"
                color="primary"
                margin="normal"
                size="small"
                onClick={() => handleRemove(index)}
                className={classes.dynamicFormRemoveContactButton}
              >
                Remove
              </Button>
            </div>
            <Divider
              variant="middle"
              style={{ color: '#FF0000', margin: '10px' }}
            />
          </div>
        );
      })}
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end'
        }}
      >
        <Button
          type="button"
          variant="contained"
          align="left"
          margin="normal"
          color="primary"
          fullWidth
          onClick={handleAdd}
          disabled={!areValidCustomerBrands(brands)}
          className={classes.dynamicFormButton}
        >
          Click to Add Brands Dealing With
        </Button>
      </div>

      <FormHelperText style={{ color: '#d50000' }}>
        {Boolean(errorMessage) && errorMessage}
      </FormHelperText>
    </>
  );
}

export default CurrentBrandsDealingForm;
