import React from 'react';
import { connect } from 'react-redux';
import { Container, Avatar, Typography } from '@material-ui/core';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import formStyles from '../../../components/Styles/FormStyles';
import EditSkuForm from '../../../components/EditSkuForm';
import { TITLES } from '../../../shared/Constants/Constant';
import { fetchAllProducts } from '../../../redux/product/product.actions';
import {
  getAllProducts,
  isProductRequestPending
} from '../../../redux/product/product.selector';
import {
  fetchSku,
  editSku,
  fetchAllSkus
} from '../../../redux/sku/sku.actions';
import {
  getSkuById,
  isSkuRequestPending,
  getAllSkus
} from '../../../redux/sku/sku.selector';
import history from '../../../routes/history';
import { ROUTE_TO_SKU } from '../../../shared/Constants/RouteConstants';
import { setCancel } from '../../../redux/event/event.actions';
import BackdropWithLoader from '../../../components/BackdropWithLoader';
import useRefreshFetchReducer from '../../../shared/Hooks/useRefreshFetchReducer';
import useFetchReducerWithId from '../../../shared/Hooks/useFetchReducerWithId';

const EditSku = (props) => {
  const classes = formStyles();
  const {
    fetchAllProductsConnect,
    fetchSkuConnect,
    productsPayload,
    editSkuConnect,
    sku,
    match: {
      params: { id }
    },
    setCancelConnect,
    isSkusLoading,
    isProductsLoading,
    fetchAllSkusConnect,
    skusPayload
  } = props;

  const skuId = id;

  useFetchReducerWithId(fetchSkuConnect, skuId, sku);
  useRefreshFetchReducer(fetchAllProductsConnect);
  useRefreshFetchReducer(fetchAllSkusConnect);

  const handleSubmit = (formValues) => {
    editSkuConnect(skuId, formValues);
  };

  const handleCancel = () => {
    setCancelConnect();
    history.push(ROUTE_TO_SKU);
  };

  if (isSkusLoading || isProductsLoading) {
    return <BackdropWithLoader />;
  }

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <ShoppingBasketIcon color="primary" />
        </Avatar>
        <Typography component="h1" variant="h5">
          {TITLES.EDIT_SKU}
        </Typography>
        <EditSkuForm
          skusPayload={skusPayload}
          parentProductsPayload={productsPayload}
          sku={sku}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
        />
      </div>
    </Container>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    isSkusLoading: isSkuRequestPending(state),
    isProductsLoading: isProductRequestPending(state),
    productsPayload: getAllProducts(state),
    sku: getSkuById(state, ownProps),
    skusPayload: getAllSkus(state)
  };
};

const mapDispatchToProps = {
  fetchSkuConnect: fetchSku,
  editSkuConnect: editSku,
  fetchAllSkusConnect: fetchAllSkus,
  fetchAllProductsConnect: fetchAllProducts,
  setCancelConnect: setCancel
};

export default connect(mapStateToProps, mapDispatchToProps)(EditSku);
