import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Avatar,
  CircularProgress,
  MenuItem
} from '@material-ui/core';
import { connect } from 'react-redux';
import { LocalShipping } from '@material-ui/icons';
import commonStyles from '../../../Styles/CommonStyles';
import FormSelect from '../../FormSelect';
import MaterialButton from '../../MaterialButton';
import { editSecondPrimaryOrder } from '../../../redux/second-primary-order/second-primary-order.actions';
import { isSecondPrimaryOrderModalRequestPending } from '../../../redux/second-primary-order/second-primary-order.selector';
import { isEqual } from 'lodash';
import {
  SECOND_PRIMARY_ORDER_CONSTANTS,
  SECOND_PRIMARY_ORDER_STATUS
} from '../../../shared/Constants/Constant';

function EditSecondPrimaryOrderStatus(props) {
  const {
    open,
    handleClose,
    order,
    isRequestPending,
    editSecondPrimaryOrderConnect,
    errorMessage
  } = props;
  const classes = commonStyles();
  const [orderStatus, setOrderStatus] = useState(props.order.status);

  const handleModalClose = () => {
    setOrderStatus(props.order.status);
    handleClose();
  };

  useEffect(() => {
    setOrderStatus(props.order.status);
  }, [props.order.status]);

  const isOrderStatusChanged = () => {
    return !isEqual(orderStatus, order.status);
  };

  const updateShipmentSubmit = () => {
    if (isOrderStatusChanged()) {
      editSecondPrimaryOrderConnect(
        {
          secondPrimaryOrderId: order.id,
          orderDetails: {
            status: orderStatus
          }
        },
        handleModalClose
      );
    }
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleModalClose}
        classes={{
          paperWidthSm: classes.paperWidthSm
        }}
      >
        <DialogTitle id="form-dialog-title">
          <Avatar className={classes.avatar}>
            <LocalShipping color="primary" />
          </Avatar>
          <Typography align="center">
            Update Second Primary Order Status
          </Typography>

          {isRequestPending && (
            <div className={classes.modalSpinner}>
              <CircularProgress />
            </div>
          )}
        </DialogTitle>
        <DialogContent>
          <FormSelect
            label="SecondPrimary Order Status"
            name="orderStatus"
            value={orderStatus}
            onChange={(e) => setOrderStatus(e.target.value)}
            showDefaultEmptyValue={false}
          >
            <MenuItem value={SECOND_PRIMARY_ORDER_CONSTANTS.ORDERED} disabled>
              {SECOND_PRIMARY_ORDER_STATUS.ORDERED}
            </MenuItem>
            <MenuItem value={SECOND_PRIMARY_ORDER_CONSTANTS.ORDER_APPROVED}>
              {SECOND_PRIMARY_ORDER_STATUS.ORDER_APPROVED}
            </MenuItem>
            <MenuItem
              value={
                SECOND_PRIMARY_ORDER_CONSTANTS.ORDER_CANCELLED_BY_SUPER_STOCKIST
              }
            >
              {SECOND_PRIMARY_ORDER_STATUS.ORDER_CANCELLED_BY_SUPER_STOCKIST}
            </MenuItem>
            <MenuItem
              value={
                SECOND_PRIMARY_ORDER_CONSTANTS.ORDER_CANCELLED_BY_DISTRIBUTOR
              }
            >
              {SECOND_PRIMARY_ORDER_STATUS.ORDER_CANCELLED_BY_DISTRIBUTOR}
            </MenuItem>

            {props.order.status !== SECOND_PRIMARY_ORDER_CONSTANTS.ORDERED && (
              <MenuItem
                value={SECOND_PRIMARY_ORDER_CONSTANTS.REACHED_DESTINATION}
              >
                {SECOND_PRIMARY_ORDER_STATUS.REACHED_DESTINATION}
              </MenuItem>
            )}
          </FormSelect>

          {!!errorMessage && (
            <Typography variant="h5" style={{ color: '#d50000', fontSize: 13 }}>
              {`**${errorMessage}`}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <MaterialButton
            handleClick={handleModalClose}
            buttonText="Cancel"
            disabled={isRequestPending}
          />
          <MaterialButton
            handleClick={updateShipmentSubmit}
            buttonText="Confirm"
            disabled={isRequestPending || !isOrderStatusChanged()}
          />
        </DialogActions>
      </Dialog>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    isRequestPending: isSecondPrimaryOrderModalRequestPending(state)
  };
};

const mapDispatchToProps = {
  editSecondPrimaryOrderConnect: editSecondPrimaryOrder
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditSecondPrimaryOrderStatus);
